import styles from './styles.module.scss';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography } from '@mui/material';
import { groupBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryClassPicker } from '@work4all/components/lib/components/entity-picker/category-class-picker/CategoryClassPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { useDataMutation } from '@work4all/data';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { InputAnsprechpartnerRelation } from '@work4all/models/lib/Classes/InputAnsprechpartnerRelation.entity';
import { InputKundeRelation } from '@work4all/models/lib/Classes/InputKundeRelation.entity';
import { InputLieferantRelation } from '@work4all/models/lib/Classes/InputLieferantRelation.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BusinessPartners } from '../../../../mask-overlays/mask-overlay/views/businessPartners/BusinessPartnerOverlayController';

import { InlineDataList } from './InlineDataList';

type ICategoryCardProps = {
  categories: Array<CategoryAssignment>;
  entity: Entities;
  id: number;
  businesspartnerType?: string;
  disabled?: boolean;
};

export const CategoryCard: FC<ICategoryCardProps> = ({
  categories: initialCategories,
  businesspartnerType,
  entity,
  id,
  disabled,
}) => {
  const { t } = useTranslation();
  const sortedCategories = (a: string, b: string) => {
    return a?.localeCompare(b);
  };

  const [updatedCategories, setUpdatedCategories] = useState<
    CategoryAssignment<EMode.entity>[]
  >([]);

  useEffect(() => {
    setUpdatedCategories(initialCategories);
  }, [initialCategories]);

  const categoryFieldRef = useRef(null);

  const prefilter = useMemo(() => {
    const typeMap = {
      [SdObjType.KUNDE]: Entities.customer,
      [SdObjType.LIEFERANT]: Entities.supplier,
    };
    const bpType: Entities = businesspartnerType
      ? typeMap[businesspartnerType]
      : entity;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let prefilter: any = [{ hide: { $eq: false } }];
    if (entity === Entities.contact) {
      if (bpType === Entities.customer) {
        prefilter = prefilter.concat([
          { isPersonCategory: { $eq: true } },
          { isCustomerCategory: { $eq: true } },
        ]);
      } else {
        prefilter = prefilter.concat([
          { isPersonCategory: { $eq: true } },
          { isSupplierCategory: { $eq: true } },
        ]);
      }
    } else {
      if (bpType === Entities.customer) {
        prefilter = prefilter.concat([
          { isFirmCategory: { $eq: true } },
          { isCustomerCategory: { $eq: true } },
        ]);
      } else {
        prefilter = prefilter.concat([
          { isFirmCategory: { $eq: true } },
          { isSupplierCategory: { $eq: true } },
        ]);
      }
    }

    return prefilter;
  }, [businesspartnerType, entity]);

  const [mutate] = useDataMutation<BusinessPartners | Contact, EMode.upsert>({
    entity: entity,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
      categoryAssignmentList: [
        {
          categoryId: null,
          id: null,
          categoryName: null,
          categoryKindName: null,
        },
      ],
    },
  });

  const mutateCategories = useCallback(() => {
    const relations:
      | InputKundeRelation
      | InputLieferantRelation
      | InputAnsprechpartnerRelation = {
      categoryMarks: {
        add: updatedCategories
          ?.filter(
            (currentCategory) =>
              !initialCategories?.find(
                (prevCategory) =>
                  currentCategory.categoryId === prevCategory.categoryId
              )
          )
          ?.map((category) => ({
            categoryCode: category.categoryId,
          })),
        remove: initialCategories
          ?.filter(
            (prevCategory) =>
              !updatedCategories?.find(
                (currentCategory) =>
                  currentCategory.categoryId === prevCategory.categoryId
              )
          )
          ?.map((categories) => categories.categoryId),
      },
    };

    const keysToMutate = { id: id, businessPartnerType: businesspartnerType };

    mutate(keysToMutate, { relations });
  }, [updatedCategories, initialCategories, id, businesspartnerType, mutate]);

  const groupedCategories = Object.entries(
    groupBy(initialCategories, 'categoryKindName')
  );
  return (
    <div>
      <div className={styles.categoryHeadWrapper}>
        <div style={{ width: '100%' }}>
          <Divider title={t('COMMON.CATEGORY_plural')} size="body" />
        </div>

        <EntityPickerPopover
          anchorEl={categoryFieldRef.current}
          disabled={disabled}
          onClose={mutateCategories}
          picker={
            <CategoryClassPicker
              multiple
              filterToCompanyCategories={entity !== Entities.contact}
              filterToContactCategories={entity === Entities.contact}
              value={
                !Array.isArray(updatedCategories)
                  ? []
                  : updatedCategories.map((categoryAssignment) => ({
                      id: categoryAssignment?.categoryId,
                    }))
              }
              prefilter={prefilter}
              onChange={(value) => {
                setUpdatedCategories(
                  value.map((cat) => {
                    return {
                      categoryId: cat.id,
                      categoryName: cat.name,
                    };
                  })
                );
              }}
            />
          }
        >
          <IconButton
            size="large"
            color="primary"
            onClick={() => ''}
            disabled={disabled}
          >
            <EditIcon />
          </IconButton>
        </EntityPickerPopover>
      </div>
      <div className={styles.categoryContentWrapper}>
        {!initialCategories || initialCategories.length === 0 ? null : (
          <InlineDataList
            items={[...groupedCategories]
              .sort((a, b) => sortedCategories(a[0], b[0]))
              .map((item) => {
                const title = item[1].map((x) => x.categoryName).join(', ');
                return {
                  id: item[0],
                  label: item[0],
                  content: (
                    <Tooltip
                      title={title}
                      color="white"
                      className={styles.tooltip}
                      placement="bottom"
                      enterDelay={1000}
                      hideOnClickIfUnhoverd
                      showOnlyOnOverflow
                    >
                      <Typography
                        variant="body2"
                        className={styles['overflow-line']}
                      >
                        {title}
                      </Typography>
                    </Tooltip>
                  ),
                };
              })}
          />
        )}
      </div>
    </div>
  );
};
