import { concat } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { SortedListItem } from '../components/SortedListItem';
import {
  EntityPickerTabbed,
  TabsConfiguration,
} from '../components/v2/EntityPickerTabbed';
import { useEntityPickerFilterResult } from '../components/v2/utils/hooks/use-entity-picker-filter-result';
import {
  PickerSettingsSortBy,
  usePickerSettings,
} from '../hooks/use-picker-settings';
import { IPickerWithTabsProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IBusinessPartnerPickerProps<TMultiple extends boolean = boolean> =
  IPickerWithTabsProps<Customer | Supplier, TMultiple> & {
    onTabSelected?: (entity: Entities) => void;
    favorites?: boolean;
  };

export function BusinessPartnerPicker<TMultiple extends boolean = boolean>(
  props: IBusinessPartnerPickerProps<TMultiple>
) {
  const {
    data,
    prefilter,
    onChange,
    filterConfig,
    onTabSelected,
    favorites = true,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(Entities.customer);

  useEffect(() => {
    onTabSelected?.(selectedTab);
  }, [selectedTab, onTabSelected]);

  const selectedTabType =
    selectedTab === Entities.customer ? 'Kunde' : 'Lieferant';

  const extendedFilterConfig = useMemo(() => {
    if (!filterConfig) return filterConfig;

    const businessPartnerTypes = {
      [Entities.customer]: SdObjType.KUNDE,
      [Entities.supplier]: SdObjType.LIEFERANT,
    };

    const typeFilter = {
      $or: [
        { businessPartnerType: { $eq: businessPartnerTypes[selectedTab] } },
      ],
    };

    const filter = concat(...[filterConfig.filter, typeFilter].filter(Boolean));

    return { ...filterConfig, filter };
  }, [filterConfig, selectedTab]);

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const normalizeValue = useCallback(
    (
      item: Customer | Supplier | BusinessPartner,
      __typename: string
    ): Customer | Supplier => {
      if (!props.filterConfig) return item as Customer | Supplier;

      return 'data' in item
        ? ({ ...item.data, __typename } as Customer | Supplier)
        : ({ ...item, __typename: item.__typename || __typename } as
            | Customer
            | Supplier);
    },
    [props.filterConfig]
  );

  const mappedChange = (value) => {
    if (!value) return onChange(value);

    const normalizedValue = Array.isArray(value)
      ? value.map((x) => normalizeValue(x, selectedTabType))
      : normalizeValue(value, selectedTabType);

    onChange(normalizedValue as Selection<Customer | Supplier, TMultiple>);
  };

  const { sortedPickerEntities } = usePickerSettings(
    selectedTabType === 'Kunde' ? Entities.customer : Entities.supplier
  );

  const renderItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      const normalizedItem = normalizeValue(item, selectedTabType);
      const number = normalizedItem.number;
      const name = normalizedItem.name;
      const extinct = normalizedItem.extinct;
      return (
        <SortedListItem
          sortedBy={sortedPickerEntities as PickerSettingsSortBy}
          name={name}
          number={number}
          website={item?.website}
          isInactive={extinct === -1}
        />
      );
    },
    [normalizeValue, selectedTabType, sortedPickerEntities]
  );

  const tabs = useMemo((): TabsConfiguration<Customer | Supplier>[] => {
    const renderCount = (count, value) => {
      if (value.id == 0) {
        return (
          <RenderCount
            entity={selectedTab}
            filterConfig={extendedFilterConfig}
            sortBy={sortedPickerEntities}
            filterBy={['name', 'number']}
          />
        );
      }
      return <>{count}</>;
    };

    return [
      {
        loadingConfig: {
          entity: Entities.customer,
          data: allFields,
          sortBy: sortedPickerEntities,
          filterBy: ['name', 'number'],
          prefilter,
          filterConfig: extendedFilterConfig,
          useSearchHistory: true,
        },
        pickerConfig: {
          label: t('COMMON.CUSTOMER'),
          placeholder: t('CUSTOMER.SEARCH_PLACEHOLDER'),
          renderItemContent: renderItem,
          renderCount,
          favorites,
          multiple: rest.multiple,
        },
      },
      {
        loadingConfig: {
          entity: Entities.supplier,
          data: allFields,
          filterBy: ['name', 'number'],
          sortBy: sortedPickerEntities,
          prefilter,
          filterConfig: extendedFilterConfig,
          useSearchHistory: true,
        },
        pickerConfig: {
          label: t('COMMON.SUPPLIER'),
          placeholder: t('SUPPLIER.SEARCH_PLACEHOLDER'),
          renderItemContent: renderItem,
          renderCount,
          favorites,
          multiple: rest.multiple,
        },
      },
    ];
  }, [
    allFields,
    extendedFilterConfig,
    favorites,
    prefilter,
    renderItem,
    rest.multiple,
    sortedPickerEntities,
    t,
  ]);

  useEffect(() => {
    props.onTabChange?.(selectedTab);
  }, [props, selectedTab]);

  return (
    <EntityPickerTabbed
      getTabIndex={getTabIndex}
      onChange={mappedChange}
      value={rest.value}
      onTabChange={setSelectedTab}
      tabs={tabs}
    />
  );
}

const FIELDS: Customer | Supplier = {
  id: null,
  name: null,
  note: null,
  number: null,
  extinct: null,
  website: null,
  isPrivateCustomer: null,
  languageId: null,
  mainContact: {
    id: null,
    name: null,
    firstName: null,
    displayName: null,
    eMail: null,
    phoneNumber: null,
    phoneNumber2: null,
    phoneNumber3: null,
    mobileNumber: null,
    mobileNumberPrivate: null,
    faxNumber: null,
    phoneNumberPrivate: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
};

function getTabIndex(value: ICustomerOrSupplierWithTypename): 0 | 1 {
  return value.__typename === 'Lieferant' ? 1 : 0;
}

type ICustomerOrSupplierWithTypename =
  | (Customer & { __typename: 'Kunde' })
  | (Supplier & { __typename: 'Lieferant' });

const COUNT_DATA = {
  id: null,
};
const RenderCount = ({
  filterConfig: incomingFilterConfig,
  entity,
  sortBy,
  filterBy,
}) => {
  const filterConfig = useMemo(() => {
    const filter = incomingFilterConfig.filter.slice(0, -1);
    filter.push({
      $or: [
        {
          businessPartnerId: {
            $eq: '0',
          },
        },
      ],
    });
    return {
      ...incomingFilterConfig,
      filter,
    };
  }, [incomingFilterConfig]);

  const filterResult = useEntityPickerFilterResult({
    entity,
    data: COUNT_DATA,
    filterConfig,
    sortBy,
    filterBy,
    query: '',
  });

  return <>{filterResult?.counts?.[0]}</>;
};
