import { useMemo } from 'react';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { settings, useSetting } from '../../../../settings';
import {
  ProjectPlanningColumnsProps,
  useProjectPlanningColumns,
} from '../../planning-columns/use-project-planning-columns';
import { useProjectTimeTrackingColumn } from '../../planning-columns/use-project-time-tracking-column';
import jsonSchema from '../../schemata/project-table-schema.json';

interface IProps {
  projectList: Project[];
  projectPlanningProps: Pick<
    ProjectPlanningColumnsProps,
    'onCellSelect' | 'onUtilizationClick' | 'selectedRessourceClassCell'
  >;
}

export const useProjectTableSchema = (props: IProps) => {
  const { projectList, projectPlanningProps } = props;

  const projectPlanningTargetSource = useSetting(
    settings.projectPlanningTargetSource()
  );

  const projectPlanningColumns = useProjectPlanningColumns({
    projectList,
    entity: projectPlanningTargetSource.value,
    ...projectPlanningProps,
  });

  const projectTimeTrackingColumn = useProjectTimeTrackingColumn({
    projectList,
  });

  const initialschema = jsonSchema as unknown as ITableSchema<never>;

  const schema = useMemo(() => {
    return {
      ...initialschema,
      columns: [
        ...initialschema.columns,
        ...projectPlanningColumns,
        projectTimeTrackingColumn,
      ],
    } as never;
  }, [projectPlanningColumns, projectTimeTrackingColumn, initialschema]);

  return schema;
};
