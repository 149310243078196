import { MbscEventcalendarView } from '@mobiscroll/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDataProvider } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../settings';
import { useBottomViews } from '../../../bottom-views';
import { ZoomValue } from '../components/project-table-bottom-section/components/project-planning-view/components/timeline-zoom/TimelineZoom';
import { ProjectPlanningView } from '../components/project-table-bottom-section/components/project-planning-view/ProjectPlanningView';
import { ProjectCalculationsTable } from '../components/project-table-bottom-section/components/ProjectCalculationsTable';
import { ProjectContractsTable } from '../components/project-table-bottom-section/components/ProjectContractsTable';
import { ResourcesTimeline } from '../components/project-table-bottom-section/components/resources-timeline/ResourcesTimeline';

type Props = {
  selectedEntities: Project[];
  selectedRessourceClassCell?: { ressourceClass: LookUp; project: Project };
  date: Date;
  onDateChange: (date: Date) => void;
  selectedUtilizationUserIds?: number[];
};

export const useProjectBottomArea = ({
  selectedEntities,
  selectedRessourceClassCell,
  date,
  onDateChange,
  selectedUtilizationUserIds = [],
}: Props) => {
  const projectIdList = useMemo(
    () => (selectedEntities ?? []).map((x) => x.id),
    [selectedEntities]
  );

  const childProjectsRequest = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
      } as Project,
      entity: Entities.project,
      filter: [
        {
          parentProjectId: {
            $in: projectIdList,
          },
        },
      ],
    };
  }, [projectIdList]);

  const { data: childProjects } = useDataProvider(
    childProjectsRequest,
    selectedEntities?.length === 0
  );

  const allProjectIdList = [
    ...new Set([...projectIdList, ...childProjects.map((pr) => pr.id)]),
  ];

  const projectProcessRequest = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
        process: null,
        ressourceClassId: null,
        ressourceClass: {
          id: null,
          name: null,
        },
        customer: {
          id: null,
          name: null,
        },
        customerId: null,
      } as ProjectProcess,
      entity: Entities.projectProcess,
      filter: [
        {
          $or: [
            {
              projectId: {
                $eq: selectedRessourceClassCell?.project?.id,
              },
            },
            {
              'project.parentProjectId': {
                $eq: selectedRessourceClassCell?.project?.id,
              },
            },
          ],
        },
        {
          ressourceClassId: {
            $eq: selectedRessourceClassCell?.ressourceClass?.id,
          },
        },
      ],
    };
  }, [
    selectedRessourceClassCell?.project?.id,
    selectedRessourceClassCell?.ressourceClass?.id,
  ]);

  const projectProcessRes = useDataProvider(
    projectProcessRequest,
    !selectedRessourceClassCell
  );

  const [focusedProjectProcess, setFocusedProjectProcess] =
    useState<ProjectProcess>(null);

  useEffect(() => {
    setFocusedProjectProcess(projectProcessRes?.data?.[0]);
  }, [projectProcessRes?.data]);

  const [zoom, setZoom] = useState<ZoomValue>(3);
  const [unit, setUnit] = useState<'year' | 'day' | 'week' | 'month'>('year');

  const view = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        type: unit,
        startDay: 1,
        endDay: 0,
        eventList: true,
        weekNumbers: true,
      },
    }),
    [unit]
  );

  const { value: resourceTimelineUserIds, set: setResourceTimelineUserIds } =
    useSetting(settings.resourcePlanningUserIds());

  useEffect(() => {
    if (selectedUtilizationUserIds?.length > 0)
      setResourceTimelineUserIds(selectedUtilizationUserIds);
  }, [selectedUtilizationUserIds, setResourceTimelineUserIds]);

  const highlightedDateRanges = useMemo(() => {
    return [
      {
        start: new Date(selectedEntities?.[0]?.startDateInner),
        end: new Date(selectedEntities?.[0]?.endDateInner),
      },
    ];
  }, [selectedEntities]);

  const handleSelectedDateChange = useCallback(
    (e) => {
      onDateChange(e.date);
    },
    [onDateChange]
  );

  const handleUsersChange = useCallback(
    (users) => {
      if (users?.length) setResourceTimelineUserIds(users?.map((x) => x.id));
    },
    [setResourceTimelineUserIds]
  );

  return useBottomViews({
    size: 'fill',
    views: [
      {
        type: 'custom',
        content: selectedEntities?.length ? (
          <ProjectPlanningView
            projectIdList={projectIdList}
            layout={projectIdList?.length > 1 ? 'stacked' : 'gantt'}
          />
        ) : null,
        titleTranslationKey: 'COMMON.PROJECTPROCESS_plural',
      },
      // TODO WW-4968 use the `table` type instead of `custom`.
      {
        type: 'custom',
        content: selectedEntities?.length ? (
          <ProjectCalculationsTable projectIdList={allProjectIdList} />
        ) : null,
        titleTranslationKey: 'COMMON.CALCULATION_plural',
      },
      {
        type: 'custom',
        content: selectedEntities?.length ? (
          <ProjectContractsTable projectIdList={allProjectIdList} />
        ) : null,
        titleTranslationKey: 'COMMON.CONTRACT_plural',
      },
      {
        type: 'custom',
        content: (
          <ResourcesTimeline
            date={date}
            onSelectedDateChange={handleSelectedDateChange}
            highlightedDateRanges={highlightedDateRanges}
            view={view}
            focusedProject={selectedRessourceClassCell?.project}
            focusedProjectProcess={focusedProjectProcess}
            userIds={resourceTimelineUserIds}
            showToolbar
            zoom={zoom}
            onZoomChange={setZoom}
            unit={unit}
            onTimeUnitChange={setUnit}
            onUsersChange={handleUsersChange}
          />
        ),
        titleTranslationKey: 'COMMON.UTILIZATION',
      },
    ],
  });
};
