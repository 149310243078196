import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { ContactPickerField } from '../../../../../../../../components/entity-picker/ContactPickerField';
import { CustomerPickerField } from '../../../../../../../../components/entity-picker/CustomerPickerField';
import { ProjectPickerField } from '../../../../../../../../components/entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../../../../../../../components/entity-picker/ProjectProcessPickerField';
import { ControllerPlus } from '../../../../../../form-plus/controller-plus';
import { Collapse, ControlWrapper } from '../../../../../components';

export const AssignmentContent = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Collapse title={t('COMMON.ASSIGNMENT')} defaultOpen>
      <ControlWrapper columns={2}>
        <ControllerPlus
          control={control}
          name="businessPartner.data"
          render={({ field: businessPartnerField }) => {
            return (
              <ControllerPlus
                control={control}
                name="contact"
                render={({ field: contactField }) => {
                  return (
                    <MultiStepControls>
                      <Step index={0} active>
                        <CustomerPickerField {...businessPartnerField} />
                      </Step>

                      <Step
                        index={1}
                        active={Boolean(businessPartnerField.value)}
                      >
                        <ContactPickerField
                          {...contactField}
                          businessPartnerId={businessPartnerField.value?.id}
                          businessPartnerType={SdObjType.KUNDE}
                        />
                      </Step>
                    </MultiStepControls>
                  );
                }}
              />
            );
          }}
        />

        <ControllerPlus
          control={control}
          name="project"
          render={({ field: projectField }) => {
            return (
              <ControllerPlus
                control={control}
                name="projectProcess"
                render={({ field: projectProcessField }) => {
                  return (
                    <MultiStepControls>
                      <Step index={0} active>
                        <ProjectPickerField {...projectField} />
                      </Step>

                      <Step index={1} active={Boolean(projectField.value)}>
                        <ProjectProcessPickerField
                          {...projectProcessField}
                          projectId={projectField.value?.id}
                        />
                      </Step>
                    </MultiStepControls>
                  );
                }}
              />
            );
          }}
        />
      </ControlWrapper>
    </Collapse>
  );
};
