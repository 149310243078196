import styles from './ProjectTimeMask.module.scss';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  decimalToDuration,
  LABEL_VISIBILITY,
  MaskActionButton,
} from '@work4all/components';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';
import { TimeInputPicker } from '@work4all/components/lib/input/time-input-picker';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { IUser, useDataMutation, useFormPlus, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { CustomField } from '@work4all/models/lib/Classes/CustomField.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';
import { TimeTrackingKind } from '@work4all/models/lib/Enums/TimeTrackingKind.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';
import {
  canAddTimeTrackingForProjects,
  canEditTimeTrackingForOthers,
} from '@work4all/utils/lib/permissions';

import { ArticlePickerField } from '../../components/entity-picker/ArticlePickerField';
import { ContractPickerField } from '../../components/entity-picker/ContractPickerField';
import { CustomerPickerField } from '../../components/entity-picker/CustomerPickerField';
import { PositionPickerField } from '../../components/entity-picker/PositionPickerField';
import { ProjectPickerField } from '../../components/entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../components/entity-picker/ProjectProcessPickerField';
import { TicketPickerField } from '../../components/entity-picker/TicketPickerField';
import { UserPickerField } from '../../components/entity-picker/UserPickerField';
import { settings, useSetting } from '../../settings';
import { ApiErrors, ValidationErrors } from '../apollo/ValidationErrors';
import { ControllerPlus } from '../mask-overlays/form-plus/controller-plus';
import { useFormContextPlus } from '../mask-overlays/form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
  MenuItem,
} from '../mask-overlays/locked-inputs';
import {
  Collapse,
  ControlWrapper,
  Form,
  MaskOverlayHeader,
} from '../mask-overlays/mask-overlay/components';
import { CustomFields } from '../mask-overlays/mask-overlay/components/custom-fields/CustomFields';
import { normalizeCustomFields } from '../mask-overlays/mask-overlay/components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../mask-overlays/mask-overlay/components/custom-fields/prepare-input-with-custom-fields';
import { WithCustomFields } from '../mask-overlays/mask-overlay/components/custom-fields/with-custom-fields';
import { MaskActions } from '../mask-overlays/mask-overlay/components/MaskActions';
import { MaskOverlayDeleteButton } from '../mask-overlays/mask-overlay/components/MaskOverlayDeleteButton';
import { MaskOverlayMenuWrapper } from '../mask-overlays/mask-overlay/components/MaskOverlayMenuWrapper';
import { MaskOverlaySubmitButton } from '../mask-overlays/mask-overlay/components/MaskOverlaySubmitButton';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContext,
  useMaskContextValue,
} from '../mask-overlays/mask-overlay/hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../mask-overlays/mask-overlay/hooks/use-confrm-before-close-mask';
import {
  EntityRightsContext,
  useEntityRightsContext,
} from '../mask-overlays/mask-overlay/hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../mask-overlays/mask-overlay/hooks/use-standard-delete-entity-handler';
import { NEW_ENTITY_ID } from '../mask-overlays/mask-overlay/mask-metadata';
import { useFormUpdate } from '../mask-overlays/mask-overlay/utils/use-form-update';
import { ProjectTimeSettings } from '../mask-overlays/mask-overlay/views/settings/project-time-settings/ProjectTimeSettings';

import DurationField from './components/DurationField';

type TimeTrackingFormValue = PathsOf<
  WithCustomFields<
    TimeTracking & {
      users?: User[];
    }
  >,
  3
>;

export interface ProjectTimeMaskInit {
  /**
   * @default TimeTrackingKind.ANZAHL
   */
  kind?: TimeTrackingKind.ANZAHL | TimeTrackingKind.VON_BIS;
  /**
   * @default new Date() // Current time
   */
  date?: Date;
  /**
   * Defaults to 1 hour before `date` or 1 hour ago if `date` is omitted.
   */
  startDateTime?: Date;
  /**
   * Defaults to the same value as `date` or current time if `date` is omitted.
   */
  endDateTime?: Date;
  /**
   * @default 0
   */
  amount?: number;
  /**
   * @default 0
   */
  pause?: number;

  id?: number;
  projectProcess?: ProjectProcess;
  project?: Project;
  customer?: Customer;
  ticket?: Ticket;
  comment?: string;
  contract?: Contract;
  activityArticle?: Article;
  activityArticleId?: number;
  users?: User[];
  position?: Position;
  customFieldList?: CustomField[];
}

export interface ProjectTimeMaskProps {
  user?: IUser | User;
  item?: TimeTracking | null;
  init?: ProjectTimeMaskInit;
  amplitudeEntryPoint?: string;
  onCompleted?: () => void;
  createdFrom?: Entities;
  onCopy?: (item: TimeTracking) => void;
}

export function ProjectTimeMask(props: ProjectTimeMaskProps) {
  const { item = null, amplitudeEntryPoint, createdFrom, onCopy } = props;

  const currentUser = useUser();
  const canEditAll = canEditTimeTrackingForOthers(currentUser, undefined);

  const user = props.user || currentUser;
  const { t } = useTranslation();

  const { goBack, close, currentStackIndex, setObjectionListener, confirm } =
    useHistoryStack();

  const onCompleted = () => {
    setObjectionListener(null);
    sendAmplitudeData(EventType.SaveProjectTime, {
      entryPoint: amplitudeEntryPoint,
    });
    if (currentStackIndex !== 0) {
      goBack();
    } else {
      close();
    }
    props.onCompleted?.();
  };

  const [errors, setErrors] = useState<ApiErrors<TimeTrackingFormValue>>([]);
  const [mutate] = useDataMutation({
    entity: Entities.timeTracking,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
    },
    onCompleted: onCompleted,
    onError: (e) => {
      const parsedErrors = ValidationErrors.parseErrors<TimeTrackingFormValue>(
        e.graphQLErrors,
        Entities.timeTracking
      );
      setErrors(parsedErrors);
    },
  });

  useEffect(() => {
    if (item === null) {
      sendAmplitudeData(EventType.AddProjectTime, {
        entryPoint: amplitudeEntryPoint,
      });
    } else {
      sendAmplitudeData(EventType.EditProjectTime, {
        entryPoint: amplitudeEntryPoint,
      });
    }
  }, [amplitudeEntryPoint, item]);

  const projectTimeTrackingMarkItemsAsDeductableAutomatically = useSetting(
    settings.projectTimeTrackingMarkItemsAsDeductableAutomatically()
  );

  const projectTimeTrackingDeductableByActivity = useSetting(
    settings.projectTimeTrackingDeductableByActivity()
  );

  const projectTimeTrackingDeductableWithoutActivity = useSetting(
    settings.projectTimeTrackingDeductableWithoutActivity()
  );

  const { value: createTimeTrackingForOthersValue } = useSetting(
    settings.createTimeTrackingForOthers()
  );

  const canCreateTimeTrackingForOthers =
    createdFrom === Entities.appointment
      ? createTimeTrackingForOthersValue
      : true;

  const onSubmit = useEventCallback(async (values: TimeTrackingFormValue) => {
    // date is always send without timezoneinfo
    values.date = DateTime.fromISO(
      values.timeTrackingKind === TimeTrackingKind.VON_BIS
        ? values.startDateTime
        : values.date
    )
      .startOf('day')
      .toFormat('yyyy-MM-dd');
    //make sure that we only send the proper date fields
    if (values.timeTrackingKind === TimeTrackingKind.VON_BIS) {
      //autofill the amount
      values.amount = Duration.fromMillis(
        DateTime.fromISO(values.endDateTime)
          .startOf('minute')
          .toJSDate()
          .getTime() -
          DateTime.fromISO(values.startDateTime)
            .startOf('minute')
            .toJSDate()
            .getTime() -
          values.pause * 60 * 1000
      )
        .shiftTo('hours')
        .toObject().hours;
    } else {
      values.startDateTime = null;
      values.endDateTime = null;
    }

    values = prepareInputWithCustomFields(values);

    if (!values.id) {
      values.isBillable =
        projectTimeTrackingMarkItemsAsDeductableAutomatically.value ||
        (projectTimeTrackingDeductableByActivity.value &&
          values.activityArticle &&
          !values.activityArticle?.notdeductable) ||
        (!values.activityArticleId &&
          projectTimeTrackingDeductableWithoutActivity.value);
    }

    if (canEditAll && canCreateTimeTrackingForOthers && values.users) {
      const multiple = values.users
        .map((x) => ({ ...values, userId: x.id }))
        .map((x) => mutate(x));
      await Promise.all(multiple);
    } else {
      await mutate(values);
    }
  });

  const projectTimeTrackingCustomerMandatory = useSetting(
    settings.projectTimeTrackingCustomerMandatory()
  );
  const projectTimeTrackingTicketMandatory = useSetting(
    settings.projectTimeTrackingTicketMandatory()
  );
  const projectTimeTrackingProjectMandatory = useSetting(
    settings.projectTimeTrackingProjectMandatory()
  );
  const projectTimeTrackingActivityMandatory = useSetting(
    settings.projectTimeTrackingActivityMandatory()
  );
  const projectTimeTrackingContractMandatory = useSetting(
    settings.projectTimeTrackingContractMandatory()
  );
  const projectTimeTrackingPositionMandatory = useSetting(
    settings.projectTimeTrackingPositionMandatory()
  );
  const projectTimeTrackingProjectProcessMandatory = useSetting(
    settings.projectTimeTrackingProjectProcessMandatory()
  );
  const projectProcessOverBookingNotPossible = useSetting(
    settings.projectProcessOverBookingNotPossible()
  );

  const defaultValuesRaw = useMemo(() => {
    if (item == null) {
      const timeTrackingKind: TimeTrackingKind =
        props.init?.kind ??
        (localStorage.getItem('TIME_TRACKING_KIND_LS_KEY') ===
        TimeTrackingKind.VON_BIS
          ? TimeTrackingKind.VON_BIS
          : TimeTrackingKind.ANZAHL);

      const currentDate = DateTime.now().set({
        second: 0,
        millisecond: 0,
      });
      const date = props.init?.date ?? currentDate.toJSDate();
      const dateToDateTime = DateTime.fromJSDate(date);
      const startDateTime = date;
      const endDateTime = dateToDateTime.plus({ minutes: 1 }).toJSDate();
      const init: Omit<
        ProjectTimeMaskInit,
        | 'comment'
        | 'project'
        | 'customer'
        | 'projectProcess'
        | 'ticket'
        | 'contract'
      > = {
        kind: timeTrackingKind,
        date: date,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        amount: 0,
        pause: 0,
        ...props.init,
      };

      const formValue: TimeTrackingFormValue = {
        date: DateTime.fromJSDate(init.date).toISO(),
        startDateTime: DateTime.fromJSDate(init.startDateTime).toISO(),
        endDateTime: DateTime.fromJSDate(init.endDateTime).toISO(),
        pause: init.pause,
        amount: init.amount,
        timeTrackingKind: init.kind,
        customer: props.init?.customer,
        businessPartnerId: props.init?.customer?.id,
        user: {
          id: (user as IUser).benutzerCode || (user as User).id,
          displayName: user.displayName,
        },
        userId: (user as IUser).benutzerCode || (user as User).id,
        users: props.init?.users ?? [
          {
            id: (user as IUser).benutzerCode || (user as User).id,
            displayName: user.displayName,
          },
        ],
        project: props.init?.project,
        projectId: props.init?.project?.id,
        projectProcess: props.init?.projectProcess,
        projectProcessId: props.init?.projectProcess?.id,
        contract: props.init?.contract,
        activityArticle: props.init?.activityArticle,
        activityArticleId: props.init?.activityArticleId,
        bZObjMemberId: props.init?.contract?.id,
        ticket: props.init?.ticket,
        ticketNumber: props.init?.ticket?.number,
        comment: props.init?.comment || '',
        position: props.init?.position,
        positionId: props.init?.position?.id,
        customFieldList: init.customFieldList,
      };
      return formValue;
    }

    // DateTimeInputPicker does not work with the format returned by the API for
    // some reason.
    const toISO = (date: string | null): string | null => {
      if (date == null) {
        return null;
      }

      return DateTime.fromISO(date).toISO();
    };

    return {
      ...item,
      __typename: undefined,
      date: DateTime.fromFormat(item.date, 'yyyy-MM-dd').toISO(),
      startDateTime: toISO(item.startDateTime),
      endDateTime: toISO(item.endDateTime),
    };
  }, [item, props.init, user]);

  const customFields = useCustomFieldsConfig({ entity: Entities.timeTracking });

  const defaultValues = useMemo(() => {
    return normalizeCustomFields(defaultValuesRaw, customFields);
  }, [defaultValuesRaw, customFields]);

  const projectProcessError = useCallback(
    (data: Required<Pick<TimeTracking, 'projectProcess' | 'amount'>>) => {
      if (
        data?.projectProcess &&
        projectProcessOverBookingNotPossible.value &&
        !data?.projectProcess?.overBookingPossible
      ) {
        const usedAmount =
          data?.projectProcess?.timeTrackings?.length === 0
            ? 0
            : data?.projectProcess?.timeTrackings
                ?.map((x) => x.amount)
                .reduce((a, b) => a + b);
        if (
          data.amount + usedAmount >=
          data?.projectProcess?.planningAmount * 8
        ) {
          return {
            message: t('VALIDATION.PROJECT_PROCESS_NO_BUDGET'),
            type: 'customValidation',
          };
        }
      }

      return null;
    },
    [projectProcessOverBookingNotPossible.value, t]
  );

  const customRules = useCallback(
    (data: TimeTracking) => {
      const errors = {};

      if (data.project?.projectStatus?.closedStatus) {
        errors['project'] = {
          message: t('VALIDATION.PROJECT_CLOSED'),
          type: 'customValidation',
        };
      }

      if (data.project?.lockInhouseServices) {
        errors['project'] = {
          message: t('VALIDATION.PROJECT_INHOUSE_SERVICE_LOCKED'),
          type: 'customValidation',
        };
      }

      const projectProcessErrorValue = projectProcessError({
        projectProcess: data.projectProcess,
        amount: data.amount ?? 0,
      });

      if (projectProcessErrorValue) {
        errors['projectProcess'] = projectProcessErrorValue;
      }

      if (data.timeTrackingKind === TimeTrackingKind.VON_BIS) {
        // we need start and end date + times
        if (!data.startDateTime || !data.endDateTime) {
          errors['startDateTime'] = {
            message: t('VALIDATION.BOTH_TIMES_NEEDED'),
            type: 'customValidation',
          };
          errors['endDateTime'] = {
            message: t('VALIDATION.BOTH_TIMES_NEEDED'),
            type: 'customValidation',
          };
        }
      } else if (data.timeTrackingKind === TimeTrackingKind.ANZAHL) {
        // we need amount
        if (!data.amount) {
          errors['amount'] = {
            message: t('VALIDATION.TIME_MISSING'),
            type: 'customValidation',
          };
        }
      }
      if (projectTimeTrackingCustomerMandatory.value && !data.customer) {
        errors['customer'] = {
          message: t('VALIDATION.CUSTOMER_MISSING'),
          type: 'customValidation',
        };
      }
      if (projectTimeTrackingContractMandatory.value && !data.contract) {
        errors['contract'] = {
          message: t('VALIDATION.CONTRACT_MISSING'),
          type: 'customValidation',
        };
      }
      if (projectTimeTrackingActivityMandatory.value && !data.activityArticle) {
        errors['activityArticle'] = {
          message: t('VALIDATION.ACTIVITY_MISSING'),
          type: 'customValidation',
        };
      }
      if (projectTimeTrackingPositionMandatory.value && !data.position) {
        errors['position'] = {
          message: t('VALIDATION.POSITION_MISSING'),
          type: 'customValidation',
        };
      }
      if (projectTimeTrackingProjectMandatory.value && !data.project) {
        errors['project'] = {
          message: t('VALIDATION.PROJECT_MISSING'),
          type: 'customValidation',
        };
      }
      if (
        projectTimeTrackingProjectProcessMandatory.value &&
        !data.projectProcess
      ) {
        errors['projectProcess'] = {
          message: t('VALIDATION.PROJECTPROCESS_MISSING'),
          type: 'customValidation',
        };
      }
      if (projectTimeTrackingTicketMandatory.value && !data.ticket) {
        errors['ticket'] = {
          message: t('VALIDATION.TICKET_MISSING'),
          type: 'customValidation',
        };
      }

      return Object.keys(errors).length > 0 ? errors : true;
    },
    [
      projectProcessError,
      projectTimeTrackingCustomerMandatory.value,
      projectTimeTrackingContractMandatory.value,
      projectTimeTrackingActivityMandatory.value,
      projectTimeTrackingPositionMandatory.value,
      projectTimeTrackingProjectMandatory.value,
      projectTimeTrackingProjectProcessMandatory.value,
      projectTimeTrackingTicketMandatory.value,
      t,
    ]
  );

  const schema = useEntityJsonSchema(Entities.timeTracking);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const form = useFormPlus<TimeTrackingFormValue>({
    resolver,
    defaultValues,
    mode: 'onChange',
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  useEffect(() => {
    const lookup = {
      positionId: 'position',
    };
    errors.forEach((x) => {
      const property = Object.keys(lookup).includes(x.property)
        ? lookup[x.property]
        : x.property;
      form.setError(property, {
        message: ValidationErrors.translateError(x.code),
        type: 'apiValidation',
      });
    });
  }, [t, errors, form]);

  // We check if the project process coming from the props valid or not
  // if not valid we set the an error when opning the mask
  useEffect(() => {
    const projectProcessErrorValue = projectProcessError({
      projectProcess: props.init?.projectProcess,
      amount: props.init?.amount ?? 0,
    });

    if (projectProcessErrorValue) {
      form.setError('projectProcess', projectProcessErrorValue);
    } else {
      form.setError('projectProcess', null);
    }
  }, [
    form,
    projectProcessError,
    props.init?.amount,
    props.init?.projectProcess,
  ]);

  const { formState, getValues } = form;

  const editedDateTime = (
    startDateTime: string,
    endDateTime: string,
    returnThe: 'start' | 'end'
  ) => {
    const start = DateTime.fromISO(startDateTime).startOf('minute');
    const end = DateTime.fromISO(endDateTime).startOf('minute').set({
      year: start.year,
      month: start.month,
      day: start.day,
    });

    if (start.toMillis() > end.toMillis()) {
      if (start.toISODate() === end.toISODate()) {
        return returnThe === 'end' ? start : end;
      } else {
        return returnThe === 'end'
          ? start.set({
              hour: end.hour,
              minute: end.minute,
            })
          : end.set({
              hour: start.hour,
              minute: start.minute,
            });
      }
    }

    return returnThe === 'end' ? end : start;
  };

  useFormUpdate(
    {
      startDateTime(startDateTime: string): TimeTrackingFormValue {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newEndDateTime = editedDateTime(
          startDateTime,
          getValues('endDateTime'),
          'end'
        ).toISO();

        return { endDateTime: newEndDateTime };
      },
      endDateTime(endDateTime: string): TimeTrackingFormValue {
        const newStartDateTime = editedDateTime(
          getValues('startDateTime'),
          endDateTime,
          'start'
        ).toISO();

        return { startDateTime: newStartDateTime };
      },
      activityArticle(value: Article): TimeTrackingFormValue {
        return {
          activityArticleId: value?.id || 0,
        };
      },
      user(value: User): TimeTrackingFormValue {
        return {
          userId: value?.id || 0,
        };
      },
      projectProcess(value: ProjectProcess): TimeTrackingFormValue {
        return { projectProcessId: value?.id || 0 };
      },

      customer(value: Customer): TimeTrackingFormValue {
        const customerId = value?.id;
        const result: { [key: string]: number | Ticket | Contract } = {
          businessPartnerId: customerId || 0,
        };

        return result;
      },
      ticket(value: Ticket): TimeTrackingFormValue {
        const result: {
          [key: string]:
            | number
            | Customer
            | Project
            | ProjectProcess
            | Contract
            | Position;
        } = {
          ticketNumber: value?.number || 0,
        };
        const customer = getValues('customer');
        const contract = getValues('contract');
        const project = getValues('project');

        if (
          !customer &&
          value?.businessPartner?.businessPartnerType === SdObjType.KUNDE
        ) {
          result.customer = value?.businessPartner?.data as Customer;
        }

        if (!project && value?.project) {
          result.project = value?.project;
          result.projectProcess = value?.projectProcess;
        }

        if (!contract && value?.contract) {
          result.contract = value?.contract;
          result.position = value?.position;
        }

        return result;
      },

      project(value: Project, prevProject: Project): TimeTrackingFormValue {
        const customer = getValues('customer');

        const result: { [key: string]: number | Customer | null } = {
          projectId: value?.id || 0,
        };

        if (value === null) {
          result.contract = null;
        }

        if (prevProject && prevProject?.id !== value?.id) {
          result.projectProcessId = 0;
          result.projectProcess = null;
          result.contract = null;
        }

        if (!customer && value?.customer) {
          result.customer = value?.customer;
        }

        return result;
      },

      contract(value: Contract, prevContract: Contract): TimeTrackingFormValue {
        const result: {
          [key: string]: number | Project | ProjectProcess | Customer;
        } = {
          bZObjMemberId: value?.id || 0,
        };

        if (prevContract && value?.id !== prevContract.id) {
          result.positionId = 0;
          result.position = null;
        }

        const customer = getValues('customer');

        if (!customer && value?.businessPartnerType === SdObjType.KUNDE) {
          result.customer = value?.businessPartnerContactCombined
            ?.businessPartner?.data as Customer;
        }

        if (value?.project) {
          result.project = value?.project;
          if (value?.projectProcess)
            result.projectProcess = value?.projectProcess;
        }

        return result;
      },
      position(value: Position): TimeTrackingFormValue {
        return {
          positionId: value?.id || 0,
          activityArticle: value?.article?.isActivity ? value.article : null,
          activityArticleId: value?.article?.isActivity ? value.article.id : 0,
        };
      },
    },
    form
  );

  useEffect(() => {
    form.reset(defaultValues, { keepErrors: true });
  }, [form, defaultValues]);

  const isDirtyForm = Object.entries(formState.dirtyFields).length > 0;

  useConfirmBeforeCloseMask(isDirtyForm);

  const { handleSubmit } = form;

  const deleteHandler = useStandardDeleteEntityHandler({
    entity: Entities.timeTracking,
    id: item?.id,
  });

  const [isSettingsOpen, setOpen] = useState(false);
  const openSettings = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const mask = useMaskConfig({
    entity: Entities.timeTracking,
    id: props?.item?.id || NEW_ENTITY_ID,
  });

  const maskContext = useMaskContextValue({ ...mask, customFields });

  const entityRights = useMemo(
    () => ({
      create: canAddTimeTrackingForProjects(currentUser),
      read: false, //ToDo there is no real read right, rather a visibility right
      update: canEditAll || canEditTimeTrackingForOthers(currentUser, item),
      delete: canEditAll || canEditTimeTrackingForOthers(currentUser, item),
    }),
    [canEditAll, currentUser, item]
  );

  const canSaveEntry =
    (entityRights.create && mask.mode === 'create') ||
    (mask.mode === 'edit' && entityRights.update);

  async function handleCopyClick() {
    if (!item) return;

    if (isDirtyForm) {
      const confirmed = await confirm(t('ALERTS.UNSAVED_CHANGES'));

      if (!confirmed) {
        return;
      }
    }

    onCopy(item);
  }

  return (
    <>
      <NavigationOverlay
        classes={{
          wrapper: styles.dialogWrapper,
        }}
        open={isSettingsOpen}
        initialView={{
          view: <ProjectTimeSettings amplitudeEntryPoint="ProjectTimeMask" />,
          title: t('TIME_TRACKER.PROJECT_TIME'),
        }}
        close={() => {
          setOpen(false);
        }}
      />

      <MaskContextProvider value={maskContext}>
        <EntityRightsContext.Provider value={entityRights}>
          <FormProvider {...form}>
            <div className={clsx(styles.root, 'custom-scrollbar')}>
              <div>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <MaskOverlayHeader
                    title={t('TIME_TRACKER.PROJECT_TIME')}
                    actions={
                      <MaskActions>
                        {onCopy && mask.isEditMode ? (
                          <MaskActionButton
                            color="primary"
                            labelVisibility={LABEL_VISIBILITY.HIDE}
                            label={t('COMMON.COPY_CREATE')}
                            icon={<ContentCopyIcon />}
                            onClick={handleCopyClick}
                          />
                        ) : null}
                        <MaskOverlaySubmitButton
                          disabled={!canSaveEntry}
                          loading={formState.isSubmitting}
                          data-test-id={'projectTimeSaveButton'}
                        >
                          {t('INPUTS.SAVE')}
                        </MaskOverlaySubmitButton>
                        {item && (
                          <MaskOverlayDeleteButton
                            data-test-id={'projectTimeDelete'}
                            disabled={
                              !(
                                canEditAll ||
                                item.userId === (user as IUser).benutzerCode ||
                                item.userId === (user as User).id
                              )
                            }
                            onClick={deleteHandler}
                          />
                        )}
                        <MaskOverlayMenuWrapper>
                          <MenuItem
                            data-test-id={'projectTimeSettings'}
                            onClick={openSettings}
                          >
                            {t('MASK.SETTINGS')}
                          </MenuItem>
                        </MaskOverlayMenuWrapper>
                      </MaskActions>
                    }
                  />

                  <ProjectTimeMaskContent
                    canChangeUser={canEditAll}
                    isEditMode={!!item}
                    renderCustomFields={customFields.length > 0}
                  />
                </Form>
              </div>
            </div>
          </FormProvider>
        </EntityRightsContext.Provider>
      </MaskContextProvider>
    </>
  );
}

const getDurationFromString = (val: string) => {
  let hours: number;
  let minutes: number;
  if (!val.includes(':')) {
    hours = parseInt(val.slice(0, -2), 10) || 0;
    minutes = parseInt(val.slice(-2), 10) || 0;
  } else {
    hours = parseInt(val.split(':')[0], 10) || 0;
    minutes = parseInt(val.split(':')[1], 10) || 0;
  }
  return Duration.fromObject({
    hours,
    minutes,
  });
};

function ProjectTimeMaskContent({
  canChangeUser,
  isEditMode,
  renderCustomFields,
}: {
  canChangeUser: boolean;
  isEditMode: boolean;
  renderCustomFields: boolean;
}) {
  const { t } = useTranslation();

  const { register, watch, control, getValues, setValue, formState } =
    useFormContextPlus<TimeTrackingFormValue>();

  const rights = useEntityRightsContext();

  const mask = useMaskContext();

  const isManualStartEnd =
    watch('timeTrackingKind') === TimeTrackingKind.VON_BIS;
  const isTrackingKindCheckboxEnabled = rights.create && mask.mode === 'create';

  const startTime = watch('startDateTime');
  const endTime = watch('endDateTime');
  const totalAmount = useMemo(() => {
    const startDateTime = DateTime.fromISO(startTime).startOf('minute');
    const endDateTime = DateTime.fromISO(endTime).startOf('minute').set({
      year: startDateTime.year,
      month: startDateTime.month,
      day: startDateTime.day,
    });

    if (!startDateTime.isValid || !endDateTime.isValid) {
      return 0;
    }

    const pause = getValues('pause') || 0;
    const duration = Duration.fromMillis(
      endDateTime.toMillis() - startDateTime.toMillis()
    ).minus({ minutes: pause });

    return duration.toFormat('hh:mm');
  }, [endTime, getValues, startTime]);

  const isAmountTouched = formState.touchedFields?.amount === true;
  const updateAmountField = useCallback(
    (value: string) => {
      const dur = getDurationFromString(value);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue('amount', dur.as('hours'), { shouldValidate: isAmountTouched });
    },
    [setValue, isAmountTouched]
  );

  const updatePauseField = useCallback(
    (value: string) => {
      const pause = getDurationFromString(value);

      setValue('pause', pause.as('minutes'));
    },
    [setValue]
  );

  const projectTimeTrackingShowActivity = useSetting(
    settings.projectTimeTrackingShowActivity()
  );
  const projectTimeTrackingShowContract = useSetting(
    settings.projectTimeTrackingShowContract()
  );

  const projectTimeTrackingShowCustomer = useSetting(
    settings.projectTimeTrackingShowCustomer()
  );
  const projectTimeTrackingShowPosition = useSetting(
    settings.projectTimeTrackingShowPosition()
  );
  const projectTimeTrackingShowProject = useSetting(
    settings.projectTimeTrackingShowProject()
  );
  const projectTimeTrackingShowProjectProcess = useSetting(
    settings.projectTimeTrackingShowProjectProcess()
  );
  const projectTimeTrackingShowTicket = useSetting(
    settings.projectTimeTrackingShowTicket()
  );

  const pickerFromWrapperRef = useRef<HTMLDivElement>();

  const renderCutomerAndWorkers = useCallback(() => {
    const customerControl = (
      <ControllerPlus
        control={control}
        name="customer"
        render={({ field }) => {
          return (
            <CustomerPickerField
              {...field}
              prefilter={[
                {
                  extinct: { $eq: 0 },
                },
              ]}
            />
          );
        }}
      />
    );

    if (canChangeUser && !isEditMode) {
      const multipleWorkerControl = (
        <ControlWrapper ref={pickerFromWrapperRef}>
          <ControllerPlus
            control={control}
            name="users"
            render={({ field }) => {
              const { value, ...others } = field;
              return (
                <UserPickerField
                  multiple={true}
                  value={value as User[]}
                  {...others}
                  clearable={false}
                  disabled={!canChangeUser}
                />
              );
            }}
          />
        </ControlWrapper>
      );

      return (
        <>
          <ControlWrapper>{multipleWorkerControl}</ControlWrapper>
          {projectTimeTrackingShowCustomer.value && (
            <ControlWrapper>{customerControl}</ControlWrapper>
          )}
        </>
      );
    }

    const workerControl = (
      <ControllerPlus
        control={control}
        name="user"
        render={({ field }) => {
          const { value, ...others } = field;

          return (
            <UserPickerField
              value={value as User}
              {...others}
              clearable={false}
              disabled={!canChangeUser}
            />
          );
        }}
      />
    );

    return (
      <ControlWrapper>
        {projectTimeTrackingShowCustomer.value && customerControl}
        {workerControl}
      </ControlWrapper>
    );
  }, [
    control,
    canChangeUser,
    isEditMode,
    projectTimeTrackingShowCustomer.value,
  ]);

  const updateTime = useCallback(
    (value: string) => {
      const start = DateTime.fromISO(getValues('startDateTime'));
      const time = DateTime.fromISO(value);

      return start.set({ hour: time.hour, minute: time.minute }).toISO();
    },
    [getValues]
  );

  return (
    <div style={{ paddingBottom: '4rem' }}>
      <Collapse title={t('COMMON.TIMETRACKING.TIME_plural')} defaultOpen>
        {isManualStartEnd ? (
          <>
            <ControlWrapper>
              <DateTimeInputPicker
                dateLabel={t('INPUTS.DAY')}
                withTime={false}
                clearable={false}
                {...register('startDateTime')}
              />

              <ControlWrapper>
                <ControllerPlus
                  name="startDateTime"
                  control={control}
                  render={({ field }) => {
                    const value = DateTime.fromISO(field.value);

                    return (
                      <TimeInputPicker
                        label={t('INPUTS.START')}
                        value={value}
                        onChange={(e) => {
                          const value = e.currentTarget?.value;

                          if (value) {
                            field.onChange(updateTime(value));
                          }
                        }}
                      />
                    );
                  }}
                />

                <ControllerPlus
                  name="endDateTime"
                  control={control}
                  render={({ field }) => {
                    const value = DateTime.fromISO(field.value);

                    return (
                      <TimeInputPicker
                        label={t('INPUTS.END')}
                        value={value}
                        onChange={(e) => {
                          const value = e.currentTarget?.value;

                          if (value) {
                            field.onChange(updateTime(value));
                          }
                        }}
                      />
                    );
                  }}
                />
              </ControlWrapper>
            </ControlWrapper>

            <ControlWrapper>
              <ControllerPlus
                name="pause"
                control={control}
                render={({ field: pause }) => {
                  const value = Duration.fromObject({
                    minutes: pause.value,
                  }).toFormat('hh:mm');

                  return (
                    <DurationField
                      label={t('INPUTS.PAUSE')}
                      value={value}
                      onChange={updatePauseField}
                      onBlur={(e) => {
                        const start = DateTime.fromISO(
                          getValues('startDateTime')
                        );
                        const end = DateTime.fromISO(getValues('endDateTime'));
                        const netTime = Duration.fromMillis(
                          end.toMillis() - start.toMillis()
                        );
                        const pause = getDurationFromString(e.target.value);

                        if (pause.toMillis() > netTime.toMillis()) {
                          setValue('pause', 0);
                        }
                      }}
                    />
                  );
                }}
              />

              <LabeledInput label={t('INPUTS.NET_TIME')} value={totalAmount} />
            </ControlWrapper>
          </>
        ) : (
          <ControlWrapper>
            <DateTimeInputPicker
              dateLabel={t('COMMON.DATE')}
              withTime={false}
              clearable={false}
              {...register('date')}
            />
            <ControllerPlus
              name="amount"
              control={control}
              render={({ field: { ref: _ref, ...field } }) => {
                return (
                  <DurationField
                    {...field}
                    label={t('INPUTS.NET_TIME')}
                    value={decimalToDuration(field.value).toFormat('hh:mm')}
                    onChange={updateAmountField}
                  />
                );
              }}
            />
          </ControlWrapper>
        )}

        <ControlWrapper fullWidth={false}>
          <ControllerPlus
            control={control}
            name="timeTrackingKind"
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!isTrackingKindCheckboxEnabled}
                      checked={field.value === TimeTrackingKind.VON_BIS}
                      onChange={(event) => {
                        const kind = event.target.checked
                          ? TimeTrackingKind.VON_BIS
                          : TimeTrackingKind.ANZAHL;

                        field.onChange(kind);
                        localStorage.setItem('TIME_TRACKING_KIND_LS_KEY', kind);
                      }}
                    />
                  }
                  label={t('TIME_TRACKER.MANUAL_LABEL')}
                />
              );
            }}
          />
        </ControlWrapper>
      </Collapse>

      <Collapse title={t('COMMON.MISC')} defaultOpen>
        {projectTimeTrackingShowProject.value && (
          <ControlWrapper>
            <ControllerPlus
              control={control}
              name="project"
              render={({ field: projectField }) => {
                const customerFilter = watch('customer')?.id
                  ? [
                      {
                        $and: [
                          {
                            $or: [
                              {
                                customerId: {
                                  $eq: watch('customer')?.id,
                                },
                              },
                              {
                                customerId: { $eq: 0 },
                              },
                            ],
                          },
                        ],
                      },
                    ]
                  : [];

                const sortBy = customerFilter.length ? 'customer.id' : 'name';
                const sortByDirection = customerFilter.length
                  ? SortDirection.DESCENDING
                  : SortDirection.ASCENDING;

                return (
                  <ControllerPlus
                    control={control}
                    name="projectProcess"
                    render={({ field: projectProcessField }) => {
                      return (
                        <MultiStepControls isColumnDirection>
                          <Step index={0} active>
                            <ProjectPickerField
                              {...projectField}
                              excludeClosedStatus={true}
                              excludeLockInhouseServicesFilter={true}
                              data={{
                                projectStatus: {
                                  id: null,
                                  closedStatus: null,
                                },
                                lockInhouseServices: null,
                              }}
                              prefilter={customerFilter}
                              sortBy={sortBy}
                              sortByDirection={sortByDirection}
                              useSearchHistory={false}
                            />
                          </Step>
                          {projectTimeTrackingShowProjectProcess.value && (
                            <Step index={1} active={projectField.value != null}>
                              {projectField.value != null && (
                                <ProjectProcessPickerField
                                  {...projectProcessField}
                                  projectId={projectField.value.id}
                                  prefilter={[
                                    {
                                      isClosed: {
                                        $eq: 0,
                                      },
                                    },
                                  ]}
                                  data={{
                                    overBookingPossible: null,
                                    planningAmount: null,
                                    timeTrackings: [
                                      {
                                        id: null,
                                        amount: null,
                                      },
                                    ],
                                  }}
                                />
                              )}
                            </Step>
                          )}
                        </MultiStepControls>
                      );
                    }}
                  />
                );
              }}
            />
          </ControlWrapper>
        )}
        {renderCutomerAndWorkers()}
        {projectTimeTrackingShowContract.value && (
          <ControlWrapper>
            <ControllerPlus
              control={control}
              name="contract"
              render={({ field: contractField }) => {
                const projectFilter = watch('project')?.id
                  ? [
                      {
                        projectId: {
                          $eq: watch('project')?.id,
                        },
                      },
                    ]
                  : [];

                const customerFilter =
                  projectFilter.length === 0
                    ? watch('customer')?.id
                      ? [
                          {
                            businessPartnerId: {
                              $eq: watch('customer')?.id,
                            },
                          },
                          {
                            businessPartnerType: {
                              $eq: SdObjType.KUNDE,
                            },
                          },
                        ]
                      : []
                    : [];
                const prefilter = [
                  ...projectFilter,
                  ...customerFilter,
                  {
                    isClosed: {
                      $eq: false,
                    },
                  },
                ];

                return (
                  <ControllerPlus
                    control={control}
                    name="position"
                    render={({ field: positionField }) => {
                      return (
                        <MultiStepControls isColumnDirection>
                          <Step active={true} index={0}>
                            <ContractPickerField
                              data={CONTRACT_DATA}
                              {...contractField}
                              onChange={(val) => {
                                contractField.onChange(val);
                              }}
                              prefilter={
                                prefilter.length ? prefilter : undefined
                              }
                            />
                          </Step>
                          {projectTimeTrackingShowPosition.value && (
                            <Step active={!!contractField.value} index={1}>
                              <PositionPickerField
                                {...positionField}
                                onChange={(e) => {
                                  positionField.onChange(e);
                                }}
                                positions={
                                  contractField.value?.positionList ?? []
                                }
                              />
                            </Step>
                          )}
                        </MultiStepControls>
                      );
                    }}
                  />
                );
              }}
            />
          </ControlWrapper>
        )}
        {projectTimeTrackingShowActivity.value && (
          <ControlWrapper>
            <ControllerPlus
              control={control}
              name="activityArticle"
              render={({ field }) => {
                return (
                  <ArticlePickerField
                    {...field}
                    placeholder={t('COMMON.ACTIVITY')}
                    data={{
                      notdeductable: null,
                    }}
                    useSearchHistory={false}
                    prefilter={[
                      {
                        articleKind: {
                          $eq: ArticleKind.TAETIGKEIT_FUER_ZEITERFASSUNG,
                        },
                      },
                    ]}
                  />
                );
              }}
            />
          </ControlWrapper>
        )}

        {projectTimeTrackingShowTicket.value && (
          <ControlWrapper>
            <ControllerPlus
              control={control}
              name="ticket"
              render={({ field }) => {
                const projectFilter = watch('project')?.id
                  ? [
                      {
                        projectId: {
                          $eq: watch('project')?.id,
                        },
                      },
                    ]
                  : [];

                const customerFilter =
                  projectFilter.length === 0
                    ? watch('customer')?.id
                      ? [
                          {
                            businessPartnerId: {
                              $eq: watch('customer')?.id,
                            },
                          },
                          {
                            businessPartnerType: {
                              $eq: SdObjType.KUNDE,
                            },
                          },
                        ]
                      : []
                    : [];

                const prefilter = [
                  ...customerFilter,
                  ...projectFilter,
                  { status1: { $ne: TicketStatus.ERLEDIGT } },
                ];
                return (
                  <TicketPickerField
                    {...field}
                    prefilter={prefilter}
                    data={{
                      projectProcess: {
                        process: null,
                        id: null,
                        overBookingPossible: null,
                        planningAmount: null,
                        timeTrackings: [
                          {
                            id: null,
                            amount: null,
                          },
                        ],
                      },
                    }}
                  />
                );
              }}
            />
          </ControlWrapper>
        )}

        <ControlWrapper>
          <LabeledInput
            multiline
            minRows={3}
            label={t('COMMON.COMMENT')}
            {...register('comment')}
          />
        </ControlWrapper>
      </Collapse>

      {renderCustomFields && <CustomFields columns={6} />}
    </div>
  );
}

const CONTRACT_DATA = {
  project: {
    id: null,
    name: null,
    number: null,
    customer: { id: null, name: null, number: null, website: null },
  },
};
