import styles from './GeneralTabPanel.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { LabeledCurrencyInputWithSeparator } from '@work4all/components/lib/input/labeled-currency-input-with-separator/LabeledCurrencyInputWithSeparator';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { formatCurrency } from '@work4all/utils/lib/format-currency';

import { roundPercent } from '../../../../../../../../components/data-tables/sales-opportunities-table/sales-opportunities-evaluation-picker/utils';
import { ContactPickerField } from '../../../../../../../../components/entity-picker/ContactPickerField';
import { CurrencyPickerField } from '../../../../../../../../components/entity-picker/CurrencyPickerField';
import { CustomerPickerField } from '../../../../../../../../components/entity-picker/CustomerPickerField';
import { ProjectPickerField } from '../../../../../../../../components/entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../../../../../../../components/entity-picker/ProjectProcessPickerField';
import { SalesOpportunityRatingStatusPickerField } from '../../../../../../../../components/entity-picker/sales-opportunity-rating-picker-field/SalesOpportunityRatingStatusPickerField';
import { SalesOpportunitiesGroupPickerField } from '../../../../../../../../components/entity-picker/SalesOpportunitiesGroupPickerField';
import { SalesOpportunitiesKindPickerField } from '../../../../../../../../components/entity-picker/SalesOpportunitiesKindPickerField';
import { SalesOpportunitiesStatusPickerField } from '../../../../../../../../components/entity-picker/SalesOpportunitiesStatusPickerField';
import { UserPickerField } from '../../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlus } from '../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledCurrencyInput,
  LabeledInput,
} from '../../../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../../../components';
import { Panel } from '../../../../../components/panel/Panel';
import { SalesOpportunitiesMaskFormValue } from '../../../types';

export const GeneralTabPanel = () => {
  const { t } = useTranslation();

  const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const isPhone = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const form = useFormContextPlus<SalesOpportunitiesMaskFormValue>();

  const { register, control, watch } = form;
  const businessPartnerId = watch('businessPartnerId');
  const businessPartnerType = watch('businessPartner.businessPartnerType');
  const bp = watch('businessPartner');
  const project = watch('project');
  const projectProcess = watch('projectProcess');
  const value = watch('value');
  const probabilityPercent = watch('probabilityPercent');
  const currency = formatCurrency(watch('currency'), t) || '€';

  const ratedValue = useRef(0);

  useEffect(() => {
    ratedValue.current = (roundPercent(probabilityPercent) * value) / 100;
  }, [probabilityPercent, value]);

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <Collapse defaultOpen title={t('MASK.DESCRIPTION')}>
          <Card>
            <ControlWrapper>
              <LabeledInput
                {...register('name')}
                className={styles.title}
                required
                label={t('INPUTS.DESCRIPTION')}
              />
            </ControlWrapper>
            <ControlWrapper columns={isLg ? 2 : 1}>
              <LabeledInput
                {...register('competitors')}
                className={styles.competitors}
                label={t('COMMON.COMPETITORS')}
              />

              <div className={styles.date}>
                <div className={styles.flexItem}>
                  <DateTimeInputPicker
                    {...register('dateNextReview')}
                    withTime={false}
                    clearable={false}
                    dateLabel={t('COMMON.NEXT_REVIEW')}
                  />
                </div>
                <div className={styles.flexItem}>
                  <DateTimeInputPicker
                    {...register('decisionDate')}
                    withTime={false}
                    clearable={false}
                    dateLabel={t('COMMON.DECISION_DATE')}
                  />
                </div>
              </div>
            </ControlWrapper>
            <ControlWrapper>
              <ControllerPlus
                name="currency"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <CurrencyPickerField
                      {...field}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
              <ControllerPlus
                name="value"
                control={control}
                render={({ field }) => {
                  return (
                    <LabeledCurrencyInputWithSeparator
                      label={t('COMMON.EXPECTED_SALES')}
                      {...field}
                      unit={currency}
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  );
                }}
              />
              <ControllerPlus
                name="expectedCosts"
                control={control}
                render={({ field }) => {
                  return (
                    <LabeledCurrencyInputWithSeparator
                      label={t('COMMON.EXPECTED_COST')}
                      {...field}
                      unit={currency}
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  );
                }}
              />
              <ControllerPlus
                name="orderValue"
                control={control}
                render={({ field }) => {
                  return (
                    <LabeledCurrencyInputWithSeparator
                      label={t('COMMON.ORDER_VALUE')}
                      {...field}
                      unit={currency}
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  );
                }}
              />
            </ControlWrapper>
            <ControlWrapper>
              <LabeledInput
                {...register('note')}
                label={t('INPUTS.NOTE')}
                multiline={true}
              />
            </ControlWrapper>
          </Card>
        </Collapse>

        <Collapse defaultOpen title={t('MASK.ASSIGNMENT')}>
          <Card>
            <ControlWrapper>
              <ControllerPlus
                control={control}
                // KM-696 use businessPartner.data
                name="businessPartner"
                render={({ field: customer }) => {
                  return (
                    <ControllerPlus
                      control={control}
                      name="contact"
                      render={({ field: contact }) => {
                        return (
                          <MultiStepControls
                            seperator
                            isColumnDirection={isPhone}
                          >
                            <Step index={0} active>
                              <CustomerPickerField
                                {...customer}
                                value={customer.value?.data ?? null}
                                onChange={(value) => {
                                  if (value !== null) {
                                    customer.onChange({
                                      data: value,
                                      businessPartnerType: SdObjType.KUNDE,
                                      id: value.id,
                                    });
                                  } else {
                                    customer.onChange(value);
                                  }
                                }}
                              />
                            </Step>
                            <Step
                              index={1}
                              active={
                                customer.value != null &&
                                !customer?.value?.data?.isPrivateCustomer
                              }
                            >
                              {customer.value != null && (
                                <ContactPickerField
                                  businessPartnerType={businessPartnerType}
                                  businessPartnerId={businessPartnerId}
                                  businessPartner={bp}
                                  project={project}
                                  projectProcess={projectProcess}
                                  {...contact}
                                />
                              )}
                            </Step>
                          </MultiStepControls>
                        );
                      }}
                    />
                  );
                }}
              />
            </ControlWrapper>
            <ControlWrapper>
              <ControllerPlus
                control={control}
                name="project"
                render={({ field: project }) => {
                  // TODO Add ProjectProcess when it is added to the API.
                  return (
                    <ControllerPlus
                      control={control}
                      name="projectProcess"
                      render={({ field: projectProcess }) => {
                        return (
                          <MultiStepControls isColumnDirection={isPhone}>
                            <Step index={0} active>
                              <ProjectPickerField
                                {...project}
                                data={PROJECT_DATA}
                              />
                            </Step>
                            <Step index={1} active={project.value != null}>
                              {project.value != null && (
                                <ProjectProcessPickerField
                                  projectId={project.value.id}
                                  {...projectProcess}
                                />
                              )}
                            </Step>
                          </MultiStepControls>
                        );
                      }}
                    />
                  );
                }}
              />
            </ControlWrapper>
            <ControlWrapper>
              <ControllerPlus
                name="kind"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <SalesOpportunitiesKindPickerField
                      {...field}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
              <ControllerPlus
                name="group"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <SalesOpportunitiesGroupPickerField
                      {...field}
                      error={fieldState?.error?.message}
                    />
                  );
                }}
              />
            </ControlWrapper>
          </Card>
        </Collapse>

        <Collapse defaultOpen title={t('INPUTS.INVOLVED')}>
          <Card>
            <ControlWrapper columns={isLg ? 2 : 1}>
              <ControllerPlus
                control={control}
                name="user"
                render={({ field }) => {
                  return (
                    <UserPickerField
                      label={t('INPUTS.RESPONSIBLE_PERSON')}
                      {...field}
                    />
                  );
                }}
              />

              <ControllerPlus
                control={control}
                name="user2"
                render={({ field }) => {
                  return (
                    <UserPickerField
                      label={t('INPUTS.RESPONSIBLE_PERSON_2')}
                      {...field}
                    />
                  );
                }}
              />
            </ControlWrapper>
          </Card>
        </Collapse>

        <Collapse defaultOpen title={t('COMMON.STATE_OF_THINGS')}>
          <Card>
            <ControlWrapper gridTemplateColumns="3fr 1fr">
              <ControllerPlus
                control={control}
                name="probabilityPercent"
                render={({ field }) => {
                  return (
                    <ControllerPlus
                      control={control}
                      name="ratingStatus"
                      render={({ field: relField }) => {
                        let value = relField.value;
                        if (
                          relField.value &&
                          field.value !== relField.value.ratingValue
                        )
                          value = null;
                        return (
                          <SalesOpportunityRatingStatusPickerField
                            value={value}
                            onChange={relField.onChange}
                          />
                        );
                      }}
                    />
                  );
                }}
              />

              <ControllerPlus
                control={control}
                name="probabilityPercent"
                render={({ field }) => {
                  return (
                    <LabeledCurrencyInput
                      label={t('COMMON.EVALUATION')}
                      type="number"
                      unit="%"
                      {...field}
                      onBlur={() => {
                        field.onBlur();
                        if (!field.value) return;
                        const percent = roundPercent(field.value);
                        field.onChange(percent);
                        ratedValue.current = (value * percent) / 100;
                      }}
                      inputProps={{
                        min: 0,
                        max: 100,
                        step: 10,
                        type: 'number',
                      }}
                    />
                  );
                }}
              />
            </ControlWrapper>
            <ControlWrapper gridTemplateColumns="3fr 1fr">
              <ControllerPlus
                control={control}
                name="status"
                render={({ field }) => {
                  return <SalesOpportunitiesStatusPickerField {...field} />;
                }}
              />

              <LabeledCurrencyInputWithSeparator
                disabled
                label={t('COMMON.RATED')}
                value={ratedValue.current}
                unit={currency}
              />
            </ControlWrapper>
          </Card>
        </Collapse>
      </Panel>
    </div>
  );
};
