import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MaskOverlayHeader } from '../../../components';
import { MaskOverlaySubmitButton } from '../../../components/MaskOverlaySubmitButton';

import { CheckListOverlayControllerTabs } from './CheckListOverlayControllerTabs';
import { CheckListMaskFormValue } from './types';

export const CheckListOverlayControllerHeader = () => {
  const { t } = useTranslation();
  const {
    formState: { isDirty, isValid, isSubmitting },
    watch,
  } = useFormContext<CheckListMaskFormValue>();

  const titleFieldValue = watch('title');
  const actions = useMemo(() => {
    const isSubmitButtonDisabled = isSubmitting || !isDirty || !isValid;
    return <MaskOverlaySubmitButton disabled={isSubmitButtonDisabled} />;
  }, [isDirty, isSubmitting, isValid]);

  return (
    <MaskOverlayHeader
      title={t('COMMON.CHECKLIST')}
      subTitle={titleFieldValue}
      actions={actions}
      tabs={<CheckListOverlayControllerTabs />}
    />
  );
};
