import styles from './styles.module.scss';

import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { checkModuleRight, Link, useModuleRights } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

import { currencyAsSign, formatNumberAsCurrency } from '@work4all/utils';

import { CurrencyExchangeInfoContext } from '../../../../mask-overlays/mask-overlay/views/inbound-invoice/currency-exchange-info-context';
import { IFileInfoPanelItems } from '../file-info-panel/types';

import { InlineData } from './InlineData';

type SalesCardProps = {
  partner: Customer | Supplier;
  isCustomer: boolean;
  loading: boolean;
  item: IFileInfoPanelItems;
};

export const SalesCard: FC<SalesCardProps> = ({
  partner,
  isCustomer,
  loading,
  item,
}) => {
  const { t } = useTranslation();

  const { rights } = useModuleRights();

  const hasAccessMAR =
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_STATISTICS) &&
    checkModuleRight(
      rights,
      ModuleAccessRightType.FEATURE_ORDER_MANAGEMENT_MODULE
    );

  const currencyExchangeInfo = useContext(CurrencyExchangeInfoContext);
  const currency = currencyAsSign(currencyExchangeInfo?.defaultCurrency?.name);

  const salesValues = partner?.kpis?.salesValues;
  const currentYearIndex = salesValues?.findIndex(
    (x) => new Date(x.toDate) >= new Date()
  );
  const currentYearSales = salesValues?.[currentYearIndex]?.value || 0;
  const currentValue = formatNumberAsCurrency(currentYearSales || 0);
  const lastYearSales = salesValues?.[currentYearIndex - 1]?.value || 0;
  const previousValue = formatNumberAsCurrency(lastYearSales || 0);

  return !hasAccessMAR ? null : (
    <InlineData
      key={item}
      label={t('DASHBOARD.SALES')}
      content={
        loading || !currency ? (
          '. / .'
        ) : (
          <Trans
            i18nKey={t('FILE_CONTACTS.SALES_VOLUME.CONTENT')}
            values={{
              current: currentYearSales ? `${currentValue} ${currency}` : '.',
              previous: lastYearSales ? `${previousValue} ${currency}` : '.',
            }}
            components={{
              currentAnchor: currentYearSales ? (
                <Link
                  className={styles.link}
                  to={`/more/statistic/${
                    isCustomer ? 'customer' : 'supplier'
                  }sheet?year=current&id=${partner?.id}`}
                />
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              ),
              previousAnchor: lastYearSales ? (
                <Link
                  className={styles.link}
                  to={`/more/statistic/${
                    isCustomer ? 'customer' : 'supplier'
                  }sheet?year=last&id=${partner?.id}`}
                />
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              ),
            }}
          />
        )
      }
    />
  );
};
