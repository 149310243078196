import styles from './../../MaskOverlay.module.scss';

import { noop } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks/object-lock';

import {
  useDataMutation,
  useDataProvider,
  useFormPlus,
  useUser,
} from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Department } from '@work4all/models/lib/Classes/Department.entity';
import { InputAnsprechpartnerRelation } from '@work4all/models/lib/Classes/InputAnsprechpartnerRelation.entity';
import { Salutation } from '@work4all/models/lib/Classes/Salutation.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import {
  canDeleteContact,
  canEditContact,
} from '@work4all/utils/lib/permissions';

import { useEMailSuggestions } from '../../../../../components/entity-picker/EMailSuggestionPickerField';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteButton } from '../../components/MaskOverlayDeleteButton';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayStandardSubmitButton } from '../../components/MaskOverlayStandardSubmitButton';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { useFormUpdate } from '../../utils/use-form-update';

import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { PRTabPanel } from './components/tab-panels/pr/PRTabPanel';
import { ContactMaskFormValue } from './types';

export type ContactSources = Customer | Supplier;
export enum ContactGroups {
  general = 'general',
  relations = 'relations',
  individual = 'individual',
}

export const ContactsOverlayController = (props: MaskControllerProps) => {
  const { t } = useTranslation();
  const mask = useMaskConfig(props);
  if (
    !mask.template ||
    !mask.template.entity ||
    !mask.template.id ||
    !([Entities.customer, Entities.supplier] as string[]).includes(
      mask.template.entity
    )
  ) {
    throw new Error(
      'The contacts overlay needs a reference to a businesspartner or will not work properly'
    );
  }

  const type =
    mask.template.entity === Entities.customer
      ? SdObjType.KUNDE
      : SdObjType.LIEFERANT;

  const schema = useEntityJsonSchema(mask.entity);

  const customFieldEntityType =
    type === SdObjType.KUNDE ? 'contact:customer' : 'contact:supplier';
  const customFields = useCustomFieldsConfig({ entity: customFieldEntityType });

  const requestData = useMemo(() => {
    // we will always filter by id and will always try to normalize the data
    // so an id field will be available
    //contactIds are not unique so this mask needs to be provided with a bpType to work properly
    const filter = [
      { id: { $eq: mask.id } },
      {
        businessPartnerType: {
          $eq: type,
        },
      },
    ];

    const data: Contact = {
      id: null,
      firstName: null,
      name: null,
      displayName: null,
      businessPartnerType: null,
      businessPartnerId: null,
      salutation: {
        id: null,
        name: null,
        standardletterSalutation: null,
      },
      departmentId: null,
      department: {
        id: null,
        name: null,
      },
      businessPartner: {
        id: null,
        businessPartnerType: null,
      },
      letterSalutation: null,
      birthdayDate: null,
      eMail: null,
      eMailPrivate: null,
      faxNumber: null,
      faxPrivate: null,
      phoneNumber: null,
      phoneNumber2: null,
      phoneNumber3: null,
      phoneNumberPrivate: null,
      mobileNumber: null,
      mobileNumberPrivate: null,
      layedOff: null,
      serialLetterLock: null,
      role: null,
      nameExtension: null,
      titleExtension: null,
      addressExtension: null,
      showDepartmentInAddress: null,
      showFunctionInAddress: null,
      note: null,
      city: null,
      postalCode: null,
      country: null,
      street: null,
      isMainContact: null,
      categoryAssignmentList: [
        {
          id: null,
          categoryId: null,
          categoryKindName: null,
          categoryName: null,
        },
      ],
      attachements: [
        {
          id: null,
          index: null,
          objMemberCode: null,
          type: null,
          fileInfos: {
            fileEntityFilename: null,
            downloadUrl: null,
            previewUrl: null,
            fileRequestType: null,
            downloadMimeType: null,
            previewMimeType: null,
            fileSize: null,
          },
        },
      ],
      customFieldList: [CUSTOM_FIELDS_DATA],
    };

    return {
      filter,
      entity: Entities.contact,
      data,
    };
  }, [mask.id, type]);

  const initialFormValue = useInitialFormValue<Contact>(
    requestData,
    mask.isCreateMode
  );

  const salutationRequest = useMemo<DataRequest>(() => {
    const salutationData: Salutation = {
      id: null,
      name: null,
      standardletterSalutation: null,
      isFemale: null,
      isMale: null,
      title: null,
      number: null,
      nameOrFirstName: null,
    };
    return {
      entity: Entities.salutation,
      data: salutationData,
      completeDataResponse: true,
      filter: [{ isMale: { $eq: true } }],
      sort: [{ field: 'number', direction: SortDirection.ASCENDING }],
    };
  }, []);

  const salutationResponse = useDataProvider<Salutation>(salutationRequest);

  const newEntityData: Contact = useMemo(() => {
    return {
      businessPartnerType: type,
      businessPartnerId: parseInt(`${mask.template.id}`, 10),
      businessPartner: {
        id: parseInt(`${mask.template.id}`, 10),
        businessPartnerType: type,
      },
      salutation: salutationResponse?.data?.[0],
      salutationId: salutationResponse?.data?.[0]?.id,
    };
  }, [mask.template.id, salutationResponse?.data, type]);

  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const data = useMemo(() => {
    return normalizeCustomFields(normalizeFormValue(dataRaw), customFields);
  }, [dataRaw, customFields]);

  const resolver = useJSONSchemaResolver(schema);
  const form = useFormPlus<ContactMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const { handleSubmit, reset, formState, watch, setValue } = form;
  const { dirtyFields } = formState;

  const [mutate] = useDataMutation<
    Contact,
    EMode.upsert,
    InputAnsprechpartnerRelation
  >({
    entity: Entities.contact,
    mutationType: EMode.upsert,
    responseData: requestData.data as unknown as Contact<EMode.entity>,
    onCompleted: props.onAfterSave,
    resetStore: !mask.isCreateMode,
  });

  useLock(
    {
      subEntityType: Entities.contact,
      subEntityIds: mask.id ? [mask.id.toString()] : [],
      forcedObjectType:
        type === SdObjType.KUNDE
          ? 'KUNDENANSPRECHPARTNER'
          : 'LIEFERANTENANSPRECHPARTNER',
    },
    [data]
  );

  const isDirty = Object.keys(dirtyFields).length > 0;

  useConfirmBeforeCloseMask(isDirty);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const [enableEMailSuggestions, setEnableEMailSuggestions] = useState(false);

  const firstName = watch('firstName');
  const name = watch('name');
  const salutation = watch('salutation');
  const titleExtension = watch('titleExtension');
  const nameExtension = watch('nameExtension');
  const businessPartnerId = watch('businessPartnerId');
  const businessPartnerType = watch('businessPartnerType');

  const contactCountRequestData = useMemo(() => {
    const filter = [
      {
        businessPartnerType: {
          $eq: type,
        },
      },
      {
        businessPartnerId: {
          $eq: businessPartnerId,
        },
      },
    ];

    const data: Contact = {
      id: null,
    };

    return {
      filter,
      entity: Entities.contact,
      data,
    };
  }, [businessPartnerId, type]);

  const { total: totalContacts } = useDataProvider<Contact>(
    contactCountRequestData
  );

  const { distinctEmailSuggestion } = useEMailSuggestions({
    businessPartnerId: businessPartnerId,
    businesspartnerType: businessPartnerType,
    firstname: firstName,
    lastname: name,
    skip: !enableEMailSuggestions,
  });

  useFormUpdate(
    {
      salutation: (salutation: Salutation) => {
        return { salutationId: salutation?.id };
      },
      department: (department: Department) => {
        return { departmentId: department?.id || 0 };
      },
    },
    form
  );

  const handleEMailSuggestion = useCallback(() => {
    if (enableEMailSuggestions && distinctEmailSuggestion) {
      setValue('eMail', distinctEmailSuggestion, { shouldDirty: true });
    }
  }, [distinctEmailSuggestion, enableEMailSuggestions, setValue]);

  useEffect(() => {
    if (mask.id || totalContacts === 0) {
      setEnableEMailSuggestions(false);
    } else {
      setEnableEMailSuggestions(true);
    }
  }, [mask.id, totalContacts]);

  useEffect(() => {
    handleEMailSuggestion();
  }, [handleEMailSuggestion]);

  const contactFullName = useMemo(() => {
    if (!firstName && !name) {
      return undefined;
    }
    return `${firstName} ${name}`;
  }, [firstName, name]);

  const user = useUser();
  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(
    mask,
    props.onDelete
  );
  const submitForm = async (submitData: ContactMaskFormValue) => {
    const relations: InputAnsprechpartnerRelation = {
      categoryMarks: {
        add: submitData?.categoryAssignmentList
          ?.filter(
            (currentAssignment) =>
              !data?.categoryAssignmentList?.find(
                (prevAssignment) =>
                  currentAssignment.categoryId === prevAssignment.categoryId
              )
          )
          ?.map((assignment) => ({
            categoryCode: assignment.categoryId,
          })),
        remove: data?.categoryAssignmentList
          ?.filter(
            (prevAssignment) =>
              !submitData?.categoryAssignmentList.find(
                (currentAssignment) =>
                  currentAssignment.categoryId === prevAssignment.categoryId
              )
          )
          ?.map((assignment) => assignment.categoryId),
      },
    };

    if (submitData?.birthdayDate?.length === 0) {
      submitData.birthdayDate = undefined;
    }

    const updateRaw = mask.isCreateMode
      ? { ...newEntityData, ...submitData }
      : pickUpdateFields(submitData, {
          ...formState.dirtyFields,
          businessPartnerType: true,
        });

    const updateMapped = prepareInputWithCustomFields(updateRaw);

    await mutate(updateMapped, relations ? { relations } : undefined);
  };

  const entityRights = useMemo(
    () => ({
      create: canEditContact(user, { businessPartnerType: type }),
      read: false,
      update: canEditContact(user, { businessPartnerType: type }),
      delete: canDeleteContact(user, { businessPartnerType: type }),
    }),
    [type, user]
  );

  const maskContext = useMaskContextValue({ ...mask, data, customFields });

  useEffect(() => {
    const isChanged =
      dirtyFields.name ||
      dirtyFields.salutation ||
      dirtyFields.firstName ||
      dirtyFields.titleExtension ||
      dirtyFields.nameExtension;

    if ((mask.isEditMode && !isChanged) || initialFormValue.loading) return;

    const greetingName =
      salutation?.nameOrFirstName === undefined
        ? name
        : salutation.nameOrFirstName
        ? name
        : firstName;

    const greeting = [
      salutation?.standardletterSalutation,
      titleExtension,
      greetingName,
      nameExtension,
    ]
      .join(' ')
      .trim()
      .replace(/\s\s+/g, ' ');

    setValue('letterSalutation', `${greeting},`, {
      shouldDirty: true,
    });
  }, [
    name,
    firstName,
    salutation,
    titleExtension,
    nameExtension,
    setValue,
    mask,
    initialFormValue,
    dirtyFields.name,
    dirtyFields.salutation,
    dirtyFields.firstName,
    dirtyFields.titleExtension,
    dirtyFields.nameExtension,
  ]);

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <EMailSuggestionContext.Provider
          value={{
            enableEMailSuggestions,
            setEnableEMailSuggestions,
          }}
        >
          <FormProvider {...form}>
            <MaskTabContext defaultValue="general">
              <Form
                className={styles.maskForm}
                onSubmit={handleSubmit(submitForm)}
              >
                <MaskOverlayHeader
                  title={t(`COMMON.CONTACT`)}
                  subTitle={contactFullName}
                  actions={
                    <>
                      <MaskOverlayStandardSubmitButton />
                      <MaskOverlayFullscreenToggleButton />
                      {mask.isEditMode && (
                        <MaskOverlayDeleteButton
                          disabled={!entityRights.delete || mask.wip}
                          onClick={handleDeleteEntitiesClick}
                        />
                      )}
                    </>
                  }
                  tabs={
                    <ContactTabs
                      renderIndividualTab={shouldRenderIndividualTab}
                    />
                  }
                />
                <ContactTabPanels
                  renderIndividualTab={shouldRenderIndividualTab}
                />
              </Form>
            </MaskTabContext>
          </FormProvider>
        </EMailSuggestionContext.Provider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const ContactTabs = memo(function ContactTabs({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab value="private" label={t('MASK.PR')}></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const ContactTabPanels = memo(function ContactTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="private">
        <PRTabPanel />
      </MaskTabPanel>
      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});

export const EMailSuggestionContext = React.createContext({
  enableEMailSuggestions: true,
  setEnableEMailSuggestions: noop,
});
