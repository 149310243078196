import styles from './HomePage.module.scss';

import { Box, Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';

import { FixedBackground } from '@work4all/components/lib/components/FixedBackground';
import { GridLayout } from '@work4all/components/lib/dataDisplay/grid-layout/GridLayout';
import { YSplit } from '@work4all/components/lib/layout/y-split/YSplit';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import {
  AppParts,
  useCanView,
  useCustomBackgroundsContext,
  useModuleRights,
  useUser,
} from '@work4all/data';

import { User } from '@work4all/models/lib/Classes/User.entity';

import { UserButtonMobile } from '../../components/user-menu/components/UserButton/UserButtonMobile';
import { CalendarEventClickData } from '../../components/vacation/VacationOverview';
import { useSuggestionsContext } from '../../contexts/SuggestionsContext';
import { useSetting } from '../../settings';
import { AppointmentMaskOverlay } from '../calendar/components/AppointmentMaskOverlay';
import { useInitialUsersDataProvider } from '../calendar/hooks/use-initial-users-data-provider';
import {
  BLANK_LAYOUT,
  LAYOUT_SIZES,
  WIDGET_COLS,
} from '../files/detail/components/card-widgets/WidgetGroupContent';
import {
  getStartForNewProjectTime,
  useProjectHoursState,
} from '../time-tracker/ProjectHours';
import {
  ProjectTimeMask,
  ProjectTimeMaskProps,
} from '../time-tracker/ProjectTimeMask';
import TimeTemplatesWidget from '../time-tracker/TimeTemplatesWidget';
import { TimeTracker } from '../time-tracker/TimeTracker';
import { useTimeTracker } from '../time-tracker/use-time-tracker';
import { VacationMaskOverlay } from '../vacations/VacationMaskOverlay';

import { CalendarCard } from './components/cards/CalendarCard';
import { OverviewCard } from './components/cards/overview/OverviewCard';
import { RelevantCard } from './components/cards/relevant/RelevantCard';
import { EntityWidget } from './components/home-widgets/EntityWidget';
import { HomeWidgetSettings } from './components/home-widgets/home-widget-settings/HomeWidgetSettings';
import {
  HomeWidget,
  HomeWidgetsContext,
} from './components/home-widgets/HomeWidgetsContextProvider';
import { HomeWidgetType } from './components/home-widgets/HomeWidgetType.enum';
import { DEFAULT_HOME_WIDGET_LAYOUTS } from './components/home-widgets/layouts';
import { WidgetDivider } from './components/home-widgets/WidgetDivider';
import { HomeWidgetGroup } from './components/home-widgets/WidgetGroup';

type WidgetID =
  | 'vacation'
  | 'calendar'
  | 'workHours'
  | 'projectHours'
  | 'relevant'
  | 'overview';

type WidgetsState = Record<WidgetID, boolean>;

const DEFAULT_WIDGETS_STATE_DESKTOP: WidgetsState = {
  vacation: false,
  calendar: false,
  workHours: false,
  projectHours: false,
  relevant: false,
  overview: false,
};

const DEFAULT_WIDGETS_STATE_MOBILE: WidgetsState = {
  vacation: true,
  calendar: true,
  workHours: false,
  projectHours: false,
  relevant: false,
  overview: false,
};

export const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { activeBackground } = useCustomBackgroundsContext();
  const user = useUser();
  const { rights } = useModuleRights();

  const isDesktop = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.up('xl'),
    {
      noSsr: true,
    }
  );

  const isTablet = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down('md'),
    {
      noSsr: true,
    }
  );

  const { value: widgetsState, set: setWidgetsState } = useSetting({
    name: `home.widgets.${isDesktop ? 'desktop' : 'mobile'}`,
    scope: 'user',
    defaultValue: isDesktop
      ? DEFAULT_WIDGETS_STATE_DESKTOP
      : DEFAULT_WIDGETS_STATE_MOBILE,
  });

  const isWidgetCollapsed = useCallback(
    (widgetId: string) => {
      return widgetsState[widgetId] ?? false;
    },
    [widgetsState]
  );

  const setWidgetCollapsed = useCallback(
    (widgetId: string) => (collapsed: boolean) => {
      setWidgetsState({ ...widgetsState, [widgetId]: collapsed });
    },
    [setWidgetsState, widgetsState]
  );

  const [editHomeWidget, setEditHomeWidget] = useState<HomeWidget>(null);

  const projectHoursState = useProjectHoursState({ userToTrack: user });

  const canViewProjectTimeTracking = useCanView(AppParts.PROJECTTIMETRACKING);
  const canViewWorkTimeTracking = useCanView(AppParts.WORKTIMETRACKING);

  const [showProjectTimeMask, setShowProjectTimeMask] =
    useState<ProjectTimeMaskProps | null>(null);

  const [showTimeTemplatesMask, setShowTimeTemplatesMask] =
    useState<boolean>(false);

  const [showHomeWidgetSettings, setShowHomeWidgetSettings] =
    useState<boolean>(false);

  const [showVacationMask, setShowVacationMask] = useState<{
    open: boolean;
    date?: Date | null;
    user?: User | null;
    init?: CalendarEventClickData;
  }>({ open: false });

  const tracker = useTimeTracker({
    disabled: !canViewProjectTimeTracking,
    amplitudeEntryPoint: 'HomeWidget',
  });

  const [showAppointmentMask, setShowAppointmentMask] = useState<{
    open: boolean;
    appointmentId?: number | null;
    presetFields?: string | null;
  }>({ open: false });

  const { hideSuggestion } = useSuggestionsContext();
  const {
    layouts,
    breakpoint,
    updateLayout,
    setBreakpoint,
    widgets,
    modifyConf,
    draggedWidget,
    hoveredWidget,
    setDraggedWidgetId,
    setHoveredWidgetId,
  } = useContext(HomeWidgetsContext);

  const heightCorrectedLayouts = useMemo(() => {
    const result = cloneDeep(layouts || BLANK_LAYOUT);
    if (result)
      for (const size of LAYOUT_SIZES) {
        result[size] = result[size].map((lay) => {
          const widget = widgets.find((x) => x.id === lay.i);
          const resolvedHeight = isWidgetCollapsed(widget?.id)
            ? 1
            : !isWidgetCollapsed(widget?.id) && lay.h === 1
            ? lay.minH || 3
            : lay.h;
          return {
            ...lay,
            h: resolvedHeight,
            isResizable: !isWidgetCollapsed(widget?.id),
          } as Layout;
        });
      }

    return result;
  }, [isWidgetCollapsed, layouts, widgets]);

  const handleMerge = useCallback(() => {
    if (draggedWidget.type === HomeWidgetType.widgetGroup) {
      if (hoveredWidget.type === HomeWidgetType.widgetGroup) {
        const newWidgets = cloneDeep(widgets);
        newWidgets
          .find((x) => x.id === hoveredWidget.id)
          ?.subWidgetIds?.push(...draggedWidget.subWidgetIds);

        modifyConf({
          widgets: {
            set: newWidgets,
          },
          layout: {
            remove: [draggedWidget.id],
          },
        });
      }
    }

    if (draggedWidget.type === HomeWidgetType.entityWidget) {
      if (hoveredWidget.type === HomeWidgetType.entityWidget) {
        const newWidget: HomeWidget = {
          id: new Date().toISOString(),
          type: HomeWidgetType.widgetGroup,
          title: t('COMMON.GROUP'),
          subWidgetIds: [draggedWidget?.id, hoveredWidget?.id],
        };
        modifyConf({
          layout: {
            remove: [draggedWidget?.id, hoveredWidget?.id],
            add: [newWidget?.id],
          },
          widgets: {
            add: [newWidget],
          },
        });
      }

      if (hoveredWidget.type === HomeWidgetType.widgetGroup) {
        const newWidgets = cloneDeep(widgets);
        newWidgets
          .find((x) => x.id === hoveredWidget.id)
          ?.subWidgetIds?.push(draggedWidget.id);

        modifyConf({
          widgets: {
            set: newWidgets,
          },
          layout: {
            remove: [draggedWidget.id],
          },
        });
      }
    }
  }, [
    draggedWidget?.id,
    draggedWidget?.subWidgetIds,
    draggedWidget?.type,
    hoveredWidget?.id,
    hoveredWidget?.type,
    modifyConf,
    t,
    widgets,
  ]);

  const homeWidgetSettingsView = useMemo(
    () => ({
      view: (
        <HomeWidgetSettings
          amplitudeEntryPoint="HomeWidget"
          widgetId={editHomeWidget?.id}
        />
      ),
      title: t('TIME_TRACKER.PROJECT_TIME'),
    }),
    [editHomeWidget?.id, t]
  );

  const initialCalendarUsers = useInitialUsersDataProvider({
    userIds: [user?.benutzerCode],
    disabled: !user,
  });

  const [minOverviewCardHeight, setMinOverviewCardHeight] = useState(
    heightCorrectedLayouts[breakpoint].find(
      (layout) => layout.i === HomeWidgetType.overviewWidget
    ).h
  );
  const [minRelevantCardHeight, setMinRelevantCardHeight] = useState(
    heightCorrectedLayouts[breakpoint].find(
      (layout) => layout.i === HomeWidgetType.relevantWidget
    ).h
  );

  const handleOverviewCardActiveItems = useCallback(
    (items: number) => {
      // workaround as sizes in react-grid-layout and OverviewCard are not related
      // and the layout row size doesn't match sizes from OverviewCard
      setMinOverviewCardHeight(
        Math.max(
          items < 8 ? items : items * 0.85,
          DEFAULT_HOME_WIDGET_LAYOUTS[breakpoint].find(
            (layout) => layout.i === HomeWidgetType.overviewWidget
          ).minH
        )
      );
    },
    [breakpoint]
  );

  const handleRelevantCardActiveItems = useCallback(
    (items: number) => {
      // workaround as sizes in react-grid-layout and RelevantCard are not related
      // and the layout row size doesn't match row size from RelevantCard
      setMinRelevantCardHeight(
        Math.max(
          items < 7 ? items / 1.3 : items / 1.5,
          DEFAULT_HOME_WIDGET_LAYOUTS[breakpoint].find(
            (layout) => layout.i === HomeWidgetType.relevantWidget
          ).minH
        )
      );
    },
    [breakpoint]
  );

  const homePageLayouts = useMemo(
    () => ({
      ...heightCorrectedLayouts,
      [breakpoint]: heightCorrectedLayouts[breakpoint].map((layout) => {
        if (isWidgetCollapsed(layout.i)) {
          return layout;
        }
        if (layout.i === HomeWidgetType.overviewWidget) {
          return {
            ...layout,
            h: Math.max(minOverviewCardHeight, layout.h),
            minH: minOverviewCardHeight,
          };
        }
        if (layout.i === HomeWidgetType.relevantWidget) {
          return {
            ...layout,
            h: Math.max(minRelevantCardHeight, layout.h),
            minH: minRelevantCardHeight,
          };
        }
        return layout;
      }),
    }),
    [
      breakpoint,
      heightCorrectedLayouts,
      isWidgetCollapsed,
      minOverviewCardHeight,
      minRelevantCardHeight,
    ]
  );

  return (
    <div data-test-id="home-page-wrapper">
      <NavigationOverlay
        classes={{
          wrapper: styles.dialogWrapper,
          paper: styles.paper,
        }}
        open={showHomeWidgetSettings}
        initialView={homeWidgetSettingsView}
        close={() => {
          setShowHomeWidgetSettings(false);
        }}
      />
      <NavigationOverlay
        classes={{
          wrapper: styles.dialogWrapper,
          paper: styles.paper,
        }}
        open={showProjectTimeMask !== null}
        initialView={{
          view: (
            <ProjectTimeMask
              user={user}
              item={{ ...showProjectTimeMask?.item, insertTime: undefined }}
              init={showProjectTimeMask?.init}
              amplitudeEntryPoint="HomeWidget"
              onCompleted={() => {
                const id = showProjectTimeMask?.init?.id;
                if (id) hideSuggestion(id);
              }}
              createdFrom={showProjectTimeMask?.createdFrom}
            />
          ),
          title: t('TIME_TRACKER.PROJECT_TIME'),
        }}
        close={() => {
          setShowProjectTimeMask(null);
        }}
      />
      <NavigationOverlay
        classes={{
          wrapper: styles.dialogWrapper,
          paper: styles.paper,
        }}
        open={showTimeTemplatesMask}
        initialView={{
          view: (
            <TimeTemplatesWidget
              mobileMode={true}
              onTemplateSelect={(init, createdFrom) => {
                const { events, date } = projectHoursState;
                const adjustedDate = getStartForNewProjectTime(
                  date.from,
                  events,
                  tracker
                );

                setShowProjectTimeMask({
                  init: { date: adjustedDate, ...init },
                  createdFrom,
                });
                setShowTimeTemplatesMask(false);
              }}
            />
          ),
          title: 'Templates',
        }}
        close={() => {
          setShowTimeTemplatesMask(false);
        }}
      />

      <VacationMaskOverlay
        open={showVacationMask.open}
        date={showVacationMask.date}
        user={showVacationMask.user}
        init={showVacationMask.init}
        onClose={() => setShowVacationMask({ open: false })}
      />

      <AppointmentMaskOverlay
        open={showAppointmentMask.open}
        appointmentId={showAppointmentMask.appointmentId}
        presetFields={showAppointmentMask.presetFields}
        onClose={() => setShowAppointmentMask({ open: false })}
      />

      <FixedBackground
        className={styles.backgroundShape}
        src={activeBackground?.url}
        blend="auto"
      />

      {!isTablet ? (
        <GridLayout
          layouts={homePageLayouts}
          onBreakpointChange={setBreakpoint}
          draggableCancel="a"
          onDragStart={(e, layout) => {
            setDraggedWidgetId(layout.i);
          }}
          onDragStop={(layout) => {
            if (draggedWidget && hoveredWidget) {
              handleMerge();
            } else {
              updateLayout(layout);
            }
            setDraggedWidgetId(null);
            setHoveredWidgetId(null);
          }}
          onResizeStop={(layout) => {
            updateLayout(layout);
          }}
          cols={WIDGET_COLS}
        >
          {homePageLayouts?.[breakpoint]?.map((w: Layout) => {
            const widget = widgets.find((x) => w.i === x.id);
            let comp = null;
            switch (widget?.type) {
              case HomeWidgetType.calendarWidget:
                comp = (
                  <CalendarCard
                    userList={initialCalendarUsers.data}
                    sx={{ height: '100%' }}
                    forceMobileView={true}
                    collapsed={isWidgetCollapsed(widget.id)}
                    onCollapsedChange={setWidgetCollapsed(widget.id)}
                    onOpenAppointmentMask={(options) => {
                      setShowAppointmentMask({ open: true, ...options });
                    }}
                  />
                );
                break;
              case HomeWidgetType.overviewWidget:
                comp = (
                  <OverviewCard
                    sx={{ height: '100%' }}
                    collapsed={isWidgetCollapsed(widget.id)}
                    onCollapsedChange={setWidgetCollapsed(widget.id)}
                    onActiveRowsChange={handleOverviewCardActiveItems}
                  />
                );
                break;
              case HomeWidgetType.relevantWidget:
                comp = (
                  <RelevantCard
                    sx={{ height: '100%' }}
                    collapsed={isWidgetCollapsed(widget.id)}
                    onCollapsedChange={setWidgetCollapsed(widget.id)}
                    onActiveRowsChange={handleRelevantCardActiveItems}
                  />
                );
                break;
              case HomeWidgetType.timeTracker:
                comp = <TimeTracker className={styles.timeTracker} />;
                break;
              case HomeWidgetType.entityWidget:
                comp = (
                  <EntityWidget
                    {...widget}
                    onDeleteClick={() =>
                      modifyConf({ widgets: { remove: [widget.id] } })
                    }
                    onEditClick={() => {
                      setEditHomeWidget(widget);
                      setShowHomeWidgetSettings(true);
                    }}
                    pageSize={(w.h - 1) * 2 || 1}
                    collapsed={isWidgetCollapsed(widget.id)}
                    onCollapsedChange={setWidgetCollapsed(widget.id)}
                    collapsible={true}
                  />
                );
                break;
              case HomeWidgetType.widgetGroup:
                comp = (
                  <HomeWidgetGroup
                    {...widget}
                    subWidgets={widget.subWidgetIds.map((id) =>
                      widgets.find((w) => w.id === id)
                    )}
                    onDeleteClick={() =>
                      modifyConf({ widgets: { remove: [widget.id] } })
                    }
                    onEditClick={() => {
                      setEditHomeWidget(widget);
                      setShowHomeWidgetSettings(true);
                    }}
                    onBreakupClick={() => {
                      modifyConf({
                        widgets: { remove: [widget.id] },
                        layout: {
                          remove: [widget.id],
                          add: widget.subWidgetIds,
                        },
                      });
                    }}
                  />
                );
                break;
              case HomeWidgetType.divider:
                comp = (
                  <WidgetDivider
                    {...widget}
                    onDeleteClick={() =>
                      modifyConf({ widgets: { remove: [widget.id] } })
                    }
                    onEditClick={() => {
                      setShowHomeWidgetSettings(true);
                      setEditHomeWidget(widget);
                    }}
                  />
                );

                break;
              default:
                break;
            }

            return (
              <div
                key={widget?.id}
                className={clsx(styles.widgetWrap, {
                  [styles.hoveredWidget]:
                    draggedWidget &&
                    draggedWidget.type === HomeWidgetType.entityWidget &&
                    widget.type === HomeWidgetType.entityWidget &&
                    hoveredWidget?.id === widget?.id,
                  [styles.draggedWidget]: draggedWidget?.id === widget?.id,
                })}
              >
                <div style={{ height: '100%' }}>{comp}</div>
              </div>
            );
          })}
        </GridLayout>
      ) : (
        <YSplit className={styles.outerWrap}>
          <Stack
            direction={!isTablet ? 'row' : 'column'}
            height="auto"
            gap="1rem"
            flex="1"
            flexBasis="0"
            maxWidth="100%"
            width="85rem"
            padding={
              isDesktop
                ? '0 1rem 1rem 1rem'
                : isTablet
                ? '1rem 0.75rem 1.5rem 0.75rem'
                : '0 0.75rem 1.5rem 0.75rem'
            }
          >
            <Grid container spacing="1rem">
              {!isDesktop && canViewWorkTimeTracking && (
                <Grid item xs={6}>
                  <TimeTracker className={styles.timeTracker} />
                </Grid>
              )}

              <Grid item xs={!isDesktop && canViewWorkTimeTracking ? 6 : 12}>
                <Box sx={{ alignSelf: 'center' }}>
                  <UserButtonMobile />
                </Box>
              </Grid>
            </Grid>

            <RelevantCard
              collapsed={isWidgetCollapsed('relevant')}
              onCollapsedChange={setWidgetCollapsed('relevant')}
            />
            {!isTimeTrackingUser(rights) && (
              <CalendarCard
                height="fixed"
                forceMobileView={true}
                collapsed={isWidgetCollapsed('calendar')}
                onCollapsedChange={setWidgetCollapsed('calendar')}
                onOpenAppointmentMask={(options) => {
                  setShowAppointmentMask({ open: true, ...options });
                }}
                userList={initialCalendarUsers.data}
              />
            )}
            {!isTimeTrackingUser(rights) && (
              <OverviewCard
                collapsed={isWidgetCollapsed('overview')}
                onCollapsedChange={setWidgetCollapsed('overview')}
              />
            )}
          </Stack>
        </YSplit>
      )}
    </div>
  );
};
