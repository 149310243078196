import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useUser } from '@work4all/data';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useInitialFormValue } from '../../../hooks/useInitialFormValue';
import { MaskExtendedConfig } from '../../../types';
import { useAssignableTemplateEntity } from '../../../utils/use-assignable-template-entity';

import { CHECK_LIST_REQUEST_DATA } from './constants';
import { CheckListMaskFormValue } from './types';

export const useCheckListFormData = (
  mask: MaskExtendedConfig
): CheckListMaskFormValue => {
  const editModeDataRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.checkList,
      data: CHECK_LIST_REQUEST_DATA,
      filter: [{ id: { $eq: mask.id } }],
    };
  }, [mask.id]);

  const { value: editModeData } = useInitialFormValue<CheckList>(
    editModeDataRequest,
    mask.isCreateMode
  );

  const user = useUser();
  const template = useAssignableTemplateEntity(mask);

  return useMemo(() => {
    if (mask.isCreateMode || !editModeData) {
      let data: CheckListMaskFormValue = {
        date: DateTime.now().toISO(),
        user: { id: user.benutzerCode, displayName: user.displayName },
        userId: user.benutzerCode,
      };

      if (template.enabled && template.data) {
        data = {
          ...data,
          businessPartner: {
            id: template.data.businessPartner?.data?.id ?? 0,
            data: template.data.businessPartner?.data
              ? { ...template.data.businessPartner?.data, mainContact: null }
              : null,
          },
          businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
          businessPartnerType:
            template.data.businessPartner?.businessPartnerType ??
            template.data.businessPartnerType ??
            SdObjType.KUNDE,
          contact: template.data.contact,
          contactId: template.data.contact?.id ?? 0,
          project: template.data.project,
          projectId: template.data.project?.id ?? 0,
          projectProcess: template.data.projectProcess,
          projectProcessId: template.data.projectProcess?.id ?? 0,
        };
      }

      return data;
    }

    return editModeData;
  }, [
    mask.isCreateMode,
    editModeData,
    user.benutzerCode,
    user.displayName,
    template.enabled,
    template.data,
  ]);
};
