import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';

import { TRAVEL_RECEIPTS_DATA } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/TravelReceiptsPreviewContainer';

import { useDataProvider, useUser } from '@work4all/data';

import { TaxKey } from '@work4all/models/lib/Classes/TaxKey.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { MaskExtendedConfig } from '../../../types';
import { CurrencyExchangeInfoContext } from '../../inbound-invoice/currency-exchange-info-context';

const getTaxKeyData = (taxKeys: TaxKey[], vatValue: number | null) => {
  if (!vatValue) {
    return [null, null] as const;
  }

  const taxKey = taxKeys.find((taxKey) => taxKey.vat === vatValue) ?? null;
  const taxKeyValue = taxKey?.taxKeyValue ?? null;

  return [taxKey, taxKeyValue] as const;
};

export const useTravelReceipts = (
  mask: MaskExtendedConfig<string>,
  defaults: TravelReceipts,
  taxKeys: TaxKey[]
) => {
  const exchangeInfo = useContext(CurrencyExchangeInfoContext);

  const request = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: mask.id } }];
    const entity = Entities.travelReceipts;
    const data = {
      ...TRAVEL_RECEIPTS_DATA,
      enumReceiptKindTravelCosts: null,
      costCenterId: null,
      costCenter: {
        id: null,
        name: null,
        number: null,
      },
      vat: null,
      vat1: null,
      vat2: null,
      vat3: null,
      amountGross1: null,
      amountGross2: null,
      amountGross3: null,
      ledgerAccount1Id: null,
      ledgerAccount2Id: null,
      ledgerAccount3Id: null,
      ledgerAccount1: {
        id: null,
        name: null,
        number: null,
      },
      ledgerAccount2: {
        id: null,
        name: null,
        number: null,
      },
      ledgerAccount3: {
        id: null,
        name: null,
        number: null,
      },
      travelExpenses: {
        ...TRAVEL_RECEIPTS_DATA.travelExpenses,
        businessPartner: {
          id: null,
          businessPartnerType: null,
        },
      },
      taxKey1: {
        id: null,
        taxKeyValue: null,
      },
      taxKey2: {
        id: null,
        taxKeyValue: null,
      },
      taxKey3: {
        id: null,
        taxKeyValue: null,
      },
    };
    return {
      filter,
      entity,
      data,
    };
  }, [mask.id]);

  const user = useUser();

  const vatRates = useDataProvider<VatRate>(
    useMemo(() => {
      return {
        entity: Entities.vatRate,
        data: {
          id: null,
          sentence: null,
        },
        completeDataResponse: true,
      };
    }, []),
    !mask.isCreateMode
  );

  const newEntityData = useMemo<TravelReceipts>(() => {
    const vat1 = defaults.vat1 ?? vatRates.data?.[0]?.sentence ?? null;
    const vat2 = defaults.vat2 ?? vatRates.data?.[0]?.sentence ?? null;
    const vat3 = defaults.vat3 ?? vatRates.data?.[0]?.sentence ?? null;

    const [taxKey1, taxKey1Value] = getTaxKeyData(taxKeys, vat1);
    const [taxKey2, taxKey2Value] = getTaxKeyData(taxKeys, vat2);
    const [taxKey3, taxKey3Value] = getTaxKeyData(taxKeys, vat3);

    return {
      date: DateTime.now().toISO(),
      userId: user.benutzerCode,
      currency: exchangeInfo?.defaultCurrency,
      currencyId: exchangeInfo?.defaultCurrency?.id,
      paymentMethod: defaults.paymentMethod,
      paymentKind: defaults.paymentKind,
      receiptKind: defaults.receiptKind,
      vat1,
      vat2,
      vat3,
      taxKey1,
      taxKey2,
      taxKey3,
      taxKey1Value,
      taxKey2Value,
      taxKey3Value,
    };
  }, [
    exchangeInfo?.defaultCurrency,
    user.benutzerCode,
    vatRates.data,
    defaults,
    taxKeys,
  ]);
  return { request, newEntityData };
};
