import { Box } from '@mui/material';
import { useCallback } from 'react';

import { useDateCellClick } from '../../components/row-render/hooks/use-cell-click';

import { DateCell, IDateCellProps } from './dateCell';

export const HookedDateCell: React.FC = (props: IDateCellProps) => {
  const { onDateCellClick: onDateSelect } = useDateCellClick();

  const handleCellClick = useCallback(() => {
    onDateSelect?.(new Date(props.value));
  }, [onDateSelect, props.value]);

  return (
    <Box
      onClick={handleCellClick}
      style={{ cursor: handleCellClick ? 'pointer' : 'auto' }}
    >
      {/**eslint-disable-next-line
        @ts-ignore*/}
      <DateCell {...props} />
    </Box>
  );
};
