import { CheckListOverlayControllerContent } from './CheckListOverlayControllerContent';
import { CheckListOverlayControllerHeader } from './CheckListOverlayControllerHeader';
import { CheckListOverlayControllerMaskFormProvider } from './CheckListOverlayControllerMaskFormProvider';
import { CheckListOverlayControllerMaskTabProvider } from './CheckListOverlayControllerMaskTabProvider';
import { CheckListOverlayControllerProps } from './types';

export const CheckListOverlayController = (
  props: CheckListOverlayControllerProps
) => {
  return (
    <CheckListOverlayControllerMaskFormProvider {...props}>
      <CheckListOverlayControllerMaskTabProvider>
        <CheckListOverlayControllerHeader />
        <CheckListOverlayControllerContent />
      </CheckListOverlayControllerMaskTabProvider>
    </CheckListOverlayControllerMaskFormProvider>
  );
};
