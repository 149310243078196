import { useCallback, useEffect } from 'react';

import { useTableStateBag } from '@work4all/components';
import { useTablePrefilterContext } from '@work4all/components/lib/components/table/TablePrefilterProvider';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { ICustomCellConfigBase } from '@work4all/models/lib/table-schema/table-schema';

import { isBoardEnabled } from '../table/components/kanban-board/Board';
import { useTableLayoutState } from '../table-layout';
import { IUseDataTableOptions, useDataTable } from '../use-data-table';

export interface IUseEntityDataOptions<
  TEntity,
  TCustomColumns extends Record<string, ICustomCellConfigBase>
> extends Omit<
      IUseDataTableOptions<TCustomColumns>,
      'prefilter' | 'layout' | 'tableStateBag'
    >,
    Partial<
      Pick<
        IUseDataTableOptions<TCustomColumns>,
        'prefilter' | 'layout' | 'tableStateBag'
      >
    > {
  rowModifiers?: (value?: TEntity) => Record<string, boolean>;
}

/**
 * Wrapper for Table entities
 * @param options Look useDataTable
 * @returns useDataTable result
 */
export function useEntityDataTable<
  Entity extends Work4AllEntity,
  CustomColumns extends Record<string, ICustomCellConfigBase>
>({ rowModifiers, ...options }: IUseEntityDataOptions<Entity, CustomColumns>) {
  const { prefilter } = useTablePrefilterContext();
  const tableStateBag = useTableStateBag();
  const layoutState = useTableLayoutState();
  const [layoutSetting, setLayout] = layoutState;

  useEffect(() => {
    if (isBoardEnabled(options.schema.entity) || layoutSetting !== 'board')
      return;
    // in case for reset to Table where entity does not suppor board
    setLayout('table');
  }, [options.schema.entity]);

  const dataTable = useDataTable<Entity, CustomColumns>({
    tableStateBag,
    layout:
      isBoardEnabled(options.schema.entity) || layoutSetting !== 'board'
        ? layoutSetting
        : 'table',
    prefilter,
    ...options,
  });

  const prepareRowDisplayModifiers = useCallback(
    (value: Entity) => {
      const defaultModifiers = dataTable.prepareRowDisplayModifiers(value);
      const extraModifiers = rowModifiers?.(value);

      return {
        ...defaultModifiers,
        ...extraModifiers,
      };
    },
    [dataTable, rowModifiers]
  );

  return { ...dataTable, prepareRowDisplayModifiers, schema: options.schema };
}
