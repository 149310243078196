import styles from './TicketPreview.module.scss';

import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation, useInaccessibleFields } from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { settings, useSetting } from '@work4all/data/lib/settings';

import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { isHTMLEmpty, typeNameToSdObjType } from '@work4all/utils';
import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { usePreviewToolbarCustomActionsComponents } from '../../../dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import { Attachments } from '../../attachments';
import {
  InfoCard,
  InfoCards,
  PreviewEditMessage,
  PreviewWrapper,
} from '../components';
import {
  BusinessPartnerPreviewPickerInput,
  ContactPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTextEditorInput,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  TicketKindPreviewPickerInput,
  TicketStatusPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';
import { includesHtml } from '../utils';

import { useTicketSolutionDialog } from './ticket-solution-dialog/use-ticket-solution-dialog';
import { TicketPreviewFields } from './TicketPreviewFields';

export interface ITicketPreviewProps extends EntityPreviewProps<Ticket> {}

export function TicketPreview(props: ITicketPreviewProps) {
  const { entries, openMaskTab, customActions, ...handlers } = props;
  const { t } = useTranslation();

  const { isInaccessible } = useInaccessibleFields();

  const dateIsAccessible = !isInaccessible(Entities.ticket, 'creationDate');

  const [mutate] = useDataMutation<
    Ticket,
    EMode.upsert,
    {
      attachements: InputTicketAttachementsRelation;
    }
  >({
    entity: Entities.ticket,
    mutationType: EMode.upsert,
    responseData: TicketPreviewFields as unknown as Ticket,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.ticket,
    entries: entries,
    mutate,
  });
  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = entityPreview;

  const ticket = {
    ...entries[0],
    problemDescription: !includesHtml(entries[0].problemDescription)
      ? entries[0].problemDescription.replace(/\r\n/g, '<br>')
      : entries[0].problemDescription,
  };

  const callMemoContactData = {
    businessPartner: entries?.[0].businessPartner,
    project: entries?.[0].project,
    projectProcess: entries?.[0].projectProcess,
  };

  const customerIsPrivate = ticket?.businessPartner?.data?.isPrivateCustomer;
  const customerIsCommon = commonFields.includes('businessPartner');

  const cleanedPersistantAttachmentList = useMemo(() => {
    return ticket?.attachmentList?.map((x) => ({
      ...x,
      fileName: x.displayName,
      __typename: undefined,
    }));
  }, [ticket?.attachmentList]);

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const ticketDialog = useTicketSolutionDialog();
  const { value: isTicketSolutionRequiered } = useSetting(
    settings.isTicketSolutionRequiered()
  );

  const solutionText = ticket?.solutionText ?? '';

  const actions = usePreviewToolbarCustomActionsComponents({
    custom: customActions,
    selectedRowsIdsList: entries.map((x) => x.id),
    selectedRowsData: entries,
  });

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.ticket}
      entries={entries}
    >
      <TempFileManagerContext.Provider value={tempFileManager}>
        <PreviewWrapper>
          <PreviewTitleInput
            accessor="title"
            {...handlers}
            leftActions={actions}
            leftSubLabel={ticket?.number ? `${ticket.number}` : undefined}
          />

          {isMultiselect && <PreviewEditMessage entityId={ticket.id} />}
          <Stack direction={'column'}>
            <InfoCards>
              {dateIsAccessible && (
                <InfoCard
                  disabled={true}
                  truncate
                  label={t('FIELDS.creationDate')}
                >
                  {isMultiselect
                    ? `(${t('COMMON.MULTIPLE')})`
                    : DateTime.fromISO(ticket?.creationDate).toLocaleString(
                        DateTimeCustom.DATE_SIMPLE_2YEAR
                      )}
                </InfoCard>
              )}

              <DatePreviewPickerInput
                labelTranslationKey="ticket.followUpDate"
                onClose={onPopoverClose}
                value={ticket?.followUpDate}
                onChange={(value) => {
                  onEdit({ followUpDate: new Date(value).toISOString() });
                }}
                accessor="followUpDate"
              />

              <BusinessPartnerPreviewPickerInput
                labelTranslationKey="COMMON.BUSINESS_PARTNER"
                onClose={onPopoverClose}
                value={ticket?.businessPartner?.data}
                onChange={(value) => {
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: typeNameToSdObjType(
                            value.__typename
                          ),
                          id: value.id,
                        }
                      : null,
                    businessPartnerId: value ? value.id : 0,
                    businessPartnerType: typeNameToSdObjType(value?.__typename),
                    contactId: value?.mainContact?.id ?? null,
                  });
                }}
                accessor="businessPartner"
                showFooter
              />

              {!customerIsPrivate && (
                <ContactPreviewPickerInput
                  labelTranslationKey="COMMON.CONTACT"
                  onClose={onPopoverClose}
                  value={ticket?.contact}
                  otherProps={{ callMemoContactData }}
                  onChange={(value) => {
                    onEdit({
                      contact: value,
                      contactId: value ? value.id : 0,
                    });
                  }}
                  accessor="contact"
                  prefilter={[
                    {
                      businessPartnerType: {
                        $eq:
                          ticket?.businessPartner?.businessPartnerType ??
                          SdObjType.KUNDE,
                      },
                    },
                    {
                      businessPartnerId: {
                        $eq: ticket?.businessPartner?.data?.id ?? 0,
                      },
                    },
                  ]}
                  disabled={!customerIsCommon || !ticket?.businessPartner?.data}
                  showFooter
                  entityTemplateValue={{
                    id: ticket?.businessPartner?.data?.id,
                    entity:
                      ticket?.businessPartner?.businessPartnerType ===
                      SdObjType.KUNDE
                        ? Entities.customer
                        : Entities.supplier,
                  }}
                />
              )}

              <TicketStatusPreviewPickerInput
                labelTranslationKey="COMMON.STATUS"
                onClose={onPopoverClose}
                value={ticket?.status1}
                onChange={async (value) => {
                  if (
                    isTicketSolutionRequiered &&
                    value === TicketStatus.ERLEDIGT &&
                    isHTMLEmpty(solutionText)
                  ) {
                    const solutionText = await ticketDialog();
                    if (!isHTMLEmpty(solutionText))
                      onEdit({ status1: value, solutionText });

                    return;
                  }

                  onEdit({ status1: value });
                }}
                accessor="status1"
              />

              <UserPreviewPickerInput
                labelTranslationKey="COMMON.EDITOR"
                onClose={onPopoverClose}
                value={ticket?.editor1}
                onChange={(value) => {
                  onEdit({
                    editor1: value,
                    editor1Id: value ? value.id : 0,
                  });
                }}
                accessor="editor1"
              />

              <ProjectPreviewPickerInput
                labelTranslationKey="COMMON.PROJECT"
                onClose={onPopoverClose}
                value={ticket?.project}
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value && !Array.isArray(value) ? value.id : 0,
                  });
                }}
                accessor="project"
                showFooter
              />

              <TicketKindPreviewPickerInput
                labelTranslationKey="COMMON.KIND"
                onClose={onPopoverClose}
                value={ticket?.ticketKind}
                onChange={(value) =>
                  onEdit({
                    ticketKindId: value ? value.id : 0,
                  })
                }
                accessor="ticketKind"
              />
            </InfoCards>
            {!isMultiselect ? (
              <>
                <Divider orientation="horizontal" />

                <PreviewTextEditorInput
                  labelTranslationKey="FIELDS.problemDescription"
                  value={ticket?.problemDescription}
                  onClose={(problemDescription) => {
                    if (problemDescription !== ticket?.problemDescription) {
                      onEdit({ problemDescription });
                    }
                    onPopoverClose();
                  }}
                  accessor="problemDescription"
                />

                {ticket.attachmentList.length ? (
                  <Stack
                    direction={'column'}
                    justifyContent="space-between"
                    width={'100%'}
                    alignItems=""
                    sx={{ padding: '0 1rem 1rem 1rem ' }}
                  >
                    <Attachments
                      entity={Entities.ticket}
                      disableAddAction={true}
                      disableRemoveAction={true}
                      layout="compact"
                      disableEditAction={true}
                      truncate={{
                        after: 3,
                        onMoreClick: () => {
                          openMaskTab('attachments');
                        },
                      }}
                      newestAttachmentsFirst
                    />
                  </Stack>
                ) : null}

                <Divider orientation="horizontal" />

                <EntityHistory
                  fitContainer={false}
                  entity={Entities.ticket}
                  fullWidth
                  id={ticket.id}
                  className={styles.hist}
                  breadcrumbTitle={`Ticket ${ticket.number} | ${ticket.title}`}
                />
              </>
            ) : null}
          </Stack>
        </PreviewWrapper>
      </TempFileManagerContext.Provider>
    </PreviewInputsProvider>
  );
}
