import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ConfirmDialog,
  getQuery,
  QUERY_NAME_BY_ENTITY,
  useDialogs,
  useGroups,
  useTableStateBag,
} from '@work4all/components';
import {
  DraggableTree,
  TreeNode,
} from '@work4all/components/lib/dataDisplay/tree';
import {
  createTreeData,
  findNodeBy,
  insertNode,
  mapTreeToGroups,
  reindexTree,
  removeNodeBy,
  replaceNodeBy,
} from '@work4all/components/lib/dataDisplay/tree/utils';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { InputGroup } from '@work4all/models/lib/Classes/InputGroup.entity';

import { createHandleColumnFilterChange } from '../../data-tables/table-quick-filters/utils';
import { NavigationOverlayHeader } from '../../navigation-overlay-header/NavigationOverlayHeader';
import { useOrganisationAreaContext } from '../hooks';
import { useMutateGroups } from '../hooks/use-mutate-groups';
import { entityToEntityGroup } from '../utils/section-to-group-type';

import { OrganisationAreaGroupsNewDialog } from './organisation-area-groups-new-dialog';

type OrganisationAreaGroupsPageProps = {
  onModify: () => void;
};

export const OrganisationAreaGroupsPage: React.FC<
  OrganisationAreaGroupsPageProps
> = ({ onModify }) => {
  const { t } = useTranslation();

  const { entity, setEditOverlayOpen } = useOrganisationAreaContext();

  const groupEntity = entityToEntityGroup(entity);
  const response = useGroups(groupEntity);
  const groups = response.data?.groups ?? null;

  const { treeData } = useMemo(() => createTreeData(groups), [groups]);

  const [draftTree, setDraftTree] = useState<TreeNode[]>([]);

  const isDirty = draftTree?.length > 0;
  const tree = !isDirty && treeData.length > 0 ? treeData : draftTree;

  const { mutateGroups, type: mutationType } = useMutateGroups(groupEntity);

  const [isRemoveGroupDialogOpen, setRemoveGroupDialogOpen] =
    useState<boolean>(false);

  const [groupIdToRemove, setGroupIdToRemove] = useState<{
    id: string;
    index: number;
    isParent: boolean;
  } | null>(null);
  const dialogs = useDialogs();

  const tableStateBag = useTableStateBag();
  const columns = {
    group: tableStateBag.columnsById['groupId'],
  };
  const filteredColumns = columns?.group?.filterValue?.value ?? null;

  const [selected, setSelected] = useState(null);

  const handleAddEditGroupDialog = (index?: number, addNew?: boolean) => {
    const group =
      index != null && !addNew ? findNodeBy(tree, index, 'index') : undefined;
    const endIndex = draftTree[draftTree?.length - 1]?.index + 1;
    const { id } = dialogs.open(OrganisationAreaGroupsNewDialog, {
      entity,
      group,
      usedIds: draftTree
        .filter((item) => Number(item.id) < 0)
        .map((item) => Number(item.id)),
      endIndex,
      onClose: () => dialogs.close(id),
      onConfirm: async (newGroup) => {
        let newTree = [];

        if (addNew && selected) {
          newTree = insertNode(
            tree,
            newGroup,
            selected?.id,
            selected?.index,
            false
          );
        } else {
          newTree =
            index != null
              ? replaceNodeBy(tree, newGroup, 'index')
              : [...tree, newGroup];
        }

        setDraftTree(newTree);
        onModify();
        dialogs.close(id);
      },
    });
  };

  const handleRemoveGroupDialog = (
    id: string,
    index: number,
    isParent: boolean
  ) => {
    setGroupIdToRemove({ id, index, isParent });
    setRemoveGroupDialogOpen(true);
  };

  const handleRemoveGroupCancel = () => {
    setGroupIdToRemove(null);
    setRemoveGroupDialogOpen(false);
  };

  const handleRemoveGroup = () => {
    const updatedDraftState = reindexTree(
      removeNodeBy(tree, groupIdToRemove.index, 'index')
    );

    setDraftTree(updatedDraftState);
    setGroupIdToRemove(null);
    setRemoveGroupDialogOpen(false);
    onModify();
  };

  const handleTreeChange = (newTreeData: TreeNode[]) => {
    setDraftTree([...newTreeData]);
    onModify();
  };

  const handleGroupsChange = createHandleColumnFilterChange<Group>(
    columns.group
  );

  const handleItemClick = (group: TreeNode) => {
    setSelected(group.id === selected?.id ? null : group);
  };

  const handleSubmit = async () => {
    const queryName = QUERY_NAME_BY_ENTITY[groupEntity];
    const refetchQuery = getQuery(groupEntity, queryName);

    const newGroupsResponse = await mutateGroups({
      variables: {
        input: {
          structure: mapTreeToGroups(tree),
        },
      },
      refetchQueries: [{ query: refetchQuery }],
    });
    const newGroups: InputGroup[] = newGroupsResponse?.data?.[mutationType];
    const filteredGroups = newGroups.filter(
      (group) => !!filteredColumns?.find(({ id }) => group.id === id)
    );

    handleGroupsChange(filteredGroups);
    setEditOverlayOpen(false);
  };

  return (
    <Stack>
      <NavigationOverlayHeader
        title={t(`MASK.GROUP_MANAGEMENT.${entity.toUpperCase()}_GROUPS.HEADER`)}
        breadcrumbsChildren={
          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={!isDirty}
            onClick={handleSubmit}
          >
            {t('INPUTS.SAVE')}
          </Button>
        }
      />

      <Stack flexDirection="row" alignItems="center" px="1rem" py={1}>
        <Button
          onClick={() => handleAddEditGroupDialog(null, true)}
          startIcon={<AddIcon />}
        >
          {t('MASK.GROUP_MANAGEMENT.NEW')}
        </Button>
      </Stack>

      <Divider />

      <Stack px="1rem" py={1} gap={1}>
        <DraggableTree
          tree={tree}
          selected={selected}
          onChange={handleTreeChange}
          onEdit={handleAddEditGroupDialog}
          onRemove={handleRemoveGroupDialog}
          onItemClick={handleItemClick}
        />
      </Stack>

      <ConfirmDialog
        open={isRemoveGroupDialogOpen}
        title={t(
          `MASK.GROUP_MANAGEMENT.${entity.toUpperCase()}_GROUPS.REMOVE.HEADER`
        )}
        description={
          groupIdToRemove?.isParent
            ? t('MASK.GROUP_MANAGEMENT.REMOVE.DESCRIPTION_SUBGROUP')
            : t('MASK.GROUP_MANAGEMENT.REMOVE.DESCRIPTION_GROUP')
        }
        confirmLabel={t('ALERTS.YES')}
        cancelLabel={t('ALERTS.NO')}
        onConfirm={handleRemoveGroup}
        onCancel={handleRemoveGroupCancel}
      />
    </Stack>
  );
};
