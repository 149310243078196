import { useMemo } from 'react';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const REQUEST_DATA: EMailTemplate<EMode.query> = {
  id: null,
  subject: null,
  body: null,
  eMailTemplateKind: null,
  name: null,
  groupId: null,
  parentId: null,
  childs: [
    {
      id: null,
      languageCode: null,
      language: {
        id: null,
        name: null,
      },
      parentId: null,
      body: null,
      subject: null,
      signaturId: null,
      signature: {
        body: null,
        id: null,
        name: null,
      },
      attachmentList: [
        {
          id: null,
          fileName: null,
          fileInfos: {
            fileSize: null,
            downloadUrl: null,
            fileEntityFilename: null,
          },
        },
      ],
    },
  ],
  templateGroup: {
    id: null,
    name: null,
  },
  signaturId: null,
  signature: {
    body: null,
    id: null,
    name: null,
  },
  languageCode: null,
  language: {
    id: null,
    name: null,
  },
  attachmentList: [
    {
      id: null,
      fileName: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
      },
    },
  ],
};

export const useEMailTemplateRequestData = (id: string | number) => {
  return useMemo<DataRequest>(() => {
    const EMailTemplateRequestData: EMailTemplate<EMode.query> = REQUEST_DATA;
    const filter = [{ id: { $eq: id } }];
    const data = EMailTemplateRequestData;
    return {
      entity: Entities.eMailTemplate,
      data,
      filter,
      keysArguments: {
        'signature.body': EMAIL_SIGNATURE_KEYS_ARGUMENTS.body,
      },
    };
  }, [id]);
};
