import styles from './CheckListPreview.module.scss';

import { Divider } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';

import { useDataMutation, useInaccessibleFields } from '@work4all/data';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import {
  BasicTable,
  IBasicTableProps,
  TableStateBagProvider,
} from '../../../dataDisplay/basic-table';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import { InfoCards, PreviewEditMessage, PreviewWrapper } from '../components';
import {
  ContactPreviewPickerInput,
  CustomerPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';

import { ChecklistPositionStatusCell } from './ChecklistPositionStatusCell';
import { checklistPreviewFields } from './ChecklistPreviewContainer';

export interface IChecklistPreviewProps extends EntityPreviewProps<CheckList> {
  currentUserId: number;
}

export function ChecklistPreview(props: IChecklistPreviewProps) {
  const { entries, onCloseClick, onEditClicked, currentUserId, ...handlers } =
    props;
  const { t } = useTranslation();
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  const { isInaccessible } = useInaccessibleFields();

  const positionListIsAccessible = !isInaccessible(
    Entities.checkList,
    'positionList'
  );

  const [mutate] = useDataMutation<CheckList, EMode.upsert>({
    entity: Entities.checkList,
    mutationType: EMode.upsert,
    responseData: checklistPreviewFields as unknown as CheckList,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.checkList,
    entries: entries,
    mutate,
  });

  const { isMultiselect, commonFields, onPopoverClose, onEdit } = entityPreview;

  const checklist = entries[0];

  const customerIsPrivate = checklist?.businessPartner?.data?.isPrivateCustomer;
  const customerIsCommon = commonFields.includes('businessPartner');

  const prepareRowDisplayModifiers = (value) => {
    return { isBold: (value as CheckListPosition).kind === 'HEADLINE' };
  };

  const sortedList = useMemo(() => {
    return [...checklist.positionList].sort((a, b) => a.number - b.number);
  }, [checklist.positionList]);

  const columns = useMemo(() => {
    const columns: IBasicTableProps['columns'] = [
      {
        Header: t('COMMON.STATUS'),
        id: 'doneDate1',
        sticky: 'left',
        accessor: 'doneDate',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (row.original.kind === 'DEFAULT') {
            return (
              <ChecklistPositionStatusCell
                value={row.original.doneDate}
                row={row}
                currentUserId={currentUserId}
              />
            );
          }
          return '';
        },
        width: 55,
      },
      {
        Header: t('COMMON.POSITION_SHORT'),
        accessor: 'positionNumber',
        width: 55,
        sticky: 'left',
      },
      {
        Header: t('COMMON.CONTENTS'),
        accessor: 'name',
        width: 250,
      },
      {
        Header: t('COMMON.RESPONSIBLE_SHORT'),
        accessor: 'plannedByUserId',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (row.original.plannedByUserId === 0) {
            return '';
          }
          return <HookedUserIcon userId={row.original.doneByUserId} size="m" />;
        },
        width: 55,
      },
      {
        Header: t('COMMON.DONE'),
        id: 'doneDate2',
        accessor: 'doneDate',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (!row.original.doneDate) {
            return '';
          }

          return (
            <div className={styles['user-icon-wrapper']}>
              <HookedUserIcon userId={row.original.doneByUserId} size="m" />
              {DateTime.fromISO(row.original.doneDate).toLocaleString(
                DateTimeCustom.DATE_SIMPLE_2YEAR
              )}
            </div>
          );
        },
        width: 200,
      },
    ];

    return columns;
  }, [t, currentUserId]);

  const callMemoContactData = {
    businessPartner: checklist?.businessPartner,
    project: checklist?.project,
  };

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.checkList}
      entries={entries}
    >
      <PreviewWrapper>
        <PreviewTitleInput accessor="title" {...handlers} />

        {isMultiselect && (
          <PreviewEditMessage entityId={String(checklist.id)} />
        )}

        <InfoCards columns={2}>
          <UserPreviewPickerInput
            labelTranslationKey="COMMON.EMPLOYEE"
            onClose={onPopoverClose}
            value={checklist?.user}
            onChange={(value) => {
              onEdit({
                userId: value.id,
              });
            }}
            accessor="user"
          />

          <DatePreviewPickerInput
            labelTranslationKey="COMMON.DATE"
            onClose={onPopoverClose}
            value={checklist?.date}
            onChange={(value) => {
              onEdit({ date: new Date(value).toISOString() });
            }}
            accessor="date"
          />

          <CustomerPreviewPickerInput
            labelTranslationKey="COMMON.CUSTOMER"
            onClose={onPopoverClose}
            value={checklist?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: SdObjType.KUNDE,
                      id: value?.id,
                    }
                  : null,
                businessPartnerId: value ? value?.id : 0,
                businessPartnerType: SdObjType.KUNDE,
                contactId: value?.mainContact?.id ?? null,
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          {!customerIsPrivate && (
            <ContactPreviewPickerInput
              labelTranslationKey="COMMON.CONTACT"
              onClose={onPopoverClose}
              value={checklist?.contact}
              otherProps={{ callMemoContactData }}
              onChange={(value) => {
                onEdit({
                  contact: value,
                  contactId: value ? value.id : 0,
                });
              }}
              accessor="contact"
              prefilter={[
                {
                  businessPartnerType: {
                    $eq:
                      checklist?.businessPartner?.businessPartnerType ??
                      SdObjType.KUNDE,
                  },
                },
                {
                  businessPartnerId: {
                    $eq: checklist?.businessPartner?.data.id ?? 0,
                  },
                },
              ]}
              disabled={!customerIsCommon || !checklist?.businessPartner?.data}
              showFooter
              entityTemplateValue={{
                id: checklist?.businessPartner?.data?.id,
                entity:
                  checklist?.businessPartner?.businessPartnerType ===
                  SdObjType.KUNDE
                    ? Entities.customer
                    : Entities.supplier,
              }}
            />
          )}

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={checklist?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
              });
            }}
            accessor="project"
            showFooter
          />
        </InfoCards>
        {!isMultiselect && positionListIsAccessible ? (
          <div style={{ display: 'grid', height: '100%' }}>
            <TableStateBagProvider tableInstance={tableInstance}>
              <BasicTable
                className={styles.transparentTable}
                ref={setTableInstance}
                mode="client"
                prepareRowDisplayModifiers={prepareRowDisplayModifiers}
                reordableColumns={true}
                resizableColumns={false}
                data={sortedList}
                columns={columns}
                allItemsCount={checklist?.positionList.length || 0}
                cardsView={false}
                selectableMultiple={false}
              />
            </TableStateBagProvider>
          </div>
        ) : null}

        <Divider orientation="horizontal" />

        <EntityHistory
          fitContainer={false}
          entity={Entities.checkList}
          fullWidth
          id={checklist.id}
          className={styles.hist}
          breadcrumbTitle={checklist.title}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
