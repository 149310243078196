const dayUnits = ['day', 'days', 'd', 'd.', 'tag', 'tage', 'tg', 'tg.'];
const hourUnits = [
  'h',
  'h.',
  'hour',
  'hours',
  'std',
  'std.',
  'stunde',
  'stunden',
];
const minuteUnits = ['minuten', 'min', 'min.', 'minutes'];

export const getFactor = (unit: string) => {
  if (dayUnits.includes(unit.toLowerCase())) {
    return 1;
  }
  if (hourUnits.includes(unit.toLowerCase())) {
    return 1 / 8;
  }
  if (minuteUnits.includes(unit.toLowerCase())) {
    return 1 / 8 / 60;
  }
  return 1;
};
