import { Layout } from 'react-grid-layout';
import { z } from 'zod';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';

import { IMaskUserConfig, SearchHistoryResults } from './types';
import { UseSettingOptions } from './use-setting';

// Configure shared settings here.

const DEFAULT_MASK_STATE: IMaskUserConfig = {};

/** Mask state. */
export function mask(options: {
  entityType: string;
}): UseSettingOptions<IMaskUserConfig> {
  const { entityType } = options;

  return {
    name: `mask.${entityType}`,
    scope: 'user',
    defaultValue: DEFAULT_MASK_STATE,
  };
}

const SEARCH_HISTORY_DEFAULT_VALUE: SearchHistoryResults = {
  [Entities.customer]: [],
  [Entities.supplier]: [],
  [Entities.project]: [],
  [Entities.article]: [],
};

/**
 * Search results for the global search component and file pages. The search
 * items are grouped by entity type.
 * */
export function searchHistory(): UseSettingOptions<SearchHistoryResults> {
  return {
    name: `searchHistory`,
    scope: 'tenant',
    defaultValue: SEARCH_HISTORY_DEFAULT_VALUE,
  };
}

type EntityPickerSettingsSort = {
  options: readonly string[];
};

export type EntityPickerSettingsFilterBy = {
  key: string;
  query: {
    $eq: number | boolean;
  };
};

type EntityPickerSettingsFilter = {
  options: EntityPickerSettingsFilterBy[];
};

export type EntityPickerSettings = {
  sort: EntityPickerSettingsSort;
  filter: EntityPickerSettingsFilter;
};

const settingsFilterBy = z.array(
  z.object({
    key: z.string(),
    query: z.object({
      $eq: z.union([z.number(), z.boolean()]),
    }),
  })
);
export type FilterPickerEntities = DeepRequired<
  z.infer<typeof settingsFilterBy>
>;

/**
 * ListEntityPicker filters
 * */
export const filterPickerEntities = (options: {
  entityType: string;
}): UseSettingOptions<FilterPickerEntities> => ({
  name: `filterPickerEntities.${options?.entityType}`,
  scope: 'user',
  defaultValue: [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseFn: settingsFilterBy.parse as any,
});

const settingsSortSchema = z.string();
export type SortPickerEntities = z.infer<typeof settingsSortSchema>;

/**
 * ListEntityPicker sorting options
 * */
export const sortPickerEntities = (options: {
  entityType: string;
  defaultValue?: string;
}): UseSettingOptions<SortPickerEntities> => ({
  name: `sortPickerEntities.${options.entityType}`,
  scope: 'user',
  defaultValue: options.defaultValue,
  parseFn: settingsSortSchema.parse,
});

const isTicketSolutionRequieredZOD = z.boolean();
export function isTicketSolutionRequiered(): UseSettingOptions<
  DeepRequired<z.infer<typeof isTicketSolutionRequieredZOD>>
> {
  return {
    name: `Pflichtfeld Ticketlösung`,
    scope: 'global',
    defaultValue: false,
    parseFn: isTicketSolutionRequieredZOD.parse,
  };
}

export function projectPlanningTargetSource(): UseSettingOptions<
  Entities.contract | Entities.calculation
> {
  return {
    name: `projectPlanning.targetSource`,
    scope: 'user',
    defaultValue: Entities.contract,
  };
}

type LayoutResponsiveBreakpoint = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
type LayoutResponsiveDefinition = Record<LayoutResponsiveBreakpoint, Layout[]>;

const DEFAULT_FILE_WIDGET_LAYOUTS = {
  layouts: [],
};

/**
 * File Widgets layout per breakpoints
 * */
export const fileWidgetLayouts = (): UseSettingOptions<{
  layouts: LayoutResponsiveDefinition[];
}> => {
  return {
    name: 'files.widgetLayouts',
    scope: 'user',
    defaultValue: DEFAULT_FILE_WIDGET_LAYOUTS,
  };
};
