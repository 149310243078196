import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { IPickerWithTabsProps } from '../types';

import { Picker } from './picker';

export type StandardEmailTemplates = {
  [K in EMailTemplateKind]?: string;
};

export type EmailTemplatePickerProps = Omit<
  IPickerWithTabsProps<EMailTemplate, false>,
  'multiple' | 'prefilter' | 'layout'
> & {
  standardTemplates: StandardEmailTemplates;
  setStandardTemplates: (value: StandardEmailTemplates) => void;
  layout?: 'simple' | 'advanced';
  noTemplate?: boolean;
  isGeneralEmailSource?: boolean;
  initialTemplateKind?: EMailTemplateKind;
  eMailTemplateKind?: EMailTemplateKind;
  className?: string;
};

export function EmailTemplatePicker(props: EmailTemplatePickerProps) {
  const {
    data,
    standardTemplates,
    setStandardTemplates,
    noTemplate,
    isGeneralEmailSource,
    initialTemplateKind,
    ...rest
  } = props;

  const { t } = useTranslation();

  const templateKind = initialTemplateKind ?? props?.value?.eMailTemplateKind;

  const allFields = useMemo(() => {
    return { ...EMAIL_TEMPLATE_PICKER_DATA, ...data };
  }, [data]);

  const isGeneralTemplate =
    isGeneralEmailSource ||
    (initialTemplateKind && initialTemplateKind === EMailTemplateKind.KEINE);

  const additionalItems = [
    { id: 0, name: t('COMMON.BLANK') },
  ] as unknown as EMailTemplate[];

  const requestData = {
    entity: Entities.eMailTemplate,
    data: allFields,
    filter: [{ parentId: { $eq: null } }, { languageCode: { $eq: 0 } }],
  };
  const response = useDataProvider<EMailTemplate>(requestData);

  const handleStandardChange = (template: EMailTemplate) => {
    if (template.eMailTemplateKind) {
      setStandardTemplates({
        ...standardTemplates,
        [template.eMailTemplateKind]:
          standardTemplates[template.eMailTemplateKind] !== template.id
            ? template.id
            : null,
      });
    }
  };

  const dataset = useCallback(
    (kind: EMailTemplateKind) => {
      const responseData = response?.data ?? [];
      const eMailTemplateKindData =
        responseData?.filter((data) => data.eMailTemplateKind === kind) || [];
      const sortedData = eMailTemplateKindData.sort((a, b) => {
        if (a.id === standardTemplates[a.eMailTemplateKind]) return -1;
        if (b.id === standardTemplates[a.eMailTemplateKind]) return 1;
        return a.name.localeCompare(b.name);
      });
      const standardTemplateIndex = sortedData.findIndex(
        (template) => template.id === standardTemplates[templateKind]
      );

      if (noTemplate) {
        if (standardTemplateIndex !== -1) {
          sortedData.splice(standardTemplateIndex + 1, 0, ...additionalItems);
        } else {
          sortedData.splice(0, 0, ...additionalItems);
        }
      }

      return {
        total: sortedData.length,
        loading: response?.loading,
        data: sortedData,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noTemplate, initialTemplateKind, response?.data, response?.loading]
  );

  return (
    <Picker
      {...rest}
      onStandardChange={handleStandardChange}
      standardTemplates={standardTemplates}
      tabs={[
        {
          label: t('COMMON.GENERAL'),
          data: dataset(EMailTemplateKind.KEINE),
        },
        {
          label: t('COMMON.TICKETS'),
          disabled:
            !isGeneralTemplate &&
            [
              EMailTemplateKind.APPOINTMENT_NOTIFICATION,
              EMailTemplateKind.ERP_OBJECTS,
            ].includes(templateKind),
          data: dataset(EMailTemplateKind.TICKET),
        },
        {
          label: t('COMMON.APPOINTMENTS'),
          disabled:
            !isGeneralTemplate &&
            [EMailTemplateKind.TICKET, EMailTemplateKind.ERP_OBJECTS].includes(
              templateKind
            ),
          data: dataset(EMailTemplateKind.APPOINTMENT_NOTIFICATION),
        },
        {
          label: t('COMMON.ERP'),
          disabled:
            !isGeneralTemplate &&
            [
              EMailTemplateKind.TICKET,
              EMailTemplateKind.APPOINTMENT_NOTIFICATION,
            ].includes(templateKind),
          data: dataset(EMailTemplateKind.ERP_OBJECTS),
        },
      ]}
    />
  );
}

export const EMAIL_TEMPLATE_PICKER_DATA: EMailTemplate = {
  id: null,
  name: null,
  body: null,
  subject: null,
  languageCode: null,
  parentId: null,
  eMailTemplateKind: null,
  childs: [
    {
      id: null,
      body: null,
      subject: null,
      languageCode: null,
      parentId: null,
      signature: {
        id: null,
        name: null,
        body: null,
      },
      attachmentList: [
        {
          id: null,
        },
      ],
    },
  ],
  signature: {
    body: null,
    id: null,
    name: null,
  },
  attachmentList: [
    {
      id: null,
    },
  ],
};
