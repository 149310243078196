import { Print } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { Button, Fab, Theme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDialogs } from '@work4all/components';
import { useReportOptions } from '@work4all/components/lib/components/entity-picker/report-picker/ReportPicker';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { convertEntityToBzObjType } from '@work4all/models/lib/utils/convertEntityToBzObjType';

import { settings, useSetting } from '../../../../../../../../settings';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { ErpData } from '../../../ErpData';
import { convertEntityToReportBzObjType } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api';
import { useShadowBzObjectApiContext } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

import { ReportPreviewMask } from './ReportPreviewMask';

interface ReportButtonProps {
  disabled?: boolean;
}

export function ReportButton(props: ReportButtonProps) {
  const { disabled } = props;
  const { t } = useTranslation();
  const mask = useMaskContext();
  const form = useFormContextPlus<ErpData>();

  const { persist, isDirty, positions } = useShadowBzObjectApiContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const reportBzObjectType = useMemo(() => {
    try {
      return convertEntityToReportBzObjType(mask.entity);
    } catch {
      return undefined;
    }
  }, [mask.entity]);

  const bzObjectType = useMemo(() => {
    try {
      return convertEntityToBzObjType(mask.entity);
    } catch {
      return undefined;
    }
  }, [mask.entity]);

  const { value: lastUsedReportTypeValue } = useSetting(
    settings.lastUsedReportType()
  );

  const { data } = useReportOptions(reportBzObjectType);
  const report = data.find((r) => r.standardReport) ?? data[0];

  const dialogs = useDialogs();

  const isMdDown = useMediaQuery<Theme>((t) => t.breakpoints.down('md'));

  const handleClick = async () => {
    // Persist the shadow object first if there are changes.
    if (isDirty || mask.isCreateMode) {
      await form.handleSubmit(async () => {
        const response = await persist();
        const data = response.data.persistShadowBzObject.data;

        if (pathname.includes('/new')) {
          const path = pathname.replace('new', `${data.id}`);
          navigate(path, { replace: true });
        }

        openReportMaskWithData({
          ...data,
          positionList: data.positionList.filter(
            (x) => 'posId' in x && !x.posId
          ),
        });
      })();
    } else {
      const data: ERPTypes = {
        ...form.getValues(),
        positionList: positions.filter((x) => !x.posId),
      };

      openReportMaskWithData(data);
    }

    function openReportMaskWithData(data: ERPTypes) {
      const contact = data.businessPartnerContactCombined.contact;
      const businessPartner =
        data.businessPartnerContactCombined.businessPartner;
      const businessPartnerType = data.businessPartnerType;

      const id = data.id ?? mask.id;

      const initialView: IStackItem = {
        view: (
          <ReportPreviewMask
            id={id}
            reportBzObjectType={reportBzObjectType}
            report={report}
            reportType={lastUsedReportTypeValue}
            data={data}
            contactId={contact?.id}
            businessPartnerId={businessPartner?.id}
            businessPartnerType={
              businessPartnerType === SdObjType.KUNDE
                ? Entities.customer
                : Entities.supplier
            }
            bzObjectType={bzObjectType}
            entity={mask.entity}
          />
        ),
      };

      const dialog = dialogs.open(NavigationOverlay, {
        initialView: initialView,
        close() {
          dialogs.close(dialog.id);
        },
      });
    }
  };

  return isMdDown ? (
    <Fab
      color="primary"
      size="medium"
      data-test-id={'preview-icon-button'}
      onClick={handleClick}
    >
      <Print />
    </Fab>
  ) : (
    <Button
      disabled={disabled}
      onClick={handleClick}
      startIcon={<DescriptionIcon />}
    >
      {t('COMMON.PREVIEW')}
    </Button>
  );
}
