import styles from '../DocumentPreview.module.scss';

import i18next from 'i18next';
import React from 'react';

import { MIME_TYPES, Preview } from '../../../../preview/Preview';
import { useDocumentPreview } from '../hooks/use-document-preview';
import { IDocumentPreviewProps } from '../types/IDocumentPreviewProps';

import { DocumentPreviewTitle } from './DocumentPreviewTitle';

export function DocumentPreview(props: IDocumentPreviewProps) {
  const { filePath, subtitleComp } = props;

  const documentPreviewProps = useDocumentPreview(filePath);

  return (
    <div className={styles.wrapper}>
      <DocumentPreviewTitle {...props} {...documentPreviewProps} />
      {subtitleComp}
      <DocumentPreviewContent {...props} {...documentPreviewProps} />
    </div>
  );
}

interface DocumentPreviewContentProps
  extends Pick<
      IDocumentPreviewProps,
      | 'title'
      | 'url'
      | 'noPreviewUrl'
      | 'downloadUrl'
      | 'filePath'
      | 'mimeType'
      | 'onCloseClick'
      | 'onEditClicked'
      | 'onDeleteClicked'
      | 'fspUrl'
      | 'exists'
      | 'iconProps'
      | 'openInFullscreen'
      | 'pdfTextmarkConf'
      | 'classes'
      | 'renderMobilePreview'
    >,
    ReturnType<typeof useDocumentPreview> {}

export const DocumentPreviewContent = (props: DocumentPreviewContentProps) => {
  const {
    title,
    url,
    noPreviewUrl,
    downloadUrl,
    filePath,
    mimeType,
    onCloseClick,
    onEditClicked,
    onDeleteClicked,
    fspUrl,
    exists,
    iconProps,
    openInFullscreen,
    pdfTextmarkConf,
    setFullscreen,
    detailInFullsceen,
    isLinkable,
    classes,
    renderMobilePreview,
  } = props;

  return (
    <Preview
      onFullscreenClose={() => {
        setFullscreen(false);
        if (openInFullscreen) onCloseClick(null);
      }}
      openInFullscreen={detailInFullsceen}
      onDeleteClicked={onDeleteClicked}
      onEditClicked={onEditClicked}
      url={isLinkable ? filePath : url}
      mimeType={isLinkable ? MIME_TYPES.urllist : mimeType}
      fileName={isLinkable ? filePath : title}
      filePath={filePath}
      exists={exists}
      fspUrl={fspUrl}
      downloadProps={
        filePath || downloadUrl
          ? {
              url: downloadUrl,
              filePath: filePath,
            }
          : undefined
      }
      noPreview={
        !exists
          ? i18next.t('MASK.NO_PREVIEWFILE')
          : i18next.t('MASK.NO_PREVIEW_DOWNLOAD')
      }
      noPreviewUrl={noPreviewUrl}
      isLinkable={isLinkable}
      iconProps={iconProps}
      pdfTextmarkConf={pdfTextmarkConf}
      imgClassName={classes?.imgClassName}
      renderMobilePreview={renderMobilePreview}
    />
  );
};
