import styles from './Profile.module.scss';

import { Settings as SettingsIcon } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as NoteIcon } from '@work4all/assets/icons/note.svg';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { Breadcrumbs } from '@work4all/components/lib/navigation/breadcrumbs/Breadcrumbs';
import {
  StackProvider,
  useHistoryStack,
} from '@work4all/components/lib/navigation/history-stack';
import { Body2 } from '@work4all/components/lib/typography/body2/Body2';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { useUser } from '@work4all/data';
import { logoutUser } from '@work4all/data/lib/actions/user-actions';

import { useReleaseNotesDialogContext } from '../../../../contexts/ReleaseNotesDialogContext';
import { LanguageToggle } from '../../components/LanguageToggle/LanguageToggle';
import { Settings } from '../Settings/Settings';

import { ProfileAvatar } from './components/Avatar/ProfileAvatar';
import { LatestNotifications } from './components/LatestNotifications/LatestNotifications';
import { ReferralBanner } from './components/ReferralBanner';

export const ProfilePage: React.FC = () => {
  const user = useUser();
  return (
    <StackProvider
      initialView={{
        view: <Profile />,
        title: '',
        breadcrumbTitle: user.shortName,
      }}
    >
      <ProfilePageInner />
    </StackProvider>
  );
};

export const ProfilePageInner: React.FC = () => {
  const { goBack, stack, current, currentStackIndex } = useHistoryStack();
  return (
    <div
      style={{ height: '100%', backgroundColor: 'var(--ui01)' }}
      className={styles['page']}
    >
      <Breadcrumbs
        stack={stack}
        goBack={goBack}
        current={current}
        currentIndex={currentStackIndex}
        forceStackItems
      />
      {current.view}
    </div>
  );
};

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { go, close } = useHistoryStack();
  const user = useUser();
  const dispatch = useDispatch();

  const { releaseNotesFile, version, openReleaseNotesDialog } =
    useReleaseNotesDialogContext();

  return (
    <Stack direction="column" sx={{ overflow: 'auto', height: '100%' }}>
      <Stack
        direction="row"
        alignItems="center"
        overflow="hidden"
        p="1rem"
        pb="0"
        flex="none"
      >
        <ProfileAvatar />
        <div className={styles.userInfo}>
          <Body2 semiBold>{`${user.firstName} ${user.lastName}`}</Body2>
          <Caption color="text03">{user.email}</Caption>
        </div>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap="0rem"
        sx={{ m: '1rem 1rem 0' }}
      >
        <Box flex="1">
          <LanguageToggle />
        </Box>

        <Tooltip title={t('COMMON.SETTINGS')}>
          <IconButton
            size="large"
            color="primary"
            onClick={() =>
              go({
                title: t('COMMON.SETTINGS'),
                view: <Settings />,
              })
            }
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('COMMON.LOGOUT')}>
          <IconButton
            size="large"
            color="primary"
            onClick={() => dispatch(logoutUser())}
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <LatestNotifications onClose={close} />

      <List>
        <Divider style={{ padding: '1rem 1rem 1rem 0' }}>
          {t('USER_MENU_SECTION.SUPPORT')}
        </Divider>

        <ListItem disablePadding>
          <ListItemButton
            disabled={!releaseNotesFile || !releaseNotesFile?.length}
            onClick={() => {
              openReleaseNotesDialog();
            }}
          >
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary={t('RELEASE_NOTES.title', { version })} />
          </ListItemButton>
        </ListItem>
      </List>
      <div className={styles.bottomWrapper}>
        <ReferralBanner />
      </div>
    </Stack>
  );
};
