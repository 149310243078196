import { useMemo } from 'react';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../settings';

import { ProjectPlanningColumnHeader } from './components/ProjectPlanningColumnHeader';
import { ProjectRessourceClassCell } from './components/ProjectRessourceClassCell';
import { useProjectAppointments } from './hooks/useProjectAppointments';
import { useProjectErpObjects } from './hooks/useProjectErpObjects';
import { useProjectTimeTrackings } from './hooks/useProjectTimeTrackings';
import { useResourceClasses } from './use-resource-classes';
import { getFactor } from './utils';

export interface ProjectPlanningColumnsProps {
  projectList: Project[];
  onCellSelect?: (props: { project: Project; ressourceClass: LookUp }) => void;
  selectedRessourceClassCell?: { project: Project; ressourceClass: LookUp };
  entity: Entities.calculation | Entities.contract;
  onUtilizationClick?: (userIds: number[], project: Project) => void;
}

export const useProjectPlanningColumns = (
  props: ProjectPlanningColumnsProps
) => {
  const {
    projectList,
    onCellSelect,
    selectedRessourceClassCell,
    entity,
    onUtilizationClick,
  } = props;
  const { data: ressourceClasses } = useResourceClasses();

  const projectIdList = useMemo(() => {
    return [
      ...projectList,
      ...projectList.flatMap((pr) => pr?.childProjects),
    ]?.map((pr) => pr?.id);
  }, [projectList]);

  const projectPlanningColumns = useSetting(settings.projectPlanningColumns());

  const { data: erpObjectList, pending: erpObjectListLoading } =
    useProjectErpObjects({
      projectIdList,
      entity,
      skip: !projectPlanningColumns.value.target,
    });
  const { data: appointmentList, pending: appointmentLoading } =
    useProjectAppointments({
      projectIdList,
      skip: !projectPlanningColumns.value.planned,
    });
  const { data: timeTrackingList, pending: timeTrackingListLoading } =
    useProjectTimeTrackings({
      projectIdList,
      skip: !projectPlanningColumns.value.actual,
    });

  const result = useMemo(
    () =>
      ressourceClasses.map((rc) => ({
        title: [rc.name],
        displayTitle: rc.name,
        accessor: (item: Project) => {
          const project = projectList.find((pr) => pr?.id === item?.id);

          if (!project) {
            return;
          }
          const allProjectIds = [
            project?.id,
            ...(project.childProjects?.map((pr) => pr?.id) || []),
          ];

          const relevantAppointments = appointmentList
            .filter((tr) => {
              return allProjectIds.includes(tr.projectId);
            })
            ?.filter((app) => app.projectProcess?.ressourceClassId === rc.id);

          const planned = relevantAppointments
            .map((app) => app.amountProjectPlaning)
            .reduce((a, b) => a + b, 0);

          const relevantTimeTrackings = timeTrackingList
            .filter((tr) => {
              return allProjectIds.includes(tr.projectId);
            })
            ?.filter((tr) => tr.projectProcess?.ressourceClassId === rc.id);

          const actual =
            relevantTimeTrackings
              .map((app) => app.amount)
              .reduce((a, b) => a + b, 0) / 8;

          const relevantErpObjects = erpObjectList.filter((erp) => {
            return allProjectIds.includes(erp.projectId);
          });

          const positionList = relevantErpObjects.flatMap(
            (erp) => erp.positionList
          );

          const positions = positionList?.filter(
            (pos) => pos?.article?.ressourceClassId === rc.id
          );
          const target = positions
            ?.map((x) => {
              const factor = getFactor(x.unit);
              return x.amount * factor;
            })
            ?.reduce((a, b) => {
              return a + b;
            }, 0);

          return (
            <ProjectRessourceClassCell
              ressourceClass={rc}
              target={target}
              actual={actual}
              planned={planned}
              targetLoading={erpObjectListLoading}
              actualLoading={timeTrackingListLoading}
              plannedLoading={appointmentLoading}
              onClick={() => {
                onCellSelect?.({
                  project: item,
                  ressourceClass: rc,
                });
              }}
              selected={
                item.id === selectedRessourceClassCell?.project?.id &&
                rc.id === selectedRessourceClassCell?.ressourceClass?.id
              }
              appointmentList={relevantAppointments}
              timeTrackingList={relevantTimeTrackings}
              erpList={relevantErpObjects}
              erpEntityType={entity}
              onUtilizationClick={(userIds) => {
                onUtilizationClick?.(userIds, item);
              }}
            />
          );
        },
        id: rc.id.toString(),
        path: ['COMMON.RESSOURCECLASS_plural'],
        Header: () => <ProjectPlanningColumnHeader ressourceClass={rc} />,
        width: 200,
        sortable: false,
        groupable: false,
        quickSearchable: false,
        defaultHidden: true,
        filterable: {
          type: 'None',
        },
        disableFooterSum: false,
      })),
    [
      ressourceClasses,
      projectList,
      appointmentList,
      timeTrackingList,
      erpObjectList,
      erpObjectListLoading,
      timeTrackingListLoading,
      appointmentLoading,
      selectedRessourceClassCell?.project?.id,
      selectedRessourceClassCell?.ressourceClass?.id,
      entity,
      onCellSelect,
      onUtilizationClick,
    ]
  );

  return result;
};
