import { useEventCallback } from '@mui/material/utils';
import { useContext, useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useDataProvider } from '@work4all/data';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { TaxKey } from '@work4all/models/lib/Classes/TaxKey.entity';
import { TravelCostInvoiceKind } from '@work4all/models/lib/Classes/TravelCostInvoiceKind.entity';
import { TravelCostInvoiceKindTransportCost } from '@work4all/models/lib/Classes/TravelCostInvoiceKindTransportCost.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useFormUpdate } from '../../../utils/use-form-update';
import { CurrencyExchangeInfoContext } from '../../inbound-invoice/currency-exchange-info-context';
import { TravelReceiptsFormValue } from '../type';

const TAX_KEY_BY_VAT = {
  vat1: 'taxKey1',
  vat2: 'taxKey2',
  vat3: 'taxKey3',
};

export const useTravelReceiptsFormUpdate = (
  form: UseFormReturn<TravelReceiptsFormValue>,
  taxKeys: TaxKey[]
) => {
  const ledgerAccountRequestData = useMemo(() => {
    return {
      entity: Entities.ledgerAccount,
      data: {
        id: null,
        name: null,
        number: null,
      },
    };
  }, []);

  const ledgerAccounts = useDataProvider<LedgerAccount>(
    ledgerAccountRequestData
  );
  const ledgerAccountsRef = useRef(ledgerAccounts);
  ledgerAccountsRef.current = ledgerAccounts;

  const exchangeInfoContext = useContext(CurrencyExchangeInfoContext);
  const exchangeRef = useRef(exchangeInfoContext);
  exchangeRef.current = exchangeInfoContext;

  const taxKeyFieldDataFromVatData = useEventCallback(
    (key: string, value: number) => {
      const taxKeyFieldKey = TAX_KEY_BY_VAT[key];

      const taxKeyFieldValue =
        taxKeys.find((taxKey) => taxKey.vat === value) ?? null;

      return { key: taxKeyFieldKey, value: taxKeyFieldValue };
    }
  );

  const updateVatAndAccountAndTaxKey = (
    receiptKindTravelCost:
      | TravelCostInvoiceKind
      | TravelCostInvoiceKindTransportCost
  ) => {
    const res: TravelReceiptsFormValue = {};
    if (ledgerAccountsRef.current?.data?.length) {
      const ledgerAccount1 = ledgerAccountsRef.current.data.find(
        (x) => x.number === receiptKindTravelCost.ledgerAccount1
      );
      const ledgerAccount2 = ledgerAccountsRef.current.data.find(
        (x) => x.number === receiptKindTravelCost.ledgerAccount2
      );
      const ledgerAccount3 = ledgerAccountsRef.current.data.find(
        (x) => x.number === receiptKindTravelCost.ledgerAccount3
      );
      if (ledgerAccount1) {
        res.ledgerAccount1 = ledgerAccount1;
        res.vat1 = receiptKindTravelCost.vat1;
        res.taxKey1 = taxKeyFieldDataFromVatData('vat1', res.vat1).value;
      }
      if (ledgerAccount2) {
        res.ledgerAccount2 = ledgerAccount2;
        res.vat2 = receiptKindTravelCost.vat2;
        res.taxKey2 = taxKeyFieldDataFromVatData('vat2', res.vat2).value;
      }
      if (ledgerAccount3) {
        res.ledgerAccount3 = ledgerAccount3;
        res.vat3 = receiptKindTravelCost.vat3;
        res.taxKey3 = taxKeyFieldDataFromVatData('vat3', res.vat3).value;
      }
    }
    return res;
  };

  const afterVatChangeHandler = useEventCallback(
    (key: string, value: number) => {
      const { key: taxKeyFieldKey, value: taxKeyFieldValue } =
        taxKeyFieldDataFromVatData(key, value);

      return {
        [taxKeyFieldKey]: taxKeyFieldValue,
      };
    }
  );

  const afterTaxKeyChangeHandler = useEventCallback(
    (key: string, value: TaxKey) => {
      return {
        [`${key}Value`]: value?.taxKeyValue ?? 0,
      };
    }
  );

  useFormUpdate(
    {
      project(project) {
        return {
          projectId: project?.id ?? 0,
        };
      },
      costCenter(costCenter) {
        return {
          costCenterId: costCenter?.number ?? 0,
        };
      },
      currency(currency) {
        let course = 1;
        if (currency?.id && exchangeRef.current) {
          course = 1 / exchangeRef.current.getExchangeRate(currency?.id);
        }

        return {
          currencyId: currency?.id ?? 0,
          course,
        };
      },
      ledgerAccount1(ledgerAccount) {
        return {
          ledgerAccount1Id: ledgerAccount?.number ?? 0,
        };
      },
      ledgerAccount2(ledgerAccount) {
        return {
          ledgerAccount2Id: ledgerAccount?.number ?? 0,
        };
      },
      ledgerAccount3(ledgerAccount) {
        return {
          ledgerAccount3Id: ledgerAccount?.number ?? 0,
        };
      },
      receiptKindTravelCost(receiptKindTravelCost) {
        const update = updateVatAndAccountAndTaxKey(receiptKindTravelCost);
        return {
          receiptKindTravelCostId: receiptKindTravelCost?.id ?? 0,
          ...update,
        };
      },
      receiptKind(receiptKind) {
        const update = updateVatAndAccountAndTaxKey(receiptKind);
        return {
          receiptKindId: receiptKind?.id ?? 0,
          ...update,
        };
      },
      paymentMethod(paymentMethod) {
        return {
          paymentKind: paymentMethod?.id ?? 0,
        };
      },
      vat1: (value) => afterVatChangeHandler('vat1', value),
      vat2: (value) => afterVatChangeHandler('vat2', value),
      vat3: (value) => afterVatChangeHandler('vat3', value),
      taxKey1: (value) => afterTaxKeyChangeHandler('taxKey1', value),
      taxKey2: (value) => afterTaxKeyChangeHandler('taxKey2', value),
      taxKey3: (value) => afterTaxKeyChangeHandler('taxKey3', value),
    },
    form
  );
};
