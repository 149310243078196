import styles from './NotePreview.module.scss';

import { Divider } from '@mui/material';

import { useDataMutation } from '@work4all/data';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { callMemoPreviewFields } from '../call-memo-preview';
import {
  InfoCards,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewWrapper,
} from '../components';
import {
  BusinessPartnerPreviewPickerInput,
  ContactPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';

import { notePreviewFields } from './NotePreviewContainer';

type EntityUntion = CallMemo | Note;

export interface INotePreviewProps extends EntityPreviewProps<EntityUntion> {
  isCallMemo?: boolean;
}

export function NotePreview(props: INotePreviewProps) {
  const { entries, isCallMemo, ...handlers } = props;

  const entityType = isCallMemo ? Entities.callMemo : Entities.note;

  const [mutate] = useDataMutation<CallMemo | Note, EMode.upsert>({
    entity: isCallMemo ? Entities.callMemo : Entities.note,
    mutationType: EMode.upsert,
    responseData: isCallMemo
      ? (callMemoPreviewFields as never as CallMemo)
      : (notePreviewFields as never as Note),
  });

  const entityPreview = useEntityPreview({
    subEntityType: entityType,
    entries: entries,
    mutate,
  });
  const { isMultiselect, commonFields, onPopoverClose, onEdit } = entityPreview;

  const note = entries[0];

  const customerIsPrivate = note?.businessPartner?.data?.isPrivateCustomer;
  const customerIsCommon = commonFields.includes('businessPartner');

  const dateFormatter = (value: string): Date => {
    const newDate = new Date(value);
    const prevDate = new Date(note?.date);
    newDate.setHours(prevDate.getHours());
    newDate.setMinutes(prevDate.getMinutes());
    return newDate;
  };

  const callMemoContactData = {
    businessPartner: note?.businessPartner,
    project: note?.project,
    projectProcess: note?.projectProcess,
    topicMarkList: note?.topicMarkList?.[0],
  };

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={entityType}
      entries={entries}
    >
      <PreviewWrapper>
        <PreviewTitleInput accessor="title" {...handlers} />

        {isMultiselect && <PreviewEditMessage entityId={String(note.id)} />}

        <InfoCards>
          <UserPreviewPickerInput
            labelTranslationKey="COMMON.EMPLOYEE"
            onClose={onPopoverClose}
            value={note?.user}
            onChange={(value) => {
              onEdit({
                userId: value.id,
              });
            }}
            accessor="user"
          />

          <DatePreviewPickerInput
            labelTranslationKey="COMMON.DATE.TIME"
            onClose={onPopoverClose}
            value={note?.date}
            onChange={(value) => {
              const newDate = dateFormatter(value);
              onEdit({ date: newDate.toISOString() });
            }}
            accessor="date"
            showTime
          />

          <BusinessPartnerPreviewPickerInput
            labelTranslationKey="COMMON.BUSINESS_PARTNER"
            onClose={onPopoverClose}
            value={note?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: typeNameToSdObjType(
                        value.__typename
                      ),
                      id: value.id,
                    }
                  : null,
                businessPartnerId: value ? value.id : 0,
                businessPartnerType: typeNameToSdObjType(value?.__typename),
                contactId: value?.mainContact?.id ?? null,
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          {!customerIsPrivate && (
            <ContactPreviewPickerInput
              labelTranslationKey="COMMON.CONTACT"
              onClose={onPopoverClose}
              value={note?.contact}
              otherProps={{ callMemoContactData }}
              onChange={(value) => {
                onEdit({
                  contact: value,
                  contactId: value ? value.id : 0,
                });
              }}
              accessor="contact"
              prefilter={[
                {
                  businessPartnerType: {
                    $eq:
                      note?.businessPartner?.businessPartnerType ??
                      SdObjType.KUNDE,
                  },
                },
                {
                  businessPartnerId: {
                    $eq: note?.businessPartner?.data.id ?? 0,
                  },
                },
              ]}
              disabled={!customerIsCommon || !note?.businessPartner?.data}
              showFooter
              entityTemplateValue={{
                id: note?.businessPartner?.data?.id,
                entity:
                  note?.businessPartner?.businessPartnerType === SdObjType.KUNDE
                    ? Entities.customer
                    : Entities.supplier,
              }}
            />
          )}

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={note?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
              });
            }}
            accessor="project"
            showFooter
          />
        </InfoCards>

        <Divider orientation="horizontal" />

        <PreviewTextContent>{note?.note}</PreviewTextContent>

        <Divider orientation="horizontal" />

        <EntityHistory
          fitContainer={false}
          entity={entityType}
          fullWidth
          id={note.id}
          className={styles.hist}
          breadcrumbTitle={note.title}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
