import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Filters, TableInstance } from 'react-table';

import { TableStateBagProvider, useTableStateBag } from '@work4all/components';
import { TablePrefilterProvider } from '@work4all/components/lib/components/table/TablePrefilterProvider';

import { CustomFieldsConfigProvider } from '@work4all/data/lib/custom-fields';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import {
  OpenMaskOverride,
  useOpenMask,
} from '../../../../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import {
  EntityTable,
  IEntityTable,
} from '../../../../entity-table/EntityTable';
import { useEntityDataTable } from '../../../../entity-table/use-entity-data-table';
import { useErpPlanningColumns } from '../../../../planning-columns/use-erp-planning-columns';
import schema from '../../../../schemata/calculation-table-schema.json';
import { ITableProps, TableAreas } from '../../../../table/Table';
import { useErpHandlersTemplate } from '../../../../use-erp-handlers-template';
import { useTableHandlers } from '../../../../use-table-handlers';

import { useProjectWithProcesses } from './project-planning-view/hooks/use-project-with-processes';

type ProjectCalculationsTableProps = {
  projectIdList?: number[];
};
type ProjectCalculationsTableInnerProps = IEntityTable & {
  columnFilters?: Filters<object>;
};

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const ProjectCalculationsTable: React.FC<
  ProjectCalculationsTableProps
> = ({ projectIdList }) => {
  const onOpenMask = useOpenMask();
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  const prefilter = useMemo(
    () => [{ projectId: { $in: projectIdList } }],
    [projectIdList]
  );

  const { data: projectList } = useProjectWithProcesses(projectIdList);

  const columnFilters: Filters<object> = [
    {
      id: 'project.id',
      value: {
        value: projectList.map((pr) => ({
          id: pr.id,
          lockInhouseServices: 0,
          lockExternalServices: 0,
        })),
        readOnly: true,
        filterType: 'picker',
      },
    },
  ];

  return (
    <CustomFieldsConfigProvider entity={Entities.calculation}>
      <TablePrefilterProvider value={{ prefilter }}>
        <TableStateBagProvider tableInstance={tableInstance}>
          <OpenMaskOverride value={{ onOpenMask }}>
            <ProjectCalculationsTableInner
              ref={setTableInstance}
              onOpenMask={onOpenMask}
              prefilter={prefilter}
              columnFilters={columnFilters}
            />
          </OpenMaskOverride>
        </TableStateBagProvider>
      </TablePrefilterProvider>
    </CustomFieldsConfigProvider>
  );
};

export const ProjectCalculationsTableInner = React.forwardRef<
  TableInstance,
  ProjectCalculationsTableInnerProps
>((props, ref) => {
  const { activeTenant } = useTenant();
  const template = useErpHandlersTemplate();

  const ressourceClassColumns = useErpPlanningColumns();

  const transformedSchema = useMemo(() => {
    return {
      ...schema,
      columns: [...schema.columns, ...ressourceClassColumns],
    };
  }, [ressourceClassColumns]);

  const schemaPatched = useMemo(
    () => ({ ...transformedSchema, card: {} }),
    [transformedSchema]
  );

  const { tableInstance } = useTableStateBag();
  useEffect(() => {
    tableInstance?.setAllFilters?.(props?.columnFilters);
  }, [props?.columnFilters, tableInstance]);

  const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
    Calculation,
    never
  >({
    schema: schemaPatched as never,
    defaultSort,
    layout: 'table',
    enableFooter: true,
    forceRequestFields: {
      positionList: [
        {
          id: null,
          unit: null,
          amount: null,
          article: {
            id: null,
            name: null,
            number: null,
            ressourceClassId: null,
          },
        },
      ],
    } as Calculation,
  });

  const rowModifiers = useCallback(
    (value: Calculation) => {
      const modifiers = prepareRowDisplayModifiers(value);
      return {
        ...modifiers,
        isFaded: value.isClosed && value.frozen,
        isShade1: !value.isClosed && value.frozen,
      };
    },
    [prepareRowDisplayModifiers]
  );

  const tableHandlers = useTableHandlers(
    Entities.contract,
    props.onOpenMask,
    template
  );

  const columnConfigs = useDeepMemo(
    () =>
      dataTable?.columnConfigs.map((config) => {
        if (
          config.accessor === 'project.name' ||
          config.accessor === 'project.number'
        ) {
          return {
            ...config,
            isPrimaryFilter: false,
            disableFilters: true,
            disableColumnVisibility: true,
          };
        } else {
          return {
            ...config,
            isPrimaryFilter: false,
          };
        }
      }),
    [dataTable?.columnConfigs]
  );

  const areas: TableAreas = { left: undefined };
  const overrides: Partial<ITableProps> = {
    actions: {
      add: null,
      convert: null,
      groupingEnabled: null,
      edit: tableHandlers.edit,
      openInNewTab: {
        getPath: (id: string) =>
          `t-${activeTenant}/more/entity/calculation/details/${id}`,
      },
      custom: {
        left: undefined,
      },
    },
    listEntityType: Entities.calculation,
    layout: 'table',
  };

  return (
    <EntityTable
      ref={ref}
      template={template}
      {...dataTable}
      areas={areas}
      prepareRowDisplayModifiers={rowModifiers}
      displayFooter={false}
      selectableMultiple={false}
      cardConfig={null}
      columnConfigs={columnConfigs}
      hideMoreButton
      overrides={overrides}
      {...props}
    />
  );
});
