import React from 'react';
import { Row } from 'react-table';

import { RowMeasurer } from '../../../../components/RowMeasurer';
import useLongPress from '../../hooks/useLongPress';
import { useTableStateBag } from '../../hooks/useTableStateBag';
import { SELECT_BOX_MARKER } from '../../utils/makeRowsSelectable';
import { ILoaderProps } from '../loader/Loader';
import { TableSkeletonRow } from '../table-skeleton-row/TableSkeletonRow';

import { Row as RowComponent } from './components/row/Row';
import { SEPARATOR_ROW_HEIGHT, SeparatorRow } from './components/SeparatorRow';

type RenderRowProps = {
  data: ILoaderProps;
  index: number;
  style?: React.CSSProperties;
};

export const RenderRow = (props: RenderRowProps) => {
  const { data, index, style } = props;

  const {
    cardsView = false,
    cardConfig = null,
    ignoreLongPress = false,
    groupBy = [],
  } = data;

  const row = data.rows[index];

  // Table's state is not actually used here, but it is passed as prop to the
  // memoized Row component to avoid rendering outdated view.
  // If rendering outside of context provider just use an empty object as it
  // will always force a re-render.
  const { tableState = {}, setUnderPressSelect } = useTableStateBag() ?? {};

  const rowEventHandlers = {
    onContextMenu(event: React.MouseEvent<HTMLDivElement>) {
      const { nativeEvent } = event;

      if (
        nativeEvent instanceof PointerEvent &&
        nativeEvent.pointerType === 'touch'
      ) {
        event.preventDefault();
      } else {
        data.onRowContextMenu?.(event, row);
      }
    },
    onDoubleClick: handleRowDoubleClick,
    ...useLongPress({
      onClick(event) {
        data.onRowClick?.(event, row);
      },
      onLongPress() {
        if (ignoreLongPress) return;
        setUnderPressSelect?.(true);
        row.toggleRowSelected();
      },
    }),
  };

  if (!row || row.original?.skeleton) {
    return (
      <TableSkeletonRow
        key={row ? row.id : index}
        style={{ ...style, width: data.width }}
      />
    );
  }

  data.prepareRow(row as unknown as Row);
  const rowProps = row.getRowProps({
    style: {
      ...style,
      width: cardsView && cardConfig !== null ? '100%' : data.width,
      minWidth: '100%',
    },
  });

  function handleRowDoubleClick(event: React.MouseEvent<HTMLElement>) {
    if (!data.onRowDoubleClick) {
      return;
    }

    // Ignore double clicks on the checkbox element

    const target = event.target as HTMLElement;

    if (target.closest(`[data-${SELECT_BOX_MARKER}=true]`)) {
      return;
    }

    // Ignore double clicks if any of the modifier keys are pressed (Ctrl, Shift, Command)
    if (event.ctrlKey || event.shiftKey || event.metaKey) {
      return;
    }

    data.onRowDoubleClick(row.id);
  }

  const measureRows = Boolean(cardsView && cardConfig);
  const isSectionStart = row.original.meta?.isSectionStart ?? false;

  if (measureRows) {
    return renderMeasuredRow();
  } else if (isSectionStart) {
    return renderSectionStartRow();
  } else {
    return renderRow();
  }

  function renderSectionStartRow() {
    // When rendering with a separator we need to manually patch the CSS styles
    // provided by the virtualized list component. Since the separator is not
    // its own row, but rendered as a part of current row and is already
    // included in this row's height calculations. We need to subtract the
    // separator height from the row hight and move the row itself down by the
    // same amount.

    const sectionLabel = row.original.meta?.sectionLabel ?? '';

    const customStyle = {
      height: (style.height as number) - SEPARATOR_ROW_HEIGHT,
      top: (style.top as number) + SEPARATOR_ROW_HEIGHT,
    };

    return (
      <React.Fragment key={row.id}>
        <SeparatorRow label={sectionLabel} rowStyle={style} />
        {renderRow(customStyle)}
      </React.Fragment>
    );
  }

  function renderMeasuredRow() {
    const sectionLabel = row.original.meta?.sectionLabel ?? '';
    const customStyle = {
      height: 'auto',
      top: isSectionStart
        ? (style.top as number) + SEPARATOR_ROW_HEIGHT
        : style?.top,
    };

    return (
      <React.Fragment key={row.id}>
        {isSectionStart && (
          <SeparatorRow label={sectionLabel} rowStyle={style} />
        )}
        <RowMeasurer index={props.index}>
          {({ measureRef }) => renderRow(customStyle, measureRef)}
        </RowMeasurer>
      </React.Fragment>
    );
  }

  function renderRow(
    customStyle?: React.CSSProperties,
    ref?: (instance: HTMLElement) => void
  ) {
    const { style, ...otherRowProps } = rowProps;

    return (
      <div
        {...rowEventHandlers}
        {...otherRowProps}
        key={row.id}
        ref={ref}
        style={{
          ...style,
          ...customStyle,
        }}
      >
        <RowComponent
          row={row}
          classes={data?.classes}
          tableState={tableState}
          cardsView={cardsView}
          cardConfig={cardConfig}
          groupBy={groupBy}
        />
      </div>
    );
  }
};
