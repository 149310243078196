import { useMemo } from 'react';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CUSTOM_FIELDS_DATA } from '../../../components/custom-fields/custom-fields-data';

export const useTicketRequestData = (id: string | number) => {
  return useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: id } }];
    const entity = Entities.ticket;
    const data: Ticket<EMode.query> = {
      id: null,
      title: null,
      number: null,
      problemDescription: null,
      followUpDate: null,
      solutionText: null,
      businessPartner: {
        id: null,
        businessPartnerType: null,
        data: {
          customer: {
            id: null,
            name: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                layedOff: null,
              },
            ],
            isPrivateCustomer: null,
          },
          supplier: {
            id: null,
            name: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                layedOff: null,
              },
            ],
            isPrivateCustomer: null,
          },
        },
      },
      businessPartnerId: null,
      businessPartnerType: null,
      contact: {
        id: null,
        displayName: null,
        eMail: null,
        businessPartnerId: null,
        businessPartnerType: null,
      },
      contactId: null,
      project: { id: null, name: null, number: null },
      projectId: null,
      projectProcess: {
        id: null,
        process: null,
        planningAmount: null,
        timeTrackings: [
          {
            id: null,
            amount: null,
          },
        ],
      },
      projectProcessId: null,
      ticketKind: {
        id: null,
        name: null,
      },
      ticketKindId: null,
      ticketCategory1: {
        id: null,
        name: null,
      },
      ticketCategory2: {
        id: null,
        name: null,
      },
      category1Id: null,
      category2Id: null,
      priority: null,
      status1: null,
      status2: null,
      editor1: {
        id: null,
        displayName: null,
        firstName: null,
        lastName: null,
        shortName: null,
      },
      editor1Id: null,

      editor2: {
        id: null,
        displayName: null,
        firstName: null,
        lastName: null,
        shortName: null,
      },
      editor2Id: null,
      checklist: [
        {
          id: null,
          lookupId: null,
        },
      ],
      profit: null,
      attachmentList: [
        {
          id: null,
          fileName: null,
          displayName: null,
          note: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          lastModificationDate: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
      timeRequired: null,
      creator: {
        id: null,
        displayName: null,
      },
      customFieldList: [CUSTOM_FIELDS_DATA],
      contract: {
        id: null,
        note: null,
        number: null,
        contractNumber: null,
        positionList: [
          {
            id: null,
            positionKind: null,
            number: null,
            longtext: null,
            amount: null,
            unit: null,
          },
        ],
      },
      serviceContract: {
        id: null,
        note: null,
        number: null,
        serviceContractNumber: null,
      },
      position: {
        id: null,
        positionKind: null,
        number: null,
        longtext: null,
        amount: null,
        unit: null,
      },
    };
    return { filter, entity, data };
  }, [id]);
};
