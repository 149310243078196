import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableInstance } from 'react-table';

import { TableCellClickProvider } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/hooks/use-cell-click';

import { useUser } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  OpenMaskOverride,
  useParameterizedOpenMask,
} from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { useStringifyTableGroupFilter } from '../../../hooks';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { useFileActions } from '../FileActions';
import { GridStateProvider } from '../grid/GridStateProvider';
import { useProjectPlanningActions } from '../ProjectPlanningActions';
import { useTableLayoutState } from '../table-layout';

import { useProjectTableAreas } from './hooks/use-project-table-areas';
import { useProjectTableSchema } from './hooks/use-project-table-schema';

type IProjectTableCustomColumns = Record<string, never>;

const defaultSort = [
  { field: 'startDateOuter', direction: SortDirection.DESCENDING },
];

export const ProjectTable = React.forwardRef<TableInstance, IEntityTable>(
  function ProjectTable(props, ref) {
    return (
      <GridStateProvider>
        <ProjectTableInner {...props} ref={ref} />
      </GridStateProvider>
    );
  }
);

export const ProjectTableInner = React.forwardRef<TableInstance, IEntityTable>(
  function ProjectTableInner(props, ref) {
    const user = useUser();

    const [projectList, setProjectList] = useState<Project[]>([]);
    const [selectedProjectList, setSelectedProjectList] = useState<Project[]>(
      []
    );
    const [selectedUtilizationUserIds, setSelectedUtilizationUserIds] =
      useState([]);

    const [selectedRessourceClassCell, setSelectedRessourceClassCell] =
      useState<{ project: Project; ressourceClass: LookUp }>(null);

    const handleUtilizationClick = useCallback((userIds, project) => {
      setSelectedUtilizationUserIds(userIds);
      setSelectedProjectList([project]);
    }, []);

    const schema = useProjectTableSchema({
      projectList,
      projectPlanningProps: {
        selectedRessourceClassCell,
        onCellSelect: setSelectedRessourceClassCell,
        onUtilizationClick: handleUtilizationClick,
      },
    });

    const { entity: entityType } = schema;

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const forceRequestFields: Project = useMemo<Project>(() => {
      if (!user.isMaster) return props.forceRequestFields;
      return {
        ...props.forceRequestFields,
        projectAccessGroups: [
          {
            id: null,
          },
        ],
        childProjects: [
          {
            id: null,
          },
        ],
      } as Project;
    }, [props.forceRequestFields, user.isMaster]);

    const dataTable = useEntityDataTable<Project, IProjectTableCustomColumns>({
      schema,
      defaultSort,
      forceRequestFields,
      rowModifiers: (value) => {
        return {
          isFaded: value.projectStatus?.closedStatus === -1,
        };
      },
    });

    const { fileActionDefinitions, openInNewTab } = useFileActions({
      entity: entityType,
    });

    const { planCalendarActionDefinitions } = useProjectPlanningActions();

    const actions = useMemo(() => {
      return {
        openInNewTab: openInNewTab,
        custom: {
          left: [...fileActionDefinitions, ...planCalendarActionDefinitions],
        },
        createWidget: false,
      };
    }, [fileActionDefinitions, planCalendarActionDefinitions, openInNewTab]);

    const [date, setDate] = useState<Date>(new Date());

    useEffect(() => {
      if (!dataTable?.data) return;
      setProjectList(dataTable.data);
    }, [dataTable?.data]);

    const areas = useProjectTableAreas({
      date,
      selectedProjectList,
      selectedRessourceClassCell,
      selectedUtilizationUserIds,
    });

    const presetFields = useStringifyTableGroupFilter<Project>({
      groupKey: 'group',
      groupIdKey: 'groupId',
    });

    const onOpenMask = useParameterizedOpenMask({
      entityType: Entities.project,
      params: { create: { presetFields } },
    });

    return (
      <TableCellClickProvider onDateCellClick={setDate}>
        <OpenMaskOverride value={{ onOpenMask }}>
          <EntityTable
            ref={ref}
            layout={layout}
            areas={areas}
            actions={actions}
            {...dataTable}
            {...props}
            onSelectedRowsChange={(rows) => {
              /**Only update if selection has changed to prevent loop */
              const newProjects = rows.map((r) => r.original as Project);
              if (
                JSON.stringify(newProjects) !==
                JSON.stringify(selectedProjectList)
              ) {
                setDate(
                  new Date(
                    newProjects?.[0]?.startDateInner ||
                      newProjects?.[0]?.startDateOuter ||
                      new Date()
                  )
                );
                setSelectedProjectList(newProjects);
              }
            }}
          />
        </OpenMaskOverride>
      </TableCellClickProvider>
    );
  }
);
