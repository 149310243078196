import PhoneIcon from '@mui/icons-material/Phone';
import SendIcon from '@mui/icons-material/Send';
import { useCallback, useMemo } from 'react';

import { EmailTemplatesPopover, useDialogs } from '@work4all/components';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { useEmailTemplates } from '@work4all/components/lib/hooks';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useTicketContactsPopoverData } from '../../../../../hooks/useTicketContacts';
import { settings, useSetting } from '../../../../../settings';
import { EmailOverlayController } from '../../../../mask-overlays/mask-overlay/views/email/EmailOverlayController';
import { ContactsPopover } from '../../../../mask-overlays/mask-overlay/views/ticket/components/contacts-popover/ContactsPopover';

interface UseTicketCustomActionsProps {
  tickets: Ticket[];
}
const eMailTemplateKind = EMailTemplateKind.TICKET;

export const useTicketCustomActions = (props: UseTicketCustomActionsProps) => {
  const ticket = props.tickets?.[0];
  const dialogs = useDialogs();

  const templates = useEmailTemplates(eMailTemplateKind);

  const contactsPopoverData = useTicketContactsPopoverData(ticket?.id);
  const defaultTicketSenderAddress = useSetting(
    settings.defaultTicketSenderAddress()
  );

  const { value: standardTemplates, set: setStandardTemplates } = useSetting(
    settings.standardEmailTemplates()
  );

  const openEmailMask = useCallback(
    (emaiTemplate: EMailTemplate | null) => {
      const template = !emaiTemplate?.id ? null : emaiTemplate;
      const businessPartner = ticket.businessPartner?.data;
      const contact = ticket.contact;

      const entityTemplate = businessPartner
        ? {
            entity: contact ? Entities.contact : Entities.customer,
            id: contact
              ? `${contact.id}:customer:${businessPartner.id}`
              : businessPartner.id,
          }
        : undefined;

      const initialView: IStackItem = {
        view: (
          <EmailOverlayController
            entity={Entities.eMail}
            id={null}
            template={entityTemplate}
            onAfterSave={closeMaskOverlay}
            params={{
              basedon: 'EmailTemplate',
              emailTemplate: template ? JSON.stringify(template) : undefined,
              ticketTemplateContext: JSON.stringify(ticket),
              senderAddress: defaultTicketSenderAddress.value,
              processedMailTemplateArgs: JSON.stringify({
                noDirectContact: !contact,
              }),
            }}
          />
        ),
      };

      const dialog = dialogs.open(NavigationOverlay, {
        initialView: initialView,
        close: closeMaskOverlay,
      });

      function closeMaskOverlay() {
        dialogs.close(dialog.id);
      }
    },
    [ticket, defaultTicketSenderAddress.value, dialogs]
  );

  const renderContactPopover = useCallback(
    ({ open, onClose, anchorEl }) => {
      return (
        <ContactsPopover
          popoverConfig={{
            open,
            anchorEl: anchorEl,
            onClose,
          }}
          {...contactsPopoverData}
        />
      );
    },
    [contactsPopoverData]
  );
  const renderEmailPopover = useCallback(
    ({ open, onClose, anchorEl }) => {
      if (!open) return;
      return (
        <EmailTemplatesPopover
          eMailTemplateKind={eMailTemplateKind}
          anchorEl={anchorEl}
          onClose={() => {
            onClose();
          }}
          onTemplateClick={(template) => {
            onClose();
            openEmailMask(template);
          }}
          standardTemplates={standardTemplates}
          setStandardTemplates={setStandardTemplates}
        />
      );
    },
    [openEmailMask, setStandardTemplates, standardTemplates]
  );

  const actions = useMemo<CustomToolbarAction[]>(() => {
    const result: CustomToolbarAction[] = [];
    result.push({
      actionKey: 'onEmailClicked',
      IconComponent: SendIcon,
      handler: (_, evt) => {
        const standardTemplateId = standardTemplates[eMailTemplateKind];
        if (standardTemplateId) {
          const standardTemplate = templates?.find(
            (t) => t.id === standardTemplateId
          );
          openEmailMask(standardTemplate);
        } else {
          const dialog = dialogs.open(renderEmailPopover, {
            onClose: () => dialogs.close(dialog.id),
            anchorEl: evt.currentTarget,
          });
        }
      },
      mode: 'Edit',
      sections: ['mobile', 'preview'],
    });
    result.push({
      actionKey: 'onPhoneClick',
      IconComponent: PhoneIcon,
      handler: (_, evt) => {
        const dialog = dialogs.open(renderContactPopover, {
          onClose: () => dialogs.close(dialog.id),
          anchorEl: evt.currentTarget,
        });
      },
      mode: 'Edit',
      sections: ['mobile', 'preview'],
    });
    return result;
  }, [
    dialogs,
    openEmailMask,
    renderContactPopover,
    renderEmailPopover,
    standardTemplates,
    templates,
  ]);

  return actions;
};
