import {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useUser } from '@work4all/data';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canEditDocument } from '@work4all/utils/lib/permissions';

import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { MIME_TYPES } from '../../../preview/Preview';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { usePdfOverlay } from '../erp-preview/hooks/use-pdf-overlay';
import {
  FileAndThumbnail,
  FileListPreview,
  getDocumentFileList,
} from '../FileListPreview';
import { AttachmentRenameContext } from '../FileListPreview/attachment-rename-context';
import { useAttachmentRenameContextState } from '../FileListPreview/use-attachment-rename-context-state';
import { useEntityPreviewDataRequest } from '../hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '../types';

import { RequestedDocumentData } from './consts/RequestDocumentData';
import { DocumentPreview } from './DocumentPreview';
import { useDocumentPdfEditRights } from './hooks/use-document-pdf-edit-rights';

interface DocumentPreviewContainerProps extends EntityPreviewContainerProps {}

export function DocumentPreviewContainer(props: DocumentPreviewContainerProps) {
  const {
    ids,
    onCloseClick,
    onShareClicked,
    onEditClicked,
    onVisibilityToggle,
    convertProps,
  } = props;

  const documentId = ids[0];
  const { data: documents, refetch } = useEntityPreviewDataRequest<Document>(
    [documentId],
    Entities.document,
    RequestedDocumentData
  );

  const document = documents?.[0];

  const [selectedFileIdx, setSelectedFileIdx] = useState<number>(null);
  const selectedDocument =
    selectedFileIdx < 1
      ? document
      : document?.childDocuments?.[selectedFileIdx - 1];

  const pdfEditAllowed = useDocumentPdfEditRights({
    fileName: selectedDocument?.fileInfos?.fileEntityFilename,
    documentUserId: document?.userId,
  });

  const reloadPreview = useCallback(async () => {
    await refetch();
    /**
     * Workaround to reinitialize the <DocumentPreviewContainerInner> component
     * todo: implement a refetch inside <DocumentPreviewContainerInner>
     */
    setShowPreview(false);
    setTimeout(() => setShowPreview(true));
  }, [refetch]);

  const { component: pdfOverlay, open: openPdfOverlay } = usePdfOverlay({
    entityId: selectedDocument?.id,
    entityType: Entities.document,
    onClose: reloadPreview,
  });

  const attachmentRenameContextState = useAttachmentRenameContextState({
    nameDisabled: true,
    noteDisabled: true,
  });

  const [showPreview, setShowPreview] = useState(true);

  if (!document) return null;

  return (
    <AttachmentRenameContext.Provider value={attachmentRenameContextState}>
      {pdfOverlay}
      {showPreview && (
        <DocumentPreviewContainerInner
          document={document}
          onCloseClick={onCloseClick}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          onEditPdfClicked={pdfEditAllowed ? () => openPdfOverlay() : undefined}
          convertProps={convertProps}
          onSelectedIndexChange={setSelectedFileIdx}
          onVisibilityToggle={onVisibilityToggle}
        />
      )}
    </AttachmentRenameContext.Provider>
  );
}

interface DocumentPreviewContainerInnerProps {
  document: Document;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditPdfClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  pdfTextmarkConf?: PDFTextmarkConf;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  onSelectedIndexChange?: (index: number) => void;
  onVisibilityToggle?: (visible: boolean) => void;
}

function DocumentPreviewContainerInner(
  props: DocumentPreviewContainerInnerProps
) {
  const {
    document: doc,
    onCloseClick,
    onEditClicked,
    onEditPdfClicked,
    onShareClicked,
    pdfTextmarkConf,
    convertProps,
    onSelectedIndexChange,
    onVisibilityToggle,
  } = props;

  const { setNote } = useContext(AttachmentRenameContext);

  const { id, childDocuments, noteHtml, note } = doc;

  useEffect(() => {
    if (id) {
      setNote(id.toString(), noteHtml);
      childDocuments.forEach((doc) => {
        const id = (doc as Document)?.id;
        setNote(id, doc?.noteHtml);
      });
    }
  }, [id, noteHtml, setNote, childDocuments]);

  const user = useUser();

  const files = useMemo<FileAndThumbnail[]>(() => {
    return getDocumentFileList(doc);
  }, [doc]);

  const isLinkable =
    typeof doc.filePath === 'string' &&
    ['www', 'http', 'https'].some((prefix) => doc.filePath.startsWith(prefix));

  const selectedRowsIdsList = useMemo(() => {
    return doc.id ? [doc.id] : [];
  }, [doc.id]);

  // If a document is created from a link, use the old preview. Otherwise use
  // the new preview component that only works file lists of files.
  if (isLinkable) {
    return (
      <DocumentPreview
        entries={[doc]}
        entity={Entities.document}
        entityData={RequestedDocumentData}
        titleKeyField="note"
        title={doc.note}
        exists={doc.fileInfos?.fileServiceProviderInfos?.exists}
        url={doc.fileInfos?.previewUrl}
        mimeType={doc.fileInfos?.previewMimeType as MIME_TYPES}
        noPreviewUrl={
          doc.fileInfos?.fileServiceProviderInfos?.fspUrl ||
          doc.fileInfos?.downloadUrl
        }
        downloadUrl={doc.fileInfos?.downloadUrl}
        filePath={doc.filePath}
        onCloseClick={onCloseClick}
        onEditClicked={onEditClicked}
        onPdfEditClicked={onEditPdfClicked}
        fspUrl={doc.fileInfos?.fileServiceProviderInfos?.fspUrl}
        iconProps={{
          showPreviewExternallyIcon: canEditDocument(user, doc),
        }}
        onShareClicked={onShareClicked}
        pdfTextmarkConf={pdfTextmarkConf}
        convertProps={convertProps}
        selectedRowsIdsList={selectedRowsIdsList}
        onVisibilityToggle={onVisibilityToggle}
      />
    );
  }

  return (
    <FileListPreview
      title={note}
      files={files}
      entries={[doc]}
      iconProps={{
        showPreviewExternallyIcon: canEditDocument(user, doc),
      }}
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      onShareClicked={onShareClicked}
      onPdfEditClicked={onEditPdfClicked}
      convertProps={convertProps}
      onSelectedIndexChange={onSelectedIndexChange}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
