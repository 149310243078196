import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useProjectTimeTrackings = (props: {
  projectIdList: number[];
  skip?: boolean;
}) => {
  const { projectIdList, skip } = props;

  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.timeTracking,
      data: {
        id: null,
        amount: null,
        projectId: null,
        startDateTime: null,
        user: {
          id: null,
          displayName: null,
        },
        projectProcess: {
          id: null,
          ressourceClassId: null,
        },
      } as TimeTracking,
      filter: [
        {
          projectId: {
            $in: projectIdList,
          },
        },
      ],
      sort: [
        {
          field: 'startDateTime',
          direction: SortDirection.DESCENDING,
        },
      ],
    };
  }, [projectIdList]);

  return useDataProvider<TimeTracking>(request, skip);
};
