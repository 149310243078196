import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Appearance } from './Appearance.entity';
import { AppointmentState } from './AppointmentState.entity';
import { ArchivePdf } from './ArchivePdf.entity';
import { Article } from './Article.entity';
import { ArticlePurchaseInformation } from './ArticlePurchaseInformation.entity';
import { BaseDataLanguage } from './BaseDataLanguage.entity';
import { BulkPriceTier } from './BulkPriceTier.entity';
import { Calculation } from './Calculation.entity';
import { ChangeEvent } from './ChangeEvent.entity';
import { ChildItemCreated } from './ChildItemCreated.entity';
import { CommentEvent } from './CommentEvent.entity';
import { CompanyKpis } from './CompanyKpis.entity';
import { Contact } from './Contact.entity';
import { ContactTelephonenumbers } from './ContactTelephonenumbers.entity';
import { ContactUnion } from './ContactUnion.entity';
import { ContactUnionWrapper } from './ContactUnionWrapper.entity';
import { Contract } from './Contract.entity';
import { CreateEvent } from './CreateEvent.entity';
import { CreateReportResult } from './CreateReportResult.entity';
import { Currency } from './Currency.entity';
import { Customer } from './Customer.entity';
import { CustomerGroup } from './CustomerGroup.entity';
import { CustomFieldDefinition } from './CustomFieldDefinition.entity';
import { DeliveryKind } from './DeliveryKind.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { Department } from './Department.entity';
import { DocumentClass } from './DocumentClass.entity';
import { DocumentTemplateGroup } from './DocumentTemplateGroup.entity';
import { EMailTemplateGroup } from './EMailTemplateGroup.entity';
import { EntitySchema } from './EntitySchema.entity';
import { Event } from './Event.entity';
import { ExchangeRate } from './ExchangeRate.entity';
import { FileMetaInfo } from './FileMetaInfo.entity';
import { FileServiceProviderConfig } from './FileServiceProviderConfig.entity';
import { GeneralSettings } from './GeneralSettings.entity';
import { Group } from './Group.entity';
import { GroupQueryResult } from './GroupQueryResult.entity';
import { Holiday } from './Holiday.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { IncomingMailBlockedSender } from './IncomingMailBlockedSender.entity';
import { Invoice } from './Invoice.entity';
import { Layout } from './Layout.entity';
import { LayoutData } from './LayoutData.entity';
import { LegalNoticeParseResult } from './LegalNoticeParseResult.entity';
import { LetterTemplateGroup } from './LetterTemplateGroup.entity';
import { LicenseInfo } from './LicenseInfo.entity';
import { LookUp } from './LookUp.entity';
import { Mailbox } from './Mailbox.entity';
import { MailboxConfiguration } from './MailboxConfiguration.entity';
import { MailboxContent } from './MailboxContent.entity';
import { MailboxFolder } from './MailboxFolder.entity';
import { MailSearchContactsResultItem } from './MailSearchContactsResultItem.entity';
import { ManagementDashboard } from './ManagementDashboard.entity';
import { ObjectLock } from './ObjectLock.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { PaginationListAddressConnection } from './PaginationListAddressConnection.entity';
import { PaginationListAppointment } from './PaginationListAppointment.entity';
import { PaginationListArticle } from './PaginationListArticle.entity';
import { PaginationListCalculation } from './PaginationListCalculation.entity';
import { PaginationListCallMemo } from './PaginationListCallMemo.entity';
import { PaginationListCategoryClass } from './PaginationListCategoryClass.entity';
import { PaginationListCheckList } from './PaginationListCheckList.entity';
import { PaginationListChronoFileItem } from './PaginationListChronoFileItem.entity';
import { PaginationListContact } from './PaginationListContact.entity';
import { PaginationListContract } from './PaginationListContract.entity';
import { PaginationListCostCenter } from './PaginationListCostCenter.entity';
import { PaginationListCountry } from './PaginationListCountry.entity';
import { PaginationListCustomer } from './PaginationListCustomer.entity';
import { PaginationListDeliveryNote } from './PaginationListDeliveryNote.entity';
import { PaginationListDemand } from './PaginationListDemand.entity';
import { PaginationListDocument } from './PaginationListDocument.entity';
import { PaginationListEMail } from './PaginationListEMail.entity';
import { PaginationListEMailSignature } from './PaginationListEMailSignature.entity';
import { PaginationListEMailTemplate } from './PaginationListEMailTemplate.entity';
import { PaginationListFileLink } from './PaginationListFileLink.entity';
import { PaginationListInboundDeliveryNote } from './PaginationListInboundDeliveryNote.entity';
import { PaginationListInboundInvoice } from './PaginationListInboundInvoice.entity';
import { PaginationListInvoice } from './PaginationListInvoice.entity';
import { PaginationListLedgerAccount } from './PaginationListLedgerAccount.entity';
import { PaginationListLetter } from './PaginationListLetter.entity';
import { PaginationListMailboxContent } from './PaginationListMailboxContent.entity';
import { PaginationListNote } from './PaginationListNote.entity';
import { PaginationListNotification } from './PaginationListNotification.entity';
import { PaginationListOffer } from './PaginationListOffer.entity';
import { PaginationListOrder } from './PaginationListOrder.entity';
import { PaginationListPosition } from './PaginationListPosition.entity';
import { PaginationListProductionContract } from './PaginationListProductionContract.entity';
import { PaginationListProject } from './PaginationListProject.entity';
import { PaginationListProjectAccessGroup } from './PaginationListProjectAccessGroup.entity';
import { PaginationListProjectAccessRights } from './PaginationListProjectAccessRights.entity';
import { PaginationListProjectCategoryClass } from './PaginationListProjectCategoryClass.entity';
import { PaginationListProjectDistributor } from './PaginationListProjectDistributor.entity';
import { PaginationListProjectProcess } from './PaginationListProjectProcess.entity';
import { PaginationListRa } from './PaginationListRa.entity';
import { PaginationListRaViewModel } from './PaginationListRaViewModel.entity';
import { PaginationListReport } from './PaginationListReport.entity';
import { PaginationListReViewModel } from './PaginationListReViewModel.entity';
import { PaginationListSalesOpportunities } from './PaginationListSalesOpportunities.entity';
import { PaginationListSavedListFilter } from './PaginationListSavedListFilter.entity';
import { PaginationListServiceContract } from './PaginationListServiceContract.entity';
import { PaginationListSickness } from './PaginationListSickness.entity';
import { PaginationListSprint } from './PaginationListSprint.entity';
import { PaginationListString } from './PaginationListString.entity';
import { PaginationListSupplier } from './PaginationListSupplier.entity';
import { PaginationListTask } from './PaginationListTask.entity';
import { PaginationListTaxKey } from './PaginationListTaxKey.entity';
import { PaginationListTextBuildingBlock } from './PaginationListTextBuildingBlock.entity';
import { PaginationListTextBuildingBlockGroup } from './PaginationListTextBuildingBlockGroup.entity';
import { PaginationListTicket } from './PaginationListTicket.entity';
import { PaginationListTicketFilter } from './PaginationListTicketFilter.entity';
import { PaginationListTimeTracking } from './PaginationListTimeTracking.entity';
import { PaginationListTravelExpenses } from './PaginationListTravelExpenses.entity';
import { PaginationListTravelReceipts } from './PaginationListTravelReceipts.entity';
import { PaginationListUnit } from './PaginationListUnit.entity';
import { PaginationListUser } from './PaginationListUser.entity';
import { PaginationListUserCollection } from './PaginationListUserCollection.entity';
import { PaginationListVacation } from './PaginationListVacation.entity';
import { PaginationListVisitationReport } from './PaginationListVisitationReport.entity';
import { PaginationListWordDocumentTemplate } from './PaginationListWordDocumentTemplate.entity';
import { PaginationListWordLetterTemplate } from './PaginationListWordLetterTemplate.entity';
import { PartialInvoiceLogic } from './PartialInvoiceLogic.entity';
import { PaymentKind } from './PaymentKind.entity';
import { PriceGroup } from './PriceGroup.entity';
import { ProcessedEMailTemplate } from './ProcessedEMailTemplate.entity';
import { Project } from './Project.entity';
import { ProjectDirectoryDefinition } from './ProjectDirectoryDefinition.entity';
import { ProjectGroup } from './ProjectGroup.entity';
import { Report2 } from './Report2.entity';
import { ResourceUtilizationInfo } from './ResourceUtilizationInfo.entity';
import { SalesOpportunityRatingStatus } from './SalesOpportunityRatingStatus.entity';
import { Salutation } from './Salutation.entity';
import { SaveSendMailJob } from './SaveSendMailJob.entity';
import { SearchResultItem } from './SearchResultItem.entity';
import { Setting } from './Setting.entity';
import { ShadowBzObject } from './ShadowBzObject.entity';
import { ShadowRe } from './ShadowRe.entity';
import { StatisticCompanyReport } from './StatisticCompanyReport.entity';
import { StatisticCustomerDatasheet } from './StatisticCustomerDatasheet.entity';
import { StatisticSalesVolumeBySupplier } from './StatisticSalesVolumeBySupplier.entity';
import { StatisticSalesVolumeYear } from './StatisticSalesVolumeYear.entity';
import { StatisticSalesVoumeByCostCenter } from './StatisticSalesVoumeByCostCenter.entity';
import { StatisticSalesVoumeByCustomer } from './StatisticSalesVoumeByCustomer.entity';
import { StatisticSalesVoumeByItem } from './StatisticSalesVoumeByItem.entity';
import { StatisticSupplierDatasheet } from './StatisticSupplierDatasheet.entity';
import { Supplier } from './Supplier.entity';
import { SupplierGroup } from './SupplierGroup.entity';
import { TaxGroup } from './TaxGroup.entity';
import { Tenant } from './Tenant.entity';
import { TicketCategory1 } from './TicketCategory1.entity';
import { TicketKind } from './TicketKind.entity';
import { TimeCard } from './TimeCard.entity';
import { TimeTrackingOverviewItem } from './TimeTrackingOverviewItem.entity';
import { TopicSimple } from './TopicSimple.entity';
import { Tour } from './Tour.entity';
import { TravelCostInvoiceKind } from './TravelCostInvoiceKind.entity';
import { TravelCostInvoiceKindTransportCost } from './TravelCostInvoiceKindTransportCost.entity';
import { TravelExpenseReportPaymentMethod } from './TravelExpenseReportPaymentMethod.entity';
import { User } from './User.entity';
import { UserGroup } from './UserGroup.entity';
import { UserPresence } from './UserPresence.entity';
import { UserRightDefinition } from './UserRightDefinition.entity';
import { UserRole } from './UserRole.entity';
import { VacationEntitlement } from './VacationEntitlement.entity';
import { VacationKind } from './VacationKind.entity';
import { VatRate } from './VatRate.entity';

export class Query<T extends EMode = EMode.entity> {
  /** Alias: getAbteilungen */
  getAbteilungen?: Department<T>[];
  /** Alias: PaginationListgetAngebot */
  getAngebot?: PaginationListOffer<T>;
  /** Alias: getAnreden */
  getAnreden?: Salutation<T>[];
  /** Alias: getArchivPdf */
  getArchivPdf?: ArchivePdf<T>[];
  /** Alias: PaginationListgetArtikel */
  getArtikel?: PaginationListArticle<T>;
  /** Alias: getArtikelGruppen */
  getArtikelGruppen?: Group<T>[];
  /** Alias: PaginationListgetAufgaben */
  getAufgaben?: PaginationListTask<T>;
  /** Alias: PaginationListgetAuftrag */
  getAuftrag?: PaginationListContract<T>;
  /** Alias: PaginationListgetBedarfsanforderung */
  getBedarfsanforderung?: PaginationListDemand<T>;
  /** Alias: PaginationListgetBenutzer */
  getBenutzer?: PaginationListUser<T>;
  /** Alias: PaginationListgetBestellung */
  getBestellung?: PaginationListOrder<T>;
  /** Alias: PaginationListgetBesuchsberichte */
  getBesuchsberichte?: PaginationListVisitationReport<T>;
  /** Alias: PaginationListgetBriefe */
  getBriefe?: PaginationListLetter<T>;
  /** Alias: PaginationListgetCheckliste */
  getCheckliste?: PaginationListCheckList<T>;
  /** Alias: PaginationListgetDokument */
  getDokument?: PaginationListDocument<T>;
  /** Alias: PaginationListgetEingangslieferschein */
  getEingangslieferschein?: PaginationListInboundDeliveryNote<T>;
  /** Alias: PaginationListgetEingangsrechnung */
  getEingangsrechnung?: PaginationListInboundInvoice<T>;
  /** Alias: PaginationListgetEinheiten */
  getEinheiten?: PaginationListUnit<T>;
  /** Alias: PaginationListgetEmails */
  getEmails?: PaginationListEMail<T>;
  /** Alias: getFileInfo */
  getFileInfo?: FileMetaInfo<T>;
  /** Alias: getGeneralSettings */
  getGeneralSettings?: GeneralSettings<T>[];
  /** Alias: PaginationListgetKalkulation */
  getKalkulation?: PaginationListCalculation<T>;
  /** Alias: PaginationListgetKategorieKlassen */
  getKategorieKlassen?: PaginationListCategoryClass<T>;
  /** Alias: PaginationListgetKostenstellen */
  getKostenstellen?: PaginationListCostCenter<T>;
  /** Alias: PaginationListgetKrankeitstage */
  getKrankeitstage?: PaginationListSickness<T>;
  /** Alias: PaginationListgetKunden */
  getKunden?: PaginationListCustomer<T>;
  /** Alias: getKundenGruppen */
  getKundenGruppen?: Group<T>[];
  /** Alias: getLayoutData */
  getLayoutData?: LayoutData<T>;
  /** Alias: getLayouts */
  getLayouts?: Layout<T>[];
  /** Alias: PaginationListgetLieferanten */
  getLieferanten?: PaginationListSupplier<T>;
  /** Alias: getLieferantenGruppen */
  getLieferantenGruppen?: Group<T>[];
  /** Alias: PaginationListgetLieferschein */
  getLieferschein?: PaginationListDeliveryNote<T>;
  /** Alias: getLieferungArt */
  getLieferungArt?: DeliveryKind<T>[];
  /** Alias: getLookups */
  getLookups?: LookUp<T>[];
  /** Alias: getMandanten */
  getMandanten?: Tenant<T>[];
  /** Alias: PaginationListgetNotizen */
  getNotizen?: PaginationListNote<T>;
  /** Alias: getPreisgruppe */
  getPreisgruppe?: PriceGroup<T>[];
  /** Alias: PaginationListgetProduktionsauftrag */
  getProduktionsauftrag?: PaginationListProductionContract<T>;
  /** Alias: getProjektGruppen */
  getProjektGruppen?: Group<T>[];
  /** Alias: PaginationListgetProjektVerteiler */
  getProjektVerteiler?: PaginationListProjectDistributor<T>;
  /** Alias: PaginationListgetProjekte */
  getProjekte?: PaginationListProject<T>;
  /** Alias: PaginationListgetRechnung */
  getRechnung?: PaginationListInvoice<T>;
  /** Alias: PaginationListgetReisekosten */
  getReisekosten?: PaginationListTravelExpenses<T>;
  /** Alias: PaginationListgetReports */
  getReports?: PaginationListReport<T>;
  /** Alias: PaginationListgetSachkonten */
  getSachkonten?: PaginationListLedgerAccount<T>;
  /** Alias: getSettings */
  getSettings?: Setting<T>[];
  /** Alias: getTeilrechnungLogik */
  getTeilrechnungLogik?: PartialInvoiceLogic<T>[];
  /** Alias: PaginationListgetTelefonate */
  getTelefonate?: PaginationListCallMemo<T>;
  /** Alias: PaginationListgetTermine */
  getTermine?: PaginationListAppointment<T>;
  /** Alias: PaginationListgetTextbausteine */
  getTextbausteine?: PaginationListTextBuildingBlock<T>;
  /** Alias: PaginationListgetTickets */
  getTickets?: PaginationListTicket<T>;
  /** Alias: getTouren */
  getTouren?: Tour<T>[];
  /** Alias: PaginationListgetUrlaub */
  getUrlaub?: PaginationListVacation<T>;
  /** Alias: getWaehrungen */
  getWaehrungen?: Currency<T>[];
  /** Alias: PaginationListgetWartungsvertrag */
  getWartungsvertrag?: PaginationListServiceContract<T>;
  /** Alias: getZahlungsarten */
  getZahlungsarten?: PaymentKind<T>[];
  /** Alias: PaginationListgetZeiterfassung */
  getZeiterfassung?: PaginationListTimeTracking<T>;
  /** Alias: getZeitkarte */
  getZeitkarte?: TimeCard<T>;
  /** Alias: groupBy */
  groupBy?: GroupQueryResult<T>;
  /** Alias: getShadowBzObject */
  getShadowBzObject?: T extends EMode.query
    ? ShadowBzObject<T>
    :
        | Calculation<T>[]
        | Offer<T>[]
        | Contract<T>[]
        | Invoice<T>[]
        | DeliveryNote<T>[]
        | Demand<T>[]
        | Order<T>[]
        | InboundDeliveryNote<T>[];
  /** Alias: getPreisstaffeln */
  getPreisstaffeln?: BulkPriceTier<T>[];
  /** Alias: getObjectLocks */
  getObjectLocks?: ObjectLock<T>[];
  /** Alias: getObjectLock */
  getObjectLock?: ObjectLock<T>;
  /** Alias: getSchema */
  getSchema?: EntitySchema<T>[];
  /** Alias: PaginationListgetAnsprechpartner */
  getContactList?: PaginationListContact<T>;
  /** Alias: getDocumentClasses */
  getDocumentList?: DocumentClass<T>[];
  /** Alias: getThemen */
  getTopicLookUps?: TopicSimple<T>[];
  /** Alias: searchMailContact */
  searchMailContact?: MailSearchContactsResultItem<T>[];
  /** Alias: getTerminFarben */
  getAppointmentState?: AppointmentState<T>[];
  /** Alias: PaginationListgetProjektVorgaenge */
  getProjectProcess?: PaginationListProjectProcess<T>;
  /** Alias: searchContacts */
  searchContacts?: ContactUnionWrapper<T>[];
  /** Alias: PaginationListgetSteuerschluessel */
  getSteuerschluessel?: PaginationListTaxKey<T>;
  /** Alias: getWaehrungKurse */
  getExchangeRates?: ExchangeRate<T>[];
  /** Alias: PaginationListgetAdressVerknuepfung */
  getAdressConnection?: PaginationListAddressConnection<T>;
  /** Alias: getTextmarkenFilled */
  getTextmarksFilled?: string;
  /** Alias: PaginationListgetCountries */
  getCountries?: PaginationListCountry<T>;
  /** Alias: PaginationListgetPostalCodes */
  getPostalCodes?: PaginationListString<T>;
  /** Alias: PaginationListgetCities */
  getCities?: PaginationListString<T>;
  /** Alias: PaginationListgetStreets */
  getStreets?: PaginationListString<T>;
  /** Alias: statisticSalesAmountByYear */
  statisticSalesAmountByYear?: StatisticSalesVolumeYear<T>[];
  /** Alias: getLetterTemplateGroups */
  getLetterTemplateGroups?: LetterTemplateGroup<T>[];
  /** Alias: getDocumentTemplateGroups */
  getDocumentTemplateGroups?: DocumentTemplateGroup<T>[];
  /** Alias: PaginationListgetWordLetterTemplates */
  getWordLetterTemplates?: PaginationListWordLetterTemplate<T>;
  /** Alias: PaginationListgetWordDocumentTemplates */
  getWordDocumentTemplates?: PaginationListWordDocumentTemplate<T>;
  /** Alias: getTimeTrackingOverview */
  getTimeTrackingOverview?: TimeTrackingOverviewItem<T>[];
  /** Alias: statisticSalesAmountByCustomer */
  statisticSalesAmountByCustomer?: StatisticSalesVoumeByCustomer<T>;
  /** Alias: statisticSalesAmountBySupplier */
  statisticSalesAmountBySupplier?: StatisticSalesVolumeBySupplier<T>;
  /** Alias: statisticSalesAmountByItem */
  statisticSalesAmountByItem?: StatisticSalesVoumeByItem<T>;
  /** Alias: statisticSalesAmountByCostCenter */
  statisticSalesAmountByCostCenter?: StatisticSalesVoumeByCostCenter<T>;
  /** Alias: getLanguages */
  getLanguages?: BaseDataLanguage<T>[];
  /** Alias: getAppearance */
  getAppearance?: Appearance<T>;
  /** Alias: getCompanyKpis */
  getCompanyKpis?: CompanyKpis<T>;
  /** Alias: getShadowRe */
  getShadowRe?: ShadowRe<T>[];
  /** Alias: getUserPresence */
  getUserPresence?: UserPresence<T>[];
  /** Alias: getProcessedMailTemplate */
  getProcessedMailTemplate?: string;
  /** Alias: PaginationListgetPositionen */
  getPositionList?: PaginationListPosition<T>;
  /** Alias: getLicenseInfo */
  getLicenseInfo?: LicenseInfo<T>;
  /** Alias: getPublicHolidays */
  getPublicHolidays?: Holiday<T>[];
  /** Alias: getUserRoles */
  getUserRoles?: UserRole<T>[];
  /** Alias: getTicketKategorien1 */
  getTicketCategory1List?: TicketCategory1<T>[];
  /** Alias: getTicketArten */
  getTicketKind?: TicketKind<T>[];
  /** Alias: PaginationListgetEvents */
  getEvents?: T extends EMode.query
    ? Event<T>
    : CreateEvent<T> | CommentEvent<T> | ChangeEvent<T> | ChildItemCreated<T>;
  /** Alias: getBusinesspartnerFromLegalNotice */
  getBusinesspartnerFromLegalNotice?: LegalNoticeParseResult<T>;
  /** Alias: PaginationListgetProjectTopLevelCategories */
  getProjectTopLevelCategories?: PaginationListProjectCategoryClass<T>;
  /** Alias: getSaveSendMailJobs */
  getSaveSendMailJobs?: SaveSendMailJob<T>[];
  /** Alias: getEMailTemplateGroups */
  getEMailTemplateGroups?: EMailTemplateGroup<T>[];
  /** Alias: PaginationListgetUserCollections */
  getUserCollections?: PaginationListUserCollection<T>;
  /** Alias: PaginationListgetFilesChronologically */
  getFilesChronologically?: PaginationListChronoFileItem<T>;
  /** Alias: getModuleAccessRights */
  getModuleAccessRights?: string[];
  /** Alias: getProcessedMailTemplateWithSubject */
  getProcessedMailTemplateWithSubject?: ProcessedEMailTemplate<T>;
  /** Alias: PaginationListgetTextbausteinGruppen */
  getTextbausteinGruppen?: PaginationListTextBuildingBlockGroup<T>;
  /** Alias: getCompleteTelephonenumbers */
  getCompleteTelephonenumbers?: ContactTelephonenumbers<T>;
  /** Alias: PaginationListgetTicketFilters */
  getTicketFilters?: PaginationListTicketFilter<T>;
  /** Alias: getProcessedMailTemplateV2 */
  getProcessedMailTemplateV2?: ProcessedEMailTemplate<T>;
  /** Alias: suggestContactEMailAddress */
  suggestContactEMailAddress?: string;
  /** Alias: getCustomFieldDefinition */
  getCustomFieldDefinition?: CustomFieldDefinition<T>[];
  /** Alias: readMailboxes */
  readMailboxes?: Mailbox<T>[];
  /** Alias: PaginationListreadMailboxContent */
  readMailboxContent?: PaginationListMailboxContent<T>;
  /** Alias: PaginationListgetNotifications */
  getNotifications?: PaginationListNotification<T>;
  /** Alias: PaginationListgetSavedListFilter */
  getSavedListFilter?: PaginationListSavedListFilter<T>;
  /** Alias: getUrlaubsarten */
  getUrlaubsarten?: VacationKind<T>[];
  /** Alias: PaginationListgetVerkaufschancen */
  getVerkaufschancen?: PaginationListSalesOpportunities<T>;
  /** Alias: getSalesOpportunityGroups */
  getSalesOpportunityGroups?: Group<T>[];
  /** Alias: getMailboxConfigurations */
  getMailboxConfigurations?: MailboxConfiguration<T>[];
  /** Alias: PaginationListgetProjectAccessRights */
  getProjectAccessRights?: PaginationListProjectAccessRights<T>;
  /** Alias: createCrystalReport */
  createCrystalReport?: CreateReportResult<T>;
  /** Alias: getUpcommingBirthdays */
  getUpcommingBirthdays?: T extends EMode.query
    ? ContactUnion<T>
    : User<T>[] | Customer<T>[] | Supplier<T>[] | Contact<T>[];
  /** Alias: getSalesOpportunityRatingStatus */
  getSalesOpportunityRatingStatus?: SalesOpportunityRatingStatus<T>[];
  /** Alias: PaginationListgetProjectAccessGroups */
  getProjectAccessGroups?: PaginationListProjectAccessGroup<T>;
  /** Alias: getManagementDashboard */
  getManagementDashboard?: ManagementDashboard<T>[];
  /** Alias: getCompanyReport */
  getCompanyReport?: StatisticCompanyReport<T>[];
  /** Alias: PaginationListgetRa */
  getRa?: PaginationListRa<T>;
  /** Alias: readMail */
  readMail?: MailboxContent<T>;
  /** Alias: PaginationListgetTravelReceipts */
  getTravelReceipts?: PaginationListTravelReceipts<T>;
  /** Alias: getReport2 */
  getReport2?: Report2<T>[];
  /** Alias: getIncomingMailBlockedSender */
  getIncomingMailBlockedSender?: IncomingMailBlockedSender<T>[];
  /** Alias: getUserrightDefinitions */
  getUserrightDefinitions?: UserRightDefinition<T>[];
  /** Alias: getVatRates */
  getVatRates?: VatRate<T>[];
  /** Alias: PaginationListgetTicketSprints */
  getTicketSprints?: PaginationListSprint<T>;
  /** Alias: getTravelAndReceiptReceiptTypes */
  getTravelAndReceiptReceiptTypes?: TravelCostInvoiceKind<T>[];
  /** Alias: getTravelAndReceiptReceiptDrivingTypes */
  getTravelAndReceiptReceiptDrivingTypes?: TravelCostInvoiceKindTransportCost<T>[];
  /** Alias: getTravelAndReceiptReceiptPaymentType */
  getTravelAndReceiptReceiptPaymentType?: TravelExpenseReportPaymentMethod<T>[];
  /** Alias: PaginationListgetFileLink */
  getFileLink?: PaginationListFileLink<T>;
  /** Alias: getTaxGroups */
  getTaxGroups?: TaxGroup<T>[];
  /** Alias: getProjectDirectoryDefinitions */
  getProjectDirectoryDefinitions?: ProjectDirectoryDefinition<T>[];
  /** Alias: PaginationListgetIncomingInvoiceViewModel */
  getIncomingInvoiceViewModel?: PaginationListReViewModel<T>;
  /** Alias: getDuplicationName */
  getDuplicationName?: string;
  /** Alias: getCustomerDatasheet */
  getCustomerDatasheet?: StatisticCustomerDatasheet<T>[];
  /** Alias: getSupplierDatasheet */
  getSupplierDatasheet?: StatisticSupplierDatasheet<T>[];
  /** Alias: PaginationListgetOutgoingInvoiceViewModel */
  getOutgoingInvoiceViewModel?: PaginationListRaViewModel<T>;
  /** Alias: PaginationListgetEMailSignaturen2 */
  getEMailSignaturen2?: PaginationListEMailSignature<T>;
  /** Alias: PaginationListgetEMailVorlagen2 */
  getEMailTemplates2?: PaginationListEMailTemplate<T>;
  /** Alias: getResourceUtilizationInfo */
  getResourceUtilizationInfo?: ResourceUtilizationInfo<T>[];
  /** Alias: getFileServiceProvider */
  getFileServiceProvider?: FileServiceProviderConfig<T>[];
  /** Alias: getProjectGroups */
  getProjectGroups?: ProjectGroup<T>[];
  /** Alias: getCustomerGroups */
  getCustomerGroups?: CustomerGroup<T>[];
  /** Alias: getSupplierGroups */
  getSupplierGroups?: SupplierGroup<T>[];
  /** Alias: getArticleCategories */
  getArticleCategories?: Group<T>[];
  /** Alias: search */
  search?: T extends EMode.query
    ? SearchResultItem<T>
    :
        | Customer<T>[]
        | Supplier<T>[]
        | Project<T>[]
        | Contact<T>[]
        | Article<T>[];
  /** Alias: readMailboxesFlat */
  readMailboxesFlat?: Mailbox<T>[];
  /** Alias: readMailboxChildFoldersFlat */
  readMailboxChildFoldersFlat?: MailboxFolder<T>[];
  /** Alias: getObjectProtectionGroups */
  getObjectProtectionGroups?: UserGroup<T>[];
  /** Alias: getArticlePurchaseInformation */
  getArticlePurchaseInformation?: ArticlePurchaseInformation<T>[];
  /** Alias: getUrlaubsanspruch */
  getUrlaubsanspruch?: VacationEntitlement<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<Query> = {
  getAbteilungen: {
    alias: 'getAbteilungen',
    entity: Entities.department,
  },
  getAngebot: {
    alias: 'getAngebot',
    entity: Entities.offer,
  },
  getAnreden: {
    alias: 'getAnreden',
    entity: Entities.salutation,
  },
  getArchivPdf: {
    alias: 'getArchivPdf',
    entity: Entities.archivePdf,
  },
  getArtikel: {
    alias: 'getArtikel',
    entity: Entities.article,
  },
  getArtikelGruppen: {
    alias: 'getArtikelGruppen',
    entity: Entities.group,
  },
  getAufgaben: {
    alias: 'getAufgaben',
    entity: Entities.task,
  },
  getAuftrag: {
    alias: 'getAuftrag',
    entity: Entities.contract,
  },
  getBedarfsanforderung: {
    alias: 'getBedarfsanforderung',
    entity: Entities.demand,
  },
  getBenutzer: {
    alias: 'getBenutzer',
    entity: Entities.user,
  },
  getBestellung: {
    alias: 'getBestellung',
    entity: Entities.order,
  },
  getBesuchsberichte: {
    alias: 'getBesuchsberichte',
    entity: Entities.visitationReport,
  },
  getBriefe: {
    alias: 'getBriefe',
    entity: Entities.letter,
  },
  getCheckliste: {
    alias: 'getCheckliste',
    entity: Entities.checkList,
  },
  getDokument: {
    alias: 'getDokument',
    entity: Entities.document,
  },
  getEingangslieferschein: {
    alias: 'getEingangslieferschein',
    entity: Entities.inboundDeliveryNote,
  },
  getEingangsrechnung: {
    alias: 'getEingangsrechnung',
    entity: Entities.inboundInvoice,
  },
  getEinheiten: {
    alias: 'getEinheiten',
    entity: Entities.unit,
  },
  getEmails: {
    alias: 'getEmails',
    entity: Entities.eMail,
  },
  getFileInfo: {
    alias: 'getFileInfo',
    entity: Entities.fileMetaInfo,
  },
  getGeneralSettings: {
    alias: 'getGeneralSettings',
    entity: Entities.generalSettings,
  },
  getKalkulation: {
    alias: 'getKalkulation',
    entity: Entities.calculation,
  },
  getKategorieKlassen: {
    alias: 'getKategorieKlassen',
    entity: Entities.categoryClass,
  },
  getKostenstellen: {
    alias: 'getKostenstellen',
    entity: Entities.costCenter,
  },
  getKrankeitstage: {
    alias: 'getKrankeitstage',
    entity: Entities.sickness,
  },
  getKunden: {
    alias: 'getKunden',
    entity: Entities.customer,
  },
  getKundenGruppen: {
    alias: 'getKundenGruppen',
    entity: Entities.group,
  },
  getLayoutData: {
    alias: 'getLayoutData',
    entity: Entities.layoutData,
  },
  getLayouts: {
    alias: 'getLayouts',
    entity: Entities.layout,
  },
  getLieferanten: {
    alias: 'getLieferanten',
    entity: Entities.supplier,
  },
  getLieferantenGruppen: {
    alias: 'getLieferantenGruppen',
    entity: Entities.group,
  },
  getLieferschein: {
    alias: 'getLieferschein',
    entity: Entities.deliveryNote,
  },
  getLieferungArt: {
    alias: 'getLieferungArt',
    entity: Entities.deliveryKind,
  },
  getLookups: {
    alias: 'getLookups',
    entity: Entities.lookUp,
  },
  getMandanten: {
    alias: 'getMandanten',
    entity: Entities.tenant,
  },
  getNotizen: {
    alias: 'getNotizen',
    entity: Entities.note,
  },
  getPreisgruppe: {
    alias: 'getPreisgruppe',
    entity: Entities.priceGroup,
  },
  getProduktionsauftrag: {
    alias: 'getProduktionsauftrag',
    entity: Entities.productionContract,
  },
  getProjektGruppen: {
    alias: 'getProjektGruppen',
    entity: Entities.group,
  },
  getProjektVerteiler: {
    alias: 'getProjektVerteiler',
    entity: Entities.projectDistributor,
  },
  getProjekte: {
    alias: 'getProjekte',
    entity: Entities.project,
  },
  getRechnung: {
    alias: 'getRechnung',
    entity: Entities.invoice,
  },
  getReisekosten: {
    alias: 'getReisekosten',
    entity: Entities.travelExpenses,
  },
  getReports: {
    alias: 'getReports',
    entity: Entities.report,
  },
  getSachkonten: {
    alias: 'getSachkonten',
    entity: Entities.ledgerAccount,
  },
  getSettings: {
    alias: 'getSettings',
    entity: Entities.setting,
  },
  getTeilrechnungLogik: {
    alias: 'getTeilrechnungLogik',
    entity: Entities.partialInvoiceLogic,
  },
  getTelefonate: {
    alias: 'getTelefonate',
    entity: Entities.callMemo,
  },
  getTermine: {
    alias: 'getTermine',
    entity: Entities.appointment,
  },
  getTextbausteine: {
    alias: 'getTextbausteine',
    entity: Entities.textBuildingBlock,
  },
  getTickets: {
    alias: 'getTickets',
    entity: Entities.ticket,
  },
  getTouren: {
    alias: 'getTouren',
    entity: Entities.tour,
  },
  getUrlaub: {
    alias: 'getUrlaub',
    entity: Entities.vacation,
  },
  getWaehrungen: {
    alias: 'getWaehrungen',
    entity: Entities.currency,
  },
  getWartungsvertrag: {
    alias: 'getWartungsvertrag',
    entity: Entities.serviceContract,
  },
  getZahlungsarten: {
    alias: 'getZahlungsarten',
    entity: Entities.paymentKind,
  },
  getZeiterfassung: {
    alias: 'getZeiterfassung',
    entity: Entities.timeTracking,
  },
  getZeitkarte: {
    alias: 'getZeitkarte',
    entity: Entities.timeCard,
  },
  groupBy: {
    alias: 'groupBy',
    entity: Entities.groupQueryResult,
  },
  getShadowBzObject: {
    alias: 'getShadowBzObject',
    entity: [
      Entities.calculation,
      Entities.offer,
      Entities.contract,
      Entities.invoice,
      Entities.deliveryNote,
      Entities.demand,
      Entities.order,
      Entities.inboundDeliveryNote,
    ],
  },
  getPreisstaffeln: {
    alias: 'getPreisstaffeln',
    entity: Entities.bulkPriceTier,
  },
  getObjectLocks: {
    alias: 'getObjectLocks',
    entity: Entities.objectLock,
  },
  getObjectLock: {
    alias: 'getObjectLock',
    entity: Entities.objectLock,
  },
  getSchema: {
    alias: 'getSchema',
    entity: Entities.entitySchema,
  },
  getContactList: {
    alias: 'getAnsprechpartner',
    entity: Entities.contact,
  },
  getDocumentList: {
    alias: 'getDocumentClasses',
    entity: Entities.documentClass,
  },
  getTopicLookUps: {
    alias: 'getThemen',
    entity: Entities.topicSimple,
  },
  searchMailContact: {
    alias: 'searchMailContact',
    entity: Entities.mailSearchContactsResultItem,
  },
  getAppointmentState: {
    alias: 'getTerminFarben',
    entity: Entities.appointmentState,
  },
  getProjectProcess: {
    alias: 'getProjektVorgaenge',
    entity: Entities.projectProcess,
  },
  searchContacts: {
    alias: 'searchContacts',
    entity: Entities.contactUnionWrapper,
  },
  getSteuerschluessel: {
    alias: 'getSteuerschluessel',
    entity: Entities.taxKey,
  },
  getExchangeRates: {
    alias: 'getWaehrungKurse',
    entity: Entities.exchangeRate,
  },
  getAdressConnection: {
    alias: 'getAdressVerknuepfung',
    entity: Entities.addressConnection,
  },
  getTextmarksFilled: {
    alias: 'getTextmarkenFilled',
  },
  getCountries: {
    alias: 'getCountries',
    entity: Entities.country,
  },
  getPostalCodes: {
    alias: 'getPostalCodes',
  },
  getCities: {
    alias: 'getCities',
  },
  getStreets: {
    alias: 'getStreets',
  },
  statisticSalesAmountByYear: {
    alias: 'statisticSalesAmountByYear',
    entity: Entities.statisticSalesVolumeYear,
  },
  getLetterTemplateGroups: {
    alias: 'getLetterTemplateGroups',
    entity: Entities.letterTemplateGroup,
  },
  getDocumentTemplateGroups: {
    alias: 'getDocumentTemplateGroups',
    entity: Entities.documentTemplateGroup,
  },
  getWordLetterTemplates: {
    alias: 'getWordLetterTemplates',
    entity: Entities.wordLetterTemplate,
  },
  getWordDocumentTemplates: {
    alias: 'getWordDocumentTemplates',
    entity: Entities.wordDocumentTemplate,
  },
  getTimeTrackingOverview: {
    alias: 'getTimeTrackingOverview',
    entity: Entities.timeTrackingOverviewItem,
  },
  statisticSalesAmountByCustomer: {
    alias: 'statisticSalesAmountByCustomer',
    entity: Entities.statisticSalesVoumeByCustomer,
  },
  statisticSalesAmountBySupplier: {
    alias: 'statisticSalesAmountBySupplier',
    entity: Entities.statisticSalesVolumeBySupplier,
  },
  statisticSalesAmountByItem: {
    alias: 'statisticSalesAmountByItem',
    entity: Entities.statisticSalesVoumeByItem,
  },
  statisticSalesAmountByCostCenter: {
    alias: 'statisticSalesAmountByCostCenter',
    entity: Entities.statisticSalesVoumeByCostCenter,
  },
  getLanguages: {
    alias: 'getLanguages',
    entity: Entities.baseDataLanguage,
  },
  getAppearance: {
    alias: 'getAppearance',
    entity: Entities.appearance,
  },
  getCompanyKpis: {
    alias: 'getCompanyKpis',
    entity: Entities.companyKpis,
  },
  getShadowRe: {
    alias: 'getShadowRe',
    entity: Entities.shadowRe,
  },
  getUserPresence: {
    alias: 'getUserPresence',
    entity: Entities.userPresence,
  },
  getProcessedMailTemplate: {
    alias: 'getProcessedMailTemplate',
  },
  getPositionList: {
    alias: 'getPositionen',
    entity: Entities.position,
  },
  getLicenseInfo: {
    alias: 'getLicenseInfo',
    entity: Entities.licenseInfo,
  },
  getPublicHolidays: {
    alias: 'getPublicHolidays',
    entity: Entities.holiday,
  },
  getUserRoles: {
    alias: 'getUserRoles',
    entity: Entities.userRole,
  },
  getTicketCategory1List: {
    alias: 'getTicketKategorien1',
    entity: Entities.ticketCategory1,
  },
  getTicketKind: {
    alias: 'getTicketArten',
    entity: Entities.ticketKind,
  },
  getEvents: {
    alias: 'getEvents',
    entity: [
      Entities.createEvent,
      Entities.commentEvent,
      Entities.changeEvent,
      Entities.childItemCreated,
    ],
  },
  getBusinesspartnerFromLegalNotice: {
    alias: 'getBusinesspartnerFromLegalNotice',
    entity: Entities.legalNoticeParseResult,
  },
  getProjectTopLevelCategories: {
    alias: 'getProjectTopLevelCategories',
    entity: Entities.projectCategoryClass,
  },
  getSaveSendMailJobs: {
    alias: 'getSaveSendMailJobs',
    entity: Entities.saveSendMailJob,
  },
  getEMailTemplateGroups: {
    alias: 'getEMailTemplateGroups',
    entity: Entities.eMailTemplateGroup,
  },
  getUserCollections: {
    alias: 'getUserCollections',
    entity: Entities.userCollection,
  },
  getFilesChronologically: {
    alias: 'getFilesChronologically',
    entity: Entities.chronoFileItem,
  },
  getModuleAccessRights: {
    alias: 'getModuleAccessRights',
  },
  getProcessedMailTemplateWithSubject: {
    alias: 'getProcessedMailTemplateWithSubject',
    entity: Entities.processedEMailTemplate,
  },
  getTextbausteinGruppen: {
    alias: 'getTextbausteinGruppen',
    entity: Entities.textBuildingBlockGroup,
  },
  getCompleteTelephonenumbers: {
    alias: 'getCompleteTelephonenumbers',
    entity: Entities.contactTelephonenumbers,
  },
  getTicketFilters: {
    alias: 'getTicketFilters',
    entity: Entities.ticketFilter,
  },
  getProcessedMailTemplateV2: {
    alias: 'getProcessedMailTemplateV2',
    entity: Entities.processedEMailTemplate,
  },
  suggestContactEMailAddress: {
    alias: 'suggestContactEMailAddress',
  },
  getCustomFieldDefinition: {
    alias: 'getCustomFieldDefinition',
    entity: Entities.customFieldDefinition,
  },
  readMailboxes: {
    alias: 'readMailboxes',
    entity: Entities.mailbox,
  },
  readMailboxContent: {
    alias: 'readMailboxContent',
    entity: Entities.mailboxContent,
  },
  getNotifications: {
    alias: 'getNotifications',
    entity: Entities.notification,
  },
  getSavedListFilter: {
    alias: 'getSavedListFilter',
    entity: Entities.savedListFilter,
  },
  getUrlaubsarten: {
    alias: 'getUrlaubsarten',
    entity: Entities.vacationKind,
  },
  getVerkaufschancen: {
    alias: 'getVerkaufschancen',
    entity: Entities.salesOpportunities,
  },
  getSalesOpportunityGroups: {
    alias: 'getSalesOpportunityGroups',
    entity: Entities.group,
  },
  getMailboxConfigurations: {
    alias: 'getMailboxConfigurations',
    entity: Entities.mailboxConfiguration,
  },
  getProjectAccessRights: {
    alias: 'getProjectAccessRights',
    entity: Entities.projectAccessRights,
  },
  createCrystalReport: {
    alias: 'createCrystalReport',
    entity: Entities.createReportResult,
  },
  getUpcommingBirthdays: {
    alias: 'getUpcommingBirthdays',
    entity: [
      Entities.user,
      Entities.customer,
      Entities.supplier,
      Entities.contact,
    ],
  },
  getSalesOpportunityRatingStatus: {
    alias: 'getSalesOpportunityRatingStatus',
    entity: Entities.salesOpportunityRatingStatus,
  },
  getProjectAccessGroups: {
    alias: 'getProjectAccessGroups',
    entity: Entities.projectAccessGroup,
  },
  getManagementDashboard: {
    alias: 'getManagementDashboard',
    entity: Entities.managementDashboard,
  },
  getCompanyReport: {
    alias: 'getCompanyReport',
    entity: Entities.statisticCompanyReport,
  },
  getRa: {
    alias: 'getRa',
    entity: Entities.ra,
  },
  readMail: {
    alias: 'readMail',
    entity: Entities.mailboxContent,
  },
  getTravelReceipts: {
    alias: 'getTravelReceipts',
    entity: Entities.travelReceipts,
  },
  getReport2: {
    alias: 'getReport2',
    entity: Entities.report2,
  },
  getIncomingMailBlockedSender: {
    alias: 'getIncomingMailBlockedSender',
    entity: Entities.incomingMailBlockedSender,
  },
  getUserrightDefinitions: {
    alias: 'getUserrightDefinitions',
    entity: Entities.userRightDefinition,
  },
  getVatRates: {
    alias: 'getVatRates',
    entity: Entities.vatRate,
  },
  getTicketSprints: {
    alias: 'getTicketSprints',
    entity: Entities.sprint,
  },
  getTravelAndReceiptReceiptTypes: {
    alias: 'getTravelAndReceiptReceiptTypes',
    entity: Entities.travelCostInvoiceKind,
  },
  getTravelAndReceiptReceiptDrivingTypes: {
    alias: 'getTravelAndReceiptReceiptDrivingTypes',
    entity: Entities.travelCostInvoiceKindTransportCost,
  },
  getTravelAndReceiptReceiptPaymentType: {
    alias: 'getTravelAndReceiptReceiptPaymentType',
    entity: Entities.travelExpenseReportPaymentMethod,
  },
  getFileLink: {
    alias: 'getFileLink',
    entity: Entities.fileLink,
  },
  getTaxGroups: {
    alias: 'getTaxGroups',
    entity: Entities.taxGroup,
  },
  getProjectDirectoryDefinitions: {
    alias: 'getProjectDirectoryDefinitions',
    entity: Entities.projectDirectoryDefinition,
  },
  getIncomingInvoiceViewModel: {
    alias: 'getIncomingInvoiceViewModel',
    entity: Entities.reViewModel,
  },
  getDuplicationName: {
    alias: 'getDuplicationName',
  },
  getCustomerDatasheet: {
    alias: 'getCustomerDatasheet',
    entity: Entities.statisticCustomerDatasheet,
  },
  getSupplierDatasheet: {
    alias: 'getSupplierDatasheet',
    entity: Entities.statisticSupplierDatasheet,
  },
  getOutgoingInvoiceViewModel: {
    alias: 'getOutgoingInvoiceViewModel',
    entity: Entities.raViewModel,
  },
  getEMailSignaturen2: {
    alias: 'getEMailSignaturen2',
    entity: Entities.eMailSignature,
  },
  getEMailTemplates2: {
    alias: 'getEMailVorlagen2',
    entity: Entities.eMailTemplate,
  },
  getResourceUtilizationInfo: {
    alias: 'getResourceUtilizationInfo',
    entity: Entities.resourceUtilizationInfo,
  },
  getFileServiceProvider: {
    alias: 'getFileServiceProvider',
    entity: Entities.fileServiceProviderConfig,
  },
  getProjectGroups: {
    alias: 'getProjectGroups',
    entity: Entities.projectGroup,
  },
  getCustomerGroups: {
    alias: 'getCustomerGroups',
    entity: Entities.customerGroup,
  },
  getSupplierGroups: {
    alias: 'getSupplierGroups',
    entity: Entities.supplierGroup,
  },
  getArticleCategories: {
    alias: 'getArticleCategories',
    entity: Entities.group,
  },
  search: {
    alias: 'search',
    entity: [
      Entities.customer,
      Entities.supplier,
      Entities.project,
      Entities.contact,
      Entities.article,
    ],
  },
  readMailboxesFlat: {
    alias: 'readMailboxesFlat',
    entity: Entities.mailbox,
  },
  readMailboxChildFoldersFlat: {
    alias: 'readMailboxChildFoldersFlat',
    entity: Entities.mailboxFolder,
  },
  getObjectProtectionGroups: {
    alias: 'getObjectProtectionGroups',
    entity: Entities.userGroup,
  },
  getArticlePurchaseInformation: {
    alias: 'getArticlePurchaseInformation',
    entity: Entities.articlePurchaseInformation,
  },
  getUrlaubsanspruch: {
    alias: 'getUrlaubsanspruch',
    entity: Entities.vacationEntitlement,
  },
  __typename: {
    alias: '__typename',
  },
};

export const queryEntityDefinition: EntitiyDefinition<Query> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Query',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
