import { noop } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppState } from '@work4all/components';

import { useCloneEntityMutation } from '@work4all/data';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  MaskTemplateEntity,
  OnOpenMask,
} from '../../containers/mask-overlays/mask-overlay/types';

export interface EntityHandlersProps {
  entity: Entities;
  onOpenMask: OnOpenMask;
  template?: MaskTemplateEntity | null;
  entries: { id?: string | number }[];
}

export function useEntityHandlers(props: EntityHandlersProps) {
  const { entity, onOpenMask, template = null, entries } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    canAdd,
    canPreview: canPreviewChecker,
    canEdit: canEditChecker,
  } = usePermissions();
  const { forcedShareBaseUrl } = useAppState();
  const cloneEntity = useCloneEntityMutation();

  const canCreate = canAdd({ entity });

  const canPreview = canPreviewChecker({ entity });

  const canEdit =
    entries?.length === 1 && canEditChecker({ entity, record: entries[0] });

  const create = useMemo(() => {
    if (!canCreate)
      return {
        handler: () => {
          //nop
        },
        disabled: true,
      };
    const mask = onOpenMask();

    const getHref = mask.getHref
      ? () => {
          return mask.getHref({
            entity,
            template,
          });
        }
      : undefined;

    return {
      getHref,
      handler: () =>
        mask.handler({
          entity,
          id: null,
          template,
        }),
    };
  }, [canCreate, entity, onOpenMask, template]);

  const edit = useMemo(() => {
    if (!canEdit) {
      return {
        handler: noop,
        disabled: true,
      };
    }
    const mask = onOpenMask();
    const selectedIds = entries.map((x) => x.id);

    const getHref = mask.getHref
      ? (input: string) => {
          return mask.getHref({ entity, id: input || selectedIds[0] });
        }
      : undefined;

    return {
      getHref,
      handler: () => {
        if (selectedIds.length === 1) {
          mask.handler({ entity, id: selectedIds[0] });
        }
      },
    };
  }, [canEdit, entity, onOpenMask, entries]);

  const convert = useCallback(
    (convertTo: Entities) => {
      const { handler } = onOpenMask();

      let template: { entity: Entities; id: string } | null = null;

      const selectedIds = entries.map((x) => x.id);
      if (selectedIds.length === 1) {
        template = { entity, id: `${selectedIds[0]}` };
      }

      handler({
        entity: convertTo,
        id: null,
        template,
      });
    },
    [entity, onOpenMask, entries]
  );

  const share = useCallback(() => {
    const mask = onOpenMask();
    const { origin, pathname } = window.location;
    if (!entries[0]) return;
    const selectedId = entries[0].id;
    const baseUrl = forcedShareBaseUrl || origin;
    const link = `${baseUrl}${pathname}/${mask.getHref({
      entity,
      id: selectedId,
    })}?fullscreen=true`;

    navigator.clipboard.writeText(link);
    enqueueSnackbar(t('COMMON.LINK_COPIED_TO_CLIPBOARD'), {
      variant: 'success',
      autoHideDuration: 3000,
    });
  }, [enqueueSnackbar, entity, forcedShareBaseUrl, onOpenMask, entries, t]);

  const clone = useMemo(() => {
    if (!canCreate) {
      return {
        handler: () => {
          //nothing
        },
        disabled: true,
      };
    }

    if (!entries[0]) return;
    const selectedId = entries[0].id;

    return {
      disabled: false,
      handler: () => {
        cloneEntity({
          id: Number(selectedId),
          entity,
        });
      },
    };
  }, [canCreate, entries, cloneEntity, entity]);

  const openMaskTab = useCallback(
    (openTab: string) => {
      const mask = onOpenMask();

      if (!entries[0]) return;
      const selectedId = entries[0].id;

      mask.handler({ entity, id: selectedId, openTab });
    },
    [entity, onOpenMask, entries]
  );

  const readonlyErp =
    canPreview && !canCreate && ERP_PREVIEW_FEATURE.includes(entity);
  return {
    create: addReadonlyReason(
      exclude(create, entity, EXCLUDE_CREATE_AND_EDIT),
      readonlyErp,
      t('MASK.ERP.PREVIEW')
    ),
    edit: exclude(edit, entity, EXCLUDE_CREATE_AND_EDIT),
    convert: include(convert, entity, CONVERT_ENTITIES),
    share,
    clone: include(clone, entity, CLONE_ENTITIES),
    openMaskTab,
  };
}

function addReadonlyReason<T>(
  obj: T,
  readonly: boolean,
  disableReason: string
) {
  if (readonly) {
    return { ...obj, disabled: true, disableReason } as T;
  }
  return obj;
}

/**
 * Readonly erp entities for those
 */
export const ERP_PREVIEW_FEATURE = [
  Entities.calculation,
  Entities.offer,
  Entities.contract,
  Entities.invoice,
  Entities.deliveryNote,
  Entities.demand,
  Entities.order,
  Entities.inboundDeliveryNote,
  Entities.reViewModel,
];

/**
 * Configuration for mask handlers
 */
const CLONE_ENTITIES = [
  Entities.appointment,
  Entities.note,
  Entities.callMemo,
  Entities.task,
  Entities.salesOpportunities,
];

const CONVERT_ENTITIES = [
  Entities.project,
  Entities.appointment,
  Entities.callMemo,
  Entities.checkList,
  Entities.customer,
  Entities.document,
  Entities.eMail,
  Entities.letter,
  Entities.note,
  Entities.supplier,
  Entities.task,
  Entities.visitationReport,
];

const EXCLUDE_CREATE_AND_EDIT = [
  Entities.productionContract,
  Entities.serviceContract,
  Entities.visitationReport,
  Entities.mailboxContent,
];

function include<T>(obj: T, entity: Entities, entities: Entities[]) {
  return entities.includes(entity) ? obj : undefined;
}

function exclude<T>(obj: T, entity: Entities, entities: Entities[]) {
  return !entities.includes(entity) ? obj : undefined;
}
