import { useState } from 'react';

import { EntityLike } from '../../types';

import {
  EntityPickerListProps,
  PickerBase,
  PickerCommonProps,
} from './PickerBase';
import { useEntityPickerFilterResult } from './utils/hooks/use-entity-picker-filter-result';
import {
  useEntityPickerRegularResult,
  UseEntityPickerRegularResultProps,
} from './utils/hooks/use-entity-picker-regular-result';

// TODO Update types
interface EntityPickerAllProps<
  Value extends EntityLike,
  Multiple extends boolean
> extends PickerCommonProps<Value, Multiple>,
    Omit<
      EntityPickerListProps<Value>,
      | 'regularResult'
      | 'filterResult'
      | 'label'
      | 'sortBy'
      | 'filterBy'
      | 'useSearchHistory'
    >,
    Omit<UseEntityPickerRegularResultProps<Value>, 'query' | 'showFavorites'> {}

type EntityPickerState = {
  query: string;
};

interface EntityPickerProps<Value extends EntityLike, Multiple extends boolean>
  extends Omit<
    EntityPickerAllProps<Value, Multiple>,
    keyof EntityPickerState
  > {}

/**
 * A picker with built-in data fetching for options list.
 *
 * Will automatically decide how to fetch the data based on the `entity`. The
 * requested fields can be controlled with the `data` prop.
 *
 * The options can be filtered and sorted by any of the fields.
 *
 * See [Pickers on
 * Wiki](https://dev.azure.com/work4all-tfs/work4all%20mobile/_wiki/wikis/App%20wiki/59/Pickers)
 */
export function EntityPicker<
  Value extends EntityLike,
  Multiple extends boolean
>(props: EntityPickerProps<Value, Multiple>) {
  const {
    entity,
    data,
    filterConfig,
    sortBy,
    sortByDirection,
    filterBy,
    prefilter,
    searchPrefilter,
    keysArguments,
    vars,
    useSearchHistory,
    transformResponse,
    onSearchValueChange,
    ...otherProps
  } = props;

  const [showFavorites, setShowFavorites] = useState(false);
  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim();

  const regularResult = useEntityPickerRegularResult({
    entity,
    data,
    filterConfig,
    sortBy,
    sortByDirection,
    filterBy,
    query: queryTrimmed,
    prefilter,
    searchPrefilter,
    keysArguments,
    vars,
    showFavorites,
    useSearchHistory,
    transformResponse,
  });

  const filterResult = useEntityPickerFilterResult({
    entity,
    data,
    filterConfig,
    sortBy,
    sortByDirection,
    filterBy,
    query: queryTrimmed,
  });

  return (
    <PickerBase
      entity={entity}
      regularResult={regularResult}
      filterResult={filterResult}
      filterConfig={filterConfig}
      filterBy={filterBy}
      searchPrefilter={searchPrefilter}
      useSearchHistory={useSearchHistory}
      onSearchValueChange={(query) => {
        setQuery(query);
        onSearchValueChange?.(query);
      }}
      onFavoritesToggled={setShowFavorites}
      {...otherProps}
    />
  );
}
