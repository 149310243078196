import { Box, Button, Typography } from '@mui/material';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';

import { formatAsFloat } from '@work4all/utils';

import { useResourceClasses } from './use-resource-classes';
import { getFactor } from './utils';

export const useErpPlanningColumns = () => {
  const { data: ressourceClasses } = useResourceClasses();

  const result = useMemo(
    () =>
      ressourceClasses.map((rc) => ({
        title: [rc.name],
        displayTitle: rc.name,
        accessor: (item: ERPTypes) => {
          const positions = item.positionList.filter(
            (pos) => pos?.article?.ressourceClassId === rc.id
          );
          const total = positions
            ?.map((x) => {
              const factor = getFactor(x.unit);
              return x.amount * factor;
            })
            ?.reduce((a, b) => {
              return a + b;
            }, 0);

          return (
            <ErpRessourceClassCell
              ressourceClass={rc}
              value={total}
              positions={positions}
            />
          );
        },
        id: rc.id.toString(),
        path: ['COMMON.RESSOURCECLASS_plural'],
        width: 200,
        sortable: false,
        groupable: false,
        quickSearchable: false,
        defaultHidden: true,
        filterable: {
          type: 'None',
        },
        disableFooterSum: false,
      })),
    [ressourceClasses]
  );

  return result;
};

export const ErpRessourceClassCell = (props: {
  ressourceClass: LookUp;
  value: number;
  positions: Position[];
}) => {
  const { value, positions } = props;
  const { t } = useTranslation();
  const popover = usePopoverState();
  const cellRef = useRef(null);

  const groupedPositions = useMemo<Position[]>(() => {
    const articleIds = [...new Set(positions.map((pos) => pos.article?.id))];

    return articleIds.map((id) => {
      const articlePositions = positions.filter(
        (pos) => pos.article?.id === id
      );
      return {
        ...articlePositions[0],
        amount: articlePositions
          .map((pos) => pos.amount)
          .reduce((a, b) => a + b, 0),
      } as Position;
    });
  }, [positions]);

  const tooltipTable = useMemo(() => {
    if (groupedPositions.length === 0) {
      return (
        <Button
          variant="contained"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            popover.handleClick();
          }}
        >
          {t('COMMON.CORRECT')}
        </Button>
      );
    }
    return (
      <table>
        {groupedPositions.map((pos) => {
          return (
            <tr key={pos.id}>
              <td align="right">
                <LabeledInput
                  onFocus={(e) => e.target.select()}
                  style={{ width: '3rem' }}
                  defaultValue={pos.amount * getFactor(pos.unit)}
                  type="number"
                />
              </td>
              <td>
                <Typography color="var(--textInvert)">
                  {t('COMMON.DAY_SHORT')}
                </Typography>
              </td>
              <td style={{ maxWidth: '9rem' }}>
                <Typography
                  color="var(--textInvert)"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {pos?.article?.name} {pos?.article?.name}
                </Typography>
              </td>
              <td>
                <Button ref={cellRef} variant="contained" size="small">
                  {t('COMMON.CORRECT')}
                </Button>
              </td>
            </tr>
          );
        })}
      </table>
    );
  }, [groupedPositions, popover, t]);

  return (
    <Tooltip placement="right" title={tooltipTable}>
      <Box textAlign="center">
        <Typography flex="1">
          {value
            ? formatAsFloat(value, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              })
            : ' '}
        </Typography>
      </Box>
    </Tooltip>
  );
};
