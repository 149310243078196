import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';

import { useGetTravelReceiptsStatus } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/use-get-travel-receipts-status';

import { AppParts, useCanView, useUser } from '@work4all/data';

import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { AttachmentCell } from './AttachmentCell';
import { TravelStatusCell } from './cell-renderer/TravelStatusCell';
import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/travelReceipts-table-schema.json';

const forceRequestFields: TravelReceipts = {
  travelExpenses: {
    closedByuserid: null,
    insertionREDate: null,
  },
};

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const TravelReceiptsTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function TravelReceiptsTable(props, ref) {
  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();
  const prefilter = useOwnTravelReceipt();
  const dataTable = useEntityDataTable<TravelReceipts, never>({
    prefilter,
    schema: schema as never,
    defaultSort,
    forceRequestFields,
    cells: {
      Attachment: AttachmentCell,
      TravelStatusCell,
    },
    rowModifiers: (value) => {
      const { key: status } = getTravelReceiptsStatus(value.travelExpenses);

      return {
        isFaded: status === 'booked',
        isShade1: status === 'closed' || status === 'booked',
      };
    },
  });

  return <EntityTable ref={ref} {...dataTable} />;
});

export const useOwnTravelReceipt = () => {
  const user = useUser();
  const prefilter = useMemo(() => {
    return {
      'travelExpenses.businessPartner.data.id': {
        $eq: user.supplierCode,
      },
    };
  }, [user.supplierCode, user.displayName]);

  const canViewTravelReceipts = useCanView(AppParts.TRAVEL_RECEIPTS);
  const isFullUser = useCanView(AppParts.FULL);

  return canViewTravelReceipts && !isFullUser ? [prefilter] : [];
};
