import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';

import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useCloneConvertTableErpActions } from './offers-table/hooks/use-clone-convert-erp';
import schema from './schemata/deliveryNote-table-schema.json';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const FORCE_REQUEST_FIELDS: DeliveryNote<EMode.query> = {
  positionList: [{ id: null, originalId: null }],
};

export const DeliveryNotesTable = React.forwardRef<TableInstance, IEntityTable>(
  function DeliveryNotesTable(_props, ref) {
    const dataTable = useEntityDataTable<DeliveryNote, never>({
      schema: schema as never,
      defaultSort,
      forceRequestFields: FORCE_REQUEST_FIELDS,
      rowModifiers: (value) => {
        return {
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
    });

    const template = useErpHandlersTemplate();

    const { actions } = useCloneConvertTableErpActions({
      entity: Entities.deliveryNote,
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    return (
      <EntityTable
        ref={ref}
        template={template}
        {...dataTable}
        actions={{
          custom,
        }}
      />
    );
  }
);
