import { createContext, PropsWithChildren, useContext } from 'react';

const TableCellClickContext = createContext<DateCellClickContextProps>({
  onDateCellClick: () => null,
});

interface DateCellClickContextProps {
  onDateCellClick: (value: Date) => void;
}

export const TableCellClickProvider = (
  props: PropsWithChildren<Partial<DateCellClickContextProps>>
) => {
  const { children, onDateCellClick } = props;

  return (
    <TableCellClickContext.Provider value={{ onDateCellClick }}>
      {children}
    </TableCellClickContext.Provider>
  );
};

export const useDateCellClick = () => useContext(TableCellClickContext);
