import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { useFormPlus } from '@work4all/data';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils';

import { Form } from '../../../components';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../../hooks/mask-context';
import { useCheckListFormUpdate, useCheckListSubmitHandler } from '../hooks';
import { useCheckListFormData } from '../hooks/use-check-list-form-data';

import {
  CheckListMaskFormValue,
  CheckListOverlayControllerMaskFormProviderProps,
} from './types';

export const CheckListOverlayControllerMaskFormProvider = ({
  children,
  ...rest
}: CheckListOverlayControllerMaskFormProviderProps) => {
  const mask = useMaskConfig(rest);
  const schema = useEntityJsonSchema(Entities.checkList);
  const resolver = useJSONSchemaResolver(schema);

  const data = useCheckListFormData(mask);

  const form = useFormPlus<CheckListMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    context: {
      schema,
    },
  });

  const onSubmit = useCheckListSubmitHandler({
    isCreateMode: mask.isCreateMode,
    dirtyFields: form.formState.dirtyFields,
    onAfterSave: rest.onAfterSave,
  });

  const maskContextValue = useMaskContextValue({
    ...mask,
    data,
  });

  useCheckListFormUpdate({ form });
  useEffect(() => form.reset(data), [data, form]);

  return (
    <MaskContextProvider value={maskContextValue}>
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit((data) => {
            onSubmit(data);
          })}
        >
          {children}
        </Form>
      </FormProvider>
    </MaskContextProvider>
  );
};
