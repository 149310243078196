import { useDataMutation } from '@work4all/data';

import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { pickUpdateFields } from '../../../utils/pick-update-fields';

import {
  CheckListMaskFormValue,
  UseCheckListSubmitHandler,
  UseCheckListSubmitHandlerSubmitHandlerFunction,
} from './types';

export const useCheckListSubmitHandler: UseCheckListSubmitHandler = ({
  isCreateMode,
  dirtyFields,
  onAfterSave,
}) => {
  const [mutate] = useDataMutation({
    entity: Entities.checkList,
    mutationType: EMode.upsert,
    responseData: { id: null },
    onCompleted: onAfterSave,
  });

  const submitHandler: UseCheckListSubmitHandlerSubmitHandlerFunction = async (
    data
  ) => {
    const mutateData = isCreateMode
      ? data
      : pickUpdateFields<CheckListMaskFormValue>(data, dirtyFields);

    await mutate(mutateData);
  };

  return submitHandler;
};
