import styles from './EMailTemplateOverlayController.module.scss';

import { Tab, Tabs } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import { pick } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Attachments } from '@work4all/components/lib/components/attachments';
import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import { AttachmentsUploadButton } from '@work4all/components/lib/components/attachments/AttachmentsUploadButton';
import { MAIN_LANGUAGE_CODE } from '@work4all/components/lib/components/entity-picker/base-data-language-picker/BaseDataLanguagePicker';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { htmlParser } from '@work4all/components/lib/input/format-text/TextEditor/utils/html-parser';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useDataMutation, useDataProvider } from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { BaseDataLanguage } from '@work4all/models/lib/Classes/BaseDataLanguage.entity';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateGroup } from '@work4all/models/lib/Classes/EMailTemplateGroup.entity';
import { InputEMailVorlagenAnhangRelation } from '@work4all/models/lib/Classes/InputEMailVorlagenAnhangRelation.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { EmailSignaturePickerField } from '../../../../../components/entity-picker/EmailSignaturePickerField';
import { EMailTemplateGroupPickerField } from '../../../../../components/entity-picker/EMailTemplateGroupPickerField';
import { EMailTemplateKindPickerField } from '../../../../../components/entity-picker/EMailTemplateKindPickerField';
import { ControllerPlus } from '../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { Collapse, ControlWrapper } from '../../components';
import { useMaskConfig, useMaskContext } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { useFormUpdate } from '../../utils/use-form-update';

import { LanguageTab } from './components/language-tab/LanguageTab';
import { useEMailTemplateRequestData } from './useEMailTemplateRequestData';

export type EMailTemplateFormValue = PathsOf<EMailTemplate, 2>;

type EMailTemplateOverlayControllerProps = MaskControllerProps & {
  amplitudeEntryPoint: string;
};

type TempAttachment = {
  id?: string | number;
  fileName?: string;
  languageCode: number;
};
type TempAttachmentList = TempAttachment[];

export function EmailTemplateOverlayController(
  props: EMailTemplateOverlayControllerProps
) {
  const { amplitudeEntryPoint } = props;

  const { goBack, close, currentStackIndex, setObjectionListener } =
    useHistoryStack();

  const mask = useMaskConfig(props);

  const requestDataLanguages = useMemo(
    () => ({
      entity: Entities.baseDataLanguage,
      data: { id: null, name: null } as BaseDataLanguage,
      operationName: 'GetLanguages',
      completeDataResponse: true,
    }),
    []
  );

  const languagesResult =
    useDataProvider<BaseDataLanguage>(requestDataLanguages);
  const languages = languagesResult?.data;
  const mainLanguage = languages?.find(
    (lang) => lang.id === MAIN_LANGUAGE_CODE
  );

  useEffect(() => {
    if (mask.isCreateMode) {
      sendAmplitudeData(EventType.AddEMailTemplate, {
        entryPoint: amplitudeEntryPoint,
      });
    } else {
      sendAmplitudeData(EventType.EditEMailTemplate, {
        entryPoint: amplitudeEntryPoint,
      });
    }
  }, [amplitudeEntryPoint, mask, mask.isCreateMode, props.id]);

  const request = useEMailTemplateRequestData(mask.id);

  const [mutate] = useDataMutation<
    EMailTemplate,
    EMode.upsert,
    {
      attachements;
    }
  >({
    entity: Entities.eMailTemplate,
    mutationType: EMode.upsert,
    responseData: request.data,
    onCompleted: props.onAfterSave,
  });

  const newEntityData = useMemo(() => {
    const defaultValues: EMailTemplate = {
      attachmentList: [],
      eMailTemplateKind: EMailTemplateKind.KEINE,
      language: mainLanguage,
      languageCode: mainLanguage?.id,
      parentId: null,
      subject: '',
      body: '',
      childs: [
        {
          attachmentList: [],
          eMailTemplateKind: EMailTemplateKind.KEINE,
          language: mainLanguage,
          languageCode: mainLanguage?.id,
          parentId: null,
          subject: '',
          body: '',
        },
      ],
    };
    return defaultValues;
  }, [mainLanguage]);

  const normalizeData = useCallback(
    (
      currentData: EMailTemplateFormValue,
      defaultValues: EMailTemplateFormValue,
      isCreateMode: boolean
    ) => {
      // Add main template data as a child to 'childs' array (for the tabs draft state purpose)
      const currentBody = htmlParser.replaceSignature(
        currentData?.body ?? '',
        currentData?.signature?.body ?? ''
      );
      const currentDataDraft = currentData && {
        ...currentData,
        body: currentBody,
        childs: [...currentData.childs, { ...currentData, body: currentBody }],
      };

      const result =
        (isCreateMode ? defaultValues : currentDataDraft) ?? defaultValues;
      return result;
    },
    []
  );

  const overlay = useMaskOverlay<EMailTemplateFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    getSubTitle: (x) => x.name,
    normalizeData,
  });

  const { form, data } = overlay;
  const { formState, getValues, reset } = form;

  const getTemplateValues = useCallback(() => {
    const language = getValues('language');
    const childs = getValues('childs') ?? [];
    const subject = getValues('subject');
    const body = getValues('body');
    const id = getValues('id');
    const name = getValues('name');
    const signaturId = getValues('signaturId');
    const signature = getValues('signature');
    const eMailTemplateKind = getValues('eMailTemplateKind');
    const templateGroup = getValues('templateGroup');

    const child = childs?.find((child) => child.languageCode === language?.id);
    const childExists = Boolean(child);
    const mainTemplateChild = childs?.find(
      (child) => child.languageCode === MAIN_LANGUAGE_CODE
    );

    return {
      id,
      name,
      subject,
      body,
      signaturId,
      signature,
      eMailTemplateKind,
      templateGroup,
      language,
      childs,
      child,
      mainTemplateChild,
      childExists,
    };
  }, [getValues]);

  const handleBodyChange = (body: string) => {
    const { childs, child, childExists } = getTemplateValues();

    if (childExists) {
      child['body'] = body ?? '';
      const updatedChilds = childs.map((c) =>
        c.languageCode === child?.languageCode ? child : c
      );
      return {
        childs: [...updatedChilds],
      };
    }
  };

  const handleSubjectChange = (subject: string) => {
    const { childs, child, childExists } = getTemplateValues();

    if (childExists) {
      child['subject'] = subject;

      const updatedChilds = childs.map((c) =>
        c.languageCode === child?.languageCode ? child : c
      );
      return {
        childs: [...updatedChilds],
      };
    }
  };

  const handleLanguageChange = (language: BaseDataLanguage) => {
    const {
      id,
      name,
      eMailTemplateKind,
      templateGroup,
      childs,
      child,
      mainTemplateChild,
      childExists,
    } = getTemplateValues();

    const isNotMainTemplateChild =
      !childExists && language.id !== MAIN_LANGUAGE_CODE;

    const commonFields = {
      name,
      languageCode: language.id,
      language,
      eMailTemplateKind,
      templateGroup,
    };

    if (isNotMainTemplateChild) {
      const currentSignature = mainTemplateChild?.signature?.body;
      const updatedBody = currentSignature
        ? htmlParser.replaceSignature('', currentSignature)
        : '';

      const newChild = {
        ...commonFields,
        parentId: id,
        signaturId: mainTemplateChild?.signaturId,
        signature: mainTemplateChild?.signature,
        body: updatedBody,
        subject: '',
      };

      return {
        childs: [...childs, newChild],
        signaturId: newChild?.signaturId || EMPTY_UID,
        signature: newChild?.signature,
        subject: newChild?.subject,
        body: updatedBody,
        languageCode: language.id,
      };
    }

    if (childExists) {
      const childSignature = child?.signature?.body;
      const updatedBody = child?.signature?.body
        ? htmlParser.replaceSignature(child?.body, childSignature)
        : child?.body;

      return {
        signaturId: child?.signaturId || EMPTY_UID,
        signature: child?.signature,
        subject: child?.subject,
        body: updatedBody,
        languageCode: language.id,
      };
    }
  };

  const handleSignatureChange = (signature: EMailSignature) => {
    const {
      body: currentBody,
      childs,
      child,
      childExists,
    } = getTemplateValues();
    const signaturId = signature?.id || EMPTY_UID;
    const updatedBody = htmlParser.replaceSignature(
      currentBody,
      signature?.body ?? ''
    );

    if (childExists) {
      child['signaturId'] = signaturId;
      child['signature'] = signature;
      child['body'] = updatedBody;
      const updatedChilds = childs.map((c) =>
        c.languageCode === child?.languageCode ? child : c
      );
      return {
        signaturId,
        body: updatedBody,
        childs: [...updatedChilds],
      };
    }
  };

  /*
    The [key: string] used to prevent entering a loop
    Which cause a "RangeError: Maximum Call Stack Size Exceeded"
  */
  useFormUpdate(
    {
      templateGroup: (value: EMailTemplateGroup) => ({
        groupId: value?.id || EMPTY_UID,
      }),
      signature: (value: EMailSignature) => handleSignatureChange(value),
      language: (value: BaseDataLanguage) => handleLanguageChange(value),
      subject: (value: string) => handleSubjectChange(value),
      body: (value: string) => handleBodyChange(value),
    },
    form
  );

  // Collection of existing attachments of all language version templates
  // to create an initial file manager state
  const cleanedPersistantAttachmentList = useMemo(
    () =>
      data.childs
        ?.flatMap((child) =>
          child.attachmentList?.map((attachment) => ({
            ...attachment,
            languageCode: child.languageCode,
            __typename: undefined,
          }))
        )
        .filter(Boolean),
    [data.childs]
  );

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const [attachmentsToUpload, setattachmentsToUpload] =
    useState<TempAttachmentList>([]);
  const [attachmentsToDelete, setattachmentsToDelete] =
    useState<TempAttachmentList>([]);

  // Add files to temporary upload list
  useEffect(() => {
    const differedAttachments = tempFileManager.temporaryFileUploads.filter(
      (file) =>
        !attachmentsToUpload.some(
          (attachmentToUpload) => file.id === attachmentToUpload.id
        )
    );
    if (differedAttachments?.length) {
      const { language } = getTemplateValues();
      const attachmentsToUpload = differedAttachments.map((attachment) => ({
        id: attachment?.id,
        fileName: attachment?.fileName,
        languageCode: language?.id,
      }));
      setattachmentsToUpload((prev) => [...prev, ...attachmentsToUpload]);
    }
  }, [
    attachmentsToUpload,
    getTemplateValues,
    tempFileManager.temporaryFileUploads,
  ]);

  // Remove files from temporary upload list
  useEffect(() => {
    const differedIdToRemove = attachmentsToUpload
      .filter(
        (attachmentToUpload) =>
          !tempFileManager.temporaryFileUploads.some(
            (file) => attachmentToUpload.id === file.id
          )
      )
      .map((attachment) => attachment.id);

    if (differedIdToRemove.length) {
      setattachmentsToUpload((prev) =>
        prev.filter((attachment) => !differedIdToRemove.includes(attachment.id))
      );
    }
  }, [attachmentsToUpload, tempFileManager.temporaryFileUploads]);

  useEffect(() => {
    // Update list of files (persistant ones) which going to be removed on submit
    const differedId = tempFileManager.fileIdsToDelete.find(
      (fileId) =>
        !attachmentsToDelete.some(
          (attachmentToDelete) => fileId === attachmentToDelete.id
        )
    );
    if (differedId) {
      const { language } = getTemplateValues();
      const attachmentToDelete = {
        id: differedId,
        languageCode: language?.id,
      };
      setattachmentsToDelete((prev) => [...prev, attachmentToDelete]);
    }
  }, [attachmentsToDelete, getTemplateValues, tempFileManager.fileIdsToDelete]);

  // Combine remained persistant files with the ones newly uploaded
  const attachmentListPerLanguage = useMemo(() => {
    const remainedFiles = cleanedPersistantAttachmentList.filter(
      (file) =>
        !attachmentsToDelete.some(
          (attachmentToDelete) => attachmentToDelete?.id === file.id
        )
    );
    return [...remainedFiles, ...attachmentsToUpload].map((attachment) => ({
      id: attachment?.id,
      fileName: attachment?.fileName,
      languageCode: attachment?.languageCode,
    }));
  }, [
    cleanedPersistantAttachmentList,
    attachmentsToDelete,
    attachmentsToUpload,
  ]);

  const relations = useMemo(
    () =>
      // Create attachment relation object splited by language code
      languages.reduce((acc, language) => {
        const attachmentsToAdd = attachmentsToUpload.filter(
          (attachment) => attachment?.languageCode === language.id
        );
        const attachmentsToRemove = attachmentsToDelete.filter(
          (attachment) => attachment?.languageCode === language.id
        );

        acc[language.id] = {
          attachements: {
            add:
              attachmentsToAdd?.length > 0
                ? attachmentsToAdd.map((attachment) => ({
                    tempFileId: attachment.id as string,
                    name: attachment.fileName,
                  }))
                : undefined,
            remove:
              attachmentsToRemove?.length > 0
                ? attachmentsToRemove.map((attachment) => attachment?.id)
                : undefined,
          },
        } as InputEMailVorlagenAnhangRelation;
        return acc;
      }, {}),
    [attachmentsToDelete, attachmentsToUpload, languages]
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSubmit = useEventCallback(
    async (values: EMailTemplateFormValue) => {
      setIsSaving(true);

      const newTemplatesValues = values.childs.map((val) =>
        pick(val, [
          'id',
          'languageCode',
          'body',
          'signaturId',
          ...Object.keys(formState.dirtyFields),
        ])
      );

      const update = newTemplatesValues.map((val) => {
        const { childs: _childs, ...newValue } = val;
        const sharedValues = {
          name: values?.name,
          eMailTemplateKind: values?.eMailTemplateKind,
          templateGroup: values?.templateGroup,
        };
        if (newValue.body?.length) {
          newValue.body = htmlParser.replaceSignature(newValue.body, '');
        }
        if (newValue.languageCode === MAIN_LANGUAGE_CODE) {
          return {
            ...newValue,
            ...sharedValues,
            id: data?.id,
          };
        } else {
          return {
            ...newValue,
            ...sharedValues,
            parentId: data?.id,
          };
        }
      });

      try {
        await Promise.allSettled(
          update.map((templateValue) =>
            mutate(templateValue, {
              relations: relations[templateValue?.languageCode ?? 0],
            })
          )
        );
        setIsSaving(false);
      } catch (error) {
        console.error('An error occurred when saved template:', error);
        setIsSaving(false);
      } finally {
        setObjectionListener(null);
        sendAmplitudeData(EventType.SaveEMailTemplate, {
          entryPoint: amplitudeEntryPoint,
        });

        if (currentStackIndex !== 0) {
          goBack();
        } else {
          close();
        }
      }
    }
  );

  return (
    <OverlayController<EMailTemplateFormValue>
      {...overlay}
      onSubmit={handleSubmit}
      actions={<AttachmentsUploadButton disabled={isSaving} />}
    >
      <AttachmentsDropZone>
        <EMailTemplateMaskContent
          languages={languages}
          attachmentListPerLanguage={attachmentListPerLanguage}
        />
      </AttachmentsDropZone>
    </OverlayController>
  );
}

function EMailTemplateMaskContent({
  languages,
  attachmentListPerLanguage,
}: {
  languages: BaseDataLanguage[];
  attachmentListPerLanguage: TempAttachmentList;
}) {
  const { t } = useTranslation();

  const { register, control, getValues, setValue } =
    useFormContextPlus<EMailTemplateFormValue>();

  const maskContexts = useMaskContext<EMailTemplate>();
  const { data: maskData } = maskContexts;

  const currentLanguageCode = getValues('languageCode');
  const disabled = currentLanguageCode !== MAIN_LANGUAGE_CODE;

  const [selectedTab, setSelectedTab] = useState<number>(
    maskData.languageCode ?? MAIN_LANGUAGE_CODE
  );

  useEffect(() => {
    setSelectedTab(maskData.languageCode ?? MAIN_LANGUAGE_CODE);
  }, [maskData.languageCode]);

  const { fileList } = useContext(TempFileManagerContext);
  const visibleAttachmentIds = fileList
    .filter((file) =>
      attachmentListPerLanguage.some(
        (attachment) =>
          attachment?.id === file.id &&
          currentLanguageCode === attachment?.languageCode
      )
    )
    .map((attachment) => attachment?.id);

  const handleTabChange = useCallback(
    (value: number) => {
      const selectedLanguage = languages?.find(
        (language) => language.id === value
      );

      setValue('language', selectedLanguage);
      setSelectedTab(value);
    },
    [languages, setValue]
  );

  return (
    <>
      <div className={styles.metaData}>
        <div className={styles.left}>
          <Collapse title={t('EMAILTEMPLATE.DESCRIPTION')} defaultOpen>
            <div className={styles.xSplit}>
              <ControlWrapper paddingBottom={false}>
                <LabeledInput
                  disabled={disabled}
                  label={t('COMMON.NAME')}
                  {...register('name')}
                />
              </ControlWrapper>

              <ControlWrapper>
                <ControllerPlus
                  control={control}
                  name="signature"
                  render={({ field }) => (
                    <EmailSignaturePickerField {...field} />
                  )}
                />
              </ControlWrapper>
            </div>
          </Collapse>
        </div>
        <div className={styles.right}>
          <Collapse title={t('COMMON.ASSIGNMENT')} defaultOpen>
            <div className={styles.xSplit}>
              <ControlWrapper>
                <ControllerPlus
                  control={control}
                  name="templateGroup"
                  render={({ field }) => (
                    <EMailTemplateGroupPickerField
                      disabled={disabled}
                      {...field}
                    />
                  )}
                />
              </ControlWrapper>
              <ControlWrapper>
                <ControllerPlus
                  control={control}
                  name="eMailTemplateKind"
                  render={({ field }) => (
                    <EMailTemplateKindPickerField
                      disabled={disabled}
                      clearable={false}
                      multiple={false}
                      {...field}
                    />
                  )}
                />
              </ControlWrapper>
            </div>
          </Collapse>
        </div>
      </div>
      {visibleAttachmentIds.length > 0 ? (
        <div className={styles.sectionPadding}>
          <Attachments
            entity={Entities.eMail}
            disableEditAction={false}
            disableAddAction={false}
            disableRemoveAction={false}
            layout="compact"
            visibleAttachmentIds={visibleAttachmentIds}
          />
        </div>
      ) : null}
      <div className={styles.tabsSection}>
        <div className={styles.tabsSectionDivider}>
          <Divider title={t('EMAILTEMPLATE.LANGUAGE')} size="body" />
        </div>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => handleTabChange(value)}
          TabIndicatorProps={{ hidden: true }}
          variant="scrollable"
        >
          {languages?.map((lang) => {
            const isDisabled = !maskData?.id && lang.id !== MAIN_LANGUAGE_CODE;
            return isDisabled ? (
              <Tooltip
                key={lang.id}
                activateForDisabled={{ activeateOnClick: true }}
                title={'Save the main template first'}
              >
                <Tab disabled value={lang.id} label={lang.name} />
              </Tooltip>
            ) : (
              <Tab key={lang.id} value={lang.id} label={lang.name} />
            );
          })}
        </Tabs>

        <LanguageTab />
      </div>
    </>
  );
}
