import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

import { useCheckModuleRight } from './use-check-module-right';

export const useErpPreviewFeature = () => {
  const checkModuleRight = useCheckModuleRight();
  const hasPreviewFeatures = checkModuleRight(
    ModuleAccessRightType.FEATURE_WORK_4_ALL_PREVIEW_FEATURES
  );
  return hasPreviewFeatures;
};
