import { Capacitor } from '@capacitor/core';

import { useEntityPreviewData } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '@work4all/components/lib/components/entity-preview/types';

import { useNavigate } from '@work4all/data/lib/hooks';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ProjectFileInfoPanel } from '../ProjectFileInfoPanel';

type IProjectFileInfoPanelContainerProps = EntityPreviewContainerProps;

export const requestedProjectData: Project<EMode.query> = {
  id: null,
  name: null,
  number: null,
  projectDirectoryRemoteUrl: null,
  creatorUserId: null,
  creator: {
    id: null,
    displayName: null,
  },
  group: {
    id: null,
    name: null,
  },
  projectKind: {
    name: null,
    id: null,
  },
  startDateInner: null,
  endDateInner: null,
  startDateOuter: null,
  endDateOuter: null,
  supplier: {
    id: null,
    name: null,
    website: null,
  },
  customer: {
    id: null,
    name: null,
    website: null,
  },
  projectStatus: {
    name: null,
    id: null,
    closedStatus: null,
  },
  note: null,
  parentProject: {
    id: null,
    name: null,
  },
  budget: null,
  categoryAssignmentList: [
    {
      categoryName: null,
      categoryId: null,
      categoryKindId: null,
      categoryKindName: null,
      id: null,
    },
  ],
};

export function ProjectFileInfoPanelContainer(
  props: IProjectFileInfoPanelContainerProps
) {
  const { ids, onCloseClick, onEditClicked, onVisibilityToggle } = props;
  const projectId = ids[0];
  const projects = useEntityPreviewData<Project>(
    [projectId],
    Entities.project,
    requestedProjectData
  );

  const { activeTenant } = useTenant();
  const navigate = useNavigate();
  const platform = Capacitor.getPlatform();

  const handleOpenFilePage = () => {
    if (platform === 'web') {
      window.open(`/t-${activeTenant}/projects/${projectId}`, '_blank');
    } else {
      navigate(`/projects/${projectId}`);
    }
  };

  if (!projects) {
    return null;
  }

  return (
    <ProjectFileInfoPanel
      project={projects[0]}
      onEdit={() => onEditClicked(null)}
      onCloseClick={() => onCloseClick(null)}
      onOpenFilePageClicked={handleOpenFilePage}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
