import type { Environment } from '../environment.types';

export const environment: Environment = {
  configuration: 'preview',
  nativeShareBaseUrl: 'https://staging.work4all.de',
  devtools: {
    graphQLExplorer: true,
  },
  entities: {
    task: true,
    note: true,
    appointment: true,
    callMemo: true,
    eMail: true,
    letter: true,
    document: true,
    ticket: true,
    customer: true,
    supplier: true,
    contact: true,
    project: true,
    'file-settings': true,
    inboundInvoice: true,
    eMailTemplate: true,
    textBuildingBlock: true,
    offer: true,
    eMailSignature: true,
    invoice: true,
    timeTracking: true,
    order: true,
    deliveryNote: true,
    contract: true,
    calculation: true,
    demand: true,
    inboundDeliveryNote: true,
    salesOpportunities: true,
    article: true,
    ra: true,
    raViewModel: true,
    travelReceipts: true,
    addressConnection: true,
    reViewModel: true,
    checkList: true,
    user: true,
    fullUser: true,
    wordDocumentTemplate: true,
    wordLetterTemplate: true,
  },
};
