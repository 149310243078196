import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useMsTeamsAvailable } from '../../../../../../hooks';
import {
  formatTopicMarkName,
  titleWithTopicMark,
} from '../../../utils/titleWithTopicMark';
import { useFormUpdate } from '../../../utils/use-form-update';
import { getBusinessPartnerType } from '../AppointmentOverlayController';
import { createContactUnionWrapper } from '../components/tab-panels/general/components/contactpicker/ContactRessourcePicker';
import { mapAttendee } from '../components/tab-panels/general/components/participant/utils';
import { AppointmentMaskFormValue } from '../types';

export const useAppointmentFormUpdate = (
  form: UseFormReturn<AppointmentMaskFormValue>,
  data: AppointmentMaskFormValue
) => {
  const { getValues, watch, setValue, getFieldState } = form;
  const msTeamsAvailable = useMsTeamsAvailable();

  const getReminderDate = useCallback(() => {
    const { isWholeDay, startDate } = watch();

    const reminder = DateTime.fromISO(startDate);
    let resultingRemindDate = null;
    if (isWholeDay) {
      resultingRemindDate = reminder
        .startOf('day')
        .minus({ day: 1 })
        .set({ hour: 13 })
        .toISO();
    } else {
      resultingRemindDate = reminder.minus({ minutes: 15 }).toISO();
    }
    return resultingRemindDate;
  }, [watch]);

  const usedTopicMark = useRef<string>();
  useEffect(() => {
    usedTopicMark.current = data?.topicMarkList?.[0]
      ? formatTopicMarkName(data.topicMarkList[0].name)
      : undefined;
  }, [data]);

  const updateContractAndDeliveryNote = (
    entity: Entities.contract | Entities.deliveryNote
  ) => {
    const contract = getValues('contract');
    const deliveryNote = getValues('deliveryNote');
    const isContract = entity === Entities.contract;

    const values = {
      clearDeliveryNote: {
        deliveryNote: null,
        deliveryNoteId: 0,
      },
      setDeliveryNote: {
        deliveryNoteId: deliveryNote?.id ?? 0,
      },
      clearContract: {
        contract: null,
        contractId: 0,
      },
      setContract: {
        contractId: contract?.id ?? 0,
      },
    };

    let result = {};
    if (contract && deliveryNote) {
      result = isContract
        ? { ...values.setContract, ...values.clearDeliveryNote }
        : { ...values.setDeliveryNote, ...values.clearContract };
    } else {
      result = isContract ? values.setContract : values.setDeliveryNote;
    }

    return result;
  };

  const updateEndDate = (start: DateTime, end: DateTime) => {
    let newEnd = end;
    if (start > end) {
      newEnd = end.set({
        day: start.day,
        month: start.month,
        year: start.year,
      });
    }
    if (start > newEnd) {
      newEnd = start;
    }
    return newEnd.toISO();
  };

  const updateStartDate = (start: DateTime, end: DateTime) => {
    let newStart = start;
    if (end < start) {
      newStart = end.set({
        day: end.day,
        month: end.month,
        year: end.year,
      });
    }
    if (end < newStart) {
      newStart = end;
    }
    return newStart.toISO();
  };

  const updateRemindDate = (remind: boolean) => {
    if (remind) {
      const newRemindDate = getReminderDate();
      setValue('remindDate', newRemindDate, { shouldDirty: true });
      return newRemindDate;
    }
  };

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.isPrivateCustomer
          ? null
          : businessPartner?.mainContact ?? null;

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        const currentAttendees: AppointmentAttendee[] = form.getValues(
          'appointmentAttendeeList'
        );
        const currentBusinessPartner = form.getValues('businessPartner');
        const businessPartnerType = getBusinessPartnerType(
          currentBusinessPartner?.data
        );

        if (contact) {
          //check if a contact of the businesspartner is already part of the appointment
          const hasAttendeeOfBusinessPartner = currentAttendees.find(
            (at) =>
              at.businessPartnerType === businessPartnerType &&
              at.businessPartnerId === currentBusinessPartner?.data.id &&
              at.contactId === contactId
          );

          if (!hasAttendeeOfBusinessPartner) {
            const t = createContactUnionWrapper(
              {
                ...contact,
                businessPartnerId: currentBusinessPartner?.data?.id,
                businessPartnerType: businessPartnerType,
                businessPartner: currentBusinessPartner,
              },
              businessPartnerType === SdObjType.KUNDE
                ? ContactKind.KUNDENANSPRECHPARTNER
                : ContactKind.LIEFERANTENANSPRECHPARTNER
            );
            const appointmentAttendeeList = [
              ...currentAttendees,
              mapAttendee(t),
            ];
            return {
              contactId,
              appointmentAttendeeList,
            };
          }
        }

        return { contactId };
      },
      appointmentAttendeeList: (
        appointmentAttendeeList: AppointmentAttendee[]
      ) => {
        return {
          exchangeMeeting:
            msTeamsAvailable &&
            appointmentAttendeeList.length > 1 &&
            getValues('exchangeMeeting'),
        };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const projectProcessField = getValues('projectProcess');
        const projectProcess =
          project?.id === projectProcessField?.projectId
            ? projectProcessField
            : null;
        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField === undefined) {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectProcess,
            'businessPartner.data': businessPartner,
          };
        } else {
          return { projectId, projectProcess };
        }
      },
      projectProcess: (process: ProjectProcess) => {
        const projectProcessId = process?.id ?? 0;
        return { projectProcessId };
      },
      appointmentState: (state: AppointmentState) => {
        return { colorId: state?.id || 0 };
      },
      isWholeDay: () => {
        const remind = watch('remind');
        if (remind) return { remindDate: getReminderDate() };
      },
      remind: (remind) => {
        if (remind) {
          const newRemindDate = updateRemindDate(remind);
          return { remindDate: newRemindDate };
        }
      },
      startDate: (startDate: string) => {
        const endDate = watch('endDate');
        const remind = watch('remind');

        const start = DateTime.fromISO(startDate);
        const end = DateTime.fromISO(endDate);

        const updatedEndDate = updateEndDate(start, end);

        setValue('endDate', updatedEndDate, { shouldDirty: true });

        if (remind) {
          const newRemindDate = updateRemindDate(remind);
          return { endDate: updatedEndDate, remindDate: newRemindDate };
        }

        return { endDate: updatedEndDate };
      },
      endDate: (endDate: string) => {
        const startDate = watch('startDate');
        const remind = watch('remind');

        const start = DateTime.fromISO(startDate);
        const end = DateTime.fromISO(endDate);

        const updatedStartDate = updateStartDate(start, end);

        setValue('startDate', updatedStartDate, { shouldDirty: true });

        if (remind) {
          const newRemindDate = updateRemindDate(remind);
          return { startDate: updatedStartDate, remindDate: newRemindDate };
        }

        return { startDate: updatedStartDate };
      },
      topicMarkList: (topicMarkList: TopicSimple[]) => {
        const { title, topicMark } = titleWithTopicMark(
          getValues('title'),
          topicMarkList,
          usedTopicMark.current,
          !getFieldState('topicMarkList').isDirty
        );

        usedTopicMark.current = topicMark;

        if (title) return { title };
      },
      contract: () => {
        return updateContractAndDeliveryNote(Entities.contract);
      },
      deliveryNote: () => {
        return updateContractAndDeliveryNote(Entities.deliveryNote);
      },
    },
    form
  );
};
