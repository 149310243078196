import styles from './UserPreview.module.scss';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDataMutation } from '@work4all/data';

import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { Divider } from '../../../dataDisplay/divider/Divider';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import { InfoCards, PreviewWrapper } from '../components';
import {
  PreviewInputsProvider,
  PreviewTitleInput,
} from '../components/preview-input';
import { PreviewInfoCard } from '../components/PreviewContent';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';

import { USER_PREVIEW_FIELDS } from './UserPreviewContainer';

export interface UserPreviewProps extends EntityPreviewProps<FullUser> {}

export function UserPreview(props: UserPreviewProps) {
  const { entries, ...handlers } = props;
  const { t } = useTranslation();

  // TODO: WW-3803 maybe mutate can be also part of  useEntityPreview
  const [mutate] = useDataMutation<FullUser, EMode.upsert>({
    entity: Entities.fullUser,
    mutationType: EMode.upsert,
    responseData: USER_PREVIEW_FIELDS as unknown as Task,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.fullUser,
    entries,
    mutate,
  });

  const entry = entries[0];
  const limitedView = UserClass.RESSOURCE === entry.userKind;
  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.fullUser}
      entries={entries}
    >
      <PreviewWrapper>
        <PreviewTitleInput accessor="name" {...handlers} />
        <div className={styles.avatarContainer}>
          <HookedUserIcon
            className={styles.avatar}
            userId={entry.id}
            size="xxxxxl"
          />
        </div>
        <div className={styles.avatarSignature}>
          <Typography fontWeight="bold">
            {entry.displayName || entry.name}
          </Typography>
          <Typography>{entry.eMail}</Typography>
        </div>
        <InfoCards>
          <PreviewInfoCard<FullUser>
            accessor={'ressourceClass.name'}
            label={t('INPUTS.RESORUCE_CLASS')}
          />
          <PreviewInfoCard<FullUser>
            accessor={'group.name'}
            label={t('INPUTS.GROUP')}
          />
          {!limitedView && (
            <>
              <PreviewInfoCard<FullUser>
                accessor={'role'}
                label={t('COMMON.ROLE')}
              />
              <PreviewInfoCard<FullUser>
                accessor={'costCenter.name'}
                label={t('COMMON.COST_CENTER')}
              />
              <PreviewInfoCard<FullUser>
                accessor={'supervisor.displayName'}
                label={t('COMMON.SUPERVISOR')}
              />
              <PreviewInfoCard<FullUser>
                accessor={'vacationApprover.displayName'}
                label={t('COMMON.VACATION_APPROVER')}
              />
            </>
          )}
        </InfoCards>
        <Divider />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
