import styles from './appointmentPreview.module.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation, useInaccessibleFields } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  fixAppointmentStartEndDates,
  typeNameToSdObjType,
} from '@work4all/utils';
import {
  DatePeriodPresets,
  formatDatePeriod,
} from '@work4all/utils/lib/date-utils/formatDateString';
import { filterAttendeesList } from '@work4all/utils/lib/filterAttendeesList';

import { usePreviewToolbarCustomActionsComponents } from '../../../dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import { Divider } from '../../../dataDisplay/divider/Divider';
import {
  InfoCard,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewWrapper,
} from '../components';
import {
  AppointmentStatePreviewPickerInput,
  BusinessPartnerPreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';

import { AppointmentPreviewFields } from './AppointmentPreviewFields';

export interface IAppointmentPreviewProps
  extends EntityPreviewProps<Appointment> {}

export function AppointmentPreview(props: IAppointmentPreviewProps) {
  const { entries, customActions: custom, ...handlers } = props;

  const { t } = useTranslation();

  const { isInaccessible, isSomeInaccessible } = useInaccessibleFields();

  const timePeriodsIsAccessible = !isSomeInaccessible(Entities.appointment, [
    'startDate',
    'endDate',
    'isWholeDay',
  ]);

  const appointmentAttendeeListIsAccessible = !isInaccessible(
    Entities.appointment,
    'appointmentAttendeeList'
  );
  const noteIsAccessible = !isInaccessible(Entities.appointment, 'note');

  const [mutate] = useDataMutation<Appointment, EMode.upsert>({
    entity: Entities.appointment,
    mutationType: EMode.upsert,
    responseData: AppointmentPreviewFields as unknown as Appointment,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.appointment,
    entries: entries,
    mutate,
  });

  const { isMultiselect, onPopoverClose, onEdit } = entityPreview;

  const originalAppointment = entries[0];
  const appointment = useMemo(() => {
    const appointment = originalAppointment
      ? fixAppointmentStartEndDates(originalAppointment)
      : originalAppointment;
    return appointment;
  }, [originalAppointment]);

  const filteredAttendeesList: AppointmentAttendee[] = useMemo(() => {
    return filterAttendeesList(appointment);
  }, [appointment]);

  const selectedRowsIdsList = useMemo(() => {
    return entries.map((x) => x.id);
  }, [entries]);

  const customActions = usePreviewToolbarCustomActionsComponents({
    custom,
    selectedRowsIdsList,
    selectedRowsData: entries,
  });

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.appointment}
      entries={entries}
    >
      <PreviewWrapper>
        <PreviewTitleInput
          accessor="title"
          {...handlers}
          leftActions={customActions}
        />

        {isMultiselect && (
          <PreviewEditMessage entityId={String(appointment.id)} />
        )}

        <div className={styles.infoCardWrapper}>
          <AppointmentStatePreviewPickerInput
            labelTranslationKey="COMMON.STATUS"
            onClose={onPopoverClose}
            value={appointment?.appointmentState}
            onChange={(value) => {
              onEdit({ colorId: value.id });
            }}
            accessor="appointmentState"
          />

          {timePeriodsIsAccessible && (
            <InfoCard staticField={true} label={t('COMMON.TIMESPAN')}>
              {formatDatePeriod(
                DatePeriodPresets.PERIOD,
                appointment?.startDate,
                appointment?.endDate,
                appointment?.isWholeDay
              )}
            </InfoCard>
          )}

          <BusinessPartnerPreviewPickerInput
            labelTranslationKey="COMMON.BUSINESS_PARTNER"
            onClose={onPopoverClose}
            value={appointment?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: typeNameToSdObjType(
                        value.__typename
                      ),
                      id: value.id,
                    }
                  : null,
                businessPartnerId: value ? value.id : 0,
                businessPartnerType: typeNameToSdObjType(value?.__typename),
                contactId: value?.mainContact?.id ?? null,
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={appointment?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
              });
            }}
            accessor="project"
            showFooter
          />

          {appointmentAttendeeListIsAccessible && (
            <InfoCard staticField={true} label={t('COMMON.PARTICIPANT')}>
              {filteredAttendeesList
                ?.map(
                  (el) =>
                    el.user?.displayName ||
                    el.contact?.displayName ||
                    el.ressource?.displayName ||
                    el?.businessPartner?.data?.name
                )
                .join(', ')}
            </InfoCard>
          )}
        </div>
        <Divider className={styles.divider} />

        {!isMultiselect && noteIsAccessible ? (
          <PreviewTextContent>{appointment?.note}</PreviewTextContent>
        ) : null}

        <Divider className={styles.divider} />

        <EntityHistory
          fitContainer={false}
          entity={Entities.appointment}
          fullWidth
          id={appointment.id}
          className={styles.hist}
          breadcrumbTitle={appointment.title}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
