import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material';

import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';

interface TicketKindMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  categories: TicketKind[];
  selectedIds: number[];
  onToggle: (id: number) => void;
}

export const TicketKindMenu = (props: TicketKindMenuProps) => {
  const { anchorEl, open, onClose, categories, selectedIds, onToggle } = props;

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      <List disablePadding sx={{ height: '24rem' }}>
        {categories.map((category) => (
          <ListItem key={category.id} disablePadding>
            <ListItemButton onClick={() => onToggle(category.id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedIds.includes(category.id)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={category.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Menu>
  );
};
