import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { useTicketCustomActions } from '../../containers/files/detail/components/file-preview-panel/use-ticket-custom-actions';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/ticket-table-schema.json';
import { TicketPriorityCell } from './TicketPriorityCell';
import { TicketsTableQuickFilters } from './tickets-table-components/tickets-table-filters/TicketsTableQuickFilters';
import { useTicketBoard } from './tickets-table-components/use-ticket-board';
import { TicketStatusCell } from './TicketStatusCell';

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

const forceRequestFields = {
  solutionText: null,
};

export const TicketsTable = React.forwardRef<TableInstance, IEntityTable>(
  function TicketsTable(props, ref) {
    const { prefilter, onTableChange, ...board } = useTicketBoard();
    const tableProps = {
      schema: schema as never,
      cells: {
        TicketStatus: TicketStatusCell,
        TicketPriority: TicketPriorityCell,
      },
      defaultSort,
      forceRequestFields,
      rowModifiers: (value) => {
        return {
          isFaded: value.status1 === TicketStatus.ERLEDIGT,
        };
      },
    };
    const dataTable = useEntityDataTable<Ticket, never>({
      ...tableProps,
      prefilter,
    });

    const quickFilters = useMemo(() => <TicketsTableQuickFilters />, []);
    const { t } = useTranslation();

    const left = useTicketCustomActions({
      tickets: dataTable.selectedEntities,
    });

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        {...props}
        actions={{
          custom: { left },
        }}
        areas={{
          left: {
            content: quickFilters,
            resizable: true,
            collapsible: true,
            title: t('COMMON.FILTER'),
          },
        }}
        board={{ ...board, ...tableProps }}
        onTableChange={onTableChange}
      />
    );
  }
);
