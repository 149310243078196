import styles from './DraggableTree.module.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  TreeItem,
  TreeItemContentProps,
  TreeItemProps,
  TreeView,
  useTreeItem,
} from '@mui/lab';
import { Divider, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  forwardRef,
  JSXElementConstructor,
  PropsWithoutRef,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';

import type { TreeNode } from '../types';
import { findNodeBy, insertNode, reindexTree, removeNodeBy } from '../utils';

interface DraggableTreeProps {
  tree: TreeNode[];
  selected: Pick<TreeNode, 'id' | 'index'> | null;
  onChange: (newTreeData: TreeNode[]) => void;
  onRemove: (id: string, index: number, hasChildren: boolean) => void;
  onEdit: (index: number) => void;
  onItemClick: (node: Pick<TreeNode, 'id' | 'index'>) => void;
  expanded?: string[];
}

interface CustomContentOurProps
  extends Pick<
    DraggableTreeProps,
    'onRemove' | 'onEdit' | 'onItemClick' | 'selected'
  > {
  isFlat: boolean;
  index: number;
  hasChildren: boolean;
}

interface CustomContentProps
  extends CustomContentOurProps,
    PropsWithoutRef<Omit<TreeItemContentProps, keyof CustomContentOurProps>> {}

const CustomContent = forwardRef<HTMLDivElement, CustomContentProps>(
  function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      index,
      hasChildren,
      selected,
      onRemove,
      onEdit,
      onItemClick,
    } = props;

    const { disabled, expanded, handleExpansion } = useTreeItem(nodeId);

    const [hovered, setHovered] = useState(false);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleExpansionClick: React.MouseEventHandler = (event) => {
      event.stopPropagation();
      handleExpansion(event);
    };

    const handleSelection: React.MouseEventHandler = (event) => {
      event.stopPropagation();
      onItemClick({ id: nodeId, index });
    };

    return (
      <div
        ref={ref}
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.disabled]: disabled,
          [classes.selected]: selected?.id === nodeId,
        })}
        onClick={handleSelection}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <DragIndicatorIcon
          color="secondary"
          sx={{
            width: 16,
            cursor: 'grab',
            visibility: hovered ? 'visible' : 'hidden',
          }}
        />

        <div className={classes.iconContainer} onClick={handleExpansionClick}>
          {icon}
        </div>

        <Typography
          className={classes.label}
          component="div"
          noWrap
          title={typeof label === 'string' ? label : undefined}
        >
          {label}
        </Typography>

        {hovered && (
          <div className={styles.actionButtonsWrapper}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onRemove(nodeId, index, hasChildren);
              }}
            >
              <DeleteIcon color="inherit" />
            </IconButton>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(index);
              }}
            >
              <SettingsIcon color="inherit" />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
);

interface CustomTreeItemProps
  extends CustomContentOurProps,
    PropsWithoutRef<
      Omit<
        TreeItemProps,
        | keyof CustomContentOurProps
        | 'classes'
        | 'ContentComponent'
        | 'ContentProps'
      >
    > {}

const CustomTreeItem = (props: CustomTreeItemProps) => {
  const {
    isFlat,
    index,
    hasChildren,
    selected,
    onRemove,
    onEdit,
    onItemClick,
    ...otherProps
  } = props;

  return (
    <TreeItem
      ContentComponent={
        CustomContent as JSXElementConstructor<TreeItemContentProps>
      }
      ContentProps={{
        // @ts-expect-error: MUI types seem to be wrong here
        onRemove,
        onEdit,
        onItemClick,
        index,
        hasChildren,
        selected,
      }}
      draggable
      onFocusCapture={(e) => e.stopPropagation()}
      classes={{
        root: styles.treeItem,
        content: styles.content,
        group: styles.group,
        iconContainer: isFlat
          ? styles['iconContainer--hidden']
          : styles.iconContainer,
        selected: styles.selected,
        label: styles.label,
      }}
      {...otherProps}
    />
  );
};

interface DragItem {
  id: string;
  parentId?: string;
  index: number;
}

const ITEM_TYPE = 'TREE_NODE';

const DraggableTreeItem = (
  props: Omit<CustomTreeItemProps, 'hasChildren'> & {
    moveNode: (
      draggedItem: DragItem,
      targetItem: DragItem,
      isBottomDrag: boolean
    ) => void;
    parentId?: string | null;
    index: number;
  }
) => {
  const {
    moveNode,
    nodeId: id,
    parentId,
    index,
    children,
    ...otherProps
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [hoveredPosition, setHoveredPosition] = useState<
    'above' | 'below' | 'middle' | null
  >(null);

  const [{ isOverCurrent }, drop] = useDrop<
    DragItem,
    unknown,
    { isOverCurrent: boolean }
  >({
    accept: ITEM_TYPE,
    hover: (draggedItem, monitor) => {
      if (!ref.current || draggedItem.id === id) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverTopY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) * 0.4;
      const hoverBottomY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) * 0.6;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) return;

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (hoverClientY < hoverTopY) {
        setHoveredPosition('above');
      } else if (hoverClientY > hoverBottomY) {
        setHoveredPosition('below');
      } else {
        setHoveredPosition('middle');
      }
    },
    drop: (draggedItem, monitor) => {
      if (!ref.current || draggedItem.id === id) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverTopY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) * 0.4;
      const hoverBottomY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) * 0.6;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) return;

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      const isMiddleDrop =
        hoverTopY < hoverClientY && hoverClientY < hoverBottomY;
      const isBottomDrop = hoverClientY > hoverBottomY;

      moveNode(
        draggedItem,
        {
          id,
          parentId: isMiddleDrop ? id : parentId,
          index: index,
        },
        isBottomDrop
      );

      setHoveredPosition(null);
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { id, parentId, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  if (!isOverCurrent && hoveredPosition !== null) {
    setHoveredPosition(null);
  }

  return (
    <div
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        border: hoveredPosition === 'middle' ? '1px dashed #ccc' : 'none',
        backgroundColor:
          hoveredPosition === 'middle' ? 'var(--hoverRow)' : 'initial',
      }}
    >
      {hoveredPosition === 'above' && (
        <Divider flexItem sx={{ opacity: 0.75 }} />
      )}
      <CustomTreeItem
        {...otherProps}
        nodeId={id}
        index={index}
        hasChildren={!!children}
        className={otherProps.className}
      >
        {children}
      </CustomTreeItem>
      {hoveredPosition === 'below' && (
        <Divider flexItem sx={{ opacity: 0.75 }} />
      )}
    </div>
  );
};

export const DraggableTree = (props: DraggableTreeProps) => {
  const { tree, selected, onChange, onRemove, onEdit, onItemClick, expanded } =
    props;
  const isFlat = tree.every((x) => !x.children);

  const moveNode = (
    draggedItem: DragItem,
    targetItem: DragItem,
    isBottomDrag: boolean
  ) => {
    const { id: draggedId } = draggedItem;
    const { parentId: targetParentId, index: targetIndex } = targetItem;

    // Find the dragged node
    const draggedNode = findNodeBy(tree, draggedId);

    // Remove dragged node
    const updatedTree = removeNodeBy([...tree], draggedId);

    // MUI tree returns different desired index depending on whether the dragged item
    // was dropped at the top or bottom part of the target item, so the filter here
    // covers that case and select correct target index
    const oldTargetIndex = updatedTree.findIndex(
      (tt) => tt.index === targetIndex
    );
    const newTargetIndex = isBottomDrag
      ? updatedTree[oldTargetIndex + 1]?.index
      : targetIndex;

    // Get target location index
    const insertArrayIndex = updatedTree.findIndex(
      (node) => node.index === newTargetIndex
    );

    // Insert dragged node into the target location
    const finalTree = targetParentId
      ? insertNode(
          updatedTree,
          draggedNode,
          targetParentId,
          targetIndex,
          isBottomDrag
        )
      : insertArrayIndex !== -1
      ? [
          ...updatedTree.slice(0, insertArrayIndex),
          { ...draggedNode, parentId: 0 },
          ...updatedTree.slice(insertArrayIndex),
        ]
      : [...updatedTree, { ...draggedNode, parentId: 0 }];

    // Reindex tree
    const reindexedTree = reindexTree(finalTree);

    onChange(reindexedTree);
  };

  const renderTree = (nodes: TreeNode[], parentId: string | null = null) => {
    return nodes.map((node) => {
      const { id, label, index, children } = node;

      return (
        <DraggableTreeItem
          key={id}
          nodeId={id}
          label={label}
          isFlat={isFlat}
          index={index}
          parentId={parentId}
          selected={selected}
          moveNode={moveNode}
          onRemove={onRemove}
          onEdit={onEdit}
          onItemClick={onItemClick}
        >
          {Array.isArray(children) ? renderTree(children, id) : null}
        </DraggableTreeItem>
      );
    });
  };

  return (
    <TreeView
      expanded={expanded}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(tree)}
    </TreeView>
  );
};
