import { useMemo, useState } from 'react';

import { IResponse } from '@work4all/data/lib/hooks/data-provider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  EntityPickerListProps,
  PickerBaseTabbed,
  PickerCommonProps,
} from './PickerBase';
import { useEntityPickerFilterResult } from './utils/hooks/use-entity-picker-filter-result';
import {
  useEntityPickerRegularResult,
  UseEntityPickerRegularResultProps,
} from './utils/hooks/use-entity-picker-regular-result';

export interface TabsConfiguration<T> {
  loadingConfig: Omit<
    UseEntityPickerRegularResultProps<T>,
    'query' | 'showFavorites'
  >;
  pickerConfig: Omit<
    PickerCommonProps<T, boolean>,
    'onChange' | 'value' | 'filterConfig'
  > &
    Omit<
      EntityPickerListProps<T>,
      'regularResult' | 'filterResult' | 'filterBy' | 'entity'
    >;
}

interface EntityPickerTabbedProps<T> {
  value: T | T[];
  onChange: PickerCommonProps<T, boolean>['onChange'];
  onTabChange: (entity: Entities) => void;
  getTabIndex?: (value: T) => number;
  tabs: TabsConfiguration<T>[];
}

/**
 * A picker with built-in data fetching for options list. Supports multiple tabs
 * for option lists.
 *
 * Will automatically decide how to fetch the data based on the `entity`. The
 * requested fields can be controlled with the `data` prop.
 *
 * The options can be filtered and sorted by any of the fields.
 *
 * See [Pickers on
 * Wiki](https://dev.azure.com/work4all-tfs/work4all%20mobile/_wiki/wikis/App%20wiki/59/Pickers)
 *
 * **Unstable**
 *
 * This component will be changed in the near future and breaking changes in the
 * interface are expected.
 */
// TODO Needs to be reworked and simplified
export function EntityPickerTabbed<T>(props: EntityPickerTabbedProps<T>) {
  const { tabs, value, onChange, getTabIndex, onTabChange } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const activeTabConfig = tabs[activeTabIndex];

  const [showFavorites, setShowFavorites] = useState(false);
  const [query, setQuery] = useState('');

  const queryTrimmed = query.trim();

  const {
    entity,
    data,
    filterConfig,
    sortBy,
    sortByDirection,
    filterBy,
    prefilter,
    searchPrefilter,
    keysArguments,
    vars,
    useSearchHistory,
    transformResponse,
  } = activeTabConfig.loadingConfig;

  const regularResult = useEntityPickerRegularResult({
    entity,
    data,
    filterConfig,
    sortBy,
    sortByDirection,
    filterBy,
    query: queryTrimmed,
    prefilter,
    searchPrefilter,
    keysArguments,
    vars,
    showFavorites,
    useSearchHistory,
    transformResponse,
  });

  const filterResult = useEntityPickerFilterResult({
    entity,
    data,
    filterConfig,
    sortBy,
    sortByDirection,
    filterBy,
    query: queryTrimmed,
  });

  const tabsPickerConfigs = useMemo(
    (): EntityPickerListProps<T>[] =>
      tabs.map((el) => {
        const config: EntityPickerListProps<T> = {
          ...el.pickerConfig,
          entity: el.loadingConfig.entity,
          filterBy: el.loadingConfig.filterBy,
          regularResult: null,
          filterResult: null,
        };

        return config;
      }),
    [tabs]
  );

  tabsPickerConfigs[activeTabIndex].regularResult =
    regularResult as IResponse<T>;
  tabsPickerConfigs[activeTabIndex].filterResult = filterResult;

  const { multiple = false, ...restPickerConfig } =
    activeTabConfig.pickerConfig;
  return (
    <PickerBaseTabbed
      onChange={onChange}
      value={value}
      {...restPickerConfig}
      tabs={tabsPickerConfigs}
      filterConfig={activeTabConfig.loadingConfig.filterConfig}
      getTabIndex={getTabIndex}
      onTabChange={(idx) => {
        setActiveTabIndex(idx);
        onTabChange(tabs[idx].loadingConfig.entity);
      }}
      onSearchValueChange={(qry) => {
        setQuery(qry);
        restPickerConfig.onSearchValueChange?.(qry);
      }}
      multiple={multiple}
      onFavoritesToggled={setShowFavorites}
    />
  );
}
