import styles from './TimeTrackingMask.module.scss';

import { useMemo } from 'react';

import {
  INavigationOverlayProps,
  NavigationOverlay,
} from '@work4all/components/lib/navigation/navigation-overlay';

import {
  ProjectTimeMask,
  ProjectTimeMaskProps,
} from '../../../../../containers/time-tracker/ProjectTimeMask';
import { useTimeTrackingData } from '../hooks';
import { timeTrackingToProjectTimeMaskInit } from '../utils';

type TimeTrackingMaskProps = {
  id?: number;
  mode: 'create' | 'edit' | 'clone';
} & Pick<INavigationOverlayProps, 'open' | 'close'>;

export const TimeTrackingMask = ({
  id,
  mode,
  ...rest
}: TimeTrackingMaskProps) => {
  const data = useTimeTrackingData(id);
  const view = useMemo(() => {
    const getProps = (): ProjectTimeMaskProps => {
      if (mode === 'create' || !data) {
        return {};
      }

      if (mode === 'edit') {
        return { item: data };
      }

      if (mode === 'clone') {
        return { init: timeTrackingToProjectTimeMaskInit(data) };
      }

      return {};
    };

    return <ProjectTimeMask {...getProps()} />;
  }, [data, mode]);

  return (
    <NavigationOverlay
      {...rest}
      classes={{
        wrapper: styles.wrapper,
        paper: styles.paper,
      }}
      initialView={{ view }}
    />
  );
};
