import { Box, Stack, Theme, useMediaQuery } from '@mui/material';

import {
  CustomToolbar,
  CustomToolbarAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { LockProvider } from '@work4all/components/lib/hooks';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { FilePreviewPanel } from '../../../containers/files/detail/components/file-preview-panel/FilePreviewPanel';
import { useOpenMask } from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { PreviewMobileWrapper } from '../PreviewMobileWrapper';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useEntityHandlers } from '../use-entity-handlers';

import { EntityActionProvider } from './EntityActionProvider';
import { mergeActions } from './utils';

interface EntityPreviewProps {
  entityType: Entities;
  onCloseClick: () => void;
  onVisibilityToggle?: (visible: boolean) => void;
  custom?: CustomToolbar<CustomToolbarAction>;
  entries: { id?: string | number }[];
}

/**
 * Wrapper to handle entity previews.
 */
export const EntityPreview = (props: EntityPreviewProps) => {
  const { entityType, onCloseClick, onVisibilityToggle, entries, custom } =
    props;

  const entityIds = entries.map((e) => e.id);
  const onOpenMask = useOpenMask({ entityType });
  const entityHandlers = useEntityHandlers({
    entity: entityType,
    onOpenMask,
    entries,
  });

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const deleteConfig = useDeleteEntitiesToolbarConfig({
    entityType,
  });

  return (
    <LockProvider>
      <EntityActionProvider entity={entityType}>
        {(actions) => (
          <PreviewMobileWrapper
            active={!isDesktop}
            actions={{
              edit: entityHandlers.edit,
              remove: deleteConfig,
              custom: mergeActions(custom, actions.custom),
            }}
            entityIds={entityIds}
          >
            <Stack direction="row" height="100%">
              <Box width="100%">
                <FilePreviewPanel
                  onCloseClick={onCloseClick}
                  onVisibilityToggle={onVisibilityToggle}
                  onEditClicked={entityHandlers.edit?.handler}
                  onShareClicked={entityHandlers.share}
                  openMaskTab={entityHandlers.openMaskTab}
                  convertProps={
                    entityHandlers.convert
                      ? {
                          exclude: entityType,
                          onClick: entityHandlers.convert,
                        }
                      : undefined
                  }
                  customActions={
                    isDesktop ? mergeActions(custom, actions.custom) : undefined
                  }
                  entityIds={entityIds}
                  entityType={entityType}
                />
              </Box>
            </Stack>
          </PreviewMobileWrapper>
        )}
      </EntityActionProvider>
    </LockProvider>
  );
};
