import styles from './SearchBarWithDropdown.module.scss';

import { ClickAwayListener } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import { ISearchBarProps, SearchBar } from '../../input/search-bar/SearchBar';

import { Dropdown } from './components/dropdown/Dropdown';

export interface ISearchBarTab {
  selected: boolean;
  label: string;
  onClick: () => void;
}

export interface ISearchBarWidthDropdownProps
  extends Omit<ISearchBarProps, 'onGoogleSearchToggle' | 'googleSearch'> {
  dropdown?: {
    suggestions?: React.ReactNode;
    footer?: JSX.Element;
    items?: JSX.Element[];
    itemsClass?: string;
    listClass?: string;
    rootClass?: string;
    inputClass?: string;
  };
  active?: boolean;
  onHideDropDown?: () => void;
  mobile: boolean;
  // tabs?: ISearchBarTab[];
  /**
   * sometimes you want dropdown to have static position instead of absolute positioning
   * (in case you render it inside wrapper that already has absolute positioning)
   */
  staticPosition?: boolean;
  classes?: {
    root?: string;
    searchBar?: string;
    searchIconWrapper?: string;
    searchIcon?: string;
  };
  searchBarWithDropDownStyles?: string;
}

export const withDropDown = (SearchBarComponent: typeof SearchBar) => {
  const SearchBarWithDropdown = React.forwardRef<
    HTMLInputElement,
    ISearchBarWidthDropdownProps
  >((props, ref) => {
    const { dropdown, active, staticPosition, classes, ...searchBarProps } =
      props;

    const [googleSearch, setGoogleSearch] = useState(false);

    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          props.onHideDropDown && props.onHideDropDown();
        }}
      >
        <div
          className={clsx(
            styles['with-dropdown'],
            props.mobile
              ? styles['with-dropdown--mobile']
              : styles['with-dropdown--default-view'],
            active && styles['with-dropdown--active'],
            props.searchBarWithDropDownStyles,
            classes?.root
          )}
        >
          <SearchBarComponent
            googleSearch={googleSearch}
            onGoogleSearchToggle={setGoogleSearch}
            ref={ref}
            className={clsx(
              styles['with-dropdown__search-bar'],
              {
                [styles.searchBarNoBorderRadius]:
                  staticPosition && dropdown.items?.length,
                [styles['with-dropdown__search-bar--active']]: active,
              },
              classes?.searchBar
            )}
            classes={{
              input: clsx(
                styles['with-dropdown__input'],
                active && styles['with-dropdown__input--active']
              ),
              iconWrapper: classes?.searchIconWrapper,
              icon: classes?.searchIcon,
            }}
            {...searchBarProps}
          />
          {active &&
            (props.mobile ? (
              dropdown.items?.length > 0 && (
                <div className={styles.items}>{dropdown.items}</div>
              )
            ) : (
              <Dropdown
                staticPosition={staticPosition}
                classes={{
                  root: dropdown.rootClass,
                  list: dropdown.listClass,
                  input: dropdown.inputClass,
                }}
              >
                {googleSearch ? (
                  dropdown.suggestions
                ) : (
                  <div
                    className={clsx(
                      styles['with-dropdown__items'],
                      dropdown.itemsClass
                    )}
                  >
                    {dropdown?.items}
                  </div>
                )}
                {dropdown?.footer}
              </Dropdown>
            ))}
        </div>
      </ClickAwayListener>
    );
  });

  return SearchBarWithDropdown;
};

export const SearchBarWithDropDown = withDropDown(SearchBar);
