import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';

import { formatCurrency } from '@work4all/utils/lib/format-currency';
import { formatNumberAsCurrency } from '@work4all/utils/lib/formatNumberAsCurrency';

export const SalesRatedCell = (props: CellProps<SalesOpportunities>) => {
  const salesOpportunity = props.cell.row.original;
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'right' }}>
      {`${formatNumberAsCurrency(
        salesOpportunity?.value *
          ((salesOpportunity?.probabilityPercent ?? 100) / 100),
        {
          maximum: 0,
          minimum: 0,
        }
      )} ${formatCurrency(salesOpportunity.currency, t)}`}
    </div>
  );
};
