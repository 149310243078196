import { MaskTabContext } from '../../../../mask-tabs';
import { useMaskContext } from '../../../hooks/mask-context';

import { ControllerTabs } from './enums';
import { CheckListOverlayControllerMaskTabProviderProps } from './types';

export const CheckListOverlayControllerMaskTabProvider = ({
  children,
}: CheckListOverlayControllerMaskTabProviderProps) => {
  const mask = useMaskContext();

  return (
    <MaskTabContext defaultValue={mask.params?.tab ?? ControllerTabs.General}>
      {children}
    </MaskTabContext>
  );
};
