import { pick } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useDataMutation } from '@work4all/data';

import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { Collapse, ControlWrapper } from '../../components';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';

type TextBuildingBlockFormValue = PathsOf<TextBuildingBlock, 2>;

type TextBuildingBlockOverlayControllerProps = MaskControllerProps & {
  amplitudeEntryPoint: string;
};

const DEFAULT_VALUES = {};
export function TextBuildingBlockOverlayController(
  props: TextBuildingBlockOverlayControllerProps
) {
  const { amplitudeEntryPoint } = props;

  const { goBack, close, currentStackIndex, setObjectionListener } =
    useHistoryStack();
  const [mutate] = useDataMutation<
    TextBuildingBlock,
    EMode.upsert,
    {
      attachements;
    }
  >({
    entity: Entities.textBuildingBlock,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
    },
  });

  const mask = useMaskConfig(props);

  useEffect(() => {
    if (mask.isCreateMode) {
      sendAmplitudeData(EventType.AddTextBuildingBlock, {
        entryPoint: amplitudeEntryPoint,
      });
    } else {
      sendAmplitudeData(EventType.EditTextBuildingBlock, {
        entryPoint: amplitudeEntryPoint,
      });
    }
  }, [amplitudeEntryPoint, mask, mask.isCreateMode, props.id]);

  const request = useTextBuildingBlockRequestData(mask.id);

  const overlay = useMaskOverlay<TextBuildingBlockFormValue>({
    ...props,
    request,
    newEntityData: DEFAULT_VALUES,
    mask,
    getSubTitle: (x) => x.name,
  });
  const { form } = overlay;

  const {
    formState: { dirtyFields },
  } = form;

  const handleSubmit = async (values: TextBuildingBlockFormValue) => {
    const update = mask.isCreateMode
      ? values
      : pick(values, ['id', ...Object.keys(dirtyFields)]);
    await mutate(update);

    setObjectionListener(null);
    sendAmplitudeData(EventType.SaveTextBuildingBlock, {
      entryPoint: amplitudeEntryPoint,
    });
    if (currentStackIndex !== 0) {
      goBack();
    } else {
      close();
    }
  };

  return (
    <OverlayController<TextBuildingBlockFormValue>
      {...overlay}
      onSubmit={handleSubmit}
    >
      <TextBuildingBlockMaskContent />
    </OverlayController>
  );
}

function TextBuildingBlockMaskContent() {
  const { t } = useTranslation();

  const { register } = useFormContextPlus<TextBuildingBlockFormValue>();

  return (
    <>
      <Collapse title={t('COMMON.DESCRIPTION')} defaultOpen>
        <ControlWrapper>
          <LabeledInput label={t('COMMON.NAME')} {...register('name')} />
        </ControlWrapper>
      </Collapse>

      <Collapse defaultOpen>
        <LabeledInput
          {...register('text')}
          label={t('INPUTS.TEXT')}
          multiline={true}
          style={{
            minHeight: '20rem',
          }}
        />
      </Collapse>
    </>
  );
}

export const useTextBuildingBlockRequestData = (id: string | number) => {
  return useMemo<DataRequest>(() => {
    const TextBuildingBlockRequestData: TextBuildingBlock<EMode.query> = {
      id: null,
      name: null,
      text: null,
    };

    const data = TextBuildingBlockRequestData;
    return {
      entity: Entities.textBuildingBlock,
      data,
      completeDataResponse: false,
      vars: {
        codes: [parseInt(id as string)],
      },
    };
  }, [id]);
};
