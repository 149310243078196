import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from '@work4all/data';

import { ILayoutDefinition } from '@work4all/models';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';
import { FileType } from '@work4all/models/lib/File';

import {
  makeTableSettings,
  stringifyTableSettings,
} from '../../../../../../components/data-tables/make-table-settings';
import { FileContext, IFileContactMetadata } from '../../FileContext';

export const createContactTemplate = (
  selectedContactId: string | number,
  businessPartnerId: string | number,
  businessPartnerType: FileType
) => {
  return `contact:${selectedContactId}:${businessPartnerType}:${businessPartnerId}`;
};

export const useEntityLinks = (
  definition: ILayoutDefinition,
  entityType: Entities
) => {
  const location = useLocation();
  const { t } = useTranslation();

  const {
    contactMetadata,
    id: businessPartnerId,
    type: businessPartnerType,
    title: businessPartnerName,
    childProjectData,
  } = useContext(FileContext);

  const selectedContact = useSelectedContact(contactMetadata);

  return useMemo(() => {
    const maskParams = new URLSearchParams();
    if (selectedContact)
      maskParams.append(
        'template',
        createContactTemplate(
          selectedContact.id,
          businessPartnerId,
          businessPartnerType
        )
      );

    const listParams = new URLSearchParams();

    const settingsInit = {
      [businessPartnerType]: {
        value: [
          {
            id: businessPartnerId,
            name: businessPartnerName,
          },
          ...(childProjectData || []),
        ],
      },
      contact: selectedContact
        ? {
            value: [
              {
                id: String(selectedContact.id),
                displayName: selectedContact.displayName,
              },
            ],
          }
        : null,
    };

    if (entityType === Entities.ticket) {
      settingsInit['status'] = {
        value: [
          {
            id: TicketStatus.OFFEN,
            name: t('TICKET_STATUS.' + TicketStatus.OFFEN),
          },
          {
            id: TicketStatus.IN_BEARBEITUNG,
            name: t('TICKET_STATUS.' + TicketStatus.IN_BEARBEITUNG),
          },
          {
            id: TicketStatus.ZURUECKGESTELLT,
            name: t('TICKET_STATUS.' + TicketStatus.ZURUECKGESTELLT),
          },
          {
            id: TicketStatus.ERLEDIGT,
            name: t('TICKET_STATUS.' + TicketStatus.ERLEDIGT),
          },
        ],
      };
    } else if (entityType === Entities.task) {
      settingsInit['status'] = {
        value: [
          { id: TaskStatus.OFFEN, name: t('TASK_STATUS.' + TaskStatus.OFFEN) },
          {
            id: TaskStatus.IN_BEARBEITUNG,
            name: t('TASK_STATUS.' + TaskStatus.IN_BEARBEITUNG),
          },
          {
            id: TaskStatus.WARTET,
            name: t('TASK_STATUS.' + TaskStatus.WARTET),
          },
          {
            id: TaskStatus.ZURUECK_GESTELLT,
            name: t('TASK_STATUS.' + TaskStatus.ZURUECK_GESTELLT),
          },
          {
            id: TaskStatus.ERLEDIGT,
            name: t('TASK_STATUS.' + TaskStatus.ERLEDIGT),
          },
        ],
      };
    }

    const settings = makeTableSettings(entityType, settingsInit);

    if (settings) {
      listParams.set('settings', stringifyTableSettings(settings));
    }

    const entityMarker = definition.derivate
      ? `${entityType}(${encodeURIComponent(definition.derivate.value)})`
      : entityType;

    const listHref = `${
      location.pathname
    }/entity/${entityMarker}?${listParams.toString()}`;
    const newHref = `${
      location.pathname
    }/details/${entityMarker}/new?${maskParams.toString()}`;
    const getItemHref = ({ id }: { id?: string | number }) =>
      `${location.pathname}/details/${entityMarker}/${id}`;

    return { listHref, newHref, getItemHref } as const;
  }, [
    selectedContact,
    businessPartnerId,
    businessPartnerType,
    businessPartnerName,
    childProjectData,
    entityType,
    definition.derivate,
    location.pathname,
    t,
  ]);
};

export const useSelectedContact = (contactMetadata: IFileContactMetadata) => {
  const selectedContact = useMemo<Contact | null>(() => {
    if (!contactMetadata?.contacts || !contactMetadata?.selectedContact) {
      return null;
    }

    const selectedContact = contactMetadata.contacts.find(
      (contact) => contact.id === contactMetadata.selectedContact
    );

    return selectedContact ?? null;
  }, [contactMetadata?.contacts, contactMetadata?.selectedContact]);
  return selectedContact;
};
