import styles from './HistoryWidget.module.scss';

import { ChevronLeft } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FilterType } from '@work4all/components';

import { useUser } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../settings';
import { EntityTableView } from '../file-entities-lists';

export const HistoryWidget = () => {
  const user = useUser();
  const { t } = useTranslation();
  const projectTimeTrackingDisplayHistory = useSetting(
    settings.projectTimeTrackingDisplayHistory()
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Typography variant="h4" color="text.primary">
          {t('COMMON.HISTORY')}
        </Typography>

        <IconButton
          onClick={() => {
            projectTimeTrackingDisplayHistory.set(false);
          }}
        >
          <ChevronLeft />
        </IconButton>
      </div>

      <EntityTableView
        entityType={Entities.timeTracking}
        initialState={{
          filters: [
            {
              id: 'user.id',
              value: {
                filterType: FilterType.Picker,
                value: [
                  {
                    id: `${user.benutzerCode}`,
                    displayName: user.displayName,
                  },
                ],
              },
            },
          ],
        }}
      />
    </div>
  );
};
