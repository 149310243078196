import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { NumberCell } from '@work4all/components';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  OpenMaskOverride,
  useOpenMask,
} from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { useStringifyTableGroupFilter } from '../../../hooks';
import { OrganisationArea } from '../../organisation-area';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/article-table-schema.json';

import { AttachmentsCell } from './cells/AttachmentsCell';
import { StockArticleCell } from './cells/StockArticleCell';
import { useTransformedArticleTableSchema } from './use-transformed-article-table-schema';

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

const forceRequestFields: Article = {
  groupId: null,
  netPrice: null,
  netPrice2: null,
  netPrice3: null,
  articlePrices: {
    singlePriceList: [
      {
        id: null,
        price: null,
        priceGroupId: null,
      },
    ],
  },
  hasBulkPrices: null,
  bulkPriceList: [
    {
      id: null,
      sdObjMemberCode: null,
      price: null,
      bulkPriceTierItem: {
        id: null,
        name: null,
        edgeValue1: null,
        edgeValue2: null,
      },
      priceGroupId: null,
    },
  ],
  articleTextList: [
    {
      id: null,
      kurztext: null,
      longtext: null,
      sprachCode: null,
    },
  ],
};

export const ArticleTable = React.forwardRef<TableInstance, IEntityTable>(
  function ArticleTable(props, ref) {
    const { t } = useTranslation();

    const transformedSchema = useTransformedArticleTableSchema({ schema });

    const dataTable = useEntityDataTable<Article, never>({
      schema: transformedSchema as never,
      defaultSort,
      forceRequestFields,
      cells: {
        AttachmentsCell,
        NumberCell,
        StockArticleCell,
      },
      rowModifiers: (value) => {
        return {
          isFaded: value.isShutDown,
        };
      },
    });

    const presetFields = useStringifyTableGroupFilter<Article>({
      groupKey: 'articleGroup',
      groupIdKey: 'groupId',
    });

    const onOpenMask = useOpenMask({
      entityType: Entities.article,
      params: { create: { presetFields } },
    });

    return (
      <OpenMaskOverride value={{ onOpenMask }}>
        <EntityTable
          ref={ref}
          areas={{
            left: {
              title: t('COMMON.ORGANISATION'),
              content: <OrganisationArea entity={Entities.article} />,
              resizable: true,
              collapsible: true,
              custom: true,
            },
          }}
          {...dataTable}
          onSelectedRowsChange={props.onSelectedRowsChange}
          actions={{
            createWidget: false,
          }}
        />
      </OpenMaskOverride>
    );
  }
);
