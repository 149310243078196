import i18next from 'i18next';

import { DueDateClassified } from '@work4all/models/lib/Enums/DueDateClassified.enum';
import { InvoiceForm } from '@work4all/models/lib/Enums/InvoiceForm.enum';
import { PaymentStatus } from '@work4all/models/lib/Enums/PaymentStatus.enum';
import { RaAccountingStatus } from '@work4all/models/lib/Enums/RaAccountingStatus.enum';
import { ReAccountingStatus } from '@work4all/models/lib/Enums/ReAccountingStatus.enum';
import { REBelegart } from '@work4all/models/lib/Enums/REBelegart.enum';
import { RePermitStatus } from '@work4all/models/lib/Enums/RePermitStatus.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { assertNever } from '@work4all/utils';

import { EmailKindChangeLookUp } from '../../../components/entity-picker/email-kind-picker/EmailKindPicker';
import { TaskStatusChangeLookUp } from '../../../components/entity-picker/task-status-picker/TaskStatusPicker';
import { TicketStatusChangeLookUp } from '../../../components/entity-picker/ticket-status-picker/TicketStatusPicker';
import { ColumnInstance, FilterType } from '../types';

import { dateToString, numbersToString } from './filters/filterConversion';

const tryGenerateDetailedPickerTitle = (header, value): string | null => {
  if (value?.length !== 1) {
    return null;
  }

  const [first] = value;

  if (typeof first === 'string') return first;

  const nameLikeProperties = ['displayName', 'name'];

  for (const property of nameLikeProperties) {
    if (property in first) {
      return `${header}: ${first[property]}`;
    }
  }

  return null;
};

const generateGenericPickerTitle = (header, value): string => {
  return (
    header + ': ' + (value?.length || '1') + ' ' + i18next.t('COMMON.CHOSEN')
  );
};

export const genFilterTitle = (col: ColumnInstance) => {
  let val: string;
  const filterType: FilterType = col.filterType;
  switch (filterType) {
    case FilterType.ClosedStatus:
      return i18next.t(col.filterParams.closedTextKey);
    case FilterType.Search:
      val = col.filterValue?.value;
      break;
    case FilterType.TravelReceiptStatus:
      val = i18next.t(
        'COMMON.TRAVELRECEIPTS_STATUS_' + col.filterValue?.value?.toUpperCase()
      );
      break;
    case FilterType.Date: {
      const start = col.filterValue?.value?.startDate;
      const end = col.filterValue?.value?.endDate;
      val = dateToString(start, end);

      break;
    }
    case FilterType.Number:
      if (
        col.filterValue?.value.from != null ||
        col.filterValue?.value.to != null
      )
        val = numbersToString(
          col.filterValue?.value.from,
          col.filterValue?.value.to
        );
      break;
    case FilterType.TaskStatus:
      return generateFixedPickerTitle(
        col,
        Object.entries(TaskStatusChangeLookUp)
      );

    case FilterType.AppointmentState:
    case FilterType.TicketStatus:
      return generateFixedPickerTitle(
        col,
        Object.entries(TicketStatusChangeLookUp)
      );

    case FilterType.PaymentStatus:
      return generateFixedPickerTitle(col, Object.entries(PaymentStatus));
    case FilterType.InvoiceForm:
      return generateFixedPickerTitle(col, Object.entries(InvoiceForm));
    case FilterType.ReAccountingStatus:
      return generateFixedPickerTitle(col, Object.entries(ReAccountingStatus));
    case FilterType.RaAccountingStatus:
      return generateFixedPickerTitle(col, Object.entries(RaAccountingStatus));
    case FilterType.PermitStatus:
      return generateFixedPickerTitle(col, Object.entries(RePermitStatus));
    case FilterType.InvoiceKind:
      return generateFixedPickerTitle(col, Object.entries(REBelegart));
    case FilterType.DueDateClassified:
      return generateFixedPickerTitle(col, Object.entries(DueDateClassified));
    case FilterType.SalesOpportunitiesStatusPicker:
      return generateFixedPickerTitle(
        col,
        Object.entries(SalesOpportunityStatus)
      );
    case FilterType.EmailKind: {
      return generateFixedPickerTitle(
        col,
        Object.entries(EmailKindChangeLookUp)
      );
    }
    case FilterType.RequiredTime: {
      const from = col.filterValue?.value?.from ?? null;
      const to = col.filterValue?.value?.to ?? null;

      if (from != null || to != null) {
        const multiplier = col.cellParams?.convertTo === 'hours' ? 8 : 1;

        val = numbersToString(
          from != null ? from * multiplier : null,
          to != null ? to * multiplier : null
        );
      }
      break;
    }
    case FilterType.SalesOpportunitiesGroupPicker:
    case FilterType.VacationKind:
    case FilterType.EInvoiceFormat:
    case FilterType.Boolean:
    case FilterType.BooleanNumber:
    case FilterType.ChronoContactPicker:
    case FilterType.ObjectType:
    case FilterType.Picker: {
      return (
        tryGenerateDetailedPickerTitle(col.Header, col.filterValue?.value) ??
        generateGenericPickerTitle(col.Header, col.filterValue?.value)
      );
    }
    case FilterType.Check:
      return `${col.Header}: ${i18next.t('FILTERS.CHECKED_ITEMS_ONLY')}`;
    default:
      assertNever(filterType, `Unknown filter type ${filterType}`);
  }

  return `${col.Header as string}: ${val}`;
};

export const filterIsEmpty = (col: ColumnInstance): boolean => {
  if (!col.filterValue) {
    return true;
  }

  switch (col.filterType) {
    case FilterType.ClosedStatus:
      return !col.filterValue?.value;
    case FilterType.Search:
      return !col.filterValue;
    case FilterType.Date:
      return (
        !col.filterValue?.value.startDate && !col.filterValue?.value.endDate
      );
    case FilterType.Number:
      return (
        col.filterValue?.value.from === null &&
        col.filterValue?.value.to === null
      );
    case FilterType.RequiredTime:
      return (
        col.filterValue?.value.from === null &&
        col.filterValue?.value.to === null
      );
    case FilterType.BooleanNumber:
    case FilterType.Boolean:
    case FilterType.EmailKind:
    case FilterType.VacationKind:
    case FilterType.EInvoiceFormat:
    case FilterType.SalesOpportunitiesGroupPicker:
    case FilterType.SalesOpportunitiesStatusPicker:
    case FilterType.TaskStatus:
    case FilterType.TicketStatus:
    case FilterType.AppointmentState:
    case FilterType.PaymentStatus:
    case FilterType.InvoiceForm:
    case FilterType.ReAccountingStatus:
    case FilterType.RaAccountingStatus:
    case FilterType.PermitStatus:
    case FilterType.InvoiceKind:
    case FilterType.DueDateClassified:
    case FilterType.ChronoContactPicker:
    case FilterType.ObjectType:
    case FilterType.TravelReceiptStatus:
    case FilterType.Picker:
      return (
        !col.filterValue ||
        !col.filterValue?.value ||
        col.filterValue?.value.length === 0
      );

    case FilterType.Check:
      return !col.filterValue?.value;
    default:
      assertNever(col.filterType, 'unknown filtertype');
  }
};
function generateFixedPickerTitle(
  col: ColumnInstance,
  //eslint-disable-next-line
  entries: [string, any][]
) {
  const values = col.filterValue?.value
    ?.map((original) => {
      const distinctBy = entries.find((y) => y[1].includes(original.id))[0];

      if (original.id === distinctBy) return original;
      return null;
    })
    .filter((x) => x);

  return (
    tryGenerateDetailedPickerTitle(col.Header, values) ??
    generateGenericPickerTitle(col.Header, values)
  );
}
