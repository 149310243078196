import { FileEntity } from '../Classes/FileEntity.entity';
import { User } from '../Classes/User.entity';
import { Entities } from '../Enums/Entities.enum';

/** GroupBy gql query uses ObjectType to identify Entity */
export type ObjectTypesUnion =
  | 'ARTIKEL'
  | 'LIEFERANT'
  | 'KUNDE'
  | 'PROJEKT'
  | 'KALKULATION'
  | 'AUFTRAG'
  | 'ANGEBOT'
  | 'RECHNUNG'
  | 'LIEFERSCHEIN'
  | 'BESTELLUNG'
  | 'BEDARFSANFORDERUNG'
  | 'EINGANGSLIEFERSCHEIN'
  | 'KUNDENANSPRECHPARTNER'
  | 'LIEFERANTENANSPRECHPARTNER'
  | 'PRODUKTIONSAUFTRAG'
  // | 'PRODUKTIONS_SCHRITT' // not used currntly (29.09.2021)
  | 'TICKET'
  | 'AUFGABE'
  | 'TERMIN'
  | 'BESUCHSBERICHT'
  | 'DOKUMENT'
  | 'E_MAIL'
  | 'TELEFONAT'
  | 'NOTIZ'
  //| 'ANHANG'
  | 'BRIEF'
  // | 'LIEFERANTEN_BEWERTUNG' // not used currently (29.09.2021)
  | 'RECHNUNGSEINGAENGE'
  // | 'KASSENBELEG' // not used currently (29.09.2021)
  // | 'PROFORMA_KOSTENBELEG' // not used currently (29.09.2021)
  | 'REISEKOSTENBELEG' // not used currently (29.09.2021)
  // | 'WARTUNGSOBJEKT' // not used currently (29.09.2021)
  | 'VERKAUFSCHANCE' // not used currently (29.09.2021)
  // | 'GUTSCHRIFTEN' // not used currently (29.09.2021)
  | 'OBJECT_LOCK'
  | 'CHECKLISTEN'
  | 'REISEKOSTENABRECHNUNG'
  // | 'REISEKOSTENABRECHNUNGBELEG'
  | 'KOMMT_GEHT_ZEITSTEMPEL'
  | 'ZEITERFASSUNG'
  | 'E_MAIL_SIGNATUR'
  | 'E_MAIL_VORLAGE_3'
  | 'WORD_DOCUMENT_TEMPLATE'
  | 'WORD_LETTER_TEMPLATE'
  | 'TEXTBAUSTEIN'
  | 'TICKET_FILTER'
  | 'SAVED_LIST_FILTER'
  | 'WARTUNGSOBJEKT'
  | 'MAILBOX_CONFIGURATION'
  | 'SALES_OPPORTUNITY_RATING_STATUS'
  | 'ADRESS_VERKNUEPFUNG'
  | 'MENTION'
  | 'PROJECT_ACCESS_GROUP'
  | 'INCOMING_MAIL_BLOCKED_SENDER'
  | 'RA'
  | 'EVENT_ITEM'
  | 'FILE_LINK'
  | 'RE_VIEW_MODEL'
  | 'BENUTZER'
  // | 'FULL_USER'
  | 'RA_VIEW_MODEL';
// | 'POSITION' // not used currently (29.09.2021)
// | 'WARENEINGANG' // not used currently (29.09.2021)
// | 'ARTIKEL'; // not used currently (29.09.2021)

export const EntityByObjectType: Record<ObjectTypesUnion, Entities> = {
  ARTIKEL: Entities.article,
  LIEFERANT: Entities.supplier,
  KUNDE: Entities.customer,
  PROJEKT: Entities.project,
  KALKULATION: Entities.calculation,
  AUFTRAG: Entities.contract,
  ANGEBOT: Entities.offer,
  RECHNUNG: Entities.invoice,
  LIEFERSCHEIN: Entities.deliveryNote,
  BESTELLUNG: Entities.order,
  BEDARFSANFORDERUNG: Entities.demand,
  EINGANGSLIEFERSCHEIN: Entities.inboundDeliveryNote,
  TICKET: Entities.ticket,
  AUFGABE: Entities.task,
  TERMIN: Entities.appointment,
  BESUCHSBERICHT: Entities.visitationReport,
  DOKUMENT: Entities.document,
  E_MAIL: Entities.eMail,
  TELEFONAT: Entities.callMemo,
  NOTIZ: Entities.note,
  BRIEF: Entities.letter,
  OBJECT_LOCK: Entities.objectLock,
  CHECKLISTEN: Entities.checkList,
  REISEKOSTENABRECHNUNG: Entities.travelExpenses,
  REISEKOSTENBELEG: Entities.travelReceipts,
  ZEITERFASSUNG: Entities.timeTracking,
  KUNDENANSPRECHPARTNER: undefined, //these two can not be mapped without context knowledge
  LIEFERANTENANSPRECHPARTNER: undefined, //these two can not be mapped without context knowledge
  KOMMT_GEHT_ZEITSTEMPEL: Entities.userPresenceTimeInfoItem,
  RECHNUNGSEINGAENGE: Entities.inboundInvoice,
  E_MAIL_SIGNATUR: Entities.eMailSignature,
  E_MAIL_VORLAGE_3: Entities.eMailTemplate,
  PRODUKTIONSAUFTRAG: Entities.productionContract,
  TEXTBAUSTEIN: Entities.textBuildingBlock,
  TICKET_FILTER: Entities.ticketFilter,
  WORD_DOCUMENT_TEMPLATE: Entities.wordDocumentTemplate,
  WORD_LETTER_TEMPLATE: Entities.wordLetterTemplate,
  VERKAUFSCHANCE: Entities.salesOpportunities,
  SAVED_LIST_FILTER: Entities.savedListFilter,
  WARTUNGSOBJEKT: Entities.serviceContract,
  MAILBOX_CONFIGURATION: Entities.mailbox,
  PROJECT_ACCESS_GROUP: Entities.projectAccessGroup,
  SALES_OPPORTUNITY_RATING_STATUS: Entities.salesOpportunityRatingStatus,
  ADRESS_VERKNUEPFUNG: Entities.addressConnection,
  MENTION: Entities.mention,
  INCOMING_MAIL_BLOCKED_SENDER: Entities.incomingMailBlockedSender,
  RA: Entities.ra,
  EVENT_ITEM: Entities.event,
  FILE_LINK: Entities.fileLink,
  RE_VIEW_MODEL: Entities.reViewModel,
  RA_VIEW_MODEL: Entities.raViewModel,
  BENUTZER: Entities.fullUser,
  // FULL_USER: Entities.fullUser
};

export const ObjectTypeByEntity = Object.keys(EntityByObjectType).reduce<
  Record<Entities, ObjectTypesUnion>
>((acc, objectType) => {
  const entity = EntityByObjectType[objectType];
  acc[entity] = objectType;
  return acc;
}, {} as Record<Entities, ObjectTypesUnion>);

export type LayoutTypesUnion =
  | 'Aufgabe'
  | 'Auftrag'
  | 'Dokument'
  | 'EMail'
  | 'Telefonat'
  | 'Lieferschein'
  | 'Notiz'
  | 'Termin'
  | 'Ticket'
  | 'Angebot'
  | 'Brief'
  | 'Rechnung'
  | 'Projekt'
  | 'Kalkulation'
  | 'Eingangsrechnung'
  | 'Bedarfsanforderung'
  | 'Eingangslieferschein'
  | 'Reisekostenabrechnung'
  | 'ReisekostenabrechnungBeleg'
  | 'Checkliste'
  | 'Bestellung'
  | 'Besuchsbericht'
  | 'Verkaufschance'
  | 'ReViewModel'
  | 'Benutzer'
  | 'Reisekostenbeleg';

export const EntityByLayoutType: Record<LayoutTypesUnion, Entities> = {
  Aufgabe: Entities.task,
  Auftrag: Entities.contract,
  Dokument: Entities.document,
  EMail: Entities.eMail,
  Telefonat: Entities.callMemo,
  Lieferschein: Entities.deliveryNote,
  Notiz: Entities.note,
  Termin: Entities.appointment,
  Ticket: Entities.ticket,
  Angebot: Entities.offer,
  Brief: Entities.letter,
  Rechnung: Entities.invoice,
  Projekt: Entities.project,
  Bestellung: Entities.order,
  Checkliste: Entities.checkList,
  Kalkulation: Entities.calculation,
  Eingangsrechnung: Entities.inboundInvoice,
  Bedarfsanforderung: Entities.demand,
  Eingangslieferschein: Entities.inboundDeliveryNote,
  Reisekostenabrechnung: Entities.travelExpenses,
  ReisekostenabrechnungBeleg: Entities.travelReceipts,
  Besuchsbericht: Entities.visitationReport,
  Verkaufschance: Entities.salesOpportunities,
  ReViewModel: Entities.reViewModel,
  Benutzer: Entities.user,
  Reisekostenbeleg: Entities.travelReceipts,
};

export const LayoutTypeByEntity = Object.keys(EntityByLayoutType).reduce(
  (acc, key: LayoutTypesUnion) => {
    const val = EntityByLayoutType[key];
    acc[val] = key;
    return acc;
  },
  {} as Record<Entities, LayoutTypesUnion>
);

// TODO: Add missing fields
export interface IAttachmentEntity {
  id?: string | number;
  date?: string;
  fileName?: string;
  fileInfos?: FileEntity;
  type?: string;
  lastModificationDate?: string;
  user?: User;
  __typename?: string;
}
