import styles from './ResourcesTimeline.module.scss';

import {
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscResource,
} from '@mobiscroll/react';
import { Close, Groups, Save, Search } from '@mui/icons-material';
import { Box, IconButton, Popover, Stack } from '@mui/material';
import { popoverClasses } from '@mui/material/Popover';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { ResourceUtilizationInfo } from '@work4all/models/lib/Classes/ResourceUtilizationInfo.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  AppointmentQuickCreateDialog,
  AppointmentTemplate,
} from '../../../../../../../containers/calendar/components/AppointmentQuickCreateDialog';
import { CreateViewDialog } from '../../../../../../../containers/calendar/components/CreateViewDialog';
import { ViewsList } from '../../../../../../../containers/calendar/components/ViewsList';
import { useCalendarViews } from '../../../../../../../containers/calendar/hooks/use-calendar-views';
import { mapUsersToAppointmentAttendees } from '../../../../../../../containers/calendar/utils/map-users-to-appointment-attendees';
import { UserCompilationPicker } from '../../../../../../entity-picker/UsersCompilationPicker';
import { TimeUnit } from '../project-planning-view/components/time-unit-switch/TimeUnitSwitch';
import { ZoomValue } from '../project-planning-view/components/timeline-zoom/TimelineZoom';
import { useColors } from '../project-planning-view/hooks/use-colors';
import {
  ZoomableTimeline,
  ZoomableTimelineProps,
} from '../zoomable-timeline/ZoomableTimeline';

import { ResourceUtilizationInfoDialog } from './components/resource-utilization-info-dialog/ResourceUtilizationInfoDialog';
import { ResourcesTimelineToolbar } from './components/resources-timeline-toolbar/ResourcesTimelineToolbar';
import { useUsersById } from './hooks/get-users-by-id';
import { useResourceInfo } from './hooks/use-resource-info';

export interface DateRangeConf {
  start: Date;
  end: Date;
}

type ResourcesTimelineProps = Pick<
  ZoomableTimelineProps,
  | 'compactView'
  | 'date'
  | 'leftAreaCollapsed'
  | 'leftAreaWidth'
  | 'onSelectedDateChange'
  | 'componentRef'
  | 'zoom'
  | 'view'
  | 'onDestroy'
  | 'onPageLoaded'
> & {
  className?: string;
  highlightedDateRanges: DateRangeConf[];
  focusedProject?: Project;
  focusedProjectProcess?: ProjectProcess;
  userIds?: number[];
  onUsersChange?: (users: User[]) => void;
  showToolbar?: boolean;
  zoom?: ZoomValue;
  unit?: 'year' | 'day' | 'week' | 'month';
  onZoomChange?: (zoom: ZoomValue) => void;
  onTimeUnitChange?: (unit: TimeUnit) => void;
};

export const ResourcesTimeline = (props: ResourcesTimelineProps) => {
  const {
    date: initialDate = new Date(),
    className,
    highlightedDateRanges,
    focusedProjectProcess,
    focusedProject,
    userIds: userIdsInitial,
    onUsersChange,
    showToolbar = false,
    zoom = 3,
    onZoomChange,
    unit = 'year',
    onTimeUnitChange,
    ...rest
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [events, setEvents] = useState<MbscCalendarEvent[]>([]);
  const [appointmentTemplate, setAppointmentTemplate] =
    useState<AppointmentTemplate>({});
  const [attendeeSuggestions, setAttendeeSuggestions] = useState<
    AppointmentAttendee[]
  >([]);

  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const [teamsDialogOpen, setTeamsDialogOpen] = useState(false);

  const [userList, setUserList] = useState<User[]>([]);

  const { t } = useTranslation();

  const userSearchBtnRef = useRef(null);
  const teamsButton = useRef(null);

  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    setDate(initialDate);
  }, [initialDate]);

  const startDate = DateTime.fromJSDate(date).startOf('year').toJSDate();
  const endDate = DateTime.fromJSDate(date).endOf('year').toJSDate();

  const resourceInfoRes = useResourceInfo({
    endDate,
    startDate,
    userIdList: userList.map((user) => user.id),
  });

  const { views, createView, deleteView } = useCalendarViews();

  const {
    data: usersFromIdList,
    loading,
    pending,
  } = useUsersById({
    idList: userIdsInitial,
  });

  useEffect(() => {
    if (!loading && !pending) {
      setUserList(usersFromIdList);
      onUsersChange?.(usersFromIdList);
    }
  }, [loading, onUsersChange, pending, usersFromIdList]);

  useEffect(() => {
    const newEvents: MbscCalendarEvent[] = resourceInfoRes.data
      .filter((x) => Boolean(x.utilizedValue || x.planedValue))
      .map((info) => {
        const tooltip = [
          info.vacationList.length ? t('COMMON.VACATION') : undefined,
          info.appointmentList?.length
            ? info.appointmentList?.length +
              ' ' +
              t(
                info.appointmentList?.length !== 1
                  ? 'COMMON.APPOINTMENT_plural'
                  : 'COMMON.APPOINTMENT'
              )
            : undefined,
          info.absenceList.length ? t('COMMON.SICKNESSDAY') : undefined,
        ]
          .filter(Boolean)
          .join('\n');

        return {
          id: info.userId + '-' + info.date.toString(),
          start: info.date,
          end: info.date,
          title:
            info.utilizedValue + info.planedValue
              ? Math.round(info.utilizedValue + info.planedValue).toString()
              : '',
          resource: info.userId,
          color: 'transparent',
          tooltip,
          data: info,
        };
      });
    setEvents(newEvents);
  }, [resourceInfoRes.data, t]);

  const [createViewDialogOpen, setCreateViewDialogOpen] = useState(false);

  const onCreateView = useCallback(
    (name: string) => {
      createView({
        name,
        params: {
          appointmentStates: [],
          focusedUserIds: [],
          groupMode: 'vertical',
          range: 'month',
          userIds: userList.map((user) => user.id),
        },
      });
    },
    [createView, userList]
  );

  const renderResourceHeader = useCallback(() => {
    return (
      <Stack direction="row" p="0.125rem" pr={0} gap="0.5rem">
        <Box width="100%" ref={userSearchBtnRef}>
          <BaseActionButton
            icon={<Search />}
            title={t('COMMON.CHOOSE', { name: t('COMMON.USER_RESOURCE') })}
            onClick={() => {
              setUsersDialogOpen(true);
            }}
          />
        </Box>

        <Tooltip title={t('COMMON.TEAMS')}>
          <IconButton
            size="small"
            onClick={() => {
              setTeamsDialogOpen(true);
            }}
            ref={teamsButton}
          >
            <Groups />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('CALENDAR.VIEWS.SAVE_VIEW')}>
          <IconButton
            size="small"
            onClick={() => {
              setCreateViewDialogOpen(true);
            }}
            disabled={userList.length === 0}
          >
            <Save />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('INPUTS.CLEAR_ALL')}>
          <IconButton
            disabled={userList.length === 0}
            size="small"
            onClick={() => {
              setUserList([]);
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }, [t, userList.length]);

  const renderResource = useCallback(
    (resource: MbscResource) => {
      const user = userList.find((x) => x.id === resource.id);
      return !user ? (
        <div>{resource.id}</div>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          p="0.25rem 0.5rem"
          gap="0.5rem"
          pr="0"
          width="100%"
        >
          <HookedUserIcon userId={user.id} size="m" />
          <Box width="100%">{user.displayName || user.shortName}</Box>
          <IconButton
            size="small"
            onClick={() => {
              setUserList(userList.filter((x) => x.id !== user.id));
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      );
    },
    [userList]
  );

  const resources = useMemo<MbscResource[]>(() => {
    return userList.map(
      (user) => ({ id: user.id, name: user.displayName } as MbscResource)
    );
  }, [userList]);

  const defaultColors = useColors({ resources });

  const colors = useMemo<MbscCalendarColor[]>(() => {
    const statusColors = resourceInfoRes.data.map((item) => {
      return {
        start: item.date,
        end: item.date,
        resource: item.userId,
        background:
          item.utilizedValue + item.planedValue >= 8
            ? 'var(--userShade7)'
            : undefined,
      } as MbscCalendarColor;
    });

    const highlightColors = resources
      .map((resource) => {
        return highlightedDateRanges
          .map((item) => {
            const result = [];
            const currentDate = new Date(item.start);
            while (currentDate <= new Date(item.end)) {
              result.push({
                start: DateTime.fromJSDate(currentDate)
                  .startOf('day')
                  .toJSDate(),
                end: DateTime.fromJSDate(currentDate).endOf('day').toJSDate(),
                resource: resource.id,
                background: 'var(--userShade5)',
              } as MbscCalendarColor);
              currentDate.setDate(currentDate.getDate() + 1);
            }

            return result;
          })
          .flat();
      })
      .flat();

    return [...defaultColors, ...highlightColors, ...statusColors];
  }, [defaultColors, resourceInfoRes.data, resources, highlightedDateRanges]);

  const handleOnEventCreated = useCallback(
    (args: MbscEventCreatedEvent) => {
      const { event } = args;

      const primaryUsers = (
        userList.length === 1
          ? userList
          : userList.filter((u) => u.id === event.resource)
      ).map((user) => ({ ...user, __typename: undefined }));

      const optionalUsers = userList.map((user) => ({
        ...user,
        __typename: undefined,
      }));

      const primaryAppointmentAttendeeList =
        mapUsersToAppointmentAttendees(primaryUsers);
      const optionalAppointmentAttendeeList =
        mapUsersToAppointmentAttendees(optionalUsers);

      const process =
        (event.process as ProjectProcess) || focusedProjectProcess;
      const project = process?.project || focusedProject;

      const businessPartnerType = process?.customerId
        ? SdObjType.KUNDE
        : process?.supplierId
        ? SdObjType.LIEFERANT
        : undefined;
      const businessPartnerId = process?.customerId || process?.supplierId;

      const template: AppointmentTemplate = {
        appointmentAttendeeList: primaryAppointmentAttendeeList,
        startDate: DateTime.fromJSDate(event.start as Date).toISO(),
        endDate: DateTime.fromJSDate(event.end as Date).toISO(),
        isWholeDay: true,
        title: event.title,
        project: project,
        projectId: project?.id,
        businessPartner: businessPartnerType
          ? {
              businessPartnerType,
              data: process?.customer || process?.supplier,
              id: businessPartnerId,
            }
          : undefined,
        businessPartnerId,
        businessPartnerType,
        projectProcess: process,
        projectProcessId: process?.id,
        isRealAppointment: false,
      };

      setAppointmentTemplate(template);
      setAttendeeSuggestions(optionalAppointmentAttendeeList);
      setDialogOpen(true);
      setEvents(cloneDeep(events));
    },
    [events, focusedProject, focusedProjectProcess, userList]
  );

  const [resourceUtilizationInfoOpen, setResourceUtilizationInfoOpen] =
    useState(false);
  const [resourceUtilizationInfoData, setResourceUtilizationInfoData] =
    useState(null);

  const handleEventClick = useCallback((args: MbscEventClickEvent) => {
    const data = args.event.data as ResourceUtilizationInfo;
    setResourceUtilizationInfoData(data);
    setResourceUtilizationInfoOpen(true);
  }, []);

  return (
    <>
      <ResourceUtilizationInfoDialog
        open={resourceUtilizationInfoOpen}
        onClose={() => setResourceUtilizationInfoOpen(false)}
        data={resourceUtilizationInfoData}
      />
      <CreateViewDialog
        open={createViewDialogOpen}
        onClose={() => setCreateViewDialogOpen(false)}
        onConfirm={(name) => {
          setCreateViewDialogOpen(false);
          onCreateView(name);
        }}
        options={views.map((x) => x.name)}
      />
      <AppointmentQuickCreateDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          resourceInfoRes.refetch();
        }}
        onOpenMask={() => null}
        template={appointmentTemplate}
        attendeeSuggestions={attendeeSuggestions}
      />

      <Popover
        open={usersDialogOpen}
        onClose={() => setUsersDialogOpen(false)}
        anchorEl={userSearchBtnRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          [`.${popoverClasses.paper}`]: {
            height: 500,
          },
        }}
      >
        <UserCompilationPicker
          value={userList}
          onChange={setUserList}
          autoFocusTextInput
        />
      </Popover>

      <Popover
        open={teamsDialogOpen}
        onClose={() => setTeamsDialogOpen(false)}
        anchorEl={teamsButton.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ViewsList
          views={views}
          activeView={null}
          onSelectView={(view) => {
            setUserList([]);
            setTimeout(() => {
              setUserList(view.params.userIds.map((id) => ({ id })));
              setTeamsDialogOpen(false);
            });
          }}
          onDeleteView={(view) => deleteView(view.id)}
          title={t('COMMON.TEAMS')}
        />
      </Popover>

      {showToolbar && (
        <ResourcesTimelineToolbar
          onDateChange={(e) => setDate?.(e)}
          unit={unit}
          date={date}
          zoom={zoom}
          onZoomChange={onZoomChange}
          onTimeUnitChange={onTimeUnitChange}
        />
      )}
      <ZoomableTimeline
        events={events}
        showControls={false}
        renderResourceHeader={renderResourceHeader}
        renderResource={renderResource}
        resources={resources}
        colors={colors}
        externalDrop
        dragToMove={false}
        onEventCreated={handleOnEventCreated}
        className={clsx(styles.resourceTimeline, className || '')}
        date={date}
        dragToCreate={true}
        newEventText={t('COMMON.NEW_PLANNING_APPOINTMENT')}
        onEventClick={handleEventClick}
        zoom={zoom}
        {...rest}
      />
    </>
  );
};
