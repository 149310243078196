import styles from './ProjectTable.module.scss';

import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useUser } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { GridStateProvider } from '../grid/GridStateProvider';
import jsonSchema from '../schemata/project-table-schema.json';
import { useSyncScroll } from '../table/hooks/use-sync-scroll';
import { TableAreas } from '../table/Table';

import { ProjectBanderoleInfo } from './components/ProjectBanderoleInfo';
import { ProjectRightsGrid } from './components/ProjectRightsGrid';
import { ProjectTableQuickFilters } from './components/ProjectTableQuickFilters';
import { useProjectActions } from './hooks/use-project-actions';
import { useProjectGrid } from './hooks/use-project-grid';
import { useRightsSchema } from './hooks/use-rights.schema';

type IProjectTableCustomColumns = Record<string, never>;

const defaultSort = [
  { field: 'startDateOuter', direction: SortDirection.DESCENDING },
];

export const ProjectRightsTableGrid = React.forwardRef<
  TableInstance,
  IEntityTable
>(function ProjectRightsTableGrid(props, ref) {
  return (
    <GridStateProvider>
      <ProjectRightsTableGridInner {...props} ref={ref} />
    </GridStateProvider>
  );
});

const ProjectRightsTableGridInner = React.forwardRef<
  TableInstance,
  IEntityTable
>(function ProjectRightsTableGridInner(props, ref) {
  const user = useUser();
  const schema = jsonSchema as unknown as ITableSchema<never>;

  const matrixSchema = useRightsSchema(schema);

  const forceRequestFields: Project = useMemo<Project>(() => {
    if (!user.isMaster) return props.forceRequestFields;
    return {
      ...props.forceRequestFields,
      projectAccessGroups: [
        {
          id: null,
        },
      ],
    };
  }, [props.forceRequestFields, user.isMaster]);

  const dataTable = useEntityDataTable<Project, IProjectTableCustomColumns>({
    schema: matrixSchema,
    defaultSort,
    forceRequestFields,
    rowModifiers: (value) => {
      return {
        isFaded: value.projectStatus?.closedStatus === -1,
      };
    },
  });

  const listScrollRef = useRef<HTMLDivElement>();
  const gridScrollRef = useRef<HTMLDivElement>();
  useSyncScroll('vertical', listScrollRef, gridScrollRef);

  const matrix = useProjectGrid({
    projects: dataTable.data as Project[],
    refetch: dataTable.refetch,
    columnConfigs: dataTable.columnConfigs,
  });
  const projectActions = useProjectActions(matrix);

  const classes = useMemo(() => {
    return {
      headerWrapper: styles.headerRow,
    };
  }, []);

  const actions = useMemo(() => {
    return {
      custom: {
        left: projectActions,
        hideDivider: true,
      },
    };
  }, [projectActions]);

  const { t } = useTranslation();
  const areas = useMemo(() => {
    const _areas: TableAreas = {
      left: {
        title: t('COMMON.FILTER'),
        content: <ProjectTableQuickFilters />,
        resizable: true,
      },
      center: {
        content: <ProjectRightsGrid scrollRef={gridScrollRef} {...matrix} />,
      },
      right: undefined,
    };

    return _areas;
  }, [matrix, t]);

  const overrides = useMemo(() => {
    return {
      actions,
      areas,
    };
  }, [actions, areas]);

  return (
    <>
      <ProjectBanderoleInfo />
      <EntityTable
        ref={ref}
        layout="table"
        areas={areas}
        actions={actions}
        {...dataTable}
        scrollRef={listScrollRef}
        rowHeightRem="3rem"
        basicClasses={classes}
        {...props}
        overrides={overrides}
      />
    </>
  );
});
