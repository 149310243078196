import styles from './AttachmentsTabPanel.module.scss';

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AttachmentProps,
  Attachments,
} from '@work4all/components/lib/components/attachments/Attachments';
import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { InputArticleAttachmentModify } from '@work4all/models/lib/Classes/InputArticleAttachmentModify.entity';

import { useTableLayoutState } from '../../../../../../../components/data-tables/table-layout';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { Panel } from '../../../../components';
import { useMaskContext } from '../../../../hooks/mask-context';
import { ArticleMaskFormValue } from '../../types';

const VISIBLE_COLUMNS: AttachmentProps['visibleColumns'] = [
  'filesize',
  'type',
  'filename',
];

export function AttachmentsTabPanel() {
  const { t } = useTranslation();
  const [tableLayout] = useTableLayoutState();
  const form = useFormContextPlus<ArticleMaskFormValue>();
  const mask = useMaskContext();
  const attachmentsContext = useContext(TempFileManagerContext);

  return (
    <Panel className={styles.maxHeight}>
      <Attachments
        entity={mask.entity}
        absoluteImage
        tableLayout={tableLayout}
        visibleColumns={VISIBLE_COLUMNS}
        renderItem={(id: string) => {
          const standardImageId = form.watch('standardImageId');

          const attachment = attachmentsContext.fileList.find(
            (x) => x.id.toString() === id
          ) as InputArticleAttachmentModify;

          if (!attachment) return <></>;

          return (
            <div className={styles.wrapper}>
              <Collapse defaultOpen title={t('ATTACHMENTS.OPTIONS')}>
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.DISABLED')}
                  checked={attachment.deactivated}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.deactivated = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.ARTICLE_DEFAULT_IMAGE')}
                  checked={
                    attachment.defaultDocumentToDisplay === undefined
                      ? standardImageId?.toString() === id
                      : attachment.defaultDocumentToDisplay
                  }
                  onChange={(_event: unknown, value: boolean) => {
                    attachmentsContext.fileList.forEach(
                      (file: InputArticleAttachmentModify) => {
                        if (!file) return;
                        file.defaultDocumentToDisplay = false;
                        attachmentsContext.markFilesToUpdate(attachment);
                      }
                    );

                    attachment.defaultDocumentToDisplay = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.SHOW_IMAGE_IN_SHOP')}
                  checked={attachment.showInShop}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.showInShop = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
              </Collapse>
              <Collapse defaultOpen title={t('ATTACHMENTS.ATTACH_TO')}>
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.OFFERS')}
                  checked={attachment.asAttachmentOffer}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.asAttachmentOffer = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.ORDER_CONFIRMATION')}
                  checked={attachment.asAttachmentOrderConfirmation}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.asAttachmentOrderConfirmation = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.DELIVERY_NOTES')}
                  checked={attachment.asAttachmentDeliveryNote}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.asAttachmentDeliveryNote = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.INVOICES_CREDIT_NOTES')}
                  checked={attachment.asAttachmentInvoice}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.asAttachmentInvoice = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.CALCULATIONS')}
                  checked={attachment.asAttachmentCalculation}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.asAttachmentCalculation = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.ORDERS')}
                  checked={attachment.asAttachmentOrder}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.asAttachmentOrder = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
                <CheckboxRadioItem
                  label={t('ATTACHMENTS.REQUIREMENT_NOTIFICATIONS')}
                  checked={attachment.asAttachmentDemand}
                  onChange={(_event: unknown, value: boolean) => {
                    attachment.asAttachmentDemand = value;
                    attachmentsContext.markFilesToUpdate(attachment);
                  }}
                />
              </Collapse>
            </div>
          );
        }}
      />
    </Panel>
  );
}
