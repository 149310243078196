import { Panel } from '../../../../../components';

import { AssignmentContent } from './AssignmentContent';
import { DescriptionContent } from './DescriptionContent';

export const CheckListOverlayControllerGeneralTabContent = () => {
  return (
    <Panel>
      <DescriptionContent />
      <AssignmentContent />
    </Panel>
  );
};
