import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useUsersById = (props: { idList: number[] }) => {
  const req = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
        displayName: null,
        lastName: null,
        firstName: null,
        userKind: null,
      } as User,
      entity: Entities.user,
      filter: [
        {
          id: {
            $in: props.idList,
          },
        },
      ],
      skip: !props.idList?.length,
    };
  }, [props.idList]);

  return useDataProvider<User>(req);
};
