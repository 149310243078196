import { decimalToDuration } from '@work4all/components';

type Props = {
  value: number;
};

export const DurationCell = ({ value }: Props) => {
  return (
    <div style={{ textAlign: 'right' }}>
      {decimalToDuration(value).toFormat('h,mm')}
    </div>
  );
};
