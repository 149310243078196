import i18next from 'i18next';
import { DateTime } from 'luxon';

import { queryEquals, queryIn } from '@work4all/utils';
import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

export const searchToQuery = (value: number | string) => {
  if (typeof value === 'string') {
    return queryEquals(`%${value.trim()}%`);
  } else {
    return queryEquals(`${value}`);
  }
};

export const booleanNumberToQuery = (
  value: { id: number | string; name: string }[]
) => {
  if (value.length === 1) {
    return queryEquals(`${value[0].id ? -1 : 0}`);
  }
};

export const booleanToQuery = (
  value: { id: number | string; name: string }[]
) => {
  if (value.length === 1) {
    return queryEquals(`${value[0].id ? true : false}`);
  }
};

export const relativeDateToDate = (
  startDate: Date | RelativeDateFilter,
  endDate: Date
) => {
  if ((startDate as RelativeDateFilter) in RelativeDateFilter) {
    switch (startDate as RelativeDateFilter) {
      case RelativeDateFilter.currentMonth:
        return {
          startDate: DateTime.now().startOf('month'),
          endDate: DateTime.now().endOf('month'),
        };
      case RelativeDateFilter.lastMonth:
        return {
          startDate: DateTime.now().startOf('month').minus({ month: 1 }),
          endDate: DateTime.now().endOf('month').minus({ month: 1 }),
        };
      case RelativeDateFilter.nextMonth:
        return {
          startDate: DateTime.now().startOf('month').plus({ month: 1 }),
          endDate: DateTime.now().endOf('month').plus({ month: 1 }),
        };
      case RelativeDateFilter.currentWeek:
        return {
          startDate: DateTime.now().startOf('week'),
          endDate: DateTime.now().endOf('week'),
        };
      case RelativeDateFilter.lastWeek:
        return {
          startDate: DateTime.now().startOf('week').minus({ week: 1 }),
          endDate: DateTime.now().endOf('week').minus({ week: 1 }),
        };
      case RelativeDateFilter.nextWeek:
        return {
          startDate: DateTime.now().startOf('week').plus({ week: 1 }),
          endDate: DateTime.now().endOf('week').plus({ week: 1 }),
        };
      case RelativeDateFilter.currentYear:
        return {
          startDate: DateTime.now().startOf('year'),
          endDate: DateTime.now().endOf('year'),
        };
      case RelativeDateFilter.lastYear:
        return {
          startDate: DateTime.now().startOf('year').minus({ year: 1 }),
          endDate: DateTime.now().endOf('year').minus({ year: 1 }),
        };
      case RelativeDateFilter.nextYear:
        return {
          startDate: DateTime.now().startOf('year').plus({ year: 1 }),
          endDate: DateTime.now().endOf('year').plus({ year: 1 }),
        };
      case RelativeDateFilter.today:
        return {
          startDate: DateTime.now().startOf('day'),
          endDate: DateTime.now().endOf('day'),
        };
      case RelativeDateFilter.yesterday:
        return {
          startDate: DateTime.now().startOf('day').minus({ day: 1 }),
          endDate: DateTime.now().endOf('day').minus({ day: 1 }),
        };
      case RelativeDateFilter.tomorrow:
        return {
          startDate: DateTime.now().startOf('day').plus({ day: 1 }),
          endDate: DateTime.now().endOf('day').plus({ day: 1 }),
        };
      case RelativeDateFilter.todayAndPast:
        return {
          endDate: DateTime.now().endOf('day'),
        };
      case RelativeDateFilter.todayAndFuture:
        return {
          startDate: DateTime.now().startOf('day'),
        };
    }
  } else {
    return {
      startDate: !startDate ? null : DateTime.fromJSDate(startDate as Date),
      endDate: !endDate ? null : DateTime.fromJSDate(endDate as Date),
    };
  }
};

export const dateToQuery = (
  startDate: Date | RelativeDateFilter,
  endDate: Date
) => {
  const date = relativeDateToDate(startDate, endDate);

  return {
    $gte: date?.startDate?.toString(),
    $lt: date?.endDate?.toString(),
  };
};

export const numbersToQuery = (from: number | null, to: number | null) => {
  const result = {};
  if (from !== null) {
    result['$gte'] = from;
  }
  if (to !== null) {
    result['$lt'] = to;
  }
  return result;
};

export const pickerToQuery = (values: []) => {
  return queryIn(values.map(({ id }) => id));
};

export const numbersToString = (from: number, to: number): string => {
  if (from != null && to != null) {
    return from + ' - ' + to;
  }
  if (from != null) {
    return i18next.t('COMMON.TIME_FROM') + ' ' + from;
  }
  if (to != null) {
    return i18next.t('COMMON.TIME_UNTIL') + ' ' + to;
  }
};

export const dateToString = (
  startDate: Date | RelativeDateFilter,
  endDate: Date,
  withPrefix = true
): string => {
  if (!startDate && !endDate) {
    return null;
  }
  if ((startDate as RelativeDateFilter) in RelativeDateFilter) {
    const relativeFilterStrings: Record<RelativeDateFilter, string> = {
      [RelativeDateFilter.currentMonth]: i18next.t('COMMON.THIS_MONTH'),
      [RelativeDateFilter.lastMonth]: i18next.t('COMMON.LAST_MONTH'),
      [RelativeDateFilter.nextMonth]: i18next.t('COMMON.NEXT_MONTH'),
      [RelativeDateFilter.currentWeek]: i18next.t('COMMON.THIS_WEEK'),
      [RelativeDateFilter.lastWeek]: i18next.t('COMMON.LAST_WEEK'),
      [RelativeDateFilter.nextWeek]: i18next.t('COMMON.NEXT_WEEK'),
      [RelativeDateFilter.currentYear]: i18next.t('COMMON.THIS_YEAR'),
      [RelativeDateFilter.lastYear]: i18next.t('COMMON.LAST_YEAR'),
      [RelativeDateFilter.nextYear]: i18next.t('COMMON.NEXT_YEAR'),
      [RelativeDateFilter.today]: i18next.t('COMMON.TODAY'),
      [RelativeDateFilter.yesterday]: i18next.t('COMMON.YESTERDAY'),
      [RelativeDateFilter.tomorrow]: i18next.t('COMMON.TOMORROW'),
      [RelativeDateFilter.todayAndPast]: i18next.t('COMMON.TODAY_AND_PAST'),
      [RelativeDateFilter.todayAndFuture]: i18next.t('COMMON.TODAY_AND_FUTURE'),
    };
    return relativeFilterStrings[startDate as RelativeDateFilter];
  }

  return timeSpanFormat(startDate as Date, endDate, withPrefix);
};

export const timeSpanFormat = (
  startDate: Date,
  endDate: Date,
  withPrefix = true
) => {
  if ((startDate === null || startDate === undefined) && endDate !== null) {
    return (
      (withPrefix ? i18next.t('COMMON.TIME_UNTIL') + ' ' : '') +
      DateTime.fromJSDate(endDate).toFormat('d.M.yyyy')
    );
  }

  if (startDate !== null && (endDate === null || endDate === undefined)) {
    return (
      (withPrefix ? i18next.t('COMMON.TIME_FROM') + ' ' : '') +
      DateTime.fromJSDate(startDate).toFormat('d.M.yyyy')
    );
  }

  let startFormatDay = 'dd.';
  let startFormatMonth = 'M.';
  let startFormatYear = 'yyyy';

  if (
    DateTime.fromJSDate(startDate).year === DateTime.fromJSDate(endDate).year
  ) {
    startFormatYear = '';
    if (
      DateTime.fromJSDate(startDate).month ===
      DateTime.fromJSDate(endDate).month
    ) {
      startFormatMonth = '';
      startFormatDay = 'dd';
    }
  }

  if (
    startDate &&
    endDate &&
    DateTime.fromJSDate(startDate).hasSame(DateTime.fromJSDate(endDate), 'day')
  ) {
    return DateTime.fromJSDate(endDate).toFormat('dd.M.yyyy');
  }

  return (
    DateTime.fromJSDate(startDate).toFormat(
      startFormatDay + startFormatMonth + startFormatYear
    ) +
    ' - ' +
    DateTime.fromJSDate(endDate).toFormat('dd.M.yyyy')
  );
};
