import styles from './VisitationReportsPreview.module.scss';

import { Typography } from '@mui/material';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation, useInaccessibleFields } from '@work4all/data';

import { TalkingPoint } from '@work4all/models/lib/Classes/TalkingPoint.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  BasicTable,
  IBasicTableProps,
  TableStateBagProvider,
} from '../../../dataDisplay/basic-table';
import { MIME_TYPES, Preview } from '../../../preview/Preview';
import { IUserIconRegisterContext } from '../../user-icon/types';
import {
  InfoCard,
  InfoCards,
  PreviewEditMessage,
  PreviewWrapper,
} from '../components';
import {
  CustomerPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';

import { visitationReportPreviewFields } from './VisitationReportPreviewContainer';

export interface IVisitationReportPreviewProps
  extends EntityPreviewProps<VisitationReport> {
  userIconContext: IUserIconRegisterContext;
  noPreviewUrl?: string;
}

export const VisitationReportPreview = (
  props: IVisitationReportPreviewProps
) => {
  const { entries, noPreviewUrl, ...handlers } = props;
  const { t } = useTranslation();

  const { isInaccessible } = useInaccessibleFields();

  const fileInfosIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'fileInfos'
  );

  const attendeeListIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'attendeeList'
  );
  const talkingPointListIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'talkingPointList'
  );

  const [detailInFullsceen, setFullscreen] = useState(false);

  const prepareRowDisplayModifiers = (value) => {
    return { isBold: (value as TalkingPoint).kind === 1 };
  };

  const columns = useMemo(() => {
    const columns: IBasicTableProps['columns'] = [
      {
        Header: t('COMMON.NUMBER_SHORT'),
        accessor: 'pozNr',
        width: 60,
      },
      {
        Header: t('COMMON.TOPIC'),
        accessor: 'thema',
        width: 220,
      },
      {
        Header: t('COMMON.CONTENTS'),
        accessor: 'erledigung',
        width: 260,
      },
    ];

    return columns;
  }, [t]);

  const [mutate] = useDataMutation<VisitationReport, EMode.upsert>({
    entity: Entities.visitationReport,
    mutationType: EMode.upsert,
    responseData: visitationReportPreviewFields as unknown as VisitationReport,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.visitationReport,
    entries: entries,
    mutate,
  });
  const { isMultiselect, onPopoverClose, onEdit } = entityPreview;

  const visitationReport = entries[0];
  const sortedList = useMemo(() => {
    return [...visitationReport.talkingPointList].sort(
      (a, b) => a.nummer - b.nummer
    );
  }, [visitationReport.talkingPointList]);

  const renderTitle = (withFullscreen?: boolean) => {
    return (
      <PreviewTitleInput
        accessor="topic"
        {...handlers}
        onFullscreenClick={
          withFullscreen
            ? () => {
                setFullscreen(!detailInFullsceen);
              }
            : undefined
        }
      />
    );
  };

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.visitationReport}
      entries={entries}
    >
      <PreviewWrapper>
        {visitationReport.fileInfos.fileServiceProviderInfos ? (
          <>
            {renderTitle(true)}
            {isMultiselect && (
              <PreviewEditMessage entityId={String(visitationReport.id)} />
            )}

            {!isMultiselect && fileInfosIsAccessible ? (
              <Preview
                onFullscreenClose={() => setFullscreen(false)}
                openInFullscreen={detailInFullsceen}
                url={`${visitationReport.fileInfos?.previewUrl}`}
                mimeType={
                  visitationReport.fileInfos?.previewMimeType as MIME_TYPES
                }
                fileName={`${visitationReport.fileInfos?.fileServiceProviderInfos?.fileName}`}
                noPreview={i18next.t('MASK.NO_PREVIEW_FOR', {
                  type: MIME_TYPES,
                })}
                noPreviewUrl={noPreviewUrl}
              />
            ) : null}
          </>
        ) : (
          <>
            {renderTitle()}
            {isMultiselect && (
              <PreviewEditMessage entityId={String(visitationReport.id)} />
            )}
            <InfoCards columns={2}>
              <UserPreviewPickerInput
                labelTranslationKey="COMMON.EMPLOYEE"
                onClose={onPopoverClose}
                value={visitationReport?.user}
                onChange={(value) => {
                  onEdit({
                    userId: value.id,
                  });
                }}
                accessor="user"
              />

              <DatePreviewPickerInput
                labelTranslationKey="COMMON.DATE"
                onClose={onPopoverClose}
                value={visitationReport?.creationDate}
                onChange={(value) => {
                  onEdit({ creationDate: new Date(value).toISOString() });
                }}
                accessor="creationDate"
                dateFormat={DateTime.DATE_SHORT}
              />

              <CustomerPreviewPickerInput
                labelTranslationKey="COMMON.CUSTOMER"
                onClose={onPopoverClose}
                value={visitationReport?.businessPartner?.data}
                onChange={(value) => {
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                    businessPartnerId: value ? value?.id : 0,
                    businessPartnerType: SdObjType.KUNDE,
                    contactId: value?.mainContact?.id ?? null,
                  });
                }}
                accessor="businessPartner"
                showFooter
              />

              <ProjectPreviewPickerInput
                labelTranslationKey="COMMON.PROJECT"
                onClose={onPopoverClose}
                value={visitationReport?.project}
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value && !Array.isArray(value) ? value.id : 0,
                  });
                }}
                accessor="project"
                showFooter
              />
            </InfoCards>

            {attendeeListIsAccessible && (
              <InfoCards columns={1}>
                <InfoCard
                  staticField={true}
                  truncate
                  label={t('COMMON.PARTICIPANT')}
                >
                  {visitationReport.attendeeList.map((attendee) => {
                    return (
                      <div>
                        <Typography component="span">
                          {attendee.name}

                          {attendee.businessPartnerId !== 0 ? (
                            <Typography component="span" color="secondary">
                              {' '}
                              | {attendee.contact?.businessPartner?.data?.name}
                            </Typography>
                          ) : null}
                        </Typography>
                      </div>
                    );
                  })}
                </InfoCard>
              </InfoCards>
            )}

            {!isMultiselect && talkingPointListIsAccessible ? (
              <div style={{ display: 'grid', height: '100%' }}>
                {/* Add new empty context so that this table does not overwrite the context in ListPage. */}
                <TableStateBagProvider tableInstance={null}>
                  <BasicTable
                    className={styles.transparentTable}
                    mode="client"
                    prepareRowDisplayModifiers={prepareRowDisplayModifiers}
                    reordableColumns={true}
                    resizableColumns={true}
                    data={sortedList}
                    columns={columns}
                    allItemsCount={
                      visitationReport.talkingPointList.length || 0
                    }
                    cardsView={false}
                    selectableMultiple={false}
                  />
                </TableStateBagProvider>
              </div>
            ) : null}
          </>
        )}
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
};
