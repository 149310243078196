import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '../types';

import { EmailSignaturePreview } from './EmailSignaturePreview';

interface EmailSignaturePreviewContainerProps
  extends EntityPreviewContainerProps<string> {}

export const EMAIL_SIGNATURE_PREVIEW_DATA: EMailSignature<EMode.query> = {
  id: null,
  name: null,
  body: null,
};

export function EmailSignaturePreviewContainer(
  props: EmailSignaturePreviewContainerProps
) {
  const { ids, onCloseClick, onEditClicked, onVisibilityToggle } = props;
  const emailSignatureId = ids[0];

  const emailSignatures = useEntityPreviewData<EMailSignature>(
    [emailSignatureId],
    Entities.eMailSignature,
    EMAIL_SIGNATURE_PREVIEW_DATA,
    undefined,
    EMAIL_SIGNATURE_KEYS_ARGUMENTS
  );

  if (!emailSignatures?.[0]) {
    return null;
  }

  return (
    <EmailSignaturePreview
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      emailSignature={emailSignatures[0]}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
