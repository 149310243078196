import styles from './StatisticPage.module.scss';

import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import { useModuleRights, useUser } from '@work4all/data';

import { NoAccess } from '../NoAccess';

import { StatisticCustomerSheetContainer } from './components/StatisticCustomerSheetContainer';
import { StatisticDashboardContainer } from './components/StatisticDashboardContainer';
import { StatisticSalesVolumeByCostCenterContainer } from './components/StatisticSalesVolumeByCostCenterContainer';
import { StatisticSalesVolumeByCustomerContainer } from './components/StatisticSalesVolumeByCustomerContainer';
import { StatisticSalesVolumeByItemContainer } from './components/StatisticSalesVolumeByItemContainer';
import { StatisticSalesVolumeBySupplierContainer } from './components/StatisticSalesVolumeBySupplierContainer';
import { StatisticSalesVolumeYearContainer } from './components/StatisticSalesVolumeYearContainer';
import { StatisticSupplierSheetContainer } from './components/StatisticSupplierSheetContainer';
import { StatisticTimeCardContainer } from './components/StatisticTimeCardContainer';
import { TopBar } from './components/top-bar/TopBar';
import { StatisticType } from './data/StatisticDefinitions';

export function StatisticPage() {
  const { statisticType } = useParams<{
    statisticType: StatisticType;
  }>();

  const user = useUser();
  const { rights } = useModuleRights();

  useEffect(() => {
    sendAmplitudeData(EventType.OpenStatistic, {
      name: statisticType,
    });
  });

  const statisticComp = useMemo(() => {
    if (
      isTimeTrackingUser(rights) &&
      statisticType !== StatisticType.timecard
    ) {
      return <NoAccess reason="route" />;
    }

    switch (statisticType) {
      case StatisticType.salesvolumeyear:
        return <StatisticSalesVolumeYearContainer />;
      case StatisticType.salesvolumebycustomer:
        return <StatisticSalesVolumeByCustomerContainer />;
      case StatisticType.salesvolumebycostcenter:
        return <StatisticSalesVolumeByCostCenterContainer />;
      case StatisticType.salesvolumebysupplier:
        return <StatisticSalesVolumeBySupplierContainer />;
      case StatisticType.dashboard:
        return <StatisticDashboardContainer />;
      case StatisticType.timecard:
        return <StatisticTimeCardContainer />;
      case StatisticType.customersheet:
        return <StatisticCustomerSheetContainer />;
      case StatisticType.suppliersheet:
        return <StatisticSupplierSheetContainer />;
      case StatisticType.salesvolumebyitem:
        return <StatisticSalesVolumeByItemContainer />;
      default:
        return null;
    }
  }, [statisticType, user]);

  return (
    <div className={styles.root}>
      <TopBar
        showSettingdeliveryPeriod={
          statisticType === StatisticType.salesvolumeyear ||
          statisticType === StatisticType.salesvolumebycustomer ||
          statisticType === StatisticType.salesvolumebycostcenter ||
          statisticType === StatisticType.dashboard
        }
      />
      <div className={styles.wrap}>
        <div className={styles.tableWrapper}>{statisticComp}</div>
      </div>
    </div>
  );
}
