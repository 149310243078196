import styles from './organisation-area-groups-overlay.module.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { MaskContextProvider } from '../../../containers/mask-overlays/mask-overlay/hooks/mask-context';
import { useOrganisationAreaContext } from '../hooks';

import { OrganisationAreaGroupsPage } from './organisation-area-groups-page';

export const OrganisationAreaGroupsOverlay = () => {
  const dialogs = useDialogs();

  const { isEditOverlayOpen, setEditOverlayOpen } =
    useOrganisationAreaContext();

  const { t } = useTranslation();

  const [isDirty, setIsDirty] = useState(false);

  if (!isEditOverlayOpen) return null;

  const handleClose = async () => {
    if (isDirty) {
      const confirmed = await dialogs.confirm({
        title: t('ALERTS.CONFIRM'),
        description: t('ALERTS.ABORT_MESSAGE'),
        confirmLabel: t('ALERTS.BTN_OK'),
        cancelLabel: t('ALERTS.BTN_ABORT'),
      });

      if (!confirmed) {
        return;
      }
    }
    setIsDirty(false);
    setEditOverlayOpen(false);
  };

  return (
    <MaskContextProvider
      value={{
        entity: undefined,
        isCreateMode: false,
        isEditMode: false,
        mode: 'edit',
        params: null,
        wip: false,
      }}
    >
      <NavigationOverlay
        open={isEditOverlayOpen}
        initialView={{
          title: t('COMMON.FILTERS'),
          view: (
            <OrganisationAreaGroupsPage onModify={() => setIsDirty(true)} />
          ),
        }}
        close={handleClose}
        classes={{ wrapper: styles.wrapper }}
      />
    </MaskContextProvider>
  );
};
