import { Portal } from '@mui/base';
import { Search } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  ClickAwayListener,
  Popover,
  Stack,
  Tab,
  TextField,
} from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { styled, useTheme } from '@mui/material/styles';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { configure, HotKeys } from 'react-hotkeys';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

import { useSearchKeyHandlers } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileData } from '@work4all/models/lib/File';

import {
  SearchObjectType,
  useGlobalSearchQuery,
} from '../../hooks/use-global-search-query';
import { EventType, sendAmplitudeData } from '../../utils/amplitude/amplitude';

import { ResultList } from './ResultList';

interface Props {
  onElementSelected?: (data: FileData, ent: Entities) => void;
  pathMapping: Partial<Record<Entities, string>>;
  lastUsedItemsMap?: Partial<Record<Entities, FileData[]>>;
  footerMap?: Partial<Record<Entities, React.ReactNode>>;
  defaultEntity?: Entities | SpecialSearch;
}
export const GlobalSearchField: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState(false);
  const ref = useRef();

  useHotkeys('ctrl+k, cmd+k', (e) => {
    e.preventDefault();
    setShowPopover(true);
  });

  useEffect(() => {
    sendAmplitudeData(EventType.Search, {
      entryPoint: 'NavigationBar',
    });
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setShowPopover(false)}>
      <div>
        <SearchField
          ref={ref}
          onClick={() => {
            setShowPopover(true);
          }}
          onBeforeInput={(e) => {
            e.preventDefault();
            setShowPopover(true);
          }}
          variant="outlined"
          InputProps={{
            startAdornment: <Search />,
          }}
          inputProps={{}}
          sx={{ width: '12.5rem' }}
          placeholder={t('PICKER.SEARCH.DEFAULT')}
        />
        <Portal>
          {showPopover ? (
            <SearchPopover
              anchorEl={ref}
              {...props}
              onClose={() => setShowPopover(false)}
            />
          ) : null}
        </Portal>
      </div>
    </ClickAwayListener>
  );
};

export enum SpecialSearch {
  'ALL' = 'all',
}
const SearchPopover: React.FC<
  Props & { anchorEl: MutableRefObject<undefined>; onClose: () => void }
> = ({
  onElementSelected,
  pathMapping,
  defaultEntity = SpecialSearch.ALL,
  onClose,
  anchorEl,
  footerMap = {},
  lastUsedItemsMap = {},
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [activeEntity, setActiveEntity] = useState<Entities | SpecialSearch>(
    defaultEntity
  );
  const types = useMemo<SearchObjectType[]>(() => {
    switch (activeEntity) {
      case Entities.customer:
        return ['CUSTOMER', 'CONTACTS'];
      case Entities.supplier:
        return ['SUPPLIER', 'CONTACTS'];
      case Entities.project:
        return ['PROJECT'];
      default:
        return ['CUSTOMER', 'SUPPLIER', 'PROJECT', 'CONTACTS'];
    }
  }, [activeEntity]);

  const { data, onChange, inputValue } = useGlobalSearchQuery({
    types,
  });

  useEffect(() => {
    setActiveEntity(defaultEntity);
  }, [defaultEntity]);

  const onSelected = useCallback(
    (data, entity) => {
      onClose();
      onChange('');
      onElementSelected?.(data, entity);
    },
    [onChange, onClose, onElementSelected]
  );

  const reslutsArray = useMemo(() => {
    const isSearchResult = inputValue.length !== 0;
    const customerLastItems = lastUsedItemsMap[Entities.customer]
      ? lastUsedItemsMap[Entities.customer]
      : [];
    const supplierLastItems = lastUsedItemsMap[Entities.supplier]
      ? lastUsedItemsMap[Entities.supplier]
      : [];
    const projectLastItems = lastUsedItemsMap[Entities.project]
      ? lastUsedItemsMap[Entities.project]
      : [];

    return (
      (activeEntity === 'all' &&
        (isSearchResult
          ? [...data.search]
          : [
              ...customerLastItems,
              ...supplierLastItems,
              ...projectLastItems,
            ])) ||
      (activeEntity === 'customer' &&
        (isSearchResult ? data : customerLastItems)) ||
      (activeEntity === 'supplier' &&
        (isSearchResult ? data : supplierLastItems)) ||
      (activeEntity === 'project' && (isSearchResult ? data : projectLastItems))
    );
  }, [inputValue.length, lastUsedItemsMap, activeEntity, data]);

  configure({
    ignoreTags: [],
    ignoreRepeatedEventsWhenKeyHeldDown: false,
    customKeyCodes: {
      13: 'NAVIGATE_TO',
    },
  });

  const { setKeyboardLocation, keyMap, keyHandlers } =
    useSearchKeyHandlers(reslutsArray);

  useEffect(() => {
    setKeyboardLocation(0);
  }, [activeEntity]);

  return (
    <HotKeys allowChanges keyMap={keyMap} handlers={keyHandlers}>
      <Popover
        open={true}
        anchorEl={anchorEl.current}
        onClose={onClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '100vw',
            maxWidth: '37.5rem',
            overflow: 'hidden',
          }}
        >
          <TabContext value={activeEntity}>
            <Stack
              direction={'column'}
              alignItems="stretch"
              justifyContent={'stretch'}
            >
              <SearchField
                autoComplete="off"
                onChange={(e) => {
                  onChange(e.currentTarget.value);

                  //scroll to the top
                  document
                    .getElementById(reslutsArray[0]?.id.toString())
                    ?.scrollIntoView();
                  setKeyboardLocation(0);
                }}
                autoFocus={true}
                sx={{
                  borderBottom: `1px solid ${theme.vars.palette.widgetBorder}`,
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: <Search sx={{ color: 'black' }} />,
                }}
                inputProps={{
                  sx: {
                    color: `${theme.vars.palette.text.primary} !important`,
                    '&::placeholder': {
                      color: `${theme.vars.palette.text.primary} !important`,
                    },
                  },
                }}
                fullWidth={true}
                placeholder={t('PICKER.SEARCH.DEFAULT')}
              />
              <TabContext value={activeEntity}>
                <TabList
                  sx={{ padding: '.5rem' }}
                  onChange={(e, val) => setActiveEntity(val)}
                >
                  <Tab label={t('COMMON.ALL')} value={SpecialSearch.ALL} />
                  <Tab label={t('COMMON.CUSTOMER')} value={Entities.customer} />
                  <Tab label={t('COMMON.SUPPLIER')} value={Entities.supplier} />
                  <Tab label={t('COMMON.PROJECT')} value={Entities.project} />
                </TabList>
              </TabContext>
            </Stack>
            <Stack direction={'column'}>
              <Box
                sx={{
                  maxHeight: '70vh',
                  width: '100%',
                  overflow: 'hidden scroll',
                }}
              >
                <TabPanel sx={{ padding: '1rem 0' }} value={SpecialSearch.ALL}>
                  <ResultList
                    searchData={data.search}
                    showTitles
                    showDefaultResults={!inputValue}
                    pathMapping={pathMapping}
                    onSelected={onSelected}
                    defaultCustomer={lastUsedItemsMap[Entities.customer]}
                    defaultProject={lastUsedItemsMap[Entities.project]}
                    defaultSupplier={lastUsedItemsMap[Entities.supplier]}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: '1rem 0' }} value={Entities.customer}>
                  <ResultList
                    searchData={data.search}
                    showDefaultResults={!inputValue}
                    pathMapping={pathMapping}
                    onSelected={onSelected}
                    defaultCustomer={lastUsedItemsMap[Entities.customer]}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: '1rem 0' }} value={Entities.supplier}>
                  <ResultList
                    searchData={data.search}
                    showDefaultResults={!inputValue}
                    pathMapping={pathMapping}
                    onSelected={onSelected}
                    defaultSupplier={lastUsedItemsMap[Entities.supplier]}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: '1rem 0' }} value={Entities.project}>
                  <ResultList
                    searchData={data.search}
                    showDefaultResults={!inputValue}
                    pathMapping={pathMapping}
                    onSelected={onSelected}
                    defaultProject={lastUsedItemsMap[Entities.project]}
                  />
                </TabPanel>
              </Box>
              {footerMap[activeEntity] && (
                <Box
                  sx={{
                    borderTop: `1px solid ${theme.vars.palette.widgetBorder}`,
                  }}
                >
                  {footerMap[activeEntity]}
                </Box>
              )}
            </Stack>
          </TabContext>
        </Box>
      </Popover>
    </HotKeys>
  );
};
const SearchField = styled(TextField)((props) => {
  return {
    [`.${inputBaseClasses.root}`]: {
      backgroundColor: 'rgba(238, 238, 238, 0.5)',
      borderRadius: '0.5rem',
    },
    [`.${inputBaseClasses.adornedStart}`]: {
      color: props.theme.vars.palette.ui1.main,
    },
    [`.${outlinedInputClasses.input}`]: {
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      height: '1.75rem',
      color: props.theme.vars.palette.ui1.main,
      '&::placeholder': {
        color: props.theme.vars.palette.ui1.main,
        opacity: 1,
      },
    },
    [`.${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
  };
});
