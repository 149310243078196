import {
  Tooltip as TooltipBase,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface Truncation {
  onTruncation?: (isTruncated: boolean) => void;
}

interface TooltipProps extends Truncation, Omit<MuiTooltipProps, 'children'> {
  activateForDisabled?:
    | boolean
    | {
        activeateOnClick?: boolean;
      };
  hideOnClickIfUnhoverd?: boolean;
  hide?: boolean;
  showOnlyOnOverflow?: boolean;
  children: React.ReactElement<{ onClick?: React.MouseEventHandler }>;
}

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const {
    title = '',
    children,
    activateForDisabled: paramActiveForDisabled,
    enterDelay = 0,
    placement,
    hideOnClickIfUnhoverd,
    hide = false,
    disableHoverListener,
    classes,
    disableInteractive = false,
    showOnlyOnOverflow = false,
    onTruncation,
  } = props;

  const activateForDisabled = !!paramActiveForDisabled;
  const activeateOnClick =
    typeof paramActiveForDisabled === 'object' &&
    paramActiveForDisabled.activeateOnClick;

  const [showTooltip, setShowTooltip] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const childRef = useRef<HTMLDivElement>(null);

  const isElementTruncated = useCallback((element: HTMLElement): boolean => {
    const hasEllipsis =
      element.scrollWidth > element.clientWidth ||
      element.scrollHeight > element.clientHeight;

    if (hasEllipsis) return true;

    return false;
  }, []);

  useEffect(() => {
    if (showOnlyOnOverflow && childRef.current) {
      setIsTruncated(isElementTruncated(childRef.current));
      if (onTruncation) {
        onTruncation(isElementTruncated(childRef.current));
      }
    }
  }, [children, showOnlyOnOverflow, isElementTruncated, onTruncation]);

  const hideOnClickIfUnhoverdHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setTimeout(() => {
        if (!(e.target as Element).matches(':hover')) {
          setShowTooltip(false);
        }
      }, 100);
    },
    []
  );

  const child = useMemo(() => {
    if (React.isValidElement(children)) {
      const childProps = {
        ref: childRef,
        onClick: (e: React.MouseEvent) => {
          if (activateForDisabled && activeateOnClick) {
            if (!disableHoverListener) setShowTooltip((active) => !active);
          }

          if (children.props.onClick) {
            children.props.onClick(e);
          }
        },
      };
      return React.cloneElement(children, childProps);
    }
    return children;
  }, [children, activateForDisabled, activeateOnClick, disableHoverListener]);

  const shouldShowTooltip = useMemo(
    () => !showOnlyOnOverflow || (showOnlyOnOverflow && isTruncated),
    [showOnlyOnOverflow, isTruncated]
  );
  const tooltipTitle = shouldShowTooltip ? title : '';

  const [hoverTimeout, setHoverTimeout] = useState<number | null>(null);
  const handleMouseEnter = () => {
    const timeout = window.setTimeout(
      () => setShowTooltip(true),
      enterDelay ?? 0
    );
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setShowTooltip(false);
  };

  const mouseHoverHandler = !disableHoverListener
    ? {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }
    : undefined;

  if (hide && !shouldShowTooltip) return child;

  if (activeateOnClick || hideOnClickIfUnhoverd) {
    const hideOnClickIfUnhoverdConfig = hideOnClickIfUnhoverd
      ? {
          onClick: hideOnClickIfUnhoverdHandler,
          ...mouseHoverHandler,
        }
      : undefined;

    //managed
    return (
      <TooltipBase
        key={'managed'}
        title={tooltipTitle}
        enterDelay={enterDelay}
        arrow
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        placement={placement}
        {...hideOnClickIfUnhoverdConfig}
        disableHoverListener={disableHoverListener}
        classes={classes}
      >
        {child}
      </TooltipBase>
    );
  }
  //auto
  return (
    <TooltipBase
      key={'auto'}
      title={tooltipTitle}
      enterDelay={enterDelay}
      placement={placement}
      open={showTooltip}
      {...mouseHoverHandler}
      disableInteractive={disableInteractive}
      disableHoverListener={disableHoverListener}
      classes={classes}
    >
      {child}
    </TooltipBase>
  );
};
