import { useCallback } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import type { IsEditableFunction } from '../types';

import { useOrganisationAreaContext } from './use-organisation-area-context';

// Todo: add next when available
const editableSections = ['groups'];
const editableEntities = [
  Entities.project,
  Entities.customer,
  Entities.supplier,
];

export const useOrganisationAreaSectionEditability = () => {
  const { entity } = useOrganisationAreaContext();

  const isEditable = useCallback<IsEditableFunction>(
    (section) => {
      return (
        editableSections.includes(section) && editableEntities.includes(entity)
      );
    },
    [entity]
  );

  return { isEditable };
};
