import styles from '../row-cell-styles.module.scss';

import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DateTimeCustom,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { Tooltip } from '../../../../../../components/tooltip/Tooltip';
import {
  ICardWidgetCellModifiersConfig,
  ICardWidgetColumnAlignment,
  ICardWidgetColumnType,
} from '../../../../types';

export type ICellProps = {
  className?: string;
  style?: React.CSSProperties;
  field: string;
  primary?: boolean;
  align?: ICardWidgetColumnAlignment;
  modifiers?: ICardWidgetCellModifiersConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  type: ICardWidgetColumnType | string;
  children?: React.ReactNode;
};

export const Cell = (props: ICellProps) => {
  const {
    className,
    style,
    field,
    type,
    primary = false,
    align = 'left',
    modifiers = {},
    value,
    children,
  } = props;

  const { t } = useTranslation();

  const formattedValue = formatValue(value, type);

  const renderChildren = () => {
    if (children != null) {
      return children;
    }

    const tooltip = primary
      ? formattedValue
      : t([`FIELDS.values.${field}.${value}`, `FIELDS.${field}`]);

    return (
      <Tooltip
        placement="right"
        enterDelay={1000}
        enterNextDelay={100}
        title={<Box whiteSpace="pre-line">{tooltip}</Box>}
        showOnlyOnOverflow={tooltip === formattedValue}
      >
        <div className={styles['cell-content']}>
          {formattedValue?.split('\n')?.[0]}
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      className={clsx(
        className,
        styles.cell,
        styles[align],
        styles[primary ? 'primary' : 'secondary'],
        {
          [styles.uppercase]: modifiers.uppercase,
        }
      )}
      style={style}
    >
      {renderChildren()}
    </div>
  );
};

function formatValue(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  type: ICardWidgetColumnType | string
): string | undefined {
  switch (type) {
    case 'date':
      return formatDateString(value, DateTimeCustom.DATE_SIMPLE_2YEAR);
    default:
      return value?.toString();
  }
}
